<template>
  <q-card>
    <q-card-section class="row items-center">
      <q-btn icon="navigate_before" @click="$emit('finished')" round dense size="sm" class="q-mr-md"></q-btn>
      <span style="font-size: 20px">Drilldown list</span>

    </q-card-section>


    <sl-list-init :space-id="lDrilldownList.spaceId"
                  :list-id="lDrilldownList.listId"
                  :view-id="lDrilldownList.viewId"
                  :filter="filter"
                  :upperListData="{list: currentList, view: currentView, item: this.currentCell.item}"
                  :key="lDrilldownList.listId"
    />
  </q-card>
</template>

<script>
// import SlListInit  from "../components/sl-list-init"

export default {
  name: "sl-list-drilldown-list",
  // components: {"SlListInit": SlListInit},
  props: ["drilldownList", "currentList", "currentView", "currentCell"],
  data: () => {
    return {
      lDrilldownList: {},
      filter: []
    }
  },

  created() {
    this.lDrilldownList = this.drilldownList
    let item = this.currentCell.item
    this.lDrilldownList.fields.forEach(el => {
      if(el.id === "id"){
        this.filter.push({fieldId: el.ddlId, val: item.id})
      }
      else{
        item.data.fieldsS[el.id] ? this.filter.push({fieldId: el.ddlId, val: item.data.fieldsS[el.id]}) : this.filter.push({fieldId: el.ddlId, val: item.data.fields[el.id]})
      }
    })
  }
}

</script>

<style scoped>

</style>