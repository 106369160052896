<template>
  <q-card>
    <q-card-section>
      <q-input v-model="phone" label="phone" color="green" disable/>
      <q-input v-model="code" label="code" color="green" v-if="confirmationResult!==null"/>
      <div id="recaptcha-container" v-if="confirmationResult===null"></div>
    </q-card-section>
    <q-card-actions>
      <q-btn label="Cancel" @click="$emit('closed')"/>
      <q-btn label="Send code" @click="send()" v-if="confirmationResult===null"/>
      <q-btn label="Verify code" @click="verify()" v-if="confirmationResult!==null"/>
    </q-card-actions>
  </q-card>
</template>

<script>
import {getAuth, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth"
import {currentUserId, db} from "@/db";

export default {
  name: "sl-user-auth-phone-form",
  props: ["phoneNumber"],
  setup() {
    return {
      auth: getAuth()
    }
  },
  data: () => {
    return {
      currentUserId: currentUserId,
      phone: "",
      code: "",
      recaptchaVerifier: "",
      confirmationResult: null,
    }
  },
  mounted() {
    this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, this.auth)
    this.phone = this.currentUserId
  },
  methods: {
    send() {
      let that = this
      signInWithPhoneNumber(that.auth, that.phone, that.recaptchaVerifier)
        .then((confirmationResult) => {
          that.confirmationResult = confirmationResult
        }).catch((error) => {
          console.log(error)
        })
    },
    verify() {
      let that = this
      this.confirmationResult.confirm(this.code).then(() => {
        db.params.get("currentUser").then((currentUser) => {
          if(currentUser?.userId === "" || !currentUser){
            debugger
            db.params.put({id: "currentUser", userId: that.auth.currentUser.phoneNumber, idType: "phoneNumber"})
          }
        })
        this.$emit('finished')
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
</style>