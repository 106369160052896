<template>
  <div>
    <div v-if="$q.platform.is.mobile">
      <sl-list-items
        :currentCell="currentCell"
        :list="list"
        :items="pItems"
        :fields="lView._data.fields"
        :view="lView"
        :upperGroupFields="''"
        :upperGroupFieldsS="''"
        :groupFieldId="''"
        :gradTotalDisplay="lView._data.grandTotal"
        :upperListDataForDrilldown="upperListDataForDrilldown"
        :level="0"
        @cellClick="cellClick($event)"
        @cellDblclick="cellDblclick($event)"
        @cellBlur="cellBlur($event)"
        @cellContextmenu="cellContextmenu($event)"
        @cellMousedown="cellMousedown($event)"
        @checkboxUpdated="checkboxUpdated($event)"
        @drilldownListSelected="drilldownListSelected($event)"
        @groupFieldsToAddonReady="$emit('groupFieldsToAddonReady', $event)"
      />
    </div>

    <table v-else class="sl-table" :style="tableStyle">
      <thead>
      <tr :class="{'tr-head-s1': view._data.hBorders, 'tr-head-s2': view._data.vBorders}">
        <th :style="view._data.fields[fieldId].headCellStyle" v-for="fieldId in view._data.fieldsOrder" :key="fieldId"
            :id="fieldId" @contextmenu="columnContextMenuOpen(fieldId)"
            @dragover="$event.preventDefault()"
            @drop="columnDragDrop($event)"
        >
        <span :style="view._data.fields[fieldId].headNameStyle" :draggable="true" @dragstart="columnDragStart($event)">
          {{ list.data.fields[fieldId] ? list.data.fields[fieldId].name : "Not exists" }}
        </span>
          <br/>
          <span style="color: lightgrey; font-weight: normal; font-size: 14px" v-if="fieldId!=='z3'">{{
              fieldId
            }}</span>
          <q-icon name="filter_alt" style="height: 10px; width: 10px" v-if="view._data.fields[fieldId].filter"
                  color="blue"/>
          <q-icon name="arrow_upward" style="height: 10px; width: 10px" v-if="view._data.fields[fieldId].sort==='desc'"
                  color="blue"/>
          <q-icon name="arrow_downward" style="height: 10px; width: 10px" v-if="view._data.fields[fieldId].sort==='asc'"
                  color="blue"/>
          <span style="font-size: 12px; color: #2196f3; font-weight: normal; margin: 5px"
                v-if="view._data.fields[fieldId].calc">{{ view._data.fields[fieldId].calc }}</span>
          <q-btn icon="add" round size="xs" dense @click="fieldAdd()" v-if="view._data.fields[fieldId].id === 'z3'"/>
        </th>
      </tr>
      </thead>
      <tbody>
      <sl-list-items
        :currentCell="currentCell"
        :list="list"
        :items="pItems"
        :fields="lView._data.fields"
        :view="lView"
        :upperGroupFields="''"
        :upperGroupFieldsS="''"
        :groupFieldId="''"
        :gradTotalDisplay="lView._data.grandTotal"
        :upperListDataForDrilldown="upperListDataForDrilldown"
        :level="0"
        @cellClick="cellClick($event)"
        @cellDblclick="cellDblclick($event)"
        @cellBlur="cellBlur($event)"
        @cellContextmenu="cellContextmenu($event)"
        @cellMousedown="cellMousedown($event)"
        @checkboxUpdated="checkboxUpdated($event)"
        @drilldownListSelected="drilldownListSelected($event)"
        @groupFieldsToAddonReady="$emit('groupFieldsToAddonReady', $event)"
      />
      <sl-list-cell-context-menu :list="list" @contextmenuSelected="contextmenuSelected($event)"/>
      </tbody>
    </table>

    <q-dialog v-model="fieldNameFormShow">
      <q-card>
        <q-card-section>
          <q-input v-model="currentColumn.fieldName" autofocus label="Field name" color="green"/>
        </q-card-section>
        <q-card-actions>
          <q-space/>
          <q-btn flat @click="fieldNameFormShow=false">cancel</q-btn>
          <q-btn flat @click="fieldNameFormSave()">save</q-btn>
          <q-space/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="fieldNameDelConformFormShow">
      <q-card>
        <q-card-section>
          Are you sure you want to delete field?
        </q-card-section>
        <q-card-actions>
          <q-space/>
          <q-btn flat @click="fieldNameDelConformFormShow=false">cancel</q-btn>
          <q-btn flat @click="fieldNameFormDelete()">delete</q-btn>
          <q-space/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="sublistFormShow">
      <q-card v-if="sublistFormShow" style="width: 300px">
        <q-card-section class="row justify-center" style="cursor: pointer">
          <div v-for="(subItem, i) in currentCell.sublist" :key="i"
               style="display: inline; padding: 5px"
          >
            <span v-if="!subItem.avatarSrc" :style="subItem.cellStringStyle"
                  @click="subItemSelect(subItem.id, subItem.value)">
              {{ subItem.value }}

            </span>
            <q-chip v-else color="white" _dense style="box-shadow: 0 0 8px lightgrey" clickable
                    @click="subItemSelect(subItem.id, subItem.value)">
              <q-avatar>
                <img :src="subItem.avatarSrc">
              </q-avatar>
              {{ subItem.value }}

            </q-chip>
            <q-badge color="green" rounded
                     v-if="subItems.findIndex(el=>el===subItem.id)>=0 ||subItems.findIndex(el=>value===subItem.value)>=0"
            />

          </div>

        </q-card-section>
        <q-card-actions class="justify-center">
          <q-btn label="Clear" @click="subItemClear()"/>
          <q-btn label="Cancel" @click="sublistFormShow=false"/>
          <q-btn label="Select" @click="subItemsSelect()" v-if="list.data.fields[currentCell.fieldId].isMultipleInput"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dateFormShow">
      <q-card>
        <q-date v-model="currentCell.value" color="green-4"
                first-day-of-week="1"
                @update:model-value="currentCellSave('datePicker')"
        />
      </q-card>
    </q-dialog>
    <q-dialog v-model="timeFormShow">
      <q-card>
        <q-time v-model="currentCell.value" color="green-4" format24h
                @update:model-value="currentCellSave('timePicker')"/>
      </q-card>
    </q-dialog>

    <q-dialog v-model="macrosFormShow">
      <q-card>
        <q-card-section>
          <q-input v-model="macrosCurrentVal" type="textarea" label="macros formula"/>
        </q-card-section>
        <q-card-actions>
          <q-btn flat @click="macrosFormShow=false">Cancel</q-btn>
          <q-btn flat @click="macrosSave()">Save</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="textHtmlFormShow" persistent _full-width _full-height>
      <sl-list-editor
        :space="space"
        :list="list"
        :view="view"
        :item="currentCell.item"
        :fieldId="currentCell.fieldId"
        v-if="textHtmlFormShow"
        @finished="textHtmlFormShow=false"
      />
    </q-dialog>
    <q-dialog v-model="columnFilterFormShow">
      <sl-list-column-filter-form
        :view="view" :list="list"
        :fieldId="currentColumn.fieldId"
        :viewField="view._data.fields[currentColumn.fieldId]"
        :items="pItems"
        @finished="columnFilterSave()"
      />
    </q-dialog>
    <q-dialog v-model="columnStyleFormShow">
      <q-card>
        <q-card-section>
          <q-scroll-area style="width: 200px; height: 300px">
            <sl-list-style-form :styleProps="currentColumn.styleProps"/>
          </q-scroll-area>
        </q-card-section>
        <q-card-actions class="justify-center">
          <q-btn flat @click="columnStyleFormShow=false">Cancel</q-btn>
          <q-btn flat @click="columnStyleSave()">Ok</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="cellPropsFormShow">
      <sl-list-cell-props-from :list="list" :view="view" :current-cell="currentCell"
                               @finished="cellPropsFormShow=false"/>
    </q-dialog>
    <q-dialog v-model="drilldownFormShow">
      <sl-list-drilldown-form :list="list" @finished="drilldownFormShow=false"/>
    </q-dialog>
    <q-dialog v-model="drilldownListShow" full-width>
      <sl-list-drilldown-list
        :drilldownList="currentDrilldownList"
        :currentList="list"
        :currentView="view"
        :currentCell="currentCell"
        @finished="drilldownListShow=false"
      />
    </q-dialog>
    <q-dialog v-model="thumbnailCropperShow">
      <sl-thumbnail-cropper @finished="saveThumbnail($event)"/>
    </q-dialog>

    <q-dialog v-model="columnContextPropsFormShow" :maximized="$q.platform.is.mobile">
      <sl-list-column-props-form :list="list" :view="view" :fieldId="currentColumn.fieldId"
                                 @finished="columnContextPropsFormShow=false"/>
    </q-dialog>
    <q-dialog v-model="itemStatusFormShow" v-if="itemStatusFormShow">
      <q-card>
        <q-card-section>
          <div>
            itemId: <span class="text-red-darken-4">{{ currentCell.item.id }}</span> <br/>
            status: <span class="text-red-darken-4">{{ currentCell.item.status }}</span> <br/>
            version: <span class="text-red-darken-4">{{ currentCell.item.$data.version }}</span> <br/>
            last user: <span class="text-red-darken-4">{{ currentCell.item.$data.lastUserId }}</span> <br/>
            last TS: <span class="text-red-darken-4">{{ currentCell.item.$data.lastTS }}</span>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-menu v-model="columnContextMenuShow" touch-position context-menu v-if="!$q.platform.is.mobile">
      <sl-list-column-context-menu :list="list" :view="view" :fieldId="currentColumn.fieldId"
                                   @menuSelected="columnContextMenuSelected($event)"/>
    </q-menu>
  </div>

</template>

<script>
import {resizableGrid} from "@/utils2"
import {currentUserId, db} from "@/db"
import {useObservable} from "@vueuse/rxjs/index"
import {liveQuery} from "dexie"
// import {generateId} from "@/utils"
import {ref} from 'vue'

import SlListItems from "@/components/sl-list-items"
import SlListCellPropsFrom from "@/components/sl-list-cell-props-from"
import SlListDrilldownForm from "@/components/sl-list-drilldown-form"
import SlListCellContextMenu from "@/components/sl-list-cell-context-menu"
import SlListColumnContextMenu from "@/components/sl-list-column-context-menu"
import SlListColumnPropsForm from "@/components/sl-list-column-props-form"
import SlListColumnFilterForm from "@/components/sl-list-column-filter-form"
import SlListStyleForm from "@/components/sl-list-style-form"
import {formulaCalculate, numFormat} from "@/utils3";
import SlListDrilldownList from "@/components/sl-list-drilldown-list";
import {fieldValSave, itemStatusChangingSet, objStatusFieldsSet, objVal} from "@/utils4";
import {generateId} from "@/utils";
import SlThumbnailCropper from "@/components/sl-thumbnail-cropper";
import SlListEditor from "@/components/sl-list-editor";

export default {
  name: "sl-list4",
  components: {
    SlListEditor,
    SlThumbnailCropper,
    SlListDrilldownList,
    SlListStyleForm,
    SlListColumnFilterForm,
    SlListColumnContextMenu,
    SlListCellContextMenu,
    SlListCellPropsFrom,
    SlListItems,
    SlListColumnPropsForm,
    SlListDrilldownForm
  },
  props: ["space", "list", "view", "isEditable", "aggregatorsItems", "upperListDataForDrilldown"],
  // inject: ["currentUserPhoneNumber"],
  data: () => {
    return {
      // ci: 0,
      // cj: 0,
      lView: null,
      columnFilterFormShow: false,
      columnStyleFormShow: false,
      columnContextPropsFormShow: false,
      columnContextMenuShow: false,
      macrosFormShow: false,
      thumbnailCropperShow: false,
      // columnContextMenuFieldId: "",
      fieldNameFormShow: false,
      fieldNameDelConformFormShow: false,
      itemStatusFormShow: false,
      fieldIdForm: "",
      fieldNameForm: "",

      draggbleColumnId: "",
      dragToColumnId: "",


      currentDrilldownList: "",
      drilldownListShow: false,

      currentColumn: {},
      currentCell: {},
      currentCellSublist: {},
      currentCellValue: ref(''),

      macrosCurrentVal: "",

      sublistFormShow: false,
      subItems: [],

      dateFormShow: false,
      timeFormShow: false,
      timeFormHourSelected: false,
      textHtmlFormShow: false,
      cellPropsFormShow: false,
      drilldownFormShow: false,
      contextMenuShow: false,

      // items: []

    }
  },
  setup(props) {

    return {
      db,
      items: useObservable(
        liveQuery(() => db.items.where("data.listId").equals(props.list.id).toArray())
      )
    }
  },
  created() {
    this.lView = this.view
  },
  async mounted() {
    if (!this.$q.platform.is.mobile) {
      setTimeout(() => {
        let tableEl = this.$el.querySelector("table.sl-table")
        resizableGrid(tableEl, this.view)
      }, 2000)
    }

    window.addEventListener("keydown", (event) => {
      if (event.defaultPrevented) {
        return; // Do nothing if the event was already processed
      }
      this.key = event.key
      // console.log(event.key)

      if (event.key === "o" && event.ctrlKey) {
        event.preventDefault()
        document.execCommand("insertOrderedList", false, null)
        // document.execCommand("indent", false, null)
        // document.execCommand("indent", false, null)
      }
      if (this.$el.querySelector('td:focus') === null) {
        if (event.key === 'ArrowLeft' && this.cj > 0) this.cj--
        if (event.key === 'ArrowRight' && this.cj < this.list.fields.length - 1) this.cj++
        if (event.key === 'ArrowUp' && this.ci > 0) this.ci--
        if (event.key === 'ArrowDown' && this.ci < this.items.length - 1) this.ci++
        //event.preventDefault()
      }
      if (event.key === 'F2') {
        //debugger
        if (this.$el.querySelector('td:focus') === null) {
          // this.$el.querySelectorAll('tr')[this.ci+1].querySelectorAll('td')[this.cj+2].focus()
          let currEl = this.$el.querySelectorAll('tr')[this.ci + 1].querySelectorAll('td')[this.cj + 2]
          this.currentCelldblClick(currEl, this.ci, this.cj)
        }
        else {
          // this.$el.querySelector('td:focus').blur()
          this.currentCellblur(this.$el.querySelector('td:focus'))
        }
        event.preventDefault()
      }
      let buttons = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab', 'F2']
      if (buttons.includes(event.key)) {
        //event.preventDefault()
      }

      // let ArrowButtons = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']
      // let currentCell = {}
      // if (ArrowButtons.includes(event.key)) {
      //
      //   currentCell.width = this.$el.querySelectorAll('tr')[this.ci - 1].querySelectorAll('td')[this.cj - 1].clientWidth
      //   currentCell.height = this.$el.querySelectorAll('tr')[this.ci - 1].querySelectorAll('td')[this.cj - 1].clientHeight
      //   currentCell.left = this.$el.querySelectorAll('tr')[this.ci - 1].querySelectorAll('td')[this.cj - 1].offsetLeft
      //   currentCell.top = this.$el.querySelectorAll('tr')[this.ci - 1].querySelectorAll('td')[this.cj - 1].offsetTop
      //
      //   let scrollArea = this.$parent
      //   let scrollInfo = scrollArea.getScroll()
      //   console.log("---")
      //   console.log(scrollInfo)
      //   console.log(currentCell)
      //   if (currentCell.top + currentCell.height > scrollInfo.verticalPosition + scrollInfo.verticalContainerSize) {
      //     scrollArea.setScrollPosition('vertical', currentCell.top + currentCell.height - scrollInfo.verticalContainerSize + 5, 300)
      //   }
      //   if (currentCell.top < scrollInfo.verticalPosition) {
      //     scrollArea.setScrollPosition('vertical', currentCell.top - 5, 300)
      //   }
      //   if (currentCell.left + currentCell.width > scrollInfo.horizontalPosition + scrollInfo.horizontalContainerSize) {
      //     scrollArea.setScrollPosition('horizontal', currentCell.left + currentCell.width - scrollInfo.horizontalContainerSize + 5, 300)
      //   }
      //   if (currentCell.left < scrollInfo.horizontalPosition) {
      //     scrollArea.setScrollPosition('horizontal', currentCell.left - 5, 300)
      //   }
      // }
    })
  },
  watch: {
    items(newVal) {
      console.log(newVal)
    },
    pItemsLength(newVal) {
      this.$emit("pItemsLength", newVal)
    },
    // viewFieldsLength(){
    //   resizableGrid(this.$el, this.view)
    // }
    'view.data': function () {
      resizableGrid(this.$el, this.view)  // this watch required because after second change width view._isChangedbyUser is not updated
    },


  },
  computed: {

    // viewFieldsLength(){
    //   debugger
    //   return this.view.data.fieldsOrder.length
    // },
    tableStyle() {
      let tableStyle = {}
      tableStyle.tableLayout = "fixed"
      tableStyle.width = this.tableWidth + "px" // table width required when window width is less than table width. Without table width table fits to window width
      // tableStyle.height = "fit-content" // for div inside td with 100% height
      return tableStyle
    },
    tableWidth() {
      let width = 0
      for (let fieldId of this.view._data.fieldsOrder) {
        if (this.view._data.fields[fieldId].headCellStyle.width) width = width + parseInt(this.view._data.fields[fieldId].headCellStyle.width, 10)
      }
      return width
    },
    pItemsLength() {
      return this.pItems.length
    },
    pItems() {
      if (this.items === undefined) return []

      let pItems = []
      let that = this

      // substitution (sublists, avatar)
      let tempItems = JSON.parse(JSON.stringify(this.items))
      // let tempItems = this.items
      let sublistFields = []
      let avatarFields = []
      for (let fieldId in this.list.data.fields) {
        if (this.list.data.fields[fieldId].type === "sublist" && !this.list.data.fields[fieldId].isFixedSublist) sublistFields.push(fieldId)
        if (this.list.data.fields[fieldId].type === "avatar") avatarFields.push(fieldId)
      }
      // debugger
      for (let tempItem of tempItems) {
        //avatar
        for (let avatarField of avatarFields) {
          let avatarNameFieldId = this.list.data.fields[avatarField].avatarNameFieldId
          tempItem.data.fields[avatarField] = tempItem.data.fields[avatarNameFieldId]
        }
        //sublists
        for (let sublistField of sublistFields) {
          let sublistBasedOnFieldId = this.list.data.fields[sublistField].sublistBasedOnFieldId
          let fieldIdForSublist = this.list.data.fields[sublistField].fieldIdForSublist
          let listIdForSublist = this.list.data.fields[sublistField].listIdForSublist
          if (sublistBasedOnFieldId) {
            tempItem.data.fieldsS[sublistField] = tempItem.data.fieldsS[sublistBasedOnFieldId]
          }
          // if (tempItem.data.fieldsS[sublistField] && this.list.sublists) {
          //   let sublist = this.list.sublists[listIdForSublist]
          if (tempItem.data.fieldsS[sublistField] && this.$store.state.sublists) {
            let sublist = this.$store.state.sublists[listIdForSublist]
            let sublistItemIds = tempItem.data.fieldsS[sublistField].split(";")
            sublistItemIds.forEach(sublistItemId => {
              tempItem.data.fields[sublistField] = tempItem.data.fields[sublistField] ? tempItem.data.fields[sublistField] : ""
              let sublistItem = sublist ? sublist.items.find(el => el.id === sublistItemId) : ""
              if (sublistItem) {
                if (sublist.list.data.fields[fieldIdForSublist].type === "avatar") {
                  let avatarNameFieldId = sublist.list.data.fields[fieldIdForSublist].avatarNameFieldId
                  // tempItem.data.fields[sublistField] = tempItem.data.fields[sublistField] + sublistItem.data.fields[avatarNameFieldId]
                  // tempItem.data.fields[sublistField] = JSON.parse(JSON.stringify(sublistItem.data.fields[avatarNameFieldId]))
                  tempItem.data.fields[sublistField] = sublistItem.data.fields[avatarNameFieldId]
                }
                else {
                  // debugger
                  //   tempItem.data.fields[sublistField] = tempItem.data.fields[sublistField] + sublistItem.data.fields[fieldIdForSublist]
                  // tempItem.data.fields[sublistField] = JSON.parse(JSON.stringify(sublistItem.data.fields[fieldIdForSublist]))
                  tempItem.data.fields[sublistField] = sublistItem.data.fields[fieldIdForSublist]
                }
              }
            })
          }
        }
      }

      // aggregator
      for (let fieldId in that.list.data.fields) {
        if (that.list.data.fields[fieldId].aggregator) {
          for (let i = 0; i < tempItems.length; i++) {
            let aggregatorItem = this.aggregatorsItems[fieldId].find(el => {
              let res = true
              that.list.data.fields[fieldId].aggregator.filterMap.forEach(map => {
                if (map.type === "fieldsMapping") {
                  if (el[map.fieldId2] !== tempItems[i].data.fields[map.fieldId]) res = false
                }
                if (map.type === "fixedValue") {
                  if (el[map.fieldId2] !== map.fixedValue) res = false
                }
              })
              return res
            })
            if (aggregatorItem) {
              tempItems[i].data.fields[fieldId] = aggregatorItem[that.list.data.fields[fieldId].aggregator.fieldId2]
            }
            else {
              tempItems[i].data.fields[fieldId] = 0
            }
          }
        }
      }

      //formula
      if (that.list.data.formulaOrders) {
        if (that.list.data.formulaOrders.beforeFilter) {
          for (let j = 0; j < that.list.data.formulaOrders.beforeFilter.length; j++) {
            for (let i = 0; i < tempItems.length; i++) {
              let calcVal = formulaCalculate(tempItems[i], this.list, that.list.data.formulaOrders.beforeFilter[j])
              tempItems[i].data.fields[that.list.data.formulaOrders.beforeFilter[j]] = calcVal
            }
          }
        }
      }


      // filter
      let filter = []
      that.lView._data.fieldsOrder.forEach(fieldId => {
        if (that.lView._data.fields[fieldId].filter) {
          if (Object.keys(that.lView._data.fields[fieldId].filter).length > 0) filter.push({
            fieldId: that.lView._data.fields[fieldId].id,
            arr: that.lView._data.fields[fieldId].filter.arr
          })
        }
      })
      if (filter.length > 0) {
        pItems = tempItems.filter(el => {
          let isFitted = true
          for (let i = 0; i < filter.length; i++) {
            if (that.list.data.fields[filter[i].fieldId].type === "sublist" && !that.list.data.fields[filter[i].fieldId].isFixedSublist) {
              if (!el.data.fieldsS[filter[i].fieldId]) {
                if (filter[i].arr.findIndex(v => v === "") === -1) {
                  isFitted = false;
                  break
                }
              }
              else if (filter[i].arr.findIndex(v => v === el.data.fieldsS[filter[i].fieldId]) === -1) {
                isFitted = false;
                break
              }
            }
            else {
              if (filter[i].arr.findIndex(v => v === el.data.fields[filter[i].fieldId]) === -1) {
                isFitted = false;
                break
              }
            }
          }
          return isFitted
        })
      }
      else {
        pItems = tempItems.filter(el => el.id === el.id) // was pItems = this.items, but this does additional computing
      }

      // sorting
      let sorting = []
      that.lView._data.fieldsOrder.forEach(fieldId => {
        if (that.lView._data.fields[fieldId].sort) sorting.push({
          fieldId: that.lView._data.fields[fieldId].id,
          sort: that.lView._data.fields[fieldId].sort
        })
      })

      if (sorting.length > 0) {
        pItems.sort((a, b) => {
          let result = 0
          let itemA = ""
          let itemB = ""
          for (let i = 0; i < sorting.length; i++) {
            itemA = a.data.fields[sorting[i].fieldId] ? a.data.fields[sorting[i].fieldId] : ""
            itemB = b.data.fields[sorting[i].fieldId] ? b.data.fields[sorting[i].fieldId] : ""
            if (sorting[i].sort === 'asc') {
              result = itemA.localeCompare(itemB)
              if (result !== 0) break
            }
            if (sorting[i].sort === 'desc') {
              result = itemB.localeCompare(itemA)
              if (result !== 0) break
            }
            // if(result !== 0) return
          }
          return result
        })
      }
      return pItems
    },
  },

  methods: {
    columnContextMenuSelected(event) {
      if (event === "columnProps") this.columnContextPropsFormShow = true
      if (event === "columnFilter") this.columnFilterFormShow = true
      if (event === "macrosSet") this.macrosFormOpen()
      if (event === "macrosRun") this.macrosRun()
      if (event === "columnGroup") this.columnGroupSet(event)
      if (event === "columnUngroup") this.columnUngroupSet(event)
      if (event === "columnSortDesc" || event === "columnSortAsc" || event === "columnSortCancel") this.columnSortSet(event)
      if (event === "columnRename") this.fieldNameFormShow = true
      if (event === "headCellStyle" || event === "headNameStyle" || event === "cellStyle" || event === "cellStringStyle") this.columnStyleFormOpen(event)
      if (event === "columnCalcSum" || event === "columnCalcMax" || event === "columnCalcMin" || event === "columnCalcMin" || event === "columnCalcAvg" || event === "columnCalcCancel") this.columnCalcSet(event)
      this.columnContextMenuShow = false // if user select Group or Ungroup context menu doesn't disappear
    },
    columnFilterSave() {
      let that = this
      for (let [fieldId, field] of Object.entries(this.lView._data.fields)) {
        if (fieldId !== that.currentColumn.fieldId) delete field.filter
      }
      this.lView._isChangedByUser = true
      this.columnFilterFormShow = false
    },
    columnStyleSave() {
      for (let prop in this.currentColumn.styleProps) if (this.currentColumn.styleProps[prop] === "") delete this.currentColumn.styleProps[prop]
      this.lView._data.fields[this.currentColumn.fieldId][this.currentColumn.styleObj] = this.currentColumn.styleProps
      this.lView._isChangedByUser = true
      this.columnStyleFormShow = false
    },
    columnSortSet(dir) {
      let that = this
      for (let [fieldId, field] of Object.entries(this.lView._data.fields)) {
        if (fieldId === that.currentColumn.fieldId) {
          if (dir === "columnSortDesc") field.sort = "desc"
          if (dir === "columnSortAsc") field.sort = "asc"
          if (dir === "columnSortCancel") delete field.sort
        }
        else delete field.sort
      }
      this.lView._isChangedByUser = true
    },
    columnCalcSet(operation) {
      debugger
      let field = this.lView._data.fields[this.currentColumn.fieldId]
      if (operation === "columnCalcSum") field.calc = "sum"
      if (operation === "columnCalcMax") field.calc = "max"
      if (operation === "columnCalcMin") field.calc = "min"
      if (operation === "columnCalcAvg") field.calc = "avg"
      if (operation === "columnCalcCancel") delete field.calc
      this.lView._isChangedByUser = true
    },
    columnGroupSet() {
      let that = this
      for (let [fieldId, field] of Object.entries(this.lView._data.fields)) {
        fieldId === that.currentColumn.fieldId ? field.group = true : delete field.group
      }
      this.lView._isChangedByUser = true
    },
    columnUngroupSet() {
      delete this.lView._data.fields[this.currentColumn.fieldId].group
      this.lView._isChangedByUser = true
    },
    columnStyleFormOpen(obj) {
      this.currentColumn.styleObj = obj
      this.currentColumn.styleProps = JSON.parse(JSON.stringify(this.view._data.fields[this.currentColumn.fieldId][obj]))
      this.columnStyleFormShow = true
    },
    columnContextMenuOpen(fieldId) {
        this.currentColumn.fieldId = fieldId
        this.currentColumn.fieldName = this.list.data.fields[fieldId].name
        this.columnContextMenuShow = true
    },
    cellClick(cell) {
      // debugger
      this.currentCell = cell
      // if (!cell.e.currentTarget.focus) {
      //   cell.e.currentTarget.contentEditable = false
      //   cell.e.currentTarget.querySelector("div.sl-cell-frame").style.display = "block"
      //   cell.e.currentTarget.style.zIndex = 0
      // }
    },
    cellDblclick(cell) {
      debugger
      this.currentCell = cell
      if (cell.fieldId === "z2") {
        this.itemStatusFormShow = true
      }
      if (this.list.data.fields[cell.fieldId].type === "text" || this.list.data.fields[cell.fieldId].type === "num") {

        if (this.list.data.fields[cell.fieldId].showInSeparateWindow) {
          cell.e.preventDefault()
          this.currentCell.value = cell.item.data.fields[cell.fieldId]
          this.textHtmlFormShow = true
        }
        else if(this.$q.platform.is.mobile){
          let obj = {type: "item",
            item: cell.item,
            list: this.list,
            fieldId: cell.fieldId
          }
          this.$store.commit("footerEditorOpen", {obj: obj, userId: currentUserId})
        }
        else if (!cell.e.currentTarget.isContentEditable) {
          if (cell.item.status !== "changing") {
            itemStatusChangingSet(cell.item.id, currentUserId)
          }
          if(cell.e.currentTarget.querySelector("div.sl-cell-frame")){
            cell.e.currentTarget.querySelector("div.sl-cell-frame").style.display = "none"
          }
          cell.e.currentTarget.contentEditable = true
          cell.e.currentTarget.style.zIndex = 100
          cell.e.currentTarget.blur()
          cell.e.currentTarget.focus()

        }
      }
      if (this.list.data.fields[cell.fieldId].type === "textHTML") {
        // cell.e.preventDefault()
        // this.currentCellValue = cell.item.data.fields[cell.fieldId] // ? currentCell.item.fields[currentCell.field.id] : '<blockquote>'+"----"+'</blockquote>'
        // this.textHtmlFormShow = true
      }
      else if (this.list.data.fields[cell.fieldId].type === "sublist" && !this.list.data.fields[cell.fieldId].isFixedSublist) {
        let fieldsSVal = objVal([cell.item.data, 'fieldsS', cell.fieldId], '')
        this.subItems = fieldsSVal.split(";")
        let listIdForSublist = objVal([this.list.data.fields, cell.fieldId, 'listIdForSublist'], '')
        let fieldIdForSublist = objVal([this.list.data.fields, cell.fieldId, 'fieldIdForSublist'], '')
        let viewIdForSublist = objVal([this.list.data.fields, cell.fieldId, 'viewIdForSublist'], '')
        //let sublist = objVal([this.list.sublists, listIdForSublist], {items: []})
        let sublist = this.$store.state.sublists[listIdForSublist]

        this.currentCell.sublist = []
        for (let item of sublist.items) {
          let newItem = {}
          if (sublist.list.data.fields[fieldIdForSublist].type !== "avatar") {
            newItem = {
              id: item.id,
              value: objVal([item.data.fields, fieldIdForSublist], ""),
              cellStringStyle: {
                ...objVal([sublist.list.data.views, viewIdForSublist, 'data', 'fields', fieldIdForSublist, 'cellStringStyle'], {}),
                ...objVal([sublist.list.data.views, viewIdForSublist, 'data', 'cellProps', item.id, fieldIdForSublist, 'cellStringStyle'], {})
              }
            }
          }
          else {
            let avatarNameFieldId = sublist.list.data.fields[fieldIdForSublist].avatarNameFieldId
            let avatarImgFieldId = sublist.list.data.fields[fieldIdForSublist].avatarImgFieldId
            let avatarSrc = item.data.fields[avatarImgFieldId] ? item.data.fields[avatarImgFieldId] : require('../assets/defaultPictureUser.svg')
            newItem = {
              id: item.id,
              value: item.data.fields[avatarNameFieldId],
              avatarSrc: avatarSrc
            }
          }
          this.currentCell.sublist.push(newItem)
        }
        // this.currentCell.sublist = this.list.sublists[this.list.data.fields[cell.fieldId].listIdForSublist]
        this.sublistFormShow = true
      }
      else if (this.list.data.fields[cell.fieldId].type === "sublist" && this.list.data.fields[cell.fieldId].isFixedSublist) {
        debugger
        if (cell.item.data.fields[cell.fieldId]) this.subItems = cell.item.data.fields[cell.fieldId].split(";")
        else this.subItems = []
        let sublist = this.list.data.fields[cell.fieldId].fixedSublist
        this.currentCell.sublist = []
        for (let item of sublist) {
          let newItem = {}
          newItem = {
            value: objVal([item, 'value'], ""),
            cellStringStyle: {
              ...objVal([item, 'style'], {}),
              ...objVal([this.list.data.views, this.lView, 'data', 'fields', cell.fieldId, 'cellStringStyle'], {})
            }
          }
          this.currentCell.sublist.push(newItem)
        }
        this.sublistFormShow = true
      }
      else if (this.list.data.fields[cell.fieldId].type === "fixedsublist") {
        debugger
        if (cell.item.data.fieldsS[cell.fieldId]) this.subItems = cell.item.data.fieldsS[cell.fieldId].split(";")
        else this.subItems = []
        let sublist = this.list.data.fields[cell.fieldId].fixedSublist
        this.currentCell.sublist = []
        for (let itemId in sublist) {
          let newItem = {}
          newItem = {
            id: itemId,
            value: objVal([sublist[itemId], 'value'], ""),
            cellStringStyle: {
              ...objVal([sublist[itemId], 'style'], {}),
              ...objVal([this.list.data.views, this.lView, 'data', 'fields', cell.fieldId, 'cellStringStyle'], {})
            }
          }
          this.currentCell.sublist.push(newItem)
        }
        this.sublistFormShow = true
      }
      else if (this.list.data.fields[cell.fieldId].type === "date") {
        cell.e.preventDefault()
        if (!cell.item.data.fields[cell.fieldId]) {
          let currDate = new Date()
          let currYear = currDate.getFullYear()
          let currMonth = currDate.getMonth() + 1
          currMonth = currMonth + ""
          if (currMonth.length === 1) currMonth = "0" + currMonth
          let currDay = currDate.getDate()
          currDay = currDay + ""
          if (currDay.length === 1) currDay = "0" + currDay

          this.currentCell.value = currYear + "/" + currMonth + "/" + currDay
        }
        else {
          this.currentCell.value = cell.e.currentTarget.innerText
        }
        this.dateFormShow = true
      }
      else if (this.list.data.fields[cell.fieldId].type === "time") {
        cell.e.preventDefault()
        if (!cell.item.data.fields[cell.fieldId]) {
          this.currentCell.value = "00:00"
        }
        else {
          this.currentCell.value = cell.e.currentTarget.innerText
        }
        this.timeFormHourSelected = false
        this.timeFormShow = true
      }
      else if (this.list.data.fields[cell.fieldId].type === "thumbnail") {
        this.thumbnailCropperShow = true
      }
      else if (this.list.data.fields[cell.fieldId].type === "checkbox") {
        debugger
        let checkboxVal = cell.item.data.fields[cell.fieldId]
        fieldValSave({
          list: this.list,
          view: this.view,
          item: cell.item,
          fieldId: cell.fieldId,
          val: !checkboxVal ? "X" : "",
          userId: currentUserId
        })
      }
    },
    cellBlur(cell) {
      let that = this
      cell.e.currentTarget.contentEditable = false
      if(cell.e.currentTarget.querySelector("div.sl-cell-frame")){
        cell.e.currentTarget.querySelector("div.sl-cell-frame").style.display = "block"
      }
      cell.e.currentTarget.style.zIndex = 0

      let cellVal = cell.e.currentTarget.innerText.trim()
      if (that.list.data.fields[cell.fieldId].type === "num") {
        cellVal = cellVal.replaceAll(",", "")
        cellVal = cellVal.replaceAll(" ", "")
        cellVal = cellVal * 1
        if (isNaN(cellVal)) {
          let previousFormattedVal = numFormat(cell.item.data.fields[cell.fieldId], that.list.data.fields[cell.fieldId])
          let innerHTML = cell.e.currentTarget.innerHTML.replace(cell.e.currentTarget.innerText, previousFormattedVal)
          cell.e.currentTarget.innerHTML = innerHTML
          return
        }
      }
      fieldValSave({
        list: this.list,
        view: this.view,
        item: cell.item,
        fieldId: cell.fieldId,
        val: cellVal,
        userId: currentUserId
      })

      if (cell.item.id === "") {
        cell.e.currentTarget.innerText = "" // empty cell val in row ror new line
      }

      // if (cell.item.id === "") {
      //   cell.e.currentTarget.innerText = "" // empty cell val in row ror new line
      // }
      // else {
      //   if (that.list.data.fields[cell.fieldId].type === "num") {
      //     let innerHTML = cell.e.currentTarget.innerHTML
      //     innerHTML = innerHTML.replace(cell.e.currentTarget.innerText, numFormat(cellVal, that.list.data.fields[cell.fieldId]))
      //     cell.e.currentTarget.innerHTML = innerHTML // only innerHTML is required to updated because with innerText <span> disappears
      //   }
      //   if (that.list.data.fields[cell.fieldId].type === "text") {
      //     cell.e.currentTarget.innerText = cellVal // this required to avoid duplication of cell value (I don't know why is duplicated then blur focus
      //   }
      // }

      // if (cell.item.id === "") {
      //   let item = {
      //     id: generateId(),
      //     spaceId: this.space.id,
      //     data: {
      //       listId: this.list.id,
      //       fields: {},
      //       fieldsS: {},
      //       fieldsF: {}
      //     }
      //   }
      //
      //   item.data.fields[cell.fieldId] = cellVal
      //   // cell.item.data.fields[cell.fieldId] = cellVal
      //   db.items.add(item).then((itemId) => {
      //     for (let fieldId of that.list.data.formulaOrders.onSave) {
      //       let calcVal = formulaCalculate(cell.item, that.list, fieldId)
      //       cell.item.data.fields[fieldId] = calcVal
      //       db.items.where('id').equals(itemId).modify(item => item.data.fields[fieldId] = calcVal)
      //     }
      //   })
      //   cell.e.currentTarget.innerText = "" // empty cell val in row ror new line
      // }
      // else {
      //   // cell.item.data.fields[cell.fieldId] = cellVal
      //   db.items.where('id').equals(cell.item.id).modify(item => item.data.fields[cell.fieldId] = cellVal).then(() => {
      //     for (let fieldId of that.list.data.formulaOrders.onSave) {
      //       if (that.list.data.fields[fieldId].onCreateOnly) continue
      //       let calcVal = formulaCalculate(cell.item, that.list, fieldId)
      //       cell.item.data.fields[fieldId] = calcVal
      //       db.items.where('id').equals(cell.item.id).modify(item => item.data.fields[fieldId] = calcVal)
      //     }
      //   })
      //   // cell.e.currentTarget.innerText = cellVal // this required to avoid duplication of cell value (I don't know why is duplicated then blur focus
      //
      //   if(that.list.data.fields[cell.fieldId].type === "num"){
      //     let innerHTML = cell.e.currentTarget.innerHTML
      //     innerHTML = innerHTML.replace(cell.e.currentTarget.innerText, numFormat(cellVal, that.list.data.fields[cell.fieldId]))
      //     cell.e.currentTarget.innerHTML = innerHTML // only innerHTML is required to updated because with innerText <span> disappears
      //   }
      // }


      // debugger
      // eslint-disable-next-line
      //let fields = cell.item.data.fields
      // for(let [, field] of Object.entries(this.list.data.fields)) {
      //   for (let fieldId in that.list.data.formulaOrders.onCreate) {
      //     let calcVal = formulaCalculate(item, that.list, fieldId)
      //     db.items.where('id').equals(cell.item.id).modify(item => item.data.fields[fieldId] = calcVal)
      //   }
      // }
    },
    saveThumbnail(blob) {
      // debugger
      fieldValSave({
        list: this.list,
        view: this.view,
        item: this.currentCell.item,
        fieldId: this.currentCell.fieldId,
        val: blob,
        userId: currentUserId
      })
      this.thumbnailCropperShow = false
    },
    // checkboxUpdated(cell) {
    //   debugger
    //   fieldValSave({
    //     list: this.list,
    //     view: this.view,
    //     item: cell.item,
    //     fieldId: cell.fieldId,
    //     val: cell.e ? "X" : "",
    //     userId: this.currentUserPhoneNumber
    //   })
    // },
    cellContextmenu(cell) {
      this.currentCell = cell

    },
    cellMousedown(cell) {
      if (this.list.data.fields[cell.fieldId].type === "textHTML" || this.list.data.fields[cell.fieldId].type === "date" || this.list.data.fields[cell.fieldId].type === "time") {
        cell.e.preventDefault()
      }
      // // debugger
      // if(currentCell.field.props.type === "text" && currentCell.e.currentTarget.contentEditable !== true) {
      //   currentCell.e.preventDefault()
      // }
    },
    contextmenuSelected(event) {
      if (event === 'cellProperties') {
        this.cellPropsFormShow = true
      }
      if (event === 'drilldownSettings') {
        this.drilldownFormShow = true
      }
      if (typeof event === 'object') {
        if (event.menu === "drilldownList") {
          this.currentDrilldownList = this.list.data.drilldownLists[event.index]
          this.drilldownListShow = true
        }
      }
      if (event === 'rowCopy') {
        db.items.get(this.currentCell.item.id).then(row => {
          row.id = generateId()
          db.items.add(row)
        })
      }
      if (event === 'rowDelete') {
        if (!confirm("Confirm deletion!")) return
        db.items.delete(this.currentCell.item.id)
      }

    },
    drilldownListSelected(pl) {
      debugger
      this.currentCell.item = pl.item
      let drilldownListId = this.list.data.fields[pl.fieldId].drilldownListId
      this.currentDrilldownList = this.list.data.drilldownLists[drilldownListId]
      this.drilldownListShow = true
    },
    currentCellSave(pickerType) {
      if (pickerType === "timePicker" && !this.timeFormHourSelected) {
        this.timeFormHourSelected = true
        return
      }
      // let currentCellValue = this.currentCell.value

      fieldValSave({
        list: this.list,
        view: this.view,
        item: this.currentCell.item,
        fieldId: this.currentCell.fieldId,
        val: this.currentCell.value,
        userId: currentUserId
      })


      // if (this.currentCell.item.id === "") {
      //   let item = {
      //     id: generateId(),
      //     spaceId: this.space.id,
      //     data: {
      //       listId: this.list.id,
      //       fields: {},
      //       fieldsS: {},
      //       fieldsF: {}
      //     }
      //   }
      //   // item.data.fields[this.currentCell.fieldId] = currentCellValue
      //   db.items.add(item)
      //   // currentCell.el.innerText = ""
      //   //db.items.where('id').equals(this.list.id).modify(list => list.fields.push(field))
      // } else {
      //   db.items.where('id').equals(this.currentCell.item.id).modify(item => item.data.fields[this.currentCell.fieldId] = currentCellValue)
      // }
      this.dateFormShow = false
      this.timeFormShow = false
      // this.textHtmlFormShow = false
    },
    subItemClear() {
      fieldValSave({
        list: this.list,
        view: this.view,
        item: this.currentCell.item,
        fieldId: this.currentCell.fieldId,
        val: "",
        userId: currentUserId
      })
      this.sublistFormShow = false
    },
    subItemSelect(subItemId, subItemVal) {
      debugger
      if (this.list.data.fields[this.currentCell.fieldId].isMultipleInput) {
        let subItem = subItemId
        if (this.list.data.fields[this.currentCell.fieldId].isFixedSublist) subItem = subItemVal
        let index = this.subItems.findIndex(el => el === subItem)
        if (index === -1) this.subItems.push(subItem)
        else this.subItems.splice(index, 1)
      }
      else {
        let val = this.list.data.fields[this.currentCell.fieldId].isFixedSublist ? subItemVal : subItemId
        if(this.list.data.fields[this.currentCell.fieldId].type === "fixedsublist") val = subItemId
        fieldValSave({
          list: this.list,
          view: this.view,
          item: this.currentCell.item,
          fieldId: this.currentCell.fieldId,
          val: val,
          userId: currentUserId
        })
        this.sublistFormShow = false
      }
    },
    subItemsSelect() {
      // debugger
      let subItemsString = ""
      this.subItems.forEach(subItem => {
        if (subItem) {
          if (subItemsString === "") subItemsString = subItem
          else subItemsString = subItemsString + ";" + subItem
        }

      })
      fieldValSave({
        list: this.list,
        view: this.view,
        item: this.currentCell.item,
        fieldId: this.currentCell.fieldId,
        val: subItemsString,
        userId: currentUserId
      })
      this.sublistFormShow = false
    },
    // fieldSort(fieldId, dir){
    //   let indx = this.currentView.fields.findIndex(f=>f.id===fieldId)
    //   this.currentView.fields[indx].sorting = dir
    // },
    fieldAdd() {
      let that = this
      let maxIdNum = 0
      let idNum = 0
      for (let p in this.list.data.fields) {
        if (p.charAt(0) === 'f') {
          idNum = p.substring(1) * 1
          if (maxIdNum < idNum) maxIdNum = idNum
        }
      }
      maxIdNum++
      let field = {id: 'f' + maxIdNum, name: 'Field name ' + maxIdNum, type: 'text'}
      let fieldView = {id: field.id, headCellStyle: {}, headNameStyle: {}, cellStyle: {}, cellContentStyle: {}}
      fieldView.headCellStyle.width = "150px"
      fieldView.headCellStyle.position = "sticky"
      fieldView.headCellStyle.top = "0px"
      fieldView.headCellStyle.zIndex = 100

      // update in db
      db.lists.where('id').equals(this.list.id).modify(list => {
        debugger
        list.data.fields[field.id] = field
        list.data.views[that.view.id].data.fields[field.id] = fieldView
        let z3Index = that.view.data.fieldsOrder.findIndex(el => el === 'z3')
        list.data.views[that.view.id].data.fieldsOrder.splice(z3Index, 0, field.id)
        objStatusFieldsSet(list, currentUserId)

        setTimeout(() => {
          // update current view
          that.lView._data.fields[field.id] = fieldView
          that.lView._data.fieldsOrder.splice(z3Index, 0, field.id)
          resizableGrid(that.$el, that.lView)
        }, 500) // required to add resize functionality to new field
      })
      // db.views.where('id').equals(this.view.id).modify(view => view.data.fields[field.id] = fieldView)
      // let z3Index = this.view.data.fieldsOrder.findIndex(el => el === 'z3')
      // db.views.where('id').equals(this.view.id).modify(view => view.data.fieldsOrder.splice(z3Index, 0, field.id))
      // setTimeout(() => {
      //   resizableGrid(this.$el, this.view)
      //   // update current view
      //   this.lView._data.fields[field.id] = fieldView
      //   let z3Index = this.lView._data.fieldsOrder.findIndex(el => el === 'z3')
      //   this.lView._data.fieldsOrder.splice(z3Index, 0, field.id)
      // }, 500) // required to add resize functionality to new field
    },


    propertiesFormOpen(fieldId) {
      this.fieldIdForm = fieldId
      this.propertiesFormShow = true
    },
    fieldNameFormSave() {
      let that = this
      db.lists.where('id').equals(this.list.id).modify(list => list.data.fields[that.currentColumn.fieldId].name = that.currentColumn.fieldName)
      this.fieldNameFormShow = false
    },
    fieldNameFormDelete() {
      debugger
      let that = this
      let fieldIndx = this.list.fields.findIndex(f => f.id === this.fieldIdForm)
      if (fieldIndx >= 0) {
        db.lists.where('id').equals(this.list.id).modify(list => list.fields.splice(fieldIndx, 1))
      }
      this.fieldNameFormShow = false
      this.fieldNameDelConformFormShow = false
      debugger
      this.list.views.forEach((v, i) => {
        debugger
        let fieldIndx = v.fields.findIndex(f => f.id === that.fieldIdForm)
        if (fieldIndx >= 0) {
          db.lists.where('id').equals(this.list.id).modify(list => list.views[i].fields.splice(fieldIndx, 1))
        }
      })
    },
    macrosFormOpen() {
      this.macrosCurrentVal = this.list.data.fields[this.currentColumn.fieldId].macrosFormula
      this.macrosFormShow = true
    },
    macrosSave() {
      let that = this
      db.lists.where("id").equals(this.list.id).modify(list => {
        list.data.fields[that.currentColumn.fieldId].macrosFormula = that.macrosCurrentVal
      })
      this.macrosFormShow = false
    },
    macrosRun() {
      let that = this
      let isMacros = true
      //formula
      this.pItems.forEach(item => {
        let resultVal = formulaCalculate(item, that.list, that.currentColumn.fieldId, isMacros)
        db.items.where("id").equals(item.id).modify(el => {
          el.data.fields[that.currentColumn.fieldId] = resultVal
          objStatusFieldsSet(el)
        })
      })
    },
    columnDragStart(e) {
      this.draggbleColumnId = e.currentTarget.parentElement.id
      console.log(e)
    },
    columnDragDrop(e) {
      debugger
      let dragToColumnId
      if (e.currentTarget.tagName === "TH") {
        dragToColumnId = e.currentTarget.id
      }
      else {
        dragToColumnId = e.currentTarget.parentElement.id
      }
      let draggbleColumnIdIndex = this.view._data.fieldsOrder.findIndex(el => el === this.draggbleColumnId)
      let dragToColumnIdIndex = this.view._data.fieldsOrder.findIndex(el => el === dragToColumnId)
      let fieldsOrder = this.view._data.fieldsOrder
      if (draggbleColumnIdIndex >= 0 && dragToColumnIdIndex >= 0) {
        fieldsOrder.splice(draggbleColumnIdIndex, 1)
        if (draggbleColumnIdIndex > dragToColumnIdIndex) fieldsOrder.splice(dragToColumnIdIndex, 0, this.draggbleColumnId)
        if (draggbleColumnIdIndex < dragToColumnIdIndex) fieldsOrder.splice(dragToColumnIdIndex - 1, 0, this.draggbleColumnId)

        let lView = this.view
        lView._isChangedByUser = true
      }

    }
  }

}
</script>

<style>
table {
  border-spacing: 0px;
}

th {
  padding: 5px;
}

th, .sl-cell {
  background-color: white;
}


.tr-head-s1 > th {
  border-bottom: 1px solid gray;
}

.tr-head-s2 > th {
  border-top: 1px solid gray;
  /*border-bottom: 1px solid gray;*/
  border-right: 1px solid gray;
  /*text-align: center;*/
}

.tr-head-s2 > th:first-child {
  border-left: 1px solid gray;
}

.tr-group-s1 > .sl-cell {
  border-bottom: 1px solid lightgray;
}

.tr-group-s2 > .sl-cell {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.tr-group-s2 > .sl-cell:first-child {
  border-left: 1px solid lightgray;
}

.tr-row-s1 > .sl-cell {
  /*border-top: 1px solid white;*/
  /*border-right: 1px solid white;*/
  border-bottom: 1px solid lightgray;
  /*border-left: 1px solid white;*/
}

.tr-row-s2 > .sl-cell {
  /*border-bottom: 1px solid lightgray;*/
  border-right: 1px solid lightgray;
}

.tr-row-s2 > .sl-cell:first-child {
  border-left: 1px solid lightgray;
}

</style>