import bases from "bases"
import { db } from './db'

// export const rowGet = async function(talbeName, rowId) {
//     let list = await db[talbeName].get(rowId)
//     debugger
//     return list
// }

// export const generalParamsGet = async function(paramId) {
//     let obj = await db.generalParams.get(paramId)
//     return obj.value
// }

// export const paramPut = async function(paramId, val) {
//     let propsArr = paramId.split(".")
//     if(propsArr.length>1){
//         for(let i=1; i<propsArr.length; i++){
//
//         }
//     }
//
//     let obj = {}
//     obj.id = paramId
//     if(typeof val === "object"){
//         for (let prop in val){
//
//         }
//     }
//     else{
//         obj.val = val
//     }
//     await db.params.put(obj)
// }

// export const paramGet = async function(paramId) {
//     let obj = await db.params.get(paramId)
//     return obj ? obj.val : undefined
// }


export const paramsGet = async function(paramId) {
    let obj = await db.params.get(paramId)
    if(obj === undefined) {
        return undefined
    }
    else{
        return obj.value
    }
}

// export const currentUserIdGet = async function() {
//     let obj = await db.params.get("user")
//     if(!obj) return undefined
//     else if(!obj.currentUserId) return undefined
//     else obj.currentUserId
// }

export const generateId = function() {
    let TS = Date.now()
    let id = bases.toBase36(TS)
    let mathRandom = Math.random()
    mathRandom = mathRandom.toString(36)
    mathRandom = mathRandom.substr(2, 3)
    return id+mathRandom
    // return bases.toBase36(Date.now())+Math.random().toString(36).substr(2, 3)
}

import { box, secretbox, randomBytes } from "tweetnacl"
import {
    decodeUTF8,
    encodeUTF8,
    encodeBase64,
    decodeBase64
} from "tweetnacl-util"

const newNonce = () => randomBytes(secretbox.nonceLength)

export const generateKey = () => encodeBase64(randomBytes(secretbox.keyLength));

export const generateKeyPair = () => box.keyPair();
export const sharedKeys = (publicKey, secretKey) => {
    return box.before(publicKey, secretKey)
}

export const encrypt = (json, key) => {
    const keyUint8Array = decodeBase64(key);

    const nonce = newNonce();
    const messageUint8 = decodeUTF8(JSON.stringify(json));
    const box = secretbox(messageUint8, nonce, keyUint8Array);

    const fullMessage = new Uint8Array(nonce.length + box.length);
    fullMessage.set(nonce);
    fullMessage.set(box, nonce.length);

    const base64FullMessage = encodeBase64(fullMessage);
    return base64FullMessage;
};

export const decrypt = (messageWithNonce, key) => {
    const keyUint8Array = decodeBase64(key);
    const messageWithNonceAsUint8Array = decodeBase64(messageWithNonce);
    const nonce = messageWithNonceAsUint8Array.slice(0, secretbox.nonceLength);
    const message = messageWithNonceAsUint8Array.slice(
        secretbox.nonceLength,
        messageWithNonce.length
    );

    const decrypted = secretbox.open(message, nonce, keyUint8Array);

    if (!decrypted) {
        throw new Error("Could not decrypt message");
    }

    const base64DecryptedMessage = encodeUTF8(decrypted);
    return JSON.parse(base64DecryptedMessage);
};

export const encryptShared = (secretOrSharedKey, json, key) => {
    const nonce = newNonce();
    const messageUint8 = decodeUTF8(JSON.stringify(json));
    const encrypted = key
        ? box(messageUint8, nonce, key, secretOrSharedKey)
        : box.after(messageUint8, nonce, secretOrSharedKey);

    const fullMessage = new Uint8Array(nonce.length + encrypted.length);
    fullMessage.set(nonce);
    fullMessage.set(encrypted, nonce.length);

    const base64FullMessage = encodeBase64(fullMessage);
    return base64FullMessage;
};

export const decryptShared = (secretOrSharedKey, messageWithNonce, key) => {
    const messageWithNonceAsUint8Array = decodeBase64(messageWithNonce);
    const nonce = messageWithNonceAsUint8Array.slice(0, box.nonceLength);
    const message = messageWithNonceAsUint8Array.slice(
        box.nonceLength,
        messageWithNonce.length
    );

    const decrypted = key
        ? box.open(message, nonce, key, secretOrSharedKey)
        : box.open.after(message, nonce, secretOrSharedKey);

    if (!decrypted) {
        throw new Error('Could not decrypt message');
    }

    const base64DecryptedMessage = encodeUTF8(decrypted);
    return JSON.parse(base64DecryptedMessage);
};