<template>
  <q-card >
    <q-card-section class="column items-center">
      <cropper
          ref="avatarCropper"
          class="cropper"
          :key="ratio"
          :src="image.src"
          :stencil-props="{ aspectRatio: ratio, handlers: {}, movable: false, resizable: false }"
          :resize-image="{ adjustStencil: false }"
          :stencil-size="{ width: dim.width, height: dim.height }"
          :canvas="{  width: dim.width*2, height: dim.height*2 }"
          imageSmoothingQuality="low"
          image-restriction="stencil"
      />
      <q-file v-model="photoFile" @update:model-value="loadImage()" accept="image/*" label="Photo" dense style="max-width: 250px"/>

      Image dimensions
      <div class="row">
        <q-chip v-for="ratioVal in [0.5, 0.75, 1, 1.5, 2]" :key="ratioVal"
                :label="ratioVal"
                :color="ratio===ratioVal?'green':''"
                :text-color="ratio===ratioVal?'white':''"
                clickable
                @click="ratio=ratioVal"
        />
      </div>
      Image quality
      <q-slider v-model="quality" :min="0" :max="1" :step="0.1"
                :label-value="quality"
      />
    </q-card-section>

    <q-card-section class="column items-center">
      {{imgSize}}
      <q-img :src="imgShow"/>
    </q-card-section>

    <q-card-actions class="justify-center">
      <q-btn flat @click="$emit('finished')">Cancel</q-btn>
      <q-btn flat @click="save()">Save</q-btn>
      <q-btn flat @click="show()">Show</q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
// import { db } from "@/db"

// This function is used to detect the actual image type,

export default {
  name: "sl-thumbnail-cropper",
  emits: ["finished"],
  props: ["userId"],
  components: {
    Cropper,
  },
  data(){
    return {
      photoFile: null,
      image: {
        src: null,
        type: null
      },
      imgShow: "",
      quality: 0.5,
      ratio: 1,
      imgSize: ""
    }
  },
  computed: {
    dim(){
      let width = 220
      let height = 220
      if(this.ratio === 0.5) width = height * this.ratio
      if(this.ratio === 0.75) width = height * this.ratio
      if(this.ratio === 1.5) height = width * this.ratio
      if(this.ratio === 2) height = width * this.ratio
      return {
        width: width,
        height: height
      }
    }
  },
  methods: {
    show(){
      let that = this
      const { canvas } = this.$refs.avatarCropper.getResult()
      // this.imgShow = canvas.toDataURL()
      canvas.toBlob((blob) => {
        // that.imgShow = URL.createObjectURL(blob)
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = function() {
          debugger
          that.imgShow = reader.result
          let base64str = reader.result.substring(reader.result.indexOf(',') + 1)
          let decoded = atob(base64str);
          console.log("FileSize: " + decoded.length / 1e+3 + " Kb")
          that.imgSize = decoded.length / 1e+3 + " Kb"
        }
      }, 'image/jpeg', this.quality)
      //this.image.type)

    },
    save() {
      let that = this
      const { canvas } = this.$refs.avatarCropper.getResult()
      canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = function() {
          that.$emit("finished", reader.result)
        }
      }, 'image/jpeg', this.quality) //this.image.type)

    },
    loadImage() {

      console.log(this.photoFile)
      // Reference to the DOM input element
      // const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (this.photoFile) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(this.photoFile);

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: this.getMimeType(e.target.result, this.photoFile.type),
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(this.photoFile);
      }
    },
    getMimeType(file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4);
      let header = '';
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case "89504e47":
          return "image/png";
        case "47494638":
          return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
          return "image/jpeg";
        default:
          return fallback;
      }
    }
  },
  // destroyed() {
  //   // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
  //   if (this.image.src) {
  //     URL.revokeObjectURL(this.image.src)
  //   }
  // }
}
</script>

<style scoped>
.cropper {
  height: 250px;
  width: 250px;
  background: #DDD;
}
</style>