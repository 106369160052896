<template>
  <q-card flat style="padding: 10px">

    <svg :width="chartWidth+(padding*2)" :height="chartHeight+(padding*2)" style="border: 1px solid lightgrey; border-radius: 5px">

      <!-- Рисуем индикаторы месяцев -->
      <g v-for="(month, i) in months" :key="month.year+'-'+month.month">
        <rect :x="month.widthFromStart+5+padding" :y="10+padding" :width="month.width-10" height="30"
              style="fill:blue;fill-opacity:0.1; rx:5; ry: 5"
        />
        <text :x="month.widthFromStart+month.width/2 + padding" :y="25 + padding"
              style="text-anchor: middle; dominant-baseline: central; fill: grey"
              v-if="month.width>50"
        >
          {{shortMonthNameGet(month.month+1) + " - " +String(month.year).slice(-2)}}
        </text>
        <line :x1="month.widthFromStart+padding" :y1="50 + padding" :x2="month.widthFromStart+padding" :y2="chartHeight-padding"
              style="stroke: gray; stroke-width: 1; opacity: 0.15"
              v-if="i>0 && params.periods === 'months'"
        />
      </g>
      <!-- Рисуем индикаторы неделя -->
      <g v-if="params.periods === 'weeks'">
        <g v-for="(week, i) in weeks" :key="week.startYear+'-'+week.number">
          <rect :x="i*weekWidth+5+padding" y="50" :width="weekWidth-10" height="20"
                style="fill:white;fill-opacity:0.1; rx:5; ry: 5"
          />
          <text :x="i*weekWidth+weekWidth/2+padding" y="60" style="text-anchor: middle; dominant-baseline: central; fill: grey; opacity: 0.5">
            {{week.number}}
          </text>
          <line :x1="i*weekWidth+padding" :y1="70 + padding" :x2="i*weekWidth+padding" :y2="chartHeight-padding"
                style="stroke: gray; stroke-width: 1; opacity: 0.15"
                v-if="i>0"
          />
        </g>
      </g>
      <g>
        <!-- Рисуем индикатор текущего дня -->
        <line :x1="daysBetweenDatesGet(grandStartDateAdjusted, currentDate) * dayWidth + padding + dayWidth/2"
              :y1="params.periods === 'weeks' ? 70+padding : 50+padding"
              :x2="daysBetweenDatesGet(grandStartDateAdjusted, currentDate)  * dayWidth + padding + dayWidth/2"
              :y2="chartHeight - padding"
              style="stroke: orange; stroke-width: 1px; opacity: 0.6"
        />
      </g>

<!--      <sl-addon-gantt-group :data="data" :params="params"-->
<!--                            :dayWidth="dayWidth"-->
<!--                            :grandStartDateAdjusted="grandStartDateAdjusted"-->
<!--                            :grandEndDateAdjusted="grandEndDateAdjusted"-->
<!--                            :initY="params.periods === 'weeks' ? 75 : 55"-->
<!--                            :padding="padding"-->
<!--                            :lineHeight="lineHeight"-->
<!--      />-->
      <sl-addon-gantt-line v-for="(line, i) in lines" :key="i"
                           :line="line" :params="params"
                           :dayWidth="dayWidth"
                           :grandStartDateAdjusted="grandStartDateAdjusted"
                           :grandEndDateAdjusted="grandEndDateAdjusted"
                           :initY="headerHeight + (i*lineHeight)"
                           :padding="padding"
                           :lineHeight="lineHeight"
      />

    </svg>


<!--    {{grandStartDateAdjusted}}-->
<!--    {{grandEndDateAdjusted}}-->

<!--    Days: {{grandDaysAdjusted}}-->

<!--    <div v-for="week in weeks" :key="week.number">-->
<!--      {{`${week.startYear}/${week.startMonth}/${week.startDay}`}} - {{`${week.endYear}/${week.endMonth}/${week.endDay}`}}, {{week.number}}-->
<!--    </div>-->
<!--    <br/>-->
<!--    <span v-for="month in months" :key="month.year+'-'+month.month">-->
<!--      {{month.year+'-'+month.month}}-->
<!--    </span>-->
  </q-card>
</template>

<script>
// import SlAddonGanttGroup from "@/components/sl-addon-gantt-group";
import SlAddonGanttLine from "@/components/sl-addon-gantt-line";
import {daysBetweenDates} from "@/addon-gantt";
export default {
  name: "sl-addon-gantt",
  components: {SlAddonGanttLine},
  props: ["data", "view"],
  data: () => {
    return {
      params: {},
      currentDate: "",
      padding: 5,
      lineHeight: 36,
      lines: []
    }
  },
  created(){
    let date = new Date()
    this.currentDate = date.getFullYear() + "/" + (date.getMonth()+1) + "/" + date.getDate()
    this.params = this.view.data.addonParams.params
    this.lines = this.linesGet(this.data).slice(1)
    // this.grandStartDate = this.data.totalFields.fields[this.params.startDateFieldId]
    // this.grandFinishDate = this.data.totalFields.fields[this.params.finishDateFieldId]
  },
  computed: {
    headerHeight(){
      let headerHeight = 0
      if(this.params.periods === 'weeks') headerHeight = 75
      if(this.params.periods === 'months') headerHeight = 55
      return headerHeight
    },
    chartWidth(){
      let chartWidth = 700
      if(this.params){
        chartWidth = parseInt(this.params.width)
      }
      return chartWidth
    },
    chartHeight(){
      return this.headerHeight + (this.lines.length * this.lineHeight)+30
    },
    dayWidth(){
      let dayWidth = this.chartWidth / this.grandDaysAdjusted
      return isNaN(dayWidth) ? 0 : dayWidth
    },
    weekWidth(){
      return this.chartWidth / this.weeks.length
    },
    monthWidth(){
      return this.chartWidth / this.months.length
    },
    grandStartDate(){
      let grandStartDate = ""
      if(this.data?.lineData?.totalFields && this.view.data.addonParams.paramsIsOk){
        grandStartDate = this.data?.lineData?.totalFields?.fields[this.params.startDateFieldId]
      }
      return grandStartDate
    },
    grandEndDate(){
      let grandEndDate = ""
      if(this.data?.lineData?.totalFields && this.view.data.addonParams.paramsIsOk){
        grandEndDate = this.data?.lineData?.totalFields?.fields[this.params.endDateFieldId]
      }
      return grandEndDate
    },
    grandStartDateAdjusted(){
      let grandStartDateAdjusted = this.grandStartDate
      if(this?.weeks?.length === 0 || this?.months?.length === 0) return grandStartDateAdjusted

      let year = ""
      let month = ""
      let day = ""
      if(this.params.periods === "weeks"){
        year = this.weeks[0].startYear
        month = String(this.weeks[0].startMonth).padStart(2, '0')
        day = String(this.weeks[0].startDay).padStart(2, '0')
        grandStartDateAdjusted =  year + "/" + month + "/" + day
      }
      if(this.params.periods === "months"){
        year = this.months[0].year
        month = String(this.months[0].month).padStart(2, '0')
        // day = String(this.months[0].numberOfDays).padStart(2, '0')
        day = '01'
        grandStartDateAdjusted =  year + "/" + month + "/" + day
      }
      return grandStartDateAdjusted
    },
    grandEndDateAdjusted(){
      let grandEndDateAdjusted = this.grandEndDate
      if(this?.weeks?.length === 0 || this?.months?.length === 0) return grandEndDateAdjusted

      let year = ""
      let month = ""
      let day = ""
      if(this.params.periods === "weeks"){
        year = this.weeks[this.weeks.length-1].endYear
        month = String(this.weeks[this.weeks.length-1].endMonth).padStart(2, '0')
        day = String(this.weeks[this.weeks.length-1].endDay).padStart(2, '0')
        grandEndDateAdjusted =  year + "/" + month + "/" + day
      }
      if(this.params.periods === "months"){
        year = this.months[this.months.length-1].year
        month = String(this.months[this.months.length-1].month).padStart(2, '0')
        day = String(this.months[this.months.length-1].numberOfDays).padStart(2, '0')
        grandEndDateAdjusted =  year + "/" + month + "/" + day
      }
      return grandEndDateAdjusted
    },
    grandDaysAdjusted(){
      let grandStartDateAdjustedTS = new Date(this.grandStartDateAdjusted)
      let grandEndDateAdjustedTS = new Date(this.grandEndDateAdjusted)
      return ( grandEndDateAdjustedTS.getTime() - grandStartDateAdjustedTS.getTime() ) / (1000 * 3600 * 24) + 1
    },
    weeks(){
      if(this.params.periods !== "weeks") return []
      const weeks = []
      const start = new Date(this.grandStartDate)
      const end = new Date(this.grandEndDate)

      // Calculate the number of milliseconds in a week
      // const oneWeek = 7 * 24 * 60 * 60 * 1000

      // Round the start date down to the beginning of the week
      // start.setHours(0, 0, 0, 0)
      // start.setDate(start.getDate() - start.getDay())

      // Round the end date up to the end of the week
      // end.setHours(23, 59, 59, 999)
      // end.setDate(end.getDate() + (6 - end.getDay()))

      // Calculate the start and end dates of the first and last weeks
      const firstWeekStartDate = new Date(start);
      firstWeekStartDate.setDate(start.getDate() - (start.getDay() + 6) % 7); // Start from the previous Monday
      const lastWeekEndDate = new Date(end);
      lastWeekEndDate.setDate(end.getDate() + (6 - (end.getDay() + 6) % 7)); // End on the following Sunday

      // Generate the weeks between the start and end dates
      let currentWeekStart = new Date(firstWeekStartDate);
      while (currentWeekStart <= lastWeekEndDate) {
        const weekEnd = new Date(currentWeekStart);
        weekEnd.setDate(weekEnd.getDate() + 6); // Set week end to the following Sunday
        const week = {
          startYear: currentWeekStart.getFullYear(),
          startMonth: currentWeekStart.getMonth() + 1,
          startDay: currentWeekStart.getDate(),
          endYear: weekEnd.getFullYear(),
          endMonth: weekEnd.getMonth() + 1,
          endDay: weekEnd.getDate(),
          number: this.weekNumberGet(currentWeekStart)
        }
        weeks.push(week)
        currentWeekStart.setDate(currentWeekStart.getDate() + 7); // Move to the next week
      }

      // // Generate weeks between the start and end dates WEEK STARTS FROM SUN
      // let currentWeek = new Date(start)
      // while (currentWeek <= end) {
      //   const weekStart = new Date(currentWeek)
      //   const weekEnd = new Date(currentWeek.getTime() + (6 * 24 * 60 * 60 * 1000))
      //   const week = {
      //     startYear: weekStart.getFullYear(),
      //     startMonth: weekStart.getMonth() + 1,
      //     startDay: weekStart.getDate(),
      //     endYear: weekEnd.getFullYear(),
      //     endMonth: weekEnd.getMonth() + 1,
      //     endDay: weekEnd.getDate(),
      //     number: this.weekNumberGet(weekStart)
      //   }
      //   weeks.push(week)
      //   currentWeek.setTime(currentWeek.getTime() + oneWeek)
      // }

      return weeks
    },
    months(){
      const months = []
      let daysFromStart = 0

      if(this.params.periods === "weeks"){
        const firstWeek = this.weeks[0]
        const lastWeek = this.weeks[this.weeks.length-1]
        const start = new Date(firstWeek.startYear+"/"+firstWeek.startMonth+"/"+firstWeek.startDay)
        const end = new Date(lastWeek.endYear+"/"+lastWeek.endMonth+"/"+lastWeek.endDay)

        let currentMonth = new Date(start)
        while (currentMonth <= new Date(end.getFullYear(), end.getMonth()+1, 0)) {
          const year = currentMonth.getFullYear()
          const month = currentMonth.getMonth()
          let numberOfDays = 0
          if(new Date(year, month, 1) < new Date(start) ){
            numberOfDays = new Date(year, month+1, 0).getDate() - start.getDate()
          }
          else if(new Date(year, month+1, 0) > new Date(end)){
            numberOfDays = end.getDate()
          }
          else {
            numberOfDays = new Date(year, month+1, 0).getDate()
          }
          const width = numberOfDays * this.dayWidth
          const widthFromStart = daysFromStart * this.dayWidth
          months.push({ year, month, numberOfDays, width, daysFromStart, widthFromStart })
          daysFromStart = daysFromStart + numberOfDays

          currentMonth.setMonth(currentMonth.getMonth() + 1)
        }
      }
      // Iterate over each month between the start and end dates

      if(this.params.periods === "months"){
        const start = new Date(this.grandStartDate)
        const end_temp = new Date(this.grandEndDate)
        const end = new Date(end_temp.getFullYear(), end_temp.getMonth()+1, 0)
        let currentMonth = new Date(start)

        while (currentMonth <= end) {
          const year = currentMonth.getFullYear()
          const month = currentMonth.getMonth() + 1
          const numberOfDays = new Date(year, month, 0).getDate()
          const width = numberOfDays * this.dayWidth
          months.push({ year, month, numberOfDays, width });
          currentMonth.setMonth(currentMonth.getMonth() + 1)
        }
      }

      return months
    }
  },
  watch: {
    data: {
      handler(newVal){
        this.lines = this.linesGet(newVal).slice(1)
      },
      deep: true
    }
  },
  methods: {
    // formatDate(date) {
    //   const year = date.getFullYear()
    //   const month = String(date.getMonth() + 1).padStart(2, '0')
    //   const day = String(date.getDate()).padStart(2, '0')
    //   return `${year}/${month}/${day}`
    // },
    weekNumberGet(startDate) {
      const start = new Date(startDate)
      const startOfYear = new Date(start.getFullYear(), 0, 1)
      const diff = start - startOfYear
      const dayOfYear = Math.floor(diff / (24 * 60 * 60 * 1000))
      const weekNumber = Math.ceil((dayOfYear + startOfYear.getDay() + 1) / 7)
      return weekNumber
    },
    shortMonthNameGet(number){
      const date = new Date()
      date.setMonth(number - 1) // Adjusting month to zero-based index
      return date.toLocaleString('en-US', { month: 'short' })
    },
    daysBetweenDatesGet(date1, date2){
      return daysBetweenDates(date1, date2)
    },
    linesGet(data){
      let lines = [{
        type: "group",
        sublinesNum: 1,
        lineData: data.lineData
      }]
      if(data.lowerGroupsFields.length > 0){
        data.lowerGroupsFields.forEach(el => {
          let sublines = this.linesGet(el)
          let sublinesNum = sublines[0].sublinesNum
          lines[0].sublinesNum = lines[0].sublinesNum + sublinesNum
          lines = lines.concat(sublines)
        })
      }
      if(data.items.length > 0){
        lines[0].sublinesNum = lines[0].sublinesNum + data.items.length
        data.items.forEach(el => {
          if(el.data.fields[this.params.startDateFieldId] && el.data.fields[this.params.endDateFieldId]){
            let line = {
              type: "task",
              lineData: {
                level: parseInt(data.lineData.level) + 1,
                expanded: null,
                totalFields: {
                  fields: el.data.fields
                }
              }
            }
            if(!line.lineData.totalFields[this.params.completionFieldId]) {
              line.lineData.totalFields[this.params.completionFieldId] = 0
            }
            lines.push(line)
          }
        })
      }
      return lines
    }
  }

}
</script>

<style scoped>

</style>