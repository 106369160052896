<template>
  <q-card>
    <q-card-section>
      test7.
      Rows quantity: {{items.length}}
      Rows with error: {{itemsErr}}
    </q-card-section>
    <q-card-section>
      <q-btn @click="selectItems()" label="Select items"/>
      <div v-for="item in itemsOk" :key="item">
        items id (Ok): {{item}}
      </div>
    </q-card-section>

  </q-card>
</template>

<script>
/* eslint-disable */

import {db} from "@/db"
export default {
  name: "test-t7",
  data: () => {
    return {
      items: [],
      itemsErr: 0,
      itemsOk: []
    }
  },
  async created() {
  },
  methods: {
    async selectItems(){
      this.items = await db.items.toArray()
      this.items.forEach(item => {
        if(!item.$data) this.itemsErr = this.itemsErr + 1
        else if(!item.$data.lastTS) this.itemsErr = this.itemsErr + 1
        else this.itemsOk.push(item.id)
      })
    }


  }
}
</script>

<style scoped>

</style>