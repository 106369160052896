export const daysBetweenDates = ((date1, date2) => {
  const startDate = new Date(date1);
  const endDate = new Date(date2);

  // Calculate the difference in milliseconds
  const timeDiff = Math.abs(endDate.getTime() - startDate.getTime())

  // Convert milliseconds to days
  const days = Math.ceil(timeDiff / (1000 * 3600 * 24))

  return isNaN(days) ? 0 : days
})