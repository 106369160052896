import Dexie from 'dexie'
import 'dexie-observable'

// get current user
let  slCurrentUserId = localStorage.getItem("slCurrentUserId")
if ( !slCurrentUserId ) {
    slCurrentUserId = "localUser"
    localStorage.setItem("slCurrentUserId", slCurrentUserId)
}

// get current user id type
// let slCurrentUsers = [{id: "localUser", idType: "local"}]
// const slCurrentUsersStr = localStorage.getItem("slCurrentUsers")
// if ( slCurrentUsersStr ) slCurrentUsers = JSON.parse(slCurrentUsersStr)
// else localStorage.setItem("slCurrentUsers", JSON.stringify(slCurrentUsers))
// let slCurrentUser = slCurrentUsers.find(slCurrentUser => slCurrentUser.id === slCurrentUserId)

// export current user data
export const currentUserId = slCurrentUserId
// export const currentUserIdType = slCurrentUser?.idType

// install and export DB
export const db = new Dexie(slCurrentUserId)
db.version(88).stores({

    params: '&id',
    spaces: '&id, *$data.userIds, status, $data.lastTS',
    lists: '&id, spaceId, [spaceId+id], status, $data.lastTS',
    items: '&id, status, data.listId, [spaceId+data.listId], [spaceId+data.listId+drillDownIndex], *data.fixedSelCriteria, *dbIndex, *drilldownFilter',
    files: '&id, spaceId',
    tasks: '&id, [data.respUserId+data.dueDate], [data.respUserId+data.status], spaceId, status, data.listId, data.itemId, data.respUserId, data.startTS, data.finishTS, data.status, $data.lastTS',
    comments: '&id, spaceId, listId, itemId, status, $data.lastTS',
    mods: '&id, [table+recordId+version], mods',

    users: '&id',


    // checklists: '&id, spaceId, listId, itemId, userId, startTS, finishTS, status',
    // checklistsComments: '&id, spaceId, listId, itemId, type, status, userId',
    // testTable: '&id'
    // pipe: '&id',
    // lastSpacesTS: '&id', // id is userId - phoneNumber or email
    // authListsGroups: '&id, spaceId',
    // authItemsGroups: '&id, spaceId',
    // views: '&id, spaceId    , data.listId',
    // changelog: '&id, spaceId, listId, itemId',

    test6: '&&id, *selField'
})





// db.on('changes', function (changes) {
//     changes.forEach(function (change) {
//         switch (change.type) {
//             case 1: // CREATED
//                 console.log('An object was created: ' + JSON.stringify(change.obj));
//                 break;
//             case 2: // UPDATED
//                 console.log('An object with key ' + change.table + ' : ' + change.key + ' was updated with modifications: ' + JSON.stringify(change.mods));
//                 break;
//             case 3: // DELETED
//                 console.log('An object was deleted: ' + JSON.stringify(change.oldObj));
//                 break;
//         }
//     });
// });


// let db = new Dexie('exportSample');
// db.version(1).stores({
//     foos: 'id'
// });
// db.on('populate', ()=>{
//     return db.foos.bulkAdd([
//         {
//             id: 1,
//             foo: 'foo',
//             date: new Date(), // Dates, Blobs, ArrayBuffers, etc are supported
//         },{
//             id: 2,
//             foo: 'bar',
//         }
//     ]);
// });
