<template>
  <q-card>
    <q-card-section class="column items-center">
      <q-avatar size="250px">
        <img :src="currentUserAvatarImg">
      </q-avatar>
      <q-icon class="q-ma-sm" name="lens" :color="currentUserStatusColor"/>
      <span class="font-weight-bold">
        {{currentUserStatus === 'unselected' ? "user is not set" : currentUserId}}
      </span>
      <span>{{currentUserName === "" ? "Enter user name" : currentUserName }}</span>

    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn @click="userIdFormOpen()" flat color="green-5" label="Set user id" title="Setup id for current user"/>
      <q-btn label="Edit" flat color="green-5" @click="userEditFormOpen()"/>
      <q-btn flat  @click="userAuthFormPhoneShow=true" v-if="!isCurrentUserAuthorized" color="green-5" label="Login"/>
      <q-btn flat @click="logout()" v-if="isCurrentUserAuthorized" color="green-5" label="Logout"/>
    </q-card-actions>
    <q-card-actions class="justify-center">
<!--      <q-btn @click="reloadAllData()" title="Reload all user data"><q-icon name="refresh"/></q-btn>-->

      <q-btn label="Ok" @click="$emit('finished')"/>
    </q-card-actions>
  </q-card>
  <q-dialog v-model="userAuthFormPhoneShow">
    <sl-user-auth-phone-form :phoneNumber="currentUser?.id" @finished="userAuthFormPhoneShow=false"/>
  </q-dialog>
  <q-dialog v-model="userIdFormShow">
    <q-card>
      <q-card-section>
        <q-input v-model="userIdForm" label="Phone number (user id)" color="green"
                 v-if="userIdTypeForm === 'phoneNumber'"
                 ref='userIdFormRef'
                 :rules="[
                     val => val.length > 0 || 'Fill mobile number',
                     val => val.charAt(0) === '+' || 'Fill mobile number starting with +',
                     val => /^\d*$/.test(val.substring(1)) || 'Use digits only'
                     ]"
        />
        <q-input v-model="userIdForm" label="Email (user id)" color="green"
                 v-else-if="userIdTypeForm === 'email'"
                 ref='userIdFormRef'
                 :rules="[
                     val => val.length > 0 || 'Fill e-mail',
                     val => val.indexOf('@') > -1 || 'Fill correct e-mail (with @)'
                     ]"
        />
        <q-input v-model="userIdForm" label="Google email (user id)" color="green"
                 v-else-if="userIdTypeForm === 'google'"
                 ref='userIdFormRef'
                 :rules="[
                     val => val.length > 0 || 'Fill e-mail',
                     val => val.indexOf('@gmail.com') > -1 || 'Fill google account email'
                     ]"
        />
<!--        <q-select v-model="userIdTypeForm" :options="userIdTypeOptions" label="User id type" color="green"/>-->
      </q-card-section>
      <q-card-actions class="justify-center">
        <q-btn icon="phone" round @click="userIdTypeForm = 'phoneNumber'" :text-color="userIdTypeForm === 'phoneNumber' ? 'orange' : 'grey'"/>
        <q-btn icon="email" round @click="userIdTypeForm = 'email'" :text-color="userIdTypeForm === 'email' ? 'orange' : 'grey'"/>
        <q-btn icon="mdi-google" round @click="userIdTypeForm = 'google'" :text-color="userIdTypeForm === 'google' ? 'orange' : 'grey'"/>
      </q-card-actions>
      <q-card-actions class="justify-center">
        <q-btn @click="userIdFormShow=false">Cancel</q-btn>
        <q-btn @click="currentUserIdSet()">Set</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="userEditFormShow">
    <sl-user-edit-form :name="currentUserName" :avatarImg="currentUserAvatarImg" @finished="userEditFinished()"/>
  </q-dialog>
  <sl-msgs :msgs="msgs" v-if="msgs.length>0" @finished="msgs=[]"/>
</template>

<script>
import { currentUserId } from "@/db"
import { getAuth, signOut } from "firebase/auth"
import SlUserAuthPhoneForm from "@/components/sl-user-auth-phone-form"
import SlUserEditForm from "@/components/sl-user-edit-form"
import SlMsgs from "@/components/sl-msgs"
import {listenersCancel, spacesListenerSet} from "@/db-sync"
export default {
  name: "sl-user-auth-form",
  components: {SlMsgs, SlUserEditForm, SlUserAuthPhoneForm },
  emits: ["finished"],
  data: () => {
    return{
      currentUserId: currentUserId,
      msgs: [],
      userEditFormShow: false,
      userAuthFormPhoneShow: false,
      userIdForm: "",
      userIdFormShow: false,
      userIdTypeForm: "phoneNumber",
      userIdTypeOptions: ["phoneNumber", "email", "google"]
    }
  },
  computed: {
    currentUser(){
      return this.$store.state.currentUser
    },
    authUser(){
      return this.$store.state.authUser
    },
    currentUserAvatarImg(){
      let avatarImg = this.currentUser?.avatarImg
      if(!avatarImg){
        avatarImg = require('@/assets/defaultPictureUser.svg')
      }
      return avatarImg
    },
    currentUserName(){
      return this.currentUser?.name
    },
    isCurrentUserSelected() {
      let isCurrentUserSelected = false
      if (this.currentUser?.phoneNumber === this.currentUserId || this.currentUser?.email === this.currentUserId) {
        isCurrentUserSelected = true
      }
      return isCurrentUserSelected
    },
    isCurrentUserAuthorized(){
      let isCurrentUserAuthorized = false
      if(this.authUser){
        if(this.currentUser?.phoneNumber === this.authUser?.phoneNumber || this.currentUser?.email === this.authUser?.email) {
          isCurrentUserAuthorized = true
        }
      }
      return isCurrentUserAuthorized
    },
    currentUserStatusColor(){
      let currentUserStatusColor = ""
      if(this.isCurrentUserSelected) currentUserStatusColor = "yellow"
      else currentUserStatusColor = "grey"
      if(this.isCurrentUserAuthorized) currentUserStatusColor = "green"
      return currentUserStatusColor
    }
  },
  methods: {
    reloadAllData(){
      if(this.currentUserStatus !== "authorized"){
        this.msgs.push({
          type: "e",
          text: "Authorize in Smart lists"
        })
        return
      }
      listenersCancel()
      spacesListenerSet(this.$store.state.currentUser.userId, true)
      this.msgs.push({
        type: "i",
        text: "Reload all user data has been launched"
      })
    },
    logout(){
      let auth = getAuth()
      signOut(auth)
    },
    userIdFormOpen(){
      this.userIdForm = ""
      this.userIdFormShow = true
    },
    userEditFinished(){
      this.userEditFormShow = false
    },
    currentUserIdSet(){

      //check userId string
      if(this.$refs.userIdFormRef.hasError) return

      // save current user in local localStorage
      localStorage.setItem("slCurrentUserId", this.userIdForm)

      // // save current user in current user list in localStorage (if required)
      // let slCurrentUsersStr = localStorage.getItem("slCurrentUsers")
      // let slCurrentUsers = []
      // if(!slCurrentUsersStr){
      //   slCurrentUsers = [{id: this.userIdForm, idType: this.userIdTypeForm}]
      //   localStorage.setItem("slCurrentUsers", JSON.stringify(slCurrentUsers))
      // }
      // else {
      //   let slCurrentUsers = JSON.parse(slCurrentUsersStr)
      //   slCurrentUsers.push({id: this.userIdForm, idType: this.userIdTypeForm})
      //   localStorage.setItem("slCurrentUsers", JSON.stringify(slCurrentUsers))
      // }

      // refresh page to reinitialize apps (to install correct dexie db)
      location.reload()

      // db.params.put({id: "currentUser", userId: this.userIdForm, idType: this.userIdTypeForm})
      // this.userIdFormShow = false
    },
    userEditFormOpen(){
      if(!window.navigator.onLine){
        this.msgs.push({
          type: "e",
          text: "Go online to edit your name and avatar"
        })
        return
      }
      if(!this.authUser){
        this.msgs.push({
          type: "e",
          text: "Edit authorized user only"
        })
        return
      }
      this.userEditFormShow = true
    }
  }
}
</script>