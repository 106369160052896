<template>
  <q-card style="width: 400px" v-if="task.data">
    <q-card-section>
      <q-input v-model="task.data.name" type="textarea" label="name"/>
    </q-card-section>
    <q-card-actions class="justify-center">
      <q-icon name="lens" :color="statusColor" size="sm" class="q-mx-md" @click="statusChange()"/>
      <span class="q-mx-md" @click="dateFormShow=true">{{task.data.dueDate}}</span>
      <span class="q-mx-md" @click="timeFormShow=true">{{task.data.dueTime}}</span>
      <q-input v-model="task.data.labour" type="text" label="labour" dense class="q-mx-md" style="width: 50px"/>
    </q-card-actions>
    <q-card-actions class="justify-center">
      <q-btn label="save as copy" @click="saveAsCopy()"/>
      <q-btn label="cancel" @click="$emit('finished')"/>
      <q-btn label="save" @click="save()"/>
    </q-card-actions>
  </q-card>
  <q-dialog v-model="dateFormShow">
    <q-date v-model="newDueDate" color="green-4"
            first-day-of-week="1"
            @update:model-value="newDueDateSet()"
    />
  </q-dialog>
  <q-dialog v-model="timeFormShow">
    <q-time v-model="newDueTime" color="green-4"
            format24h
            @update:model-value="newDueTimeSet()"
    />
  </q-dialog>
</template>

<script>
import {currentUserId, db} from "@/db"
import {obj, objStatusFieldsSet} from "@/utils4";
import {toRaw} from "vue";
import {generateId} from "@/utils";
export default {
  name: "sl-task-form",
  props: ["taskId", "spaceId", "listId", "itemId"],
  data: () => {
    return {
      task: {},
      dateFormShow: false,
      newDueDate: "",
      timeFormShow: false,
      newDueTime: ""
    }
  },
  async created() {
    let task = {}
    if(this.taskId){
      task = await db.table("tasks").get(this.taskId)
    }
    else{
      obj(task, "task")
      task.spaceId = this.spaceId
      task.data.listId = this.listId
      task.data.itemId = this.itemId
      task.data.dueDate = this.currentDate()
      task.data.dueTime = "--:--"
      task.data.status = "new"
      task.data.respUserId = currentUserId
    }
    this.task = task
  },
  computed: {
    statusColor(){
      if(!this.task.data) return ""
      let statusColor = ""
      if(this.task.data.status === "new") statusColor = "grey"
      if(this.task.data.status === "processing") statusColor = "yellow"
      if(this.task.data.status === "done") statusColor = "green"
      if(this.task.data.status === "postponed") statusColor = "brown"
      return statusColor
    }
  },
  methods: {
    newDueTimeSet(){
      this.task.data.dueTime = this.newDueTime
      this.newDueTime = ""
      this.timeFormShow = false
    },
    newDueDateSet(){
      this.task.data.dueDate = this.newDueDate
      this.newDueDate = ""
      this.dateFormShow = false
    },
    statusChange(){
      if(this.task.data.status === "new") this.task.data.status = "processing"
      else if(this.task.data.status === "processing") this.task.data.status = "done"
      else if(this.task.data.status === "done") this.task.data.status = "postponed"
      else if(this.task.data.status === "postponed") this.task.data.status = "new"
    },
    save(){
      debugger
      if(this.taskId){
        db.table("tasks").where("id").equals(this.taskId).modify(task => {
          task.data.name = this.task.data.name
          task.data.status = this.task.data.status
          task.data.dueDate = this.task.data.dueDate
          task.data.dueTime = this.task.data.dueTime
          task.data.labour = this.task.data.labour
          objStatusFieldsSet(task)
          this.$emit("finished")
        })
      }
      else{
        objStatusFieldsSet(this.task)
        db.table("tasks").put(toRaw(this.task))
        this.$emit("finished")
      }

    },
    saveAsCopy(){
      this.task.id = generateId()
      delete this.task.status
      objStatusFieldsSet(this.task)
      db.table("tasks").put(toRaw(this.task))
      this.$emit("finished")
    },
    currentDate(){
      let date = new Date()
      let curY = date.getFullYear()
      let curM = date.getMonth()+1
      let curD = date.getDate()
      if(curM<10) curM = "0"+curM
      if(curD<10) curD = "0"+curD
      return curY + "/" + curM + "/" + curD
    },
  }
}
</script>

<style scoped>

</style>