import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify.js'
import { loadFonts } from './plugins/webfontloader.js'
import store from './store.js'
import router from "./router.js"
import './sw.js'
import Quasar from 'quasar/src/vue-plugin.js';import setCssVar from 'quasar/src/utils/set-css-var.js';
import quasarUserOptions from './quasar-user-options'
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'
setCssVar('primary', '#4CAF50')



loadFonts()

import SlListInit  from "@/components/sl-list-init"
import SlMsgs from "@/components/sl-msgs"
    // if (confirm("Close Window?")) {
    //     alert(11)
    //     window.close();
    // }

// eslint-disable-next-line
let $val = ""


let app = createApp(App)
app.use(Quasar, quasarUserOptions)
    .use(vuetify)
    .use(store)
    .use(router)
    .use(ContextMenu)
    .component("SlListInit", SlListInit)
    .component("SlMsgs", SlMsgs)

app.config.unwrapInjectedRef = true
app.mount('#app')

// if("serviceWorker" in navigator){
//     navigator.serviceWorker.register("/sw3.js")
//         .then((sw)=>{console.log("registred", sw)})
//         .catch((err)=>{console.log("registred err", err)})
// }