<template>
  <q-card>
    <q-card-section>
      <div class="q-pa-sm" v-if="!isEditMode" style="white-space: pre-wrap; min-width: 400px">{{textVal}}</div>
      <div :contenteditable="true" class="bg-grey-lighten-3 q-pa-sm" v-else style="white-space: pre-wrap; min-width: 400px">
        {{textVal}}
      </div>
<!--      <textarea v-else v-model="textVal" :type="'textarea'" style="width: 300px; height: 100px"/>-->
    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn @click="$emit('finished')" label="Ok" v-if="!isEditMode"/>
      <q-btn @click="isEditMode=true" label="Edit" v-if="!isEditMode"/>
      <q-btn @click="cancel()" label="Cancel" v-if="isEditMode"/>
      <q-btn @click="save()" label="Save" v-if="isEditMode"/>
    </q-card-actions>
  </q-card>
</template>

<script>
import {db} from '@/db'
import {objStatusFieldsSet} from "@/utils4";
export default {
  name: "sl-list-cell-form-text",
  props: ["list5Id", "itemId", "fieldId"],
  data: () => {
    return {
      isEditMode: false,
      textVal: ""
    }
  },
  created() {
    db.table("items").get(this.itemId).then(item => this.textVal = item.data.fields[this.fieldId])
  },
  methods: {
    save(){
      db.table("items").where("id").equals(this.itemId).modify(item => {
        debugger
        item.data.fields[this.fieldId] = this.$el.querySelector("div[contenteditable=true]").innerText.trim()
        objStatusFieldsSet(item)
        this.$emit("finished")
      })
    },
    cancel(){
      db.table("items").get(this.itemId).then(item => {
        this.textVal = item.data.fields[this.fieldId]
        this.isEditMode = false
      })
    }
  }

}
</script>

<style scoped>

</style>