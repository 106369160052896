<template>
  <q-layout view="hHh lpR fFf" _class="no-scroll" style="_position: relative">
    <q-drawer
        v-model="drawer"
        show-if-above
        bordered
        :width="drawerWidth"
    >
      <q-scroll-area
          :content-active-style="contentActiveStyle"
          :thumb-style="thumbStyle"
          :content-style="contentStyle"
          class="fit"
          style="height: 300px; background-color: grey"
      >
        <sl-drawer-spaces :spaces="spaces"/>
      </q-scroll-area>
    </q-drawer>
<!--    <q-dialog v-if="$store.state.screen.mode==='phone'" v-model="drawer" position="left" maximized :transition-duration="500">-->

<!--      <q-card :style="{width: $store.state.screen.width+'px'}" v-touch-swipe.left="drawerClose">-->
<!--        <q-scroll-area-->
<!--          :content-active-style="contentActiveStyle"-->
<!--          :thumb-style="thumbStyle"-->
<!--          :content-style="contentStyle"-->
<!--          class="fit"-->
<!--          _style="height: 300px; background-color: grey"-->
<!--        >-->
<!--          <sl-drawer-spaces />-->
<!--        </q-scroll-area>-->
<!--      </q-card>-->

<!--    </q-dialog>-->
    <div v-if="$store.state.screen.mode==='phone'">
      <q-toolbar class="bg-white text-light-green-6">
        <q-btn class="q-mx-sm" round icon="menu" dense @click="drawer=!drawer"/>
        <span class="q-ml-md" style="font-weight: bold">smart lists</span>
        <q-space/>
        <div class="row justify-center" style="cursor: pointer">
          <q-btn class="q-mx-sm text-grey" round icon="list" dense
                 title="My tasks" @click="checklistShow=!checklistShow"/>
        </div>
        <div class="row justify-center" style="cursor: pointer">
          <q-avatar size="20" @click="userAuthFormShow=true">
            <img :src="avatarImg">
          </q-avatar>
        </div>
      </q-toolbar>
    </div>

    <q-header v-else elevated>
      <q-toolbar class="bg-white text-light-green-6">
        <q-btn class="q-mx-sm" round icon="menu" dense @click="drawer=!drawer"/>
        <span style="font-weight: bold">smart lists</span>
        <q-space/>

        <div class="row justify-center" style="cursor: pointer">
          <q-btn class="q-mx-sm text-orange" round icon="support_agent" dense
                  title="Help request, issue, idea" @click="requestFormShow=!requestFormShow"
          />
        </div>

        <div class="row justify-center" style="cursor: pointer">
          <q-btn class="q-mx-sm text-grey" round icon="list" dense
                  title="My tasks" @click="checklistShow=!checklistShow"/>
        </div>
        <div class="row justify-center mx-2 text-weight-bold text-black">
          <q-btn rounded dense @click="userAuthFormShow=true">
            <span class="q-mx-xs font-weight-bold">{{currentUserId}}</span>
          </q-btn>
        </div>

        <div class="row justify-center">
          <q-avatar size="20">
            <img :src="avatarImg">
          </q-avatar>
        </div>

        <div class="row justify-center mx-2 text-green" v-if="$store.state.authUser">
          <q-icon name="cloud"/>
        </div>
        <div class="row justify-center mx-2 text-red" v-else>
          <q-icon name="cloud_off"/>
        </div>

        <div class="row justify-center mx-2 text-green" v-if="$store.state.connectionStatus === 'online'">
          <q-icon name="mdi-wifi"/>
        </div>
        <div class="row justify-center mx-2 text-red" v-if="$store.state.connectionStatus === 'offline'">
          <q-icon name="mdi-wifi-off"/>
        </div>

      </q-toolbar>
    </q-header>

    <q-page-container _v-touch-swipe.right="drawerOpen" @scroll="scroll($event)">
      <router-view :key="$route.fullPath"/>
    </q-page-container>
    <sl-footer-editor :key="$store.state.footerEditorTS" v-if="$q.platform.is.mobile && $store.state.footerEditorIsOpen"/>
<!--    <q-footer class="bg-white row justify-evenly q-pa-sm" bordered reveal v-if="!$store.state.footerEditorIsOpen">-->
<!--      <q-btn class="q-ma-xs" icon="list" round text-color="grey"/>-->
<!--      <q-btn class="q-ma-xs" icon="share" round text-color="grey"/>-->
<!--    </q-footer>-->

    <q-dialog v-model="userAuthFormShow">
      <sl-user-auth-form @finished="userAuthFormShow=false"/>
    </q-dialog>
    <q-dialog v-model="checklistShow" :maximized="$store.state.screen.mode==='phone'">
      <sl-checklist @formClose="checklistShow=false"/>
    </q-dialog>
    <q-dialog v-model="requestFormShow">
      <sl-request-form @finished="requestFormShow=false"/>
    </q-dialog>
  </q-layout>
</template>
<script>
/* eslint-disable */
// import { computed } from 'vue'
import {currentUserId, db} from "@/db"
import {useObservable} from "@vueuse/rxjs/index"
import {liveQuery} from "dexie"
import SlDrawerSpaces from "@/components/sl-drawer-spaces"
import SlUserAuthForm from "@/components/sl-user-auth-form"
import SlChecklist from "@/components/sl-checklist"
import SlRequestForm from "@/components/sl-request-form"
import SlFooterEditor from "@/components/sl-footer-editor"
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth"
import {syncSet, changedSpacesUpdate, authUserListenerSet, spacesListenerSet, listenersCancel} from "./db-sync"
import {consoleLog} from "@/utils3"
// import {paramsInit} from "@/utils3"
// import {objVal} from "@/utils4"

const auth = getAuth()

export default {
  name: 'App',
  components: {
    SlFooterEditor,
    SlRequestForm,
    SlChecklist,
    SlUserAuthForm,
    SlDrawerSpaces
  },
  data: () => ({
    currentUserId: currentUserId,
    fabButton: false,
    drawer: false,
    drawerTrue: true,
    drawerRight: false,
    userAuthFormShow: false,
    checklistShow: false,
    requestFormShow: false,
    usersCurrentUser: null,
    paramsCurrentUser: null,
    toolbarElevated: false,
    user: {},
    spaces: [], // are defined in app.vue and are passed to sl-drawer-spaces for better performance in sl-drawer-spaces
    contentStyle: {
      backgroundColor: 'rgba(0,0,0,0.02)',
      color: '#555'
    },
    contentActiveStyle: {
      backgroundColor: '#eee',
      color: 'black'
    },
    thumbStyle: {
      right: '2px',
      borderRadius: '5px',
      backgroundColor: '#ced2c8',
      width: '5px',
      opacity: '0.50'
    }
  }),
  created() {
    // show consoleLog params
    if(localStorage.getItem("tracerParams")){
      let params = JSON.parse(localStorage.getItem("tracerParams"))
      if ( params.isTraceOn ) {
        console.log("Console log params: ", params)
      }
    }

    // set current user to Vuex from localStorage
    let  slCurrentUserId = currentUserId
    let slCurrentUsers = [{id: "localUser", idType: "local", userName: "local", avatarImg: ""}]
    const slCurrentUsersStr = localStorage.getItem("slCurrentUsers")
    if ( slCurrentUsersStr ) slCurrentUsers = JSON.parse(slCurrentUsersStr)
    else localStorage.setItem("slCurrentUsers", JSON.stringify(slCurrentUsers))
    let slCurrentUser = slCurrentUsers.find(slCurrentUser => slCurrentUser.id === slCurrentUserId)
    this.$store.commit("currentUserSet", slCurrentUser)

    // start initializing functions
    consoleLog({text: "App.js > created()"})
    this.screenDimensionsSet()
    this.screenEventsSet()
    syncSet()
    this.authEventSet()

    this.spaces = useObservable(
      liveQuery(() => {
        return db.table("spaces").toArray()
      })
    )

    // connection status
    let connectionStatus = "offline"
    if(window.navigator.onLine) connectionStatus = "online"
    this.$store.commit("connectionStatusSet", connectionStatus)

    window.addEventListener('online', () => {
      this.$store.commit("connectionStatusSet", "online")
    })
    window.addEventListener('offline', () => {
      this.$store.commit("connectionStatusSet", "offline")
    })



    //current user observer
    this.paramsCurrentUser = useObservable(
      liveQuery(() => db.params.get("currentUser"))
    )
  },
  // setup() {
  //   console.log("sl: starting App -> setup()")
  //   return {
  //     contentStyle: {
  //       backgroundColor: 'rgba(0,0,0,0.02)',
  //       color: '#555'
  //     },
  //     contentActiveStyle: {
  //       backgroundColor: '#eee',
  //       color: 'black'
  //     },
  //     thumbStyle: {
  //       right: '2px',
  //       borderRadius: '5px',
  //       backgroundColor: '#ced2c8',
  //       width: '5px',
  //       opacity: '0.50'
  //     }
  //   }
  // },
  watch: {
    // usersCurrentUser(newVal){
    //   if(!newVal) return
    //   this.$store.commit("currentUserDataSet", newVal.data)
    // },

    paramsCurrentUser(newVal){
      if(!newVal) return
      this.$store.commit("currentUserSet", newVal.data)
    },
    // currentUserId(newVal){
    //   if(newVal && newVal !== "" && this.spaces.length === 0){
    //     this.spaces = useObservable(
    //       liveQuery(() => {
    //         return db.table("spaces").where("$data.userIds").equals(newVal).or("status").equals("unpublished").toArray()
    //       })
    //     )
    //   }
    // }
    // initialSpacesListenerDone(newVal){
    //   if(newVal === true){
    //     changedSpacesUpdate()
    //   }
    // }
    // userPhoneNumber(newVal){
    //   if(newVal === null){
    //     console.log("email: "+newVal)
    //     this.$store.dispatch("spacesListenerSet", newVal)
    //   }
    //   else{
    //     console.log("email: "+newVal)
    //   }
    // }
  },
  computed: {
    drawerWidth(){
      let drawerWidth = 280
      if ( this.$store.state.screen.mode==='phone' ) {
        drawerWidth = window.innerWidth
      }
      return drawerWidth
    },
    avatarImg(){
      let avatarImg = this.$store.state.currentUser?.avatarImg
      if (!avatarImg) avatarImg = require('@/assets/defaultPictureUser.svg')
      return avatarImg
    }
  },
  methods: {
    screenDimensionsSet(){
      let innerWidth = window.innerWidth
      let innerHeight = window.innerHeight
      this.$store.commit("screenWidthSet", innerWidth)
      this.$store.commit("screenHeightSet", innerHeight)
      if(innerWidth < innerHeight && innerWidth < 500) {
        this.$store.commit("screenModeSet", "phone")
      }
      else {
        this.$store.commit("screenModeSet", "desktop")
      }
    },
    // goToList(listId){
    //   this.$router.push({ name: 'list', params: { listId: listId } })
    // }
    goToSpaces(){
      this.$router.push({ name: 'overview' })
    },
    screenEventsSet() {
      window.addEventListener("resize", () => {
        this.screenDimensionsSet()
      })

      window.addEventListener('scroll', function() {
        let scrollPosition = window.pageYOffset || document.documentElement.scrollTop
        if (scrollPosition > 0) {
          this.toolbarElevated = true
        }
        else {
          this.toolbarElevated = false
        }
      })
    },
    authEventSet(){
      consoleLog({text: "App.js > created() > authEventSet()"})
      onAuthStateChanged(auth, (authUser) => {

        if (authUser) {
          // debugger

          // logout if current user not equal authUser
          if(authUser.providerData[0].providerId === "phone" && authUser.phoneNumber !== this.currentUserId){
            signOut(auth)
          }
          if((authUser.providerData[0].providerId === "email" || authUser.providerData[0].providerId === "google") && authUser.email !== this.currentUserId){
            signOut(auth)
          }

          consoleLog({text: "App.js > created() > authEventSet() > onAuthStateChanged()",
            params: [["authUserIds [phoneNumber, email]", authUser.phoneNumber, authUser.email]]
          })
          this.$store.commit("authUserSet", authUser)

          if(window.navigator.onLine) {
            consoleLog({text: "App.js > created() > authEventSet() > onAuthStateChanged() > window.navigator.onLine is true at app starts",
              params: [["authUserIds [phoneNumber, email]", authUser.phoneNumber, authUser.email]]
            })
            authUserListenerSet()
            changedSpacesUpdate(this.currentUserId)
            // spacesListenerSet(authUser.phoneNumber)
          }
          window.addEventListener('online', () => {
            consoleLog({text: "App.js > created() > authEventSet() > onAuthStateChanged() > window.addEventListener() switched to online",
              params: [["authUserIds [phoneNumber, email]", authUser.phoneNumber, authUser.email]]
            })
            console.log("movedToOnline")
            authUserListenerSet()
            changedSpacesUpdate(this.currentUserId)
            // spacesListenerSet(authUser.phoneNumber)
          })
          window.addEventListener('offline', () => {
            consoleLog({text: "App.js > created() > authEventSet() > onAuthStateChanged() > window.addEventListener() switched to offline",
              params: [["authUserIds [phoneNumber, email]", authUser.phoneNumber, authUser.email]]
            })
            console.log("movedToOffline")
            listenersCancel()
          })
        }
        else {
          consoleLog({text: "App.js > created() > authEventSet() > onAuthStateChanged() > logoff"})
          listenersCancel()
          this.$store.commit("authUserSet", null)
        }
      })
    },
    drawerOpen(){
      this.drawer = true
    },
    drawerClose(){
      this.drawer = false
    },
    scroll(e){
      console.log(e)
    }
  }
}
</script>
<style>
body.q-body--dialog {
  /* when dialog appears header (sticked) disappears */
  overflow: clip;
}
::-webkit-scrollbar {
  display: none;
}
html {
  /*overflow: hidden;*/
  overscroll-behavior: contain;
}
[contenteditable] {
  _outline: 2px solid red;
}
</style>