<template>
  <sl-addon-gantt :data="data" :view="view" v-if="view.data?.addonParams?.addonId === 'gantt'"/>
</template>

<script>

import SlAddonGantt from "@/components/sl-addon-gantt";
export default {
  name: "sl-addon-init",
  components: {SlAddonGantt},
  props: ["data", "view"]
}
</script>

<style scoped>

</style>