<template>
  <q-card>
    <q-card-section>
      <span class="font-weight-bold q-ml-sm">{{space?.data?.name}}</span>
      <span class="q-ml-sm"> > </span>
      <span class="q-mx-sm">{{list?.data.name}}</span>
      <span class="q-ml-sm"> > </span>
      <span class="q-mx-sm">{{view?.data.name}}</span>


      <q-btn round icon="layers" dense class="q-ma-xs text-grey" size="sm"/>
      <q-btn round icon="favorite" dense class="q-ma-xs text-grey" size="sm"/>
      <q-btn round icon="more_vert" dense class="q-ma-xs text-grey" size="sm">
        <q-menu
          transition-show="jump-down"
          transition-hide="jump-up"
        >
          <q-list dense style="min-width: 100px">
            <q-item clickable>
              <q-item-section side>
                <q-icon name="settings" size="xs"/>
              </q-item-section>
              <q-item-section>
                Layout settings
              </q-item-section>
            </q-item>
            <q-item clickable>
              <q-item-section side>
                <q-icon name="upload" size="xs"/>
              </q-item-section>
              <q-item-section>
                Excel upload
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <q-btn round icon="save" dense class="q-ma-xs text-red" size="sm"
             v-if="$store.state.lists[list5Id]?.isListChanged"
             @click="listChangesSave()"
      />
      <span v-if="drilldownFilter">{{drilldownFilter?.fieldId+": "+drilldownFilter?.value}}</span>
    </q-card-section>
    <q-card-section>
      <q-scroll-area
        :style="{height: scrollAreaHeight+'px', width: '100%', padding: '5px', borderBottom: '1px solid grey'}"
        :thumb-style="{right: '0px',
                     borderRadius: '5px',
                     backgroundColor: 'lightgrey',
                     width: '10px',opacity: '0.75'}"
      >
        <table :style="tableStyle">
          <thead>
          <tr>
            <th v-for="hFieldId in view?.data?.fieldsOrder" :key="hFieldId"
                :id="hFieldId"
                :style="view?.data.fields[hFieldId].headCellStyle"
                class="headCell"
                @contextmenu="columnPropsFormOpen($event, hFieldId)"
                @dragover="$event.preventDefault()"
                @drop="columnDragDrop($event)"
            >
              <span :draggable="true" @dragstart="columnDragStart($event)">
                {{list?.data.fields[hFieldId].name}}
              </span>
              <br/>
              <span class="text-grey font-weight-thin" v-if="hFieldId!=='z3'">{{hFieldId}}</span>
              <q-btn icon="add" round size="xs" dense @click="fieldAdd()" v-else/>
            </th>
          </tr>
          <q-dialog v-model="columnPropsFormShow">
            <sl-list-column-props-form :list="list" :view="view"
                                       :list5-id="list5Id"
                                       :fieldId="columnPropsFormFieldId"
                                       @finished="columnPropsFormClose()"
            />
          </q-dialog>
          </thead>
          <tbody>
            <sl-list5-subtotal-row :subtotals="$store.state.lists[list5Id]?.subTotals" :list5-id="list5Id" v-if="$store.state.lists[list5Id]?.subTotals" :key="'grandTotal'"/>
          </tbody>
        </table>
      </q-scroll-area>
    </q-card-section>
  </q-card>
</template>

<script>
import {db} from '@/db'

import {resizableGrid2} from "@/utils2";
import SlList5SubtotalRow from "@/components/sl-list5-subtotal-row";
import {formulaRun, listSortSubtotal, sublistSubstitute} from "@/db-sync";
import {formulaOrderGen, objStatusFieldsSet} from "@/utils4";
import {toRaw} from "vue";
import SlListColumnPropsForm from "@/components/sl-list-column-props-form";

export default {
  name: "sl-list5",
  components: {SlListColumnPropsForm, SlList5SubtotalRow},
  props: ["spaceId", "listId", "viewId", "drilldownFilter"],
  data: () => {
    return {
      list5Id: "id_" + Date.now(),
      items: [],
      subTotals: {},
      windowHeight: window.innerHeight,
      columnPropsFormFieldId: "",
      columnPropsFormShow: false,
      draggbleColumnId: ""
    }
  },
  async mounted() {
    setTimeout(() => {
      let tableEl = this.$el.querySelector("table")
      resizableGrid2(tableEl, this.list5Id)
    }, 500)
  },
  async created() {
    await this.listBeforePrepare()
    await this.listPrepare()
    this.listSortSubTotal()
    addEventListener("resize", (event) => {
      this.windowHeight = event.currentTarget.innerHeight
    })

    window.addEventListener("keydown", (event) => {
      if (event.defaultPrevented) return // Do nothing if the event was already processed

      let buttons = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab', 'F2']
      if (buttons.includes(event.key)) {
        // event.preventDefault()

      }
    })
  },
  computed: {
    space(){
      return this.$store.state.lists[this.list5Id]?.space
    },
    list(){
      return this.$store.state.lists[this.list5Id]?.list
    },
    view(){
      return this.$store.state.lists[this.list5Id]?.list.data.views[this.$store.state.lists[this.list5Id]?.viewId]
    },
    tableWidth(){
      let tableWidth = 0
      if(this.view) {
        for (let fieldId of this.view?.data.fieldsOrder) {
          if (this.view?.data.fields[fieldId].headCellStyle.width) tableWidth = tableWidth + parseInt(this.view?.data.fields[fieldId].headCellStyle.width, 10)
        }
      }
      return tableWidth
    },
    tableStyle(){
      let tableStyle = {}
      tableStyle.tableLayout = "fixed"
      tableStyle.width = this.tableWidth + "px" // table width required when window width is less than table width. Without table width table fits to window width
      return tableStyle
    },

    scrollAreaHeight(){
      return this.windowHeight - 200
    },
  },
  // watch: {
  //   view: function (newVal, oldVal){
  //
  //   }
  // },
  methods: {
    async listBeforePrepare(){
      let space = await db.table("spaces").get(this.spaceId)
      let list = await db.table("lists").get(this.listId)

      this.$store.commit("listsList5Add", {
        list5Id: this.list5Id,
        list5: {
          space: space,
          listId: list.id,
          list: list,
          listFieldsForSubst: {},
          viewId: list.data.defaultAdminViewId,
          sublists: {},
          drilldownFilter: {},
          isListChanged: false,
          currentCell: {itemId: "", fieldId: ""},
          isActive: true
        }
      })
    },
    async listPrepare(){
      let list = this.$store.state.lists[this.list5Id].list
      let listData = this.$store.state.lists[this.list5Id].list.data
      let items = []
      if(this.drilldownFilter){
        items = await db.table("items")
          .where("drilldownFilter")
          .equals(this.listId+"_"+this.drilldownFilter.fieldId+"_"+this.drilldownFilter.value)
          .filter(el => el.spaceId === this.spaceId)
          .toArray()
      }
      else{
        items = await db.table("items").where({spaceId: this.spaceId, 'data.listId': this.listId}).toArray()
      }


      // listFieldsForSubst
      let listFieldsForSubst = JSON.parse(JSON.stringify(list.data.fields))
      let that = this
      let funListFieldsForSubst = async function (initFieldId, list, fieldId){
        let listField = list.data.fields[fieldId]
        if(!that.$store.state.lists[that.list5Id].sublists[listField.listIdForSublist]){
          that.$store.commit("sublistAddList", {list5Id: that.list5Id, list: await db.table("lists").get(listField.listIdForSublist)})
        }
        let sublist = that.$store.state.lists[that.list5Id].sublists[listField.listIdForSublist]
        if(sublist.list.data.fields[listField.fieldIdForSublist].type === "sublist"){
          listFieldsForSubst[initFieldId].listIdForSublist = sublist.list.data.fields[listField.fieldIdForSublist].listIdForSublist
          listFieldsForSubst[initFieldId].viewIdForSublist = sublist.list.data.fields[listField.fieldIdForSublist].viewIdForSublist
          listFieldsForSubst[initFieldId].fieldIdForSublist = sublist.list.data.fields[listField.fieldIdForSublist].fieldIdForSublist
          await funListFieldsForSubst(initFieldId, sublist.list, listField.fieldIdForSublist)
        }
      }

      for (let fieldId in listData.fields) {
        if ( listData.fields[fieldId].type === "sublist"){
          await funListFieldsForSubst(fieldId, list, fieldId)
        }
      }
      this.$store.commit("listsList5SetProp", {list5Id: this.list5Id, propName: "listFieldsForSubst", propVal: listFieldsForSubst})


        console.log("sublists: ", this.$store.state.lists[this.list5Id].sublists)
      // this.$store.commit("listsList5SetProp", {list5Id: this.list5Id, propName: "sublistFields", propVal: sublistFields})

      // formula fields order
      // let formulaOrderLevelsArr = [[]]
      // for (let fieldId in listData.fields) {
      //   if (listData.fields[fieldId].type === "formula") formulaOrderLevelsArr[0].push(fieldId)
      // }
      // let fun = function (level) {
      //   if(formulaOrderLevelsArr[level].length === 0) return
      //   formulaOrderLevelsArr.push([])
      //   for(let i = 0; i < formulaOrderLevelsArr[level].length; i++){
      //     let fieldId = formulaOrderLevelsArr[level][i]
      //     let regexp = /item.f\d+/gi
      //     let formulaFieldsArr = listData.fields[fieldId].formula.match(regexp)
      //
      //     for (let itemField of formulaFieldsArr){
      //       let itemFieldId = itemField.substring(5)
      //       if(formulaOrderLevelsArr[level].includes(itemFieldId)){
      //         formulaOrderLevelsArr[level].splice(i, 1)
      //         formulaOrderLevelsArr[level+1].push(fieldId)
      //         i-- // reindex array formulaOrder[level] after deleting
      //         break
      //       }
      //     }
      //   }
      //   fun(level+1)
      // }
      // fun(0)
      // let formulaOrder = []
      // for(let formulaOrderLevelArr of formulaOrderLevelsArr){
      //   if (formulaOrderLevelArr.length === 0) continue
      //   formulaOrder = formulaOrder.concat(formulaOrderLevelArr)
      // }
      // console.log("formula order", formulaOrder)
      // this.$store.commit("listsList5SetProp", {list5Id: this.list5Id, propName: "formulaOrder", propVal: formulaOrder})

      // sublists substitution in items
      for(let item of items) {
        await sublistSubstitute(this.list5Id, item)
        formulaRun(this.$store.state.lists[this.list5Id].list.data, "beforeDisplay", item.data.fields)
      }
      console.log("sublists: ", this.$store.state.lists[this.list5Id].sublists)
      this.$store.commit("listsList5SetProp", {list5Id: this.list5Id, propName: "items", propVal: items})
    },
    listSortSubTotal(){
      listSortSubtotal(this.list5Id)
    },
    listChangesSave(){
      db.table("lists").where("id").equals(this.$store.state.lists[this.list5Id].listId).modify(list => {
        debugger
        list.data = toRaw(this.$store.state.lists[this.list5Id].list.data)
        objStatusFieldsSet(list)
        this.$store.commit("listsIsListChangedReset",this.list5Id)
      })
    },
    columnPropsFormOpen(e, fieldId){
      e.preventDefault()
      this.columnPropsFormFieldId = fieldId
      this.columnPropsFormShow = true
    },
    async fieldAdd() {
      let maxIdNum = 0
      let idNum = 0
      let list = await db.table("lists").get(this.listId)
      let view = list.data.views[this.viewId]
      for (let p in list.data.fields) {
        if (p.charAt(0) === 'f') {
          idNum = p.substring(1) * 1
          if (maxIdNum < idNum) maxIdNum = idNum
        }
      }
      maxIdNum++
      let field = {id: 'f' + maxIdNum, name: 'Field name ' + maxIdNum, type: 'text'}
      let fieldView = {id: field.id, headCellStyle: {}, headNameStyle: {}, cellStyle: {}, cellContentStyle: {}}
      fieldView.headCellStyle.width = "150px"
      fieldView.headCellStyle.position = "sticky"
      fieldView.headCellStyle.top = "0px"
      fieldView.headCellStyle.zIndex = 100

      // update in db
      db.lists.where('id').equals(list.id).modify(list => {
        debugger
        list.data.fields[field.id] = field
        list.data.views[view.id].data.fields[field.id] = fieldView
        let z3Index = view.data.fieldsOrder.findIndex(el => el === 'z3')
        list.data.views[view.id].data.fieldsOrder.splice(z3Index, 0, field.id)
        objStatusFieldsSet(list)
      })
    },
    columnDragStart(e) {
      this.draggbleColumnId = e.currentTarget.parentElement.id
    },
    columnDragDrop(e) {
      let dragToColumnId
      if (e.currentTarget.tagName === "TH") {
        dragToColumnId = e.currentTarget.id
      }
      else {
        dragToColumnId = e.currentTarget.parentElement.id
      }

      let view = this.$store.state.lists[this.list5Id].list.data.views[this.$store.state.lists[this.list5Id].viewId]

      let draggbleColumnIdIndex = view.data.fieldsOrder.findIndex(el => el === this.draggbleColumnId)
      let dragToColumnIdIndex = view.data.fieldsOrder.findIndex(el => el === dragToColumnId)

      if (draggbleColumnIdIndex >= 0 && dragToColumnIdIndex >= 0) {
        view.data.fieldsOrder.splice(draggbleColumnIdIndex, 1)
        if (draggbleColumnIdIndex > dragToColumnIdIndex) view.data.fieldsOrder.splice(dragToColumnIdIndex, 0, this.draggbleColumnId)
        if (draggbleColumnIdIndex < dragToColumnIdIndex) view.data.fieldsOrder.splice(dragToColumnIdIndex - 1, 0, this.draggbleColumnId)

        this.$store.state.lists[this.list5Id].isListChanged = true
        this.listSortSubTotal()
      }
    },
    async columnPropsFormClose(){
      let list5 = this.$store.state.lists[this.list5Id]
      list5.list.data.formulaOrders = {
        onSave: formulaOrderGen(list5.list.data, "onSave"),
        beforeDisplay: formulaOrderGen(list5.list.data, "beforeDisplay")
      }
      this.columnPropsFormShow = false
      await this.listPrepare()
      this.listSortSubTotal()
      list5.isListChanged = true
    }
  }

}
</script>

<style scoped>
.headCell {
  border-bottom: 2px solid lightgrey;
}
</style>