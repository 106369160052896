<template>
  <q-card>
    <q-list>
      <q-item v-for="view in list.views" :key="view.id">
        <q-item-section>
          {{view.name}}
        </q-item-section>
        <q-item-section side>
          <q-btn icon="edit" round dense size="xs"/>
        </q-item-section>
        <q-item-section side>
          <q-btn icon="delete" @click="viewDelete(view.id)" round dense size="xs"/>
        </q-item-section>
      </q-item>
    </q-list>
    <sl-list-view-form v-if="listViewFormShow"/>
  </q-card>
</template>

<script>
import SlListViewForm from "@/components/sl-list-view-form"
import {db} from "@/db"
export default {
  name: "sl-list-views",
  components: {SlListViewForm},
  props: ["list"],
  data: ()=>{
    return {
      listViewFormShow: false
    }
  },
  methods: {
    viewDelete(viewId){
      debugger
      let viewIndx = this.list.views.findIndex(v=>v.id===viewId)
      if(viewIndx>=0){
        db.lists.where('id').equals(this.list.id).modify(list => list.views.splice(viewIndx, 1))
      }
    }
  }
}
</script>

<style scoped>

</style>