<template>
  <sl-list-init :space-id="spaceId" :list-id="listId" :view-id="viewId" v-if="spaceId && listId && viewId"/>
<!--  <q-card flat square>-->
<!--    <q-card-section v-if="isDataPrepared">-->
<!--      <sl-list-header :space="space" :list="list" :view="view" @refreshView="viewEnrich()"/>-->
<!--    </q-card-section>-->
<!--    <q-card-section v-if="isDataPrepared">-->
<!--        <q-scroll-area :style="'height: 600px; width: 100%; padding: 10px; border-bottom: 1px solid grey'" :thumb-style="{right: '2px',-->
<!--            borderRadius: '5px',-->
<!--            backgroundColor: 'lightgrey',-->
<!--            width: '5px',-->
<!--            opacity: '0.75'}"-->
<!--        >-->
<!--          <sl-list4 :space="space" :list="list" :view="view" :isEditable="isEditable" @pItemsLength="pItemsLength=$event"></sl-list4>-->
<!--          <br/>-->
<!--          <q-page-sticky position="bottom-right" :offset="[18, 18]">-->
<!--            <q-btn :icon="isEditable?'visibility':'edit'" color="accent" dense round size="sm" @click="isEditable=!isEditable"/>-->
<!--          </q-page-sticky>-->
<!--        </q-scroll-area>-->
<!--      {{pItemsLength}}-->
<!--    </q-card-section>-->
<!--  </q-card>-->
</template>

<script>
// import SlList4 from "@/components/sl-list4"
// import SlListHeader from "@/components/sl-list-header"
import {db} from "@/db";
import {useQuasar} from 'quasar'
// import {useObservable} from "@vueuse/rxjs/index"
// import {liveQuery} from "dexie"
import { useRoute, useRouter } from "vue-router"
import SlListInit from "@/components/sl-list-init";

export default {
  name: "ListView",
  // data: function(){
  //   return{
  //     isEditable: false,
  //     pItemsLength: 0,
  //     view: null,
  //     isDataPrepared: false
  //   }
  // },
  components: {SlListInit},
  setup(){
    const $q = useQuasar()
    const route = useRoute()
    const router = useRouter()
    let spaceId = route.params.spaceId
    let listId = route.params.listId
    let viewId = route.params.viewId

    if(viewId === "" || viewId === undefined){
      db.lists.get(listId).then((list) => {
        let defaultViewId = ""
        if($q.platform.is.mobile){
          defaultViewId = list.data.defaultAdminPhoneViewId
        }
        else{
          defaultViewId = list.data.defaultAdminViewId
        }
        router.push({ name: 'list', params: { spaceId: spaceId, listId: listId, viewId: defaultViewId } })

      })
      return
    }
    return {
      spaceId,
      listId,
      viewId
    }
  },
  watch: {
    listId(){
      this.$store.commit("sublistInitialize")
    }
  }

  // async created(){
  //   const route = useRoute()
  //   this.view = await db.views.get(route.params.viewId)
  //   await this.viewEnrich()
  //   if (this.drilldownList !== undefined){
  //     this.drilldownList.filter.forEach(field => {
  //       field.forEach(el => {
  //         el.arr.forEach(filterEl => {
  //           if(this.view._data.fields[el.id].filter){
  //             if(!this.view._data.fields[el.id].filter.arr.includes(filterEl)) {
  //               this.view._data.fields[el.id].filter.arr.push(filterEl)
  //             }
  //           }
  //         })
  //       })
  //     })
  //   }
  //   this.isDataPrepared = true
    // let list  = await db.lists.get(this.listId)
    // let view = {}
    // let that = this
    // if(this.viewId === "" || this.viewId === undefined){
    //   view.id = ""
    //   view.name = "All fields (unformatted view)"
    //   view.owner = ""
    //   view.fields = []
    //   list.fields.forEach(f=>{
    //     view.fields.push({id: f.id})
    //   })
    // }
    // else {
    //   view = list.views.find(el=>el.id===that.viewId)
    // }
    // // debugger
    // for(let i=0; i<view.fields.length; i++){
    //   view.fields[i].props = list.fields.find(el=>el.id===view.fields[i].id)
    //   if(list.fields.find(el=>el.id===view.fields[i].id).type === "sublist"){
    //     view.fields[i].sublistItems = {}
    //     let sublist = await db.lists.get(list.fields.find(el=>el.id===view.fields[i].id).listForDropdown)
    //     let fieldForDropdown = sublist.fields.find(el=>el.fieldForDropdown === true)
    //     if(fieldForDropdown) {
    //       let fieldIdForDropdown = fieldForDropdown.id
    //       let sublistItems = await db.items.where("listId").equals(sublist.id).toArray()
    //       for(let j=0; j<sublistItems.length; j++){
    //         view.fields[i].sublistItems[sublistItems[j].id] = {}
    //         view.fields[i].sublistItems[sublistItems[j].id].fieldForDropdown = sublistItems[j].fields[fieldIdForDropdown]
    //         view.fields[i].sublistItems[sublistItems[j].id].fields = sublistItems[j].fields
    //       }
    //     }
    //   }
    // }
    // this.view = view
  // },
  // computed: {
    // view(){
    //   let that = this
    //   let view = {}
    //   db.transaction('rw', db.lists, db.items, async ()=>{
    //     let list  = await db.lists.get(this.listId)
    //     if(this.viewId === "" || this.viewId === undefined){
    //       view.id = ""
    //       view.name = "All fields (unformatted view)"
    //       view.owner = ""
    //       view.fields = []
    //       list.fields.forEach(f=>{
    //         view.fields.push({id: f.id})
    //       })
    //     }
    //     else {
    //       view = list.views.find(el=>el.id===that.viewId)
    //     }
    //     // debugger
    //     for(let i=0; i<view.fields.length; i++) {
    //       view.fields[i].props = list.fields.find(el => el.id === view.fields[i].id)
    //       if (list.fields.find(el => el.id === view.fields[i].id).type === "sublist") {
    //         view.fields[i].sublistItems = {}
    //         let sublist = await db.lists.get(list.fields.find(el => el.id === view.fields[i].id).listForDropdown)
    //         let fieldForDropdown = sublist.fields.find(el => el.fieldForDropdown === true)
    //         if (fieldForDropdown) {
    //           let fieldIdForDropdown = fieldForDropdown.id
    //           let sublistItems = await db.items.where("listId").equals(sublist.id).toArray()
    //           for (let j = 0; j < sublistItems.length; j++) {
    //             view.fields[i].sublistItems[sublistItems[j].id] = {}
    //             view.fields[i].sublistItems[sublistItems[j].id].fieldForDropdown = sublistItems[j].fields[fieldIdForDropdown]
    //             view.fields[i].sublistItems[sublistItems[j].id].fields = sublistItems[j].fields
    //           }
    //         }
    //       }
    //     }
    //     debugger
    //     return view
    //   })
    //   return undefined


    // }




    // view(){
      // let view = {}
      // let that = this
      // if(this.viewId === "" || this.viewId === undefined){
      //   view.id = ""
      //   view.name = "All fields (unformatted view)"
      //   view.owner = ""
      //   view.fields = []
      //   this.list.fields.forEach(f=>{
      //     view.fields.push({id: f.id})
      //   })
      // }
      // else if(this.list) {
      //   view = this.list.views.find(el=>el.id===that.viewId)
      // }
      // debugger
      // for(let i=0; i<view.fields.length; i++){
      //   if(this.list.fields.find(el=>el.id===view.fields[i].id).type === "sublist"){
      //     view.fields[i].sublistItems = {}
      //     let sublist = rowGet("lists", this.list.id)
      //     let fieldForDropdown = sublist.fields.find(el=>el.fieldForDropdown === 'true')
      //     if(fieldForDropdown) {
      //       let fieldIdForDropdown = fieldForDropdown.id
      //       let sublistItems = db.items.where("listId").equals(this.list.id).toArray()
      //       for(let j=0; j<sublistItems.length; j++){
      //         view.fields[i].sublistItems[sublistItems[j].id] = sublistItems[j].fields[fieldIdForDropdown]
      //       }
      //     }
      //
      //   }
      // }
      // return view
    // }
  // },
  // watch: {
    // list(){
    //   this.viewEnrich()
    // },
    // view(){
    //   this.viewEnrich()
    //   this.view._isChangedByUser = false
      // this.view._isChangedInDB = true
    // },
    // 'view._data': {
    //   handler(){
    //     debugger
    //     if(this.view._isChangedInDB === true){
    //       this.view._isChangedInDB = false
    //     }
    //     else{
    //       this.view._isChangedByUser = true
    //     }
    //   },
    //   deep: true
    // }
  // },
  // methods: {
  //   async viewEnrich(){
  //
  //     if(!this.view) return
  //
  //     this.view._data = JSON.parse(JSON.stringify(this.view.data)) // user can do changes in view with no changes to db, after user save changes in view _data overwrites data
  //     this.view._isChangedByUser = false
  //     this.view._sublists = {}
  //     for(let fieldId of this.view._data.fieldsOrder){
  //       if(this.list.data.fields[fieldId].type === "sublist" && this.list.data.fields[fieldId].isFixedSublist){
  //         this.view._sublists[fieldId] = {}
  //         for (let fixedSublistItemId in this.list.data.fields[fieldId].fixedSublist) {
  //           this.view._sublists[fieldId][fixedSublistItemId] = {}
  //           this.view._sublists[fieldId][fixedSublistItemId].id = fixedSublistItemId
  //           this.view._sublists[fieldId][fixedSublistItemId].value = this.list.data.fields[fieldId].fixedSublist[fixedSublistItemId].value
  //           this.view._sublists[fieldId][fixedSublistItemId].style = {}
  //           this.view._sublists[fieldId][fixedSublistItemId].style.cellStringStyle = this.list.data.fields[fieldId].fixedSublist[fixedSublistItemId].style
  //           this.view._sublists[fieldId][fixedSublistItemId].style.cellStyle = {}
  //         }
  //       }
  //       if(this.list.data.fields[fieldId].type === "sublist" && !this.list.data.fields[fieldId].isFixedSublist){
  //         let listIdForSublist = this.list.data.fields[fieldId].listIdForSublist
  //         let fieldIdForSublist = this.list.data.fields[fieldId].fieldIdForSublist
  //         let viewIdForSublist = this.list.data.fields[fieldId].viewIdForSublist
  //         let viewForSublist = await db.views.get(viewIdForSublist)
  //         let sublistItems = await db.items.where("data.listId").equals(listIdForSublist).toArray()
  //
  //         this.view._sublists[fieldId] = {}
  //         for (let sublistItem of sublistItems) {
  //           this.view._sublists[fieldId][sublistItem.id] = {}
  //           this.view._sublists[fieldId][sublistItem.id].id = sublistItem.id
  //           this.view._sublists[fieldId][sublistItem.id].value = sublistItem.data.fields[fieldIdForSublist]
  //           this.view._sublists[fieldId][sublistItem.id].style = {cellStyle: {}, cellStringStyle: {}}
  //           // fields props
  //           if(viewForSublist.data.fields){
  //             if(viewForSublist.data.fields[fieldIdForSublist]){
  //               if(viewForSublist.data.fields[fieldIdForSublist].cellStyle) {
  //                 this.view._sublists[fieldId][sublistItem.id].style.cellStyle = viewForSublist.data.fields[fieldIdForSublist].cellStyle
  //               }
  //               if(viewForSublist.data.fields[fieldIdForSublist].cellStringStyle) {
  //                 this.view._sublists[fieldId][sublistItem.id].style.cellStringStyle = viewForSublist.data.fields[fieldIdForSublist].cellStringStyle
  //               }
  //             }
  //           }
  //           // cell props
  //           if (viewForSublist.data.cellsProps){
  //             if (viewForSublist.data.cellsProps[sublistItem.id]){
  //               if (viewForSublist.data.cellsProps[sublistItem.id][fieldIdForSublist]){
  //                 if (viewForSublist.data.cellsProps[sublistItem.id][fieldIdForSublist].cellStyle) {
  //                   this.view._sublists[fieldId][sublistItem.id].style.cellStyle = {
  //                     ... this.view._sublists[fieldId][sublistItem.id].style.cellStyle,
  //                     ... viewForSublist.data.cellsProps[sublistItem.id][fieldIdForSublist].cellStyle
  //                   }
  //                 }
  //                 if (viewForSublist.data.cellsProps[sublistItem.id][fieldIdForSublist].cellStringStyle) {
  //                   this.view._sublists[fieldId][sublistItem.id].style.cellStringStyle = {
  //                     ... this.view._sublists[fieldId][sublistItem.id].style.cellStringStyle,
  //                     ... viewForSublist.data.cellsProps[sublistItem.id][fieldIdForSublist].cellStringStyle
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
</script>

<style scoped>

</style>