<template>
  <sl-list5 :space="space" :listId="listId" v-if="space.id && listId"/>
</template>

<script>
import {db} from '@/db'
import SlList5 from "@/components/sl-list5"

export default {
  name: "test-t11",
  components: {SlList5},
  data: () => {
    return {
      space: {},
      listId: ""
    }
  },
  async created(){
    this.listId = "lw26r9zlg23" // "lvv68jgqplz" // "lvv21evrvw0" ltju196s1aa ltghulgou2m
    this.space = await db.table("spaces").get("lw26qzoqeoo") //("ltgfvtmzi6w")
  }
}
</script>

<style scoped>

</style>