<template>
  <td :class="{cell: true, cellNoneSelectable: listField.type !== 'text' && listField.type !== 'num'}"
      @click="$store.commit('listCurrentCellSet', {list5Id: list5Id, itemId: itemId, fieldId: fieldId})"
      @dblclick="cellDblClick()"
      v-if="!isEditable"
  >
    <div v-if="fieldId === 'z1'" class="text-grey text-center">{{itemI===''?'':itemI+1}}</div>
    <div v-if="fieldId === 'z2'" class="text-center"><q-icon :name="itemId==='new'?'wb_sunny':'circle'" :color="statusColor" class="q-my-sm"/></div>

    <div v-if="fieldId === 'z3' && itemId !== 'new'" class="text-grey text-center">
      <q-btn icon="list" round size="xs" @click="tasksOpen()"/> ({{tasks?tasks.length:0}})
      <q-btn class="q-ml-sm" icon="forum" round size="xs" @click="commentsOpen()"/> ({{comments?comments.length:0}})
      <sl-drilldown-menu :list5Id="list5Id" :item-id="itemId"/>
    </div>

    <div v-if="(listField.type === 'text' || listField.type === 'num') && !listField.showInSeparateWindow">{{displayVal}}</div>
    <div v-else-if="listField.type === 'text' && listField.showInSeparateWindow" class="text-center">
      <q-icon name="article" :color="displayVal?'green':'grey'" style="cursor: pointer" size="sm"/>
    </div>
    <div v-else-if="listField.type === 'sublist' || listField.type === 'fixedsublist'">
            <span v-for="(displayValue, key) in displayValArr" :key="key" :style="displayValue.style">
        {{displayValue.val}}
      </span>
    </div>
    <div v-else>{{displayVal}}</div>

    <q-dialog v-model="cellFormShow">
      <sl-list-cell-form-status v-if="statusShow" :list5Id="list5Id" :itemId="itemId" @finished="cellFormShow=!cellFormShow"/>
      <sl-list-cell-form-fixedsublist v-if="fixedSublistShow" :list5Id="list5Id" :itemId="itemId" :fieldId="fieldId" @finished="cellFormShow=!cellFormShow"/>
      <sl-list-cell-form-sublist v-if="sublistShow" :list5Id="list5Id" :itemId="itemId" :fieldId="fieldId" :newItem="newItem" @finished="cellFormShow=!cellFormShow"/>
      <sl-list-cell-form-datetime v-if="datetimeShow" :list5Id="list5Id" :itemId="itemId" :fieldId="fieldId" :newItem="newItem" @finished="cellFormShow=!cellFormShow"/>
      <sl-list-cell-form-editor v-if="editorShow" :list5Id="list5Id" :itemId="itemId" :fieldId="fieldId" @finished="cellFormShow=!cellFormShow"/>
      <sl-list-cell-form-text v-if="textShow" :list5Id="list5Id" :itemId="itemId" :fieldId="fieldId" @finished="cellFormShow=!cellFormShow"/>
      <sl-tasks-of-obj v-if="tasksShow" :space-id="spaceId" :list-id="listId" :item-id="itemId" :tasks="tasks" @finished="cellFormShow=!cellFormShow"/>
    </q-dialog>

    <div class="cellFrame" v-if="isCurrentCell && !isEditable"/>
  </td>
  <td class="cellEditable" @blur="cellBlur()" :contenteditable="true" v-else>
     {{displayVal}}
  </td>
</template>

<script>
import { db } from '@/db'
import {objStatusFieldsSet} from "@/utils4";
import SlListCellFormStatus from "@/components/sl-list-cell-form-status"
import SlListCellFormFixedsublist from "@/components/sl-list-cell-form-fixedsublist"
import SlListCellFormSublist from "@/components/sl-list-cell-form-sublist"
import SlListCellFormDatetime from "@/components/sl-list-cell-form-datetime"
import SlListCellFormEditor from "@/components/sl-list-cell-form-editor"
import {generateId} from "@/utils";
import SlTasksOfObj from "@/components/sl-tasks-of-obj";
import {useObservable} from "@vueuse/rxjs/index";
import {liveQuery} from "dexie";
import SlDrilldownMenu from "@/components/sl-drilldown-menu";
import SlListCellFormText from "@/components/sl-list-cell-form-text";
import {formulaRun} from "@/db-sync";
export default {
  name: "sl-list5-cell",
  components: {
    SlListCellFormText,
    SlDrilldownMenu,
    SlTasksOfObj,
    SlListCellFormStatus, SlListCellFormFixedsublist, SlListCellFormSublist, SlListCellFormDatetime, SlListCellFormEditor},
  props: ['list5Id', 'itemId', 'itemStatus', 'fieldId', 'val', 'valS', 'itemI', 'newItem'],
  data: () => {
    return {
      listId: "",
      spaceId: "",
      isEditable: false,
      displayVal: "",
      displayValArr: [],
      cellFormShow: false,
      fixedSublistShow: false,
      sublistShow: false,
      datetimeShow: false,
      editorShow: false,
      textShow: false,
      statusShow: false,
      tasksShow: false,
      commentsShow: false,
      tasks: [],
      comments: []
    }
  },
  created() {
    this.listId = this.$store.state.lists[this.list5Id].list.id
    this.spaceId = this.$store.state.lists[this.list5Id].list.spaceId
    this.tasks = useObservable(
      liveQuery(() => db.table("tasks").where("spaceId").equals(this.spaceId)
        .and(task => task.data.listId === this.listId && task.data.itemId === this.itemId).toArray())
    )
    this.comments = useObservable(
      liveQuery(() => db.table("comments").where("spaceId").equals(this.spaceId)
        .and(comment => comment.data.listId === this.listId && comment.data.itemId === this.itemId).toArray())
    )

    this.displayVal = this.val
    if(this.listFieldSubst.type === "fixedsublist" ){
      this.displayValArrFixedSublist(this.valS)
    }
    if(this.listFieldSubst.type === "sublist" ){
      this.displayValArrSublist(this.valS)
    }
  },
  watch: {
    val: function (newVal){
      this.displayVal = newVal
    },
    valS: function (newVal){
      if(this.listFieldSubst.type === "fixedsublist" ){
        this.displayValArrFixedSublist(newVal)
      }
      if(this.listFieldSubst.type === "sublist" ){
        this.displayValArrSublist(newVal)
      }
    }
  },
  computed: {
    isCurrentCell(){
      if(this.fieldId === "z3") return false
      let isCurrentCell = false
      let currentCell = this.$store.state.lists[this.list5Id].currentCell
      if(currentCell.itemId === this.itemId && currentCell.fieldId === this.fieldId) isCurrentCell = true
      return isCurrentCell
    },
    listField(){
      return this.$store.state.lists[this.list5Id].list.data.fields[this.fieldId]
    },
    listFieldSubst(){
      return this.$store.state.lists[this.list5Id].listFieldsForSubst[this.fieldId]
    },
    viewField(){
      let viewId = this.$store.state.lists[this.list5Id].viewId
      return this.$store.state.lists[this.list5Id].list.data.views[viewId].data.fields[this.fieldId]
    },
    statusColor(){
      let statusColor = ""
      if(this.itemStatus === "updated") statusColor = "green"
      else if(this.itemStatus === "changing") statusColor = "blue-lighten-3"
      else if(this.itemStatus === "changed") statusColor = "yellow"
      else if(this.itemStatus === "new") statusColor = "red"
      else statusColor = "grey"
      return statusColor
    }
  },
  methods: {
    displayValArrFixedSublist(valS){
      let valSIds = valS ? valS.split(";") : []
      for(let valSId of valSIds){
        this.displayValArr.push({
          val: this.listFieldSubst.fixedSublist[valSId]?.value,
          valS: valSId,
          style: this.listFieldSubst.fixedSublist[valSId]?.style
        })
      }
    },
    displayValArrSublist(valS){
      // debugger
      let sublistListData = this.$store.state.lists[this.list5Id].sublists[this.listFieldSubst.listIdForSublist].list.data
      if(sublistListData.fields[this.listFieldSubst.fieldIdForSublist].type === "fixedsublist") {
        let valSIds = valS ? valS.split(";") : []
        for (let valSId of valSIds) {
          let fValS = this.$store.state.lists[this.list5Id].sublists[this.listFieldSubst.listIdForSublist].items[valSId].data.fieldsS[this.listFieldSubst.fieldIdForSublist]
          let fValSIds = fValS ? fValS.split(";") : []
          for (let fValSId of fValSIds) {
            this.displayValArr.push({
              val: sublistListData.fields[this.listFieldSubst.fieldIdForSublist].fixedSublist[fValSId]?.value,
              valS: fValSId,
              style: sublistListData.fields[this.listFieldSubst.fieldIdForSublist].fixedSublist[fValSId]?.style
            })
          }
        }
      }
      else{
        let sublistViewData = sublistListData.views[this.listFieldSubst.viewIdForSublist].data
        let sublistViewField = sublistViewData.fields[this.fieldId]
        let sublistItems = this.$store.state.lists[this.list5Id].sublists[this.listFieldSubst.listIdForSublist].items
        let valSIds = valS ? valS.split(";") : []
        for(let valSId of valSIds){
          this.displayValArr.push({
            val: sublistItems[valSId]?.data.fields[this.listFieldSubst.fieldIdForSublist],
            valS: valSId,
            style: sublistViewField?.cellStringStyle
          })
        }
      }
    },
    tasksOpen(){
      this.tasksShow = true
      this.cellFormShow = true
    },
    commentsOpen(){
      this.tasksShow = true
      this.cellFormShow = true
    },
    cellDblClick(){
      debugger
      if(this.listField.showInSeparateWindow){
        if(this.listField.richText) this.editorShow = true
        else this.textShow = true
      }
      else if(this.listField.type === "text" || this.listField.type === "num"){
        this.isEditable = true
        setTimeout(()=>{this.$el.focus()}, 1)
      }
      if(this.listField.type === "fixedsublist") {this.fixedSublistShow = true}
      else if (this.listField.type === "sublist") {this.sublistShow = true}
      else if (this.listField.type === "date" || this.listField.type === "time") {this.datetimeShow = true}
      else if (this.listField.type === "html") {this.editorShow = true}
      else if (this.fieldId === "z2") {this.statusShow = true}


      if(this.fixedSublistShow ||
        this.sublistShow ||
        this.datetimeShow ||
        this.editorShow ||
        this.textShow ||
        this.statusShow
      ) this.cellFormShow = true
    },
    cellBlur(){
      this.isEditable = false
      this.displayVal = this.$el.innerText.trim()
      if(this.itemId === "new"){
        let newItemPut = this.newItem
        newItemPut.id = generateId()
        // newItemPut.status = "created"
        // newItemPut.$data.version = 0
        let listFields = this.$store.state.lists[this.list5Id].list.data.fields
        for(let fieldId in listFields){
          if(listFields[fieldId].type === "fixedsublist" || listFields[fieldId].type === "sublist"){
            delete newItemPut.data.fields[fieldId]
            if(listFields[fieldId].sublistBasedOnFieldId) delete newItemPut.data.fieldsS[fieldId]
          }
          else{
            delete newItemPut.data.fieldsS[fieldId]
          }
        }
        db.table("items").put(newItemPut).then(itemId => {
          db.table("items").where("id").equals(itemId).modify(item => {
            item.data.fields[this.fieldId] = this.displayVal
            formulaRun(this.$store.state.lists[this.list5Id].list.data, "onSave", item.data.fields)
            objStatusFieldsSet(item)
          })
        })
      }
      else{
        db.table("items").where("id").equals(this.itemId).modify(item => {
          item.data.fields[this.fieldId] = this.displayVal
          formulaRun(this.$store.state.lists[this.list5Id].list.data, "onSave", item.data.fields)
          objStatusFieldsSet(item)
        })
      }
    }
  }
}
</script>

<style scoped>
.cell {
  white-space: pre-wrap;
  padding: 4px;
  position: relative;
  line-height: 1.2;
  z-index: 1;
  border-bottom: 1px solid lightgrey;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cellNoneSelectable {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.cellEditable {
  white-space: pre-wrap;
  padding: 4px;
  line-height: 1.2;
}
.cellFrame {
  border: 2px solid orange;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
</style>