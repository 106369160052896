<template>
  <q-card>
    <q-card-section>
      <q-chip v-for="table in tables" clickable :key="table.name" dense
              @click="selectedTable=table"
              :color="selectedTableName===table.name?'orange':''"
              :text-color="selectedTableName===table.name?'white':''"
      >
        {{table.name}}
      </q-chip>
    </q-card-section>
    <db-table-rows :table="selectedTable" :key="selectedTableName" v-if="selectedTable"/>
  </q-card>
</template>

<script>
import { db } from '@/db'
import DbTableRows from "@/components/db-table-rows";
// import Dexie from 'dexie'
export default {
  name: "dbViewer",
  components: {DbTableRows},
  data: () => {
    return {
      tables: [],
      selectedTable: null
    }
  },
  created() {
    let that = this
    that.tables = db.tables
    // debugger
    // Dexie.getDatabaseNames().then((dbs)=>{
    //   that.dbs = dbs
    // })
  },
  computed: {
    selectedTableName(){
      let selectedTableName = ""
      if (this.selectedTable) selectedTableName = this.selectedTable.name
      return selectedTableName
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>