<template>
  <q-card>
    <q-card-section class="text-center font-weight-bold">
      {{ list.data.fields[fieldId].name }}
    </q-card-section>
    <q-card-section>
      <q-scroll-area style="width: 200px; height: 300px">
        <div style="color: blue; cursor: pointer; text-decoration: underline">
          <span @click="selectAll()">Select all</span>
        </div>
        <div style="color: blue; cursor: pointer; text-decoration: underline">
          <span @click="deselectAll()">Deselect all</span>
        </div>
        <div v-for="arrValue in allValues" :key="arrValue.value">
          <q-checkbox v-model="arrValue.selected" :label="arrValue.value" class="q-ma-sm" dense color="green"/>
        </div>
      </q-scroll-area>
    </q-card-section>
    <q-card-actions>
      <q-space/>
      <q-btn flat @click="saveFilter()">Ok</q-btn>
      <q-space/>
    </q-card-actions>
  </q-card>
</template>

<script>
// import {db} from "@/db"

export default {
  name: "sl-list-column-filter-form",
  props: ["list", "view", "viewField", "fieldId", "items"],
  data: () => {
    return {
      lViewField: {},
      filter: {},
      allValues: []
    }
  },
  async created() {
    debugger
    this.lViewField = this.view._data.fields[this.fieldId]//this.viewField
    let that = this
    if (this.lViewField.filter) {
      this.filter = JSON.parse(JSON.stringify(this.lViewField.filter))
    }
    if (!this.filter.arr) this.filter.arr = []
    if (!this.filter.pattern) this.filter.pattern = ""
    if (!this.filter.less) this.filter.less = ""
    if (!this.filter.lessEqual) this.filter.lessEqual = ""
    if (!this.filter.more) this.filter.more = ""
    if (!this.filter.moreEqual) this.filter.moreEqual = ""
    // debugger

    // let items = await db.items.where("data.listId").equals(this.list.id).toArray()
    let items = this.items
    if (this.list.data.fields[this.fieldId].type === "sublist" && !this.list.data.fields[this.fieldId].isFixedSublist) {
      let listIdForSublist = that.list.data.fields[that.fieldId].listIdForSublist
      let fieldIdForSublist = that.list.data.fields[that.fieldId].fieldIdForSublist
      items.forEach((item) => {
        let id = item.data.fieldsS[this.fieldId]
        if (!id) {
          if (!that.allValues.some(v => v.id === "")) that.allValues.push({value: "", id: "", selected: false})
          return
        }

        // if (this.list.data.fields[this.fieldId].isFixedSublist) {
        //   if (!that.allValues.some(v => v.id === id)) {
        //     that.allValues.push({
        //       value: that.list.data.fields[that.fieldId].fixedSublist[id].value,
        //       id: id,
        //       selected: false
        //     })
        //   }
        // }
        // else {
        if (that.allValues.findIndex(v => v.id === id) === -1) {
          debugger
          let sublistItem = that.$store.state.sublists[listIdForSublist].items.find(el => el.id === id)
          if(sublistItem){
            that.allValues.push({
              value: sublistItem.data.fields[fieldIdForSublist], //that.view._sublists[that.fieldId][id].value,
              id: id,
              selected: false
            })
          }

        }
        // }
      })
    }
    else {
      items.forEach((item) => {
        if (!that.allValues.some(v => v.value === item.data.fields[this.fieldId])) that.allValues.push({
          value: item.data.fields[this.fieldId],
          selected: false
        })
      })
    }
    this.allValues.sort((a, b) => {
      if (a.value < b.value) return -1
      if (a.value > b.value) return 1
      return 0
    })

    if (this.list.data.fields[this.fieldId].type === "sublist" && !this.list.data.fields[this.fieldId].isFixedSublist) {
      this.allValues.forEach(el => {
        if (that.filter.arr.includes(el.id)) el.selected = true
      })
    }
    else {
      this.allValues.forEach(value => {
        if (that.filter.arr.includes(value.value)) value.selected = true
      })
    }
  },
  methods: {
    saveFilter() {
      let that = this
      this.filter.arr = []
      if (this.list.data.fields[this.fieldId].type === "sublist" && !this.list.data.fields[this.fieldId].isFixedSublist) {
        this.allValues.forEach(el => {
          if (el.selected) that.filter.arr.push(el.id)
        })
      }
      else {
        this.allValues.forEach(el => {
          if (el.selected) that.filter.arr.push(el.value)
        })
      }


      let allValuesSelected = true
      this.allValues.forEach(value => {
        if (!value.selected) allValuesSelected = false
      })

      if (this.filter.arr.length === 0 || allValuesSelected) {
        if (this.lViewField.filter) delete this.lViewField.filter
      }
      else {
        this.lViewField.filter = this.filter
      }
      let lView = this.view
      lView._isChangedByUser = true
      this.$emit("finished")
    },
    selectAll() {
      this.allValues.forEach(value => value.selected = true)
    },
    deselectAll() {
      this.allValues.forEach(value => value.selected = false)
    }
  }
}
</script>

<style scoped>

</style>