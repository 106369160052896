<template>
  <q-card v-if="listField.sublistBasedOnFieldId" >
    <q-card-section class="text-red">
      Make selection in field {{listField.sublistBasedOnFieldId}}
    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn label="Ok" @click="$emit('finished')"/>
    </q-card-actions>
  </q-card>
  <q-card v-else>
    <q-card-section>
      <div v-for="sublistItem in sublistItems" :key="sublistItem.id"
           class="q-ma-sm"
           @click="selectSubitem(sublistItem.id)"
      >
        {{sublistItem.data.fields[listField.fieldIdForSublist]}}
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { db } from "@/db"
import {objStatusFieldsSet} from "@/utils4";
import {generateId} from "@/utils";
export default {
  name: "sl-list-cell-form-sublist",
  props: ["list5Id", "fieldId", "itemId", "newItem"],
  data: () => {
    return {
      listId: "",
      listField: {},
      sublistItems: []
    }
  },
  created() {
    debugger
    this.listId = this.$store.state.lists[this.list5Id].list.id
    this.listField = this.$store.state.lists[this.list5Id].list.data.fields[this.fieldId]
    db.table("items").where("data.listId").equals(this.listField.listIdForSublist).toArray().then(items => {
      this.sublistItems = items
    })
  },
  methods: {
    selectSubitem(selectedSubitemId){
      if(this.itemId === "new"){
        let newItemPut = this.newItem
        newItemPut.id = generateId()
        delete newItemPut.data.fieldsFS
        let listFields = this.$store.state.lists[this.list5Id].list.data.fields
        for(let fieldId in listFields){
          if(listFields[fieldId].type === "fixedsublist" || listFields[fieldId].type === "sublist"){
            delete newItemPut.data.fields[fieldId]
            if(listFields[fieldId].sublistBasedOnFieldId) delete newItemPut.data.fieldsS[fieldId]
          }
          else{
            delete newItemPut.data.fieldsS[fieldId]
          }
        }
        db.table("items").put(newItemPut).then(itemId => {
          db.table("items").where("id").equals(itemId).modify(item => {
            item.data.fieldsS[this.fieldId] = selectedSubitemId
            objStatusFieldsSet(item)
            this.$emit("finished")
          })
        })
      }
      else{
        db.table("items").where("id").equals(this.itemId).modify(item => {
          item.data.fieldsS[this.fieldId] = selectedSubitemId
          objStatusFieldsSet(item)
          this.$emit("finished")
        })
      }
    }
  }
}
</script>

<style scoped>

</style>