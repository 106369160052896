<template>
  <q-card>
    <q-card-section>
      <q-chip v-for="_viewId in viewIds" :key="_viewId"
              :label="list.data.views[_viewId].data.name"
              :color="_viewId === viewId ? 'green':'lightgrey'"
              :text-color="_viewId === viewId ? 'white':'black'"
              @click="selectView(_viewId)" clickable
      />
    </q-card-section>
    <q-card-actions>
      <q-btn @click="$emit('finished')" label="Cancel"/>
    </q-card-actions>
  </q-card>
</template>

<script>
export default {
  name: "sl-list-header-viewslist",
  props: ["list", "viewId"],
  data: () => {
    return {
      viewIds: []
    }
  },
  created() {
    for(let viewId in this.list.data.views){
      this.viewIds.push(viewId)
    }
  },
  methods: {
    selectView(viewId){
      this.$router.push({ name: 'list', params: {
        spaceId: this.list.spaceId, listId: this.list.id, viewId: viewId
      }})
    }
  }
}
</script>

<style scoped>

</style>