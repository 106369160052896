<template>
<q-card>
  <q-card-section>
    <div>itemId: <span class="text-red-darken-4">{{item.id}}</span></div>
    <div>status: <span class="text-red-darken-4">{{item.status}}</span></div>

  </q-card-section>
  <q-card-actions class="justify-center">
    <q-btn @click="$emit('finished')">Ok</q-btn>
  </q-card-actions>
</q-card>
</template>

<script>
export default {
  name: "sl-list-cell-form-status",
  props: ["list5Id", "itemId"],
  data: () => {
    return {
      item: {}
    }
  },
  created() {
    this.item = this.$store.state.lists[this.list5Id].items.find(el=>el.id===this.itemId)
  }
}
</script>

<style scoped>

</style>