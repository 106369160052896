<template>
  <q-card-section>
    <div  class="row">
    <q-input v-model="filterProp" label="filter prop" dense class="q-mr-sm"/>
    <q-input v-model="filterVal" label="filter value" dense/>
    </div>
    <div  class="row q-ma-xs">
      <q-chip v-for="prop in commonProps" :key="prop" clickable @click="commonPropsSet(prop)" dense>{{prop}}</q-chip>
      <q-btn icon="add" round flat @click="commonPropSave()" size="sm"/>
      <q-btn icon="delete" round flat @click="commonPropDel()" size="sm"/>
    </div>
  </q-card-section>
  <q-card-section>
    <div>
      <db-table-row v-for="row in pRows" :key="row.id" :row="row" :tableName="table.name"/>
    </div>
    <div>
      <q-btn icon="arrow_left" round dense size="xs" class="q-mx-sm" @click="pageMove('left')"/>
      {{pageNum}} of {{pages}}
      <q-btn icon="arrow_right" round dense size="xs" class="q-mx-sm" @click="pageMove('right')"/>
    </div>
  </q-card-section>
</template>

<script>
import {db} from "@/db"
import {useObservable} from "@vueuse/rxjs/index"
import {liveQuery} from "dexie"
import DbTableRow from "@/components/db-table-row"

export default {
  name: "db-table-rows",
  components: {DbTableRow},
  props: ["table"],
  data: () => {
    return {
      pageNum: 0,
      filterOptions: [],
      filterProp: "id",
      filterVal: "",
      commonProps: [],
      // rows: []
    }
  },
  async created() {
    for(let prop in this.table){
      if(typeof prop !== 'object') this.filterOptions.push(prop)
    }
    this.commonPropsGet()
    // let that = this
    // debugger
    // this.rows = await db.table(this.table.name).toArray()
      //   .then(arr=>{
      // debugger
      // that.rows = arr})
  },
  setup(props) {
    console.log("tableee", props.table.name)
    return {
      db,
      rows: useObservable(
          liveQuery(() => db.table(props.table.name).toArray())
      )
    }
  },
  computed: {
    fRows(){
      let fRows = []
      if(this.rows) fRows = this.rows
      if(this.filterProp !== "" && this.filterVal !== ""){
        fRows = this.rows.filter(el=>{
          let arr = this.filterProp.split(".")
          let filterEl = el
          arr.forEach(prop => {
            if(filterEl[prop]) filterEl=filterEl[prop]
          })
          return filterEl===this.filterVal
          //el[this.filterProp]===this.filterVal
        })
      }
      return fRows
    },
    pRows(){
      let pRows = this.fRows.slice(this.pageNum * 10, (this.pageNum+1) * 10)
      return pRows
    },
    pages(){
      if (!this.rows) return []
      return this.rows.length / 10
    }
  },
  methods: {
    pageMove(dir){
      if(dir==="left"){
        this.pageNum--
      }
      if(dir==="right"){
        this.pageNum++
      }
    },
    commonPropSave(){
      this.commonProps.push(this.filterProp)
      localStorage.setItem("commonProps", JSON.stringify(this.commonProps))
    },
    commonPropDel(){
      let index = this.commonProps.findIndex(el=>el===this.filterProp)
      this.commonProps.splice(index, 1)
      localStorage.setItem("commonProps", JSON.stringify(this.commonProps))
      this.filterProp = ""
    },
    commonPropsSet(prop){
      this.filterProp = prop
    },
    commonPropsGet(){
      let commonProps = JSON.parse(localStorage.getItem("commonProps"))
      if(commonProps) this.commonProps = commonProps
    },
  }
}
</script>

<style scoped>

</style>