<template>
  <tr v-if="subtotals.vals && subtotals.level > 0">
    <template v-for="rFieldId in view?.data.fieldsOrder" :key="rFieldId">
      <td v-if="subtotals.vals[rFieldId]" :style="{borderBottom: '1px solid lightgrey'}" :class="bgClass">
        {{subtotals.vals[rFieldId]}}
      </td>
      <td v-else-if="subtotals.fields[rFieldId]" :style="{borderBottom: '1px solid lightgrey'}" :class="bgClass">
        {{subtotals.fields[rFieldId]}}
      </td>
      <td v-else :style="{borderBottom: '1px solid lightgrey'}" :class="bgClass">
      </td>
    </template>
  </tr>
  <sl-list5-subtotal-row v-for="(row, i) in subtotals.subTotals0Arr" :key="i" :subtotals="row" :list="list" :view="view"/>
  <tr v-if="subtotals.vals && subtotals.level === 0">
    <template v-for="rFieldId in view?.data.fieldsOrder" :key="rFieldId">
      <td v-if="subtotals.vals[rFieldId]" :style="{borderBottom: '1px solid lightgrey'}" :class="bgClass + ' font-weight-bold'">
        {{subtotals.vals[rFieldId]}}
      </td>
      <td v-else-if="subtotals.fields[rFieldId]" :style="{borderBottom: '1px solid lightgrey'}" :class="bgClass + ' font-weight-bold'">
        {{subtotals.fields[rFieldId]}}
      </td>
      <td v-else :style="{borderBottom: '1px solid lightgrey'}" :class="bgClass + ' font-weight-bold'">
      </td>
    </template>
  </tr>
</template>

<script>
export default {
  name: "sl-list5-subtotal-row",
  props: ["subtotals", "list", "view"],
  computed: {
    bgClass(){
      let bgClass = "bg-yellow-lighten-2"
      if(this.subtotals.level > 0){
        bgClass = 'bg-grey-lighten-'+this.subtotals.level
      }
      return bgClass
    }
  },
}
</script>

<style scoped>

</style>