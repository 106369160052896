<template>
  <q-card>
    <q-card-section>
      <q-select v-model="params.listId2" :options="listsOptions"
                option-value="id" option-label="desc" emit-value map-options
                label="Aggregator list"
      />
      <q-select v-model="params.fieldId2" :options="mapFieldsOptions2"
                option-value="id" option-label="desc" emit-value map-options
                label="Aggregator field"
      />
      <q-select v-model="params.operation" :options="operationsOptions" label="Operation"/>
    </q-card-section>
    <q-card-section>
<!--      <table>-->
<!--        <tr v-for="(el, i) in params.map" :key="i">-->
<!--          <td>-->
<!--            {{el.fieldId}} - {{list.data.fields[el.fieldId].name}}-->
<!--            <br/>-->
<!--            {{el.fieldId2}} - {{list2.data.fields[el.fieldId].name}}-->
<!--          </td>-->
<!--          <td><q-btn round dense flat icon="delete" @click="params.map.splice(i,1)"/></td>-->
<!--        </tr>-->
<!--      </table>-->
      Filter mapping
      <div v-for="(el, i) in params.filterMap" :key="i">
        <q-select v-model="el.fieldId" :options="mapFieldsOptions"
                  :label="el.fieldId2 + ' - ' + list2.data.fields[el.fieldId2].name"
                  option-value="id" option-label="desc"
                  emit-value map-options
                  v-if="el.type==='fieldsMapping'"
        >
          <template v-slot:after>
            <q-btn round dense flat icon="delete" @click="params.filterMap.splice(i,1)"/>
          </template>
        </q-select>
        <q-input v-model="el.fixedValue"
                  :label="el.fieldId2 + ' - ' + list2.data.fields[el.fieldId2].name"
                  v-if="el.type==='fixedValue'"
        >
          <template v-slot:after>
            <q-btn round dense flat icon="delete" @click="params.filterMap.splice(i,1)"/>
          </template>
        </q-input>
      </div>
      <q-select v-model="newFilterMap.fieldId2" :options="mapFieldsOptions2"
                option-value="id" option-label="desc"
                emit-value map-options
                label="new field for mapping"
      >
        <template v-slot:after>
          <q-btn round dense icon="add" @click="newMapAdd()"/>
        </template>
      </q-select>
      <v-chip class="q-mt-xs q-mr-xs" @click="newFilterMap.type = 'fieldsMapping'"
              :color="newFilterMap.type === 'fieldsMapping' ? 'green' : 'lightgrey'"
      >Fields mapping</v-chip>
      <v-chip  class="q-mt-xs" @click="newFilterMap.type = 'fixedValue'"
              :color="newFilterMap.type === 'fixedValue' ? 'green' : 'lightgrey'"
      >Fixed value</v-chip>
    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn flat @click="paramsClear()">Clear</q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import {db} from "@/db"

export default {
  name: "sl-list-column-props-aggregator",
  props: ["list", "fieldId", "aggregator"],
  data: () => {
    return {
      lists: [],
      listsOptions: [],
      newFieldId2: "",
      newIsFixedValue: false,
      params: {
        // listId2: "",
        // operation: "",
        // fieldId2: "",
        // filterMap: []
      },
      newFilterMap: {
        fieldId2: "", type: "fieldsMapping", fieldId: "", fixedValue: ""
      },
      operationsOptions: ["sum", "avg", "min", "max", "qnt"]
    }
  },
  async created() {
    this.lists = await db.lists.where("spaceId").equals(this.list.spaceId).toArray()
    this.lists.forEach(list => {
      this.listsOptions.push({id: list.id, desc: list.data.name})
    })
    this.params = this.aggregator
    if(!this.params.listId2){
      this.params.listId2 = ""
      this.params.operation = ""
      this.params.fieldId2 = ""
      this.params.filterMap = []
    }
  },
  watch: {
    'params.listId2': function (newVal, oldVal){
      if(!oldVal) return
      if(newVal !== oldVal) this.params.filterMap = []
    }

  },
  computed: {
    list2(){
      return this.lists.find(el=>el.id === this.params.listId2)
    },
    mapFieldsOptions(){
      let fieldsOptions = []
      for (let fieldId in this.list.data.fields){
        if(fieldId.charAt(0) !== "f") continue
        fieldsOptions.push({id: fieldId, desc: fieldId + " - " + this.list.data.fields[fieldId].name})
      }
      return fieldsOptions
    },
    mapFieldsOptions2(){
      if(!this.list2) return []
      let fieldsOptions = []
      for (let fieldId in this.list2.data.fields){
        if(fieldId.charAt(0) !== "f") continue
        fieldsOptions.push({id: fieldId, desc: fieldId + " - " + this.list2.data.fields[fieldId].name})
      }
      return fieldsOptions
    }
  },
  methods: {
    newMapAdd(){
      if(this.newFilterMap.fieldId2 === "") return
      this.params.filterMap.push(JSON.parse(JSON.stringify(this.newFilterMap)))
      this.newFilterMap.fieldId2 = ""
      this.newFilterMap.type = "fieldsMapping"
      this.newFilterMap.fieldId = ""
      this.newFilterMap.fixedValue = ""
      debugger
    },
    paramsClear(){
      this.params.listId2 = ""
      this.params.operation = ""
      this.params.fieldId2 = ""
      this.params.filterMap = []
    }
  }
}
</script>

<style scoped>

</style>