<template>
  <q-card>
    <q-card-section>
      space template
      <pre>
        {{spaceTemplate}}
      </pre>
    </q-card-section>
  </q-card>
</template>

<script>
import {obj} from "@/utils4"

export default {
  name: "test-t999",
  data: () => {
    return {
      spaceTemplate: {}
    }
  },
  created() {
    this.spaceTemplate = obj({}, "space")
  }
}
</script>

<style scoped>

</style>