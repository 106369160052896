<template>
  <q-card flat>
  <q-card-section>
    <span v-for="elId in lFixedSublistOrder" :style="lFixedSublist[elId].style" :key="elId" @click="sublistElFormOpen(elId)">{{lFixedSublist[elId].value}}</span>
    <q-btn icon="add" size="xs" round @click="sublistElFormOpen()" class="q-ma-md"/>
    <q-btn icon="repeat" size="xs" round @click="sublistOrderFormShow=true"/>
  </q-card-section>

  <q-dialog v-model="sublistOrderFormShow">
    <q-card>
      <q-card-section>
        <q-list>
          <q-item v-for="(elId, index) in lFixedSublistOrder" :key="index">
            <q-item-section>
              <span :style="lFixedSublist[elId].style">{{lFixedSublist[elId].value}}</span>
            </q-item-section>
            <q-item-section side>
              <q-btn icon="keyboard_double_arrow_up" round @click="elMove(index, 'toStart')" size="xs"/>
            </q-item-section>
            <q-item-section side>
              <q-btn icon="keyboard_double_arrow_down" round @click="elMove(index, 'toEnd')" size="xs"/>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
      <q-card-actions class="justify-center">
        <q-btn flat @click="sublistOrderFormShow = false">Ok</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
    <q-dialog v-model="sublistElFormShow">
      <q-card>
        <q-card-section class="text-center">
          <span :style="currentFixedSublistEl.style">{{currentFixedSublistEl.value}}</span>
        </q-card-section>
        <q-card-section>
          <q-input v-model="currentFixedSublistEl.value" label="Value" dense color="green"/>
          <br/>
          <q-scroll-area style="height: 200px; max-width: 300px;">
            <sl-list-style-form :styleProps="currentFixedSublistEl.style"/>
          </q-scroll-area>
        </q-card-section>
        <q-card-actions class="justify-center">
          <q-btn flat color="green" @click="sublistElDelete()">Delete</q-btn>
          <q-btn flat color="green" @click="sublistElFormShow=false">Cancel</q-btn>
          <q-btn flat color="green" @click="sublistElSave()">Ok</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import SlListStyleForm from "@/components/sl-list-style-form"
export default {
  name: "sl-list-column-props-fixed-sublist_old",
  components: {SlListStyleForm},
  props: ["fixedSublist", "fixedSublistOrder"],
  data: () => {
    return {
      lFixedSublist: null,
      lFixedSublistOrder: null,
      sublistElFormShow: false,
      sublistOrderFormShow: false,
      currentFixedSublistElId: "",
      currentFixedSublistEl: {
        value: "",
        style: {}
      }
    }
  },
  created() {
    this.lFixedSublist = this.fixedSublist
    this.lFixedSublistOrder = this.fixedSublistOrder
  },
  methods: {
    sublistElFormOpen(elId){
      if(elId){
        this.currentFixedSublistElId = elId
        this.currentFixedSublistEl = this.lFixedSublist[elId]
      }
      else{
        this.currentFixedSublistElId = ""
        this.currentFixedSublistEl.value = ""
        this.currentFixedSublistEl.style = {
          backgroundColor: "grey",
          color: "white",
          padding: "3px 8px",
          borderRadius: "15px",
          margin: "2px",
          cursor: "pointer",
          boxShadow: "0 0 8px lightgrey",
          display: "inline-block"
        }
      }
      this.sublistElFormShow = true
    },
    sublistElSave(){
      for (let prop in this.currentFixedSublistEl.style) {
        if(this.currentFixedSublistEl.style[prop] === "") delete this.currentFixedSublistEl.style[prop]
      }
      if(this.currentFixedSublistElId === ""){
        let maxId = 100
        for(let prop in this.lFixedSublist){
          if(maxId < parseInt(prop)) maxId = parseInt(prop)
        }
        maxId++
        this.currentFixedSublistElId = maxId
        this.lFixedSublistOrder.push(this.currentFixedSublistElId)
      }
      this.lFixedSublist[this.currentFixedSublistElId] = JSON.parse(JSON.stringify(this.currentFixedSublistEl))
      this.sublistElFormShow = false
    },
    sublistElDelete(){
      // todo: before delete check if there are records with element is to be deleted
      delete this.lFixedSublist[this.currentFixedSublistElId]
      let i = this.lFixedSublistOrder.findIndex(el=>el===this.currentFixedSublistElId)
      if(i >= 0) this.lFixedSublistOrder.splice(i, 1)
      this.sublistElFormShow = false
    },
    elMove(index, dir){
      if(index === 0 && dir === "toStart" ) return
      if(index === this.lFixedSublistOrder.length-1 && dir === "toEnd" ) return
      let elToMove = this.lFixedSublistOrder.splice(index, 1)[0]
      if(dir === "toStart") {
        this.lFixedSublistOrder.splice(index-1, 0, elToMove)
      }
      if(dir === "toEnd") {
        this.lFixedSublistOrder.splice(index+1, 0, elToMove)
      }
    }
  }
}
</script>

<style scoped>

</style>