<template>
  <q-card flat>
  <q-card-section>
    <table>
        <tr v-for="(el, index) in lFixedSublist" :key="index" >
          <td style="padding: 5px">
            <span :style="el.style">{{el.value}}</span>
          </td>
          <td style="padding: 5px">
            <q-btn icon="edit" round @click="elFormOpen(index)" size="xs"/>
          </td>
          <td style="padding: 5px">
            <q-btn icon="keyboard_double_arrow_up" round @click="elMove(index, 'up')" size="xs"/>
          </td>
          <td style="padding: 5px">
            <q-btn icon="keyboard_double_arrow_down" round @click="elMove(index, 'down')" size="xs"/>
          </td>
        </tr>
    </table>
    <q-btn icon="add" size="xs" round @click="elFormOpen()" class="q-ma-md"/>
  </q-card-section>
    <q-dialog v-model="elFormShow">
      <q-card>
        <q-card-section class="text-center">
          <span :style="currentEl.style">{{currentEl.value}}</span>
        </q-card-section>
        <q-card-section>
          <q-input v-model="currentEl.value" label="Value" dense color="green"/>
          <br/>
          <q-scroll-area style="height: 200px; max-width: 300px;">
            <sl-list-style-form :styleProps="currentEl.style"/>
          </q-scroll-area>
        </q-card-section>
        <q-card-actions class="justify-center">
          <q-btn flat color="green" @click="elDelete()">Delete</q-btn>
          <q-btn flat color="green" @click="elFormShow=false">Cancel</q-btn>
          <q-btn flat color="green" @click="elSave()">Save</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import SlListStyleForm from "@/components/sl-list-style-form"
export default {
  name: "sl-list-column-props-fixed-sublist",
  components: {SlListStyleForm},
  props: ["fixedSublist"],
  data: () => {
    return {
      lFixedSublist: [],
      elFormShow: false,
      currentElIndex: null,
      currentEl: {
        value: "",
        style: {}
      }
    }
  },
  created() {
    // this.lFixedSublist = JSON.parse(JSON.stringify(this.fixedSublist))
    this.lFixedSublist = this.fixedSublist
  },
  methods: {
    elFormOpen(index){
      if(index !== undefined){
        this.currentEl = JSON.parse(JSON.stringify(this.lFixedSublist[index]))
        this.currentElIndex = index
      }
      else{
        this.currentElIndex = null
        this.currentEl.value = ""
        this.currentEl.style = {
          backgroundColor: "grey",
          color: "white",
          padding: "2px 7px",
          borderRadius: "15px",
          cursor: "pointer",
          boxShadow: "0 0 8px lightgrey",
          display: "inline",
          whiteSpace: "nowrap"
        }
      }
      this.elFormShow = true
    },
    elSave(){
      for (let prop in this.currentEl.style) {
        if(this.currentEl.style[prop] === "") delete this.currentEl.style[prop]
      }
      if(this.currentElIndex === null){
        this.lFixedSublist.push(JSON.parse(JSON.stringify(this.currentEl)))
      }
      else{
        this.lFixedSublist[this.currentElIndex] = JSON.parse(JSON.stringify(this.currentEl))
      }
      this.elFormShow = false
    },
    elDelete(){
      // todo: before delete check if there are records with element is to be deleted
      this.lFixedSublist.splice(this.currentElIndex, 1)
      this.elFormShow = false
    },
    elMove(index, dir){
      if(index === 0 && dir === "up" ) return
      if(index === this.lFixedSublist.length-1 && dir === "down" ) return

      let elToMove = this.lFixedSublist.splice(index, 1)[0]
      if(dir === "up") {
        this.lFixedSublist.splice(index-1, 0, elToMove)
      }
      if(dir === "down") {
        this.lFixedSublist.splice(index+1, 0, elToMove)
      }
    }
  }
}
</script>

<style scoped>

</style>