<template>
  <div :class="['sl-cell row items-center', phoneLayoutField?.bgShow ? 'bg-'+phoneLayoutField?.bgColor+'-1' : '']"
       :style="cellStyle"
       @click="$emit('cellClick', $event)"
       @mousedown="$emit('cellMousedown', $event)"
       @dblclick="$emit('cellDblclick', $event)"
       @blur="$emit('cellBlur', $event)"
       @contextmenu="$emit('cellContextmenu', $event)"
  >
<!--    <span v-html="cellString"></span>-->




    <!--    <div class="row _bg-red" :style="cellDivStyle">-->

    <template v-if="list.data.fields[fieldId].type === 'sublist' && list.data.fields[fieldId].isFixedSublist && item.data.fields[this.fieldId]">
      <sl-list-cell-content
          v-for="(subitemIndex, i) in item.data.fields[this.fieldId].split(';')" :key="subitemIndex"
          :fieldId="fieldId" :item="item" :view="view" :list="list" :itemNumber="itemNumber"
          :currentCell="currentCell" :upperGroupFields="upperGroupFields" :upperGroupFieldId="upperGroupFieldId"
          :subitemIndex="i"
          @checkboxUpdated="$emit('checkboxUpdated', $event)"
      />
    </template>
    <template v-else-if="list.data.fields[fieldId].type === 'sublist' && !list.data.fields[fieldId].isFixedSublist && item.data.fieldsS[this.fieldId]">
      <sl-list-cell-content
          v-for="(subitemIndex, i) in item.data.fieldsS[this.fieldId].split(';')" :key="subitemIndex"
          :fieldId="fieldId" :item="item" :view="view" :list="list" :itemNumber="itemNumber"
          :currentCell="currentCell" :upperGroupFields="upperGroupFields" :upperGroupFieldId="upperGroupFieldId"
          :subitemIndex="i"
          @checkboxUpdated="$emit('checkboxUpdated', $event)"
      />
    </template>
    <template v-else  >
      <sl-list-cell-content
          :fieldId="fieldId" :item="item" :view="view" :list="list" :itemNumber="itemNumber"
          :currentCell="currentCell" :upperGroupFields="upperGroupFields" :upperGroupFieldId="upperGroupFieldId"
          :subitemIndex="''"
          @checkboxUpdated="$emit('checkboxUpdated', $event)"
          @drilldownListSelected="$emit('drilldownListSelected', $event)"
      />
    </template>
<!--    <template v-else>-->
<!--      {{cellString}}-->
<!--    </template>-->


    <div class="sl-cell-frame"
         style="border: 2px solid orange; position: absolute; top: 0; left: 0; z-index: -1; width: 100%; height: 100%;"
         v-if="isCurrentCell"
    />



    <div :class="['text-'+phoneLayoutField?.nameColor+'-9']"
         :style="{ fontSize: '10px', opacity: isCurrentCell ? '1':'1', whiteSpace: 'nowrap', padding: '2px',
        position: 'absolute', top: '-13px', left: 0, zIndex: 3, width: '100%', _overflow: 'hidden'}"
         v-if="$q.platform.is.mobile && isCurrentCell"
    >
      <span v-if="phoneLayoutField?.nameShow">{{ list.data.fields[fieldId].name }}</span>
    </div>

<!--    </div>-->


<!--    <span v-if="fieldId === 'z1'" :style="item.id === '' ? 'color: lightgrey' : ''">-->
<!--      {{itemNumber}}-->
<!--    </span>-->
<!--    <span v-if="fieldId === 'z2'">-->
<!--      <span v-if="item.id===''" class="text-red">*</span>-->
<!--      <q-icon name="cloud_upload" color="orange" v-if="false"/>-->
<!--      <q-icon name="drive_file_rename_outline" color="orange" v-if="false"/>-->
<!--      <q-icon name="circle" color="green" v-if="item.id!==''"/>-->
<!--    </span>-->
<!--    <span v-if="fieldId === 'z3'">-->
<!--      <q-btn icon="keyboard_arrow_right" round size="xs" dense v-if="item.id!==''"/>-->
<!--    </span>-->
<!--    <q-icon name="article" size="xs" style="cursor: pointer"-->
<!--            v-if="this.list.data.fields[this.fieldId].showInSeparateWindow"-->
<!--            :color="this.item.data.fields[this.fieldId] === '' || this.item.data.fields[this.fieldId] === undefined ? 'grey' : 'green'"/>-->
<!--    <q-img :src="cellString" :style="cellStringStyle" v-if="list.data.fields[fieldId].type === 'thumbnail' && this.item.id !== ''" no-spinner/>-->
<!--    <q-checkbox dense v-model="checkboxVal" color="green" v-else-if="list.data.fields[fieldId].type === 'checkbox' && this.item.id !== ''" @update:model-value="$emit('checkboxUpdated', $event)"/>-->

<!--    <q-chip v-else-if="fieldInitialType === 'avatar' && avatarData" color="white" _dense  style="margin-top: -4px; margin-bottom: -4px; _box-shadow: 0 0 8px lightgrey">-->
<!--      <q-avatar>-->
<!--        <img :src="avatarData.src">-->
<!--      </q-avatar>-->
<!--      {{avatarData.name}}-->
<!--    </q-chip>-->
<!--    <span :style="cellStringStyle" v-else v-html="cellString"/>-->
  </div>
</template>

<script>
// import {numFormat} from "@/utils3";
// import {cellStringStyleForSublist, objVal} from "@/utils4";

import SlListCellContent from "@/components/sl-list-cell-content";
// import {numFormat} from "@/utils3";
export default {
  name: "sl-list-cell",
  components: {SlListCellContent},
  props: ["fieldId", "item", "view", "list", "itemNumber", "currentCell", "upperGroupFields", "upperGroupFieldId", "phoneLayoutField"],
  emits: ["cellClick", "cellDblclick", "cellBlur", "cellContextmenu", "cellMousedown", "checkboxUpdated", "drilldownListSelected"],
  data: () => {
    return {
    }
  },
  mounted() {
  },
  computed: {
    isCurrentCell(){
      let isCurrentCell =
        this?.currentCell?.upperGroupField === this?.upperGroupFields[this.upperGroupFieldId] &&
        this?.currentCell?.item?.id === this?.item?.id &&
        this?.currentCell?.fieldId === this?.fieldId
      return isCurrentCell
    },
    // cellString() {
    //   let cellString = " "
    //   if(this.list.data.fields[this.fieldId].type === "num"){
    //     cellString = numFormat(cellString, this.list.data.fields[this?.fieldId])
    //   }
    //   if(this.list.data.fields[this.fieldId].type === "text"){
    //     cellString = this.item.data.fields[this?.fieldId]
    //   }
    //   return cellString
    // },
    cellStyle(){
      let cellStyle = {}

      if (this?.view?._data?.fields?.[this.fieldId]?.cellStyle) {
        cellStyle = {
          ...cellStyle,
          ...this.view._data.fields[this.fieldId].cellStyle
        }
      }
      if (this?.item?.data?.fieldsProps?.[this.view.id]?.[this.fieldId]) {
        cellStyle = {
          ...cellStyle,
          ...this.item.data.fieldsProps[this.view.id][this.fieldId].cellStyle
        }
      }

      // if (this.isCurrentCell) {
      //   cellStyle.border = '2px solid orange'
      //
      // }
      // else {
      //   cellStyle.border = '2px solid white'
      // }

      cellStyle.padding = '4px'
      // cellStyle.overflow = 'hidden'
      cellStyle.whiteSpace = 'pre-wrap'
      cellStyle.width = "100%"//this.$q.platform.is.mobile ? this.view._data.fields[this.fieldId].headCellStyle.width : ""
      cellStyle.position = "relative"
      cellStyle.lineHeight = "1.2"

      if(this.$q.platform.is.mobile && this.phoneLayoutField){
        cellStyle.borderRadius = this.phoneLayoutField.bgShow ? this.phoneLayoutField.bgRadius+'px' : ""
        cellStyle.margin = this.$q.platform.is.mobile ? '3px' : ''
        cellStyle.minHeight = this.$q.platform.is.mobile ? '30px' : ''
      }
      else{
        cellStyle.display = 'table-cell'
        cellStyle.zIndex = 1
      }
      // cellStyle.height = this.elHeight

      return cellStyle
    },
    // cellDivStyle(){
    //   // let cellDivStyle = {position: 'relative', top: '0px', left: '0px', right: '0px', bottom: '0px'}
    //   let cellDivStyle = {}
    //   if(this.currentCell) {
    //     if (this.currentCell.fieldId) {
    //       let isCurrentCell =
    //           this.currentCell.upperGroupField === this.upperGroupFields[this.upperGroupFieldId] &&
    //           this.currentCell.item.id === this.item.id &&
    //           this.currentCell.fieldId === this.fieldId
    //
    //       if(isCurrentCell){
    //         cellDivStyle.border = "1px solid orange"
    //         cellDivStyle.padding = "0px"
    //       }
    //       else{
    //         cellDivStyle.border = "0px"
    //         cellDivStyle.padding = "1px"
    //       }
    //
    //     }
    //   }
    //   return cellDivStyle
    // }
  }

}
</script>

<style scoped>
</style>