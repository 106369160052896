<template>
  <q-card>
    <q-card-section class="column items-center">
      <q-avatar size="200px" color="grey-5" text-color="white">
        <img :src="newAvatarImg" v-if="newAvatarImg">
        <span v-else>{{newName.charAt(0)}}</span>
      </q-avatar>
      <q-dialog v-model="cropperShow">
        <q-card>
          <q-card-section>
            <cropper
                ref="avatarCropper"
                class="cropper"
                :src="avatarFileSrc"
                :stencil-props="{ aspectRatio: 1, handlers: {}, movable: false, resizable: false }"
                :resize-image="{ adjustStencil: false }"
                :stencil-size="{ width: 200, height: 200 }"
                :canvas="{ height: 150, width: 150 }"
                image-restriction="stencil"
            />
          </q-card-section>
          <q-card-actions class="justify-center">
            <q-btn flat label="Cancel" @click="cropperShow=false"/>
            <q-btn flat label="Ok" @click="avatarCrop()"/>
          </q-card-actions>
        </q-card>
      </q-dialog>
      <q-file v-model="avatarFile"  @update:model-value="file=>avatarFileSelected(file)" accept="image/*" label="Photo" dense clearable color="green"/>
    </q-card-section>
    <q-card-section>
      <q-input v-model="newName" label="Name" dense color="green" class="q-mb-md"/>
      <q-input v-model="newPublicName" label="Public name (if encrypted)" dense color="green" class="q-mb-md"/>
      <q-input type="textarea" v-model="newDescription" label="Description" dense color="green" style="background-color: #f6f6f6"/>

    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn @click="$emit('finished')">Cancel</q-btn>
      <q-btn @click="save()">Save</q-btn>
    </q-card-actions>
  </q-card>
  <sl-msgs :msgs="msgs" v-if="msgs.length>0" @finished="msgs=[]"/>
</template>

<script>
import {currentUserId, db} from "@/db"
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import SlMsgs from "@/components/sl-msgs"
import {objStatusFieldsSet} from "@/utils4";
import {consoleLog} from "@/utils3";
export default {
  name: "sl-space-general-data-form",
  emits: ["finished"],
  props: ["spaceId", "name", "publicName", "description", "avatarImg"],
  // inject: ["currentUserPhoneNumber"],
  components: {
    SlMsgs,
    Cropper,
  },
  data(){
    return {
      msgs: [],
      cropperShow: false,
      avatarFile: null,
      avatarFileSrc: null,
      newAvatarImg: null,
      newName: "",
      newPublicName: "",
      newDescription: ""
    }
  },
  created() {
    this.newName = this.name
    this.newPublicName = this.publicName
    this.newDescription = this.description
    this.newAvatarImg = this.avatarImg
  },
  methods: {
    avatarCrop(){
      let that = this
      const { canvas } = this.$refs.avatarCropper.getResult()
      canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = function() {
          that.newAvatarImg = reader.result
          that.cropperShow = false
        }
      })
    },
    async save() {
      if(this.newName === ""){
        this.msgs.push({
          type: "e",
          text: "Enter name"
        })
      }
      if(this.msgs.length > 0) return

      let that = this
      db.table("spaces").where("id").equals(this.spaceId).modify(space => {
        if(!space.$data.adminIds.includes(currentUserId) && space.status !== "unpublished"){
          that.msgs.push({
            type: "e",
            text: "Only admins are able to change space data"
          })
        }
        else{
          space.data.name = that.newName
          space.$data.publicName = that.newPublicName
          space.data.description = that.newDescription
          space.data.avatarImg = that.newAvatarImg
          // space.$data.lastTS = Date.now()
          // space.$data.lastUserId = that.currentUserPhoneNumber
          // if(space.status === "updated") space.$data.version = parseInt(space.$data.version, 10) + 1
          // space.status = space.status === "unpublished" ? "unpublished" : "changed"
          objStatusFieldsSet(space)
          consoleLog({text: "sl-space-general-form.vue > save() > db.spaces...modify()",
            params: [["space", space]],
            filter: {spaceId: space.id}
          })
        }
      }).then(() => {
        if (that.msgs.length === 0) that.$emit("finished")
      })
    },
    avatarFileSelected(file){
      if(file){
        this.avatarFileSrc = URL.createObjectURL(this.avatarFile)
        this.cropperShow = true
      }
      else{
        this.avatarFileSrc = null
        this.newAvatarImg = this.avatarImg
      }
    },
  },
}
</script>

<style scoped>
.cropper {
  height: 250px;
  width: 250px;
  background: #DDD;
}
</style>