<template>
<!--  group-->
  <g v-if="line.type === 'group'">
    <rect :x="lineX" :y="barY" :width="barWidth" :height="bgHeight" class="gantt-bg" :fill="bgFillColor" :fill-opacity="level<=2?0.3:0" />
    <rect :x="lineX" :y="barY" :width="barWidth" :height="barHeight"
          class="gantt-group" :fill="barGroupFillColor"
          @click="expand()"
    />
    <rect :x="lineX" :y="barY" :width="barWidthDone" :height="barHeight"
          class="gantt-group" :fill="barGroupFillColorDone"
          @click="expand()"
    />
    <text :x="lineX + 5" :y="textY+12" class="group-label" :fill="'black'">{{ textName }}</text>
  </g>
<!--  task-->
  <g @click="taskFormOpen()" v-if="line.type === 'task'">
    <rect :x="lineX" :y="barY" :width="barWidth" :height="barHeight" class="gantt-task" :fill="barTaskFillColor" />
    <rect :x="lineX" :y="barY" :width="barWidthDone" :height="barHeight" class="gantt-task" :fill="barTaskFillColorDone" />
    <text :x="lineX + 5" :y="textY+12" class="task-label" :fill="'black'">{{ textName }}</text>
  </g>
</template>

<script>
import {daysBetweenDates} from "@/addon-gantt"

export default {
  name: "sl-addon-gantt-line",
  props: ["line", "params", "grandStartDateAdjusted", "grandEndDateAdjusted", "dayWidth", "initY", "padding", "lineHeight"],
  data: () => {
    return {
      textHeight: 20,
      barHeight: 10,
      spaceHeight: 2
    }
  },
  created(){
  },
  computed: {
    bgHeight(){
      let sublineNum = this.line.sublinesNum - 1
      let sublinesHeight = sublineNum * this.lineHeight
      let bgHeight = sublinesHeight + this.barHeight
      return bgHeight
    },
    bgFillColor(){
      let bgFillColor = ""
      if(this.line.lineData.level === 0) bgFillColor = "#faf3bd"
      if(this.line.lineData.level === 1) bgFillColor = "#e8e8e8"
      if(this.line.lineData.level === 2) bgFillColor = "#d1e1f6"
      if(this.line.lineData.level === 3) bgFillColor = "#afccaf"

      return bgFillColor
    },
    lineX(){
      if(!this.line.lineData.totalFields) return 0
      let date1 = this.grandStartDateAdjusted
      let date2 = this.line.lineData.totalFields.fields[this.params.startDateFieldId]
      let days = daysBetweenDates(date1, date2)
      return days * this.dayWidth + this.padding
    },
    barY(){
      return this.initY + this.spaceHeight + this.textHeight + this.spaceHeight  + this.padding
    },
    barWidth(){
      if(!this.line.lineData.totalFields) return 0
      let days = daysBetweenDates(this.line.lineData.totalFields.fields[this.params.startDateFieldId], this.line.lineData.totalFields.fields[this.params.endDateFieldId])
      let width = (days + 1) * this.dayWidth
      return width
    },
    barWidthDone(){
      if(!this.line.lineData.totalFields) return 0
      let width = this.barWidth * parseInt(this.line.lineData.totalFields.fields[this.params.completionFieldId]) / 100
      if(width < 10) width = 8
      return width
    },
    barGroupFillColorDone(){
      let groupFillColorDone = ""
      if(this.line.lineData.level === 0) groupFillColorDone = "#fff892"
      if(this.line.lineData.level === 1) groupFillColorDone = "#757575"
      if(this.line.lineData.level === 2) groupFillColorDone = "#92aac5" //""
      if(this.line.lineData.level === 3) groupFillColorDone = "#9aab8f"
      return groupFillColorDone
    },
    barGroupFillColor(){
      let groupFillColor = ""
      if(this.line.lineData.level === 0) groupFillColor = "lightyellow"
      if(this.line.lineData.level === 1) groupFillColor = "#eeeeee"
      if(this.line.lineData.level === 2) groupFillColor = "#eeeeee"
      if(this.line.lineData.level === 3) groupFillColor = "#eeeeee"
      return groupFillColor
    },
    barTaskFillColorDone(){
      return "#49d249"
    },
    barTaskFillColor(){
      return "#b5fcb5"
    },
    textY(){
      return this.initY + this.spaceHeight + this.padding
    },
    textName(){
      if(!this.line.lineData.totalFields) return ""
      let textName
      if (this.line.lineData.groupFieldId) {
        textName = this.line.lineData.totalFields.fields[this.line.lineData.groupFieldId]
      }
      else if(this.params.taskNameFieldId){
        textName = this.line.lineData.totalFields.fields[this.params.taskNameFieldId]
      }
      else {
        textName = ""
      }
      return textName
    },
    level(){
      return this.line.lineData.level
    }
  },
  methods: {
    expand(){
      let lineData = this.line.lineData
      if(lineData.expanded){
        lineData.userAction = "collapse"
      }
      else{
        lineData.userAction = "expand"
      }
    },
    taskFormOpen(){

    }
  }
}
</script>

<style scoped>
.gantt-bg {
  stroke: none;
  rx: 5px;
  ry: 5px;
}
.gantt-group {
  stroke: none;
  rx: 5px;
  ry: 5px;
  filter: drop-shadow(0 0 3px darkgray)
}
.gantt-task {
   stroke: none;
   rx: 5px;
   ry: 5px;
   filter: drop-shadow(0 0 3px darkgray)
 }
.group-label {
  font-size: 14px;
  text-anchor: start;
  dominant-baseline: middle;
  font-weight: bold
}
.task-label {
  font-size: 14px;
  text-anchor: start;
  dominant-baseline: middle;
  font-weight: normal;
}
</style>