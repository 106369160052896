<template>
  <q-card>
    <q-card-section class="text-center">
      Task for list
    </q-card-section>
    <q-card-section class="text-center">
      {{space.data?.name}} > {{list.data?.name}} > {{item.data?.fields["f1"]}}
    </q-card-section>
    <q-card-section>
      <table border="0">
        <tr><td style="border-bottom: 1px solid grey" colspan="4"></td></tr>
        <tr v-for="task in tasksSorted" :key="task.id" @click="taskFormOpen(task.id)">
          <td style="width: 50px; vertical-align: top; border-bottom: 1px solid grey" class="text-center q-pa-sm">
            <q-icon name="lens" :color="statusColor(task.data.status)" size="sm"/>
          </td>
          <td style="width: 300px; vertical-align: top; border-bottom: 1px solid grey" class="text-left q-pa-sm">
            {{ task.data.name }}
          </td>
          <td style="width: 100px; vertical-align: top; border-bottom: 1px solid grey" class="text-right q-pa-sm">
            <div>{{ task.data.dueDate }}</div>
            <div>{{ task.data.labour }}, {{ task.data.dueTime }}</div>
          </td>
          <td style="width: 100px; vertical-align: top; border-bottom: 1px solid grey" class="text-center q-pa-sm">
            <q-avatar size="lg">
              <img src="https://cdn.quasar.dev/img/boy-avatar.png">
            </q-avatar>
          </td>
        </tr>
      </table>
<!--      <div v-for="task in tasks" :key="task.id">-->
<!--        status: {{task.data.status}}-->
<!--        name: {{task.data.name}}-->
<!--        dueDate: {{task.data.dueDate}}-->
<!--        dueTime: {{task.data.dueTime}}-->
<!--        respUserId: {{task.data.respUserId}}-->
<!--      </div>-->
    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn @click="$emit('finished')" label="cancel"/>
      <q-btn @click="taskFormOpen()" label="create"/>
    </q-card-actions>
    <q-dialog v-model="taskFormShow">
      <sl-task-form :task-id="taskFormTaskId" :space-id="spaceId" :list-id="listId" :item-id="itemId" @finished="taskFormShow=false"/>
    </q-dialog>
  </q-card>
</template>

<script>
import {currentUserId, db} from "@/db";
// import {useObservable} from "@vueuse/rxjs/index";
// import {liveQuery} from "dexie";
import {obj, objStatusFieldsSet} from "@/utils4";
import {generateId} from "@/utils";
import SlTaskForm from "@/components/sl-task-form";
import {useObservable} from "@vueuse/rxjs/index";
import {liveQuery} from "dexie";

export default {
  name: "sl-tasks-of-obj",
  components: {SlTaskForm},
  props: ["spaceId", "listId", "itemId"],
  data: () => {
    return {
      taskFormShow: false,
      taskFormTaskId: "",
      taskFormTask: {
        name: "",
        status: "",
        dueTS: "",
        respUserId: ""
      },
      tasks: [],
      space: {},
      list: {},
      item: {}
    }
  },
  // setup(props){
  //   return{
  //     db,
  //     tasks: useObservable(
  //       liveQuery(() => db.table("tasks").where("spaceId").equals(props.spaceId)
  //         .and(task => task.data.listId === props.listId && task.data.itemId === props.itemId).toArray())
  //     )
  //   }
  // },
  created() {
    this.tasks = useObservable(
      liveQuery(() => db.table("tasks").where("spaceId").equals(this.spaceId)
        .and(task => task.data.listId === this.listId && task.data.itemId === this.itemId).toArray())
    )
    db.table("spaces").get(this.spaceId).then(space => this.space = space)
    db.table("lists").get(this.listId).then(list => this.list = list)
    db.table("items").get(this.itemId).then(item => this.item = item)
  },
  computed: {
    tasksSorted(){
      if(!this.tasks) return []
      let tasksSorted = this.tasks
      tasksSorted.sort((a, b) => {
        return a.data.dueDate.localeCompare(b.data.dueDate) || a.data.dueTime.localeCompare(b.data.dueTime)
      })
      return tasksSorted
    }
  },
  methods: {
    taskFormOpen(taskId){
      if(taskId) {
        this.taskFormTaskId = taskId
      }
      else{
        this.taskFormTaskId = ""
      }
      this.taskFormShow = true
    },
    taskFormSave(){
      let task = obj({}, "task")
      if(this.taskFormTaskId === ""){
        task.id = generateId()
        task.spaceId = this.spaceId
        task.data.listId = this.listId ? this.listId : ""
        task.data.itemId = this.itemId ? this.itemId : ""
      }
      task.data.name = this.taskFormTask.name
      task.data.respUserId = currentUserId
      task.data.status = "new"
      task.data.dueDate = this.currentDate()
      task.data.dueTime = this.currentTime()

      objStatusFieldsSet(task)
      db.table("tasks").put(task).then(() => {
        this.taskFormShow = false
      })
    },
    currentDate(){
      let currentDate = new Date()
      let curY = currentDate.getFullYear()
      let curM = currentDate.getMonth()+1
      let curD = currentDate.getDate()
      if(curM<10) curM = "0"+curM
      if(curD<10) curD = "0"+curD
      return curY + "/" + curM + "/" + curD
    },
    currentTime(){
      let currentDate = new Date()
      let curH = currentDate.getHours()
      let curM = currentDate.getMinutes()
      if(curH<10) curH = "0"+curH
      if(curM<10) curM = "0"+curM
      return curH + ":" + curM
    },
    statusColor(status){
      let statusColor = ""
      if(status === "new") statusColor = "grey"
      if(status === "processing") statusColor = "yellow"
      if(status === "done") statusColor = "green"
      if(status === "postponed") statusColor = "brown"
      return statusColor
    }

  }
}
</script>

<style scoped>

</style>