<template>
  <q-menu
      touch-position
      context-menu
  >
    <q-list dense style="min-width: 100px">
      <q-item clickable v-close-popup @click="$emit('contextmenuSelected', 'cellProperties')" @mouseover="showSubmenu('')">
        <q-item-section>Cell properties</q-item-section>
      </q-item>
      <q-item clickable @mouseover="showSubmenu('row')">
        <q-item-section>Row</q-item-section>
        <q-item-section side>
          <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <q-menu v-model="rowSubmenuShow" anchor="top end" self="top start">
          <q-list dense style="min-width: 100px">
            <q-item clickable v-close-popup @click="$emit('contextmenuSelected', 'rowCopy')">
              <q-item-section>Copy</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('contextmenuSelected', 'rowDelete')">
              <q-item-section>Delete</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-item>
      <q-item clickable @mouseover="showSubmenu('drilldown')">
        <q-item-section>Drilldown</q-item-section>
        <q-item-section side>
          <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <q-menu v-model="drilldownSubmenuShow" anchor="top end" self="top start">
          <q-list dense style="min-width: 100px">
            <q-item clickable v-close-popup
                    v-for="(drilldownList, i) in this.list.data.drilldownLists" :key="i"
                    @click="$emit('contextmenuSelected', {menu: 'drilldownList', index: i})"
            >
              <q-item-section>{{drilldownList.name}}</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('contextmenuSelected', 'drilldownSettings')">
              <q-item-section><q-icon name="settings"/></q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-item>
    </q-list>
  </q-menu>

</template>

<script>
export default {
  name: "sl-list-cell-context-menu",
  props: ["list"],
  data: () => {
    return {
      rowSubmenuShow: false,
      drilldownSubmenuShow: false,
      // drilldownLists: []
    }
  },
  created() {
    // if(this.list.data.drilldownList){
    //
    // }
  },
  methods: {
    showSubmenu(currentMenu){
      currentMenu === "row" ? this.rowSubmenuShow=true : this.rowSubmenuShow=false
      currentMenu === "drilldown" ? this.drilldownSubmenuShow=true : this.drilldownSubmenuShow=false
    }
  }
}
</script>

<style scoped>

</style>