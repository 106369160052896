<template>
  <v-card class="_d-flex justify-center pa-2 _bg-grey-lighten-4" elevation="7" style="border-radius: 10px">
    <table class="bg-white" style="border-radius: 5px" >
      <thead style="text-align: center">
        <td style="background-color: lightgreen; border-radius: 5px;">№</td>
        <td :style="{ fontWeight: 'normal', color: '_grey', width: field.widthDefault+'px', backgroundColor: 'lightgreen', padding: '3px', borderRadius: '5px'}"
            class="px-3"
            v-for="(field, i) in fields" :key="i"
            @click="dialogFieldOpen({i: i, field: field})"
        >
          {{field.name}}
        </td>
        <td>
          <v-icon style="cursor: pointer" class="text-green-lighten-1" @click="dialogFieldOpen()">mdi-plus-circle-outline</v-icon>
        </td>
      </thead>
      <tr v-for="(item, j) in items" :key="j">
        <td style="text-align: right; border-radius: 5px" class="bg-grey-lighten-2 px-1">
          {{j}}
        </td>
        <td v-for="field in fields" :key="field.id" class="bg-grey-lighten-5 pa-2" :style="{borderRadius: '5px', lineHeight: '1.1', whiteSpace: 'pre-line'}"
            @click="dialogItemFieldOpen({itemId: item.id, fieldId: field.id, value: item.fields[field.id]})"
        >
          <span :style="field.key?'color: maroon':''">{{item.fields[field.id]}}</span>
        </td>
        <td>
          <v-icon style="cursor: pointer" class="text-grey-lighten-3" @click="listItemDelete(item.id)">mdi-minus-circle-outline</v-icon>
        </td>
      </tr>
      <tr>
        <td style="_background-color: lightgray">
          <v-icon color="grey" style="cursor: pointer" @click="itemAdd">mdi-plus-circle-outline</v-icon>
        </td>
        <td class="bg-yellow-lighten-5" v-for="(field, i) in fields" :key="i" @click="itemAddAndEdit(field.id)"></td>
        <td>
        </td>
      </tr>
    </table>
  </v-card>
  <v-dialog v-model="dialogItemFieldShow">
    <v-card class="pa-2" min-width="250" max-width="550">
      <v-card-title class="justify-center">Field value</v-card-title>
      <v-textarea v-model="itemField.value" :autofocus="true" label="Field value"></v-textarea>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="dialogItemFieldShow=false">Cancel</v-btn>
        <v-btn @click="itemFieldSave()">Save</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogFieldShow">
    <v-card class="pa-2" min-width="250" max-width="550">
      <v-card-title class="justify-center">Field</v-card-title>
      <v-text-field v-model="field.name" :autofocus="true" label="Field name"></v-text-field>
      <span style="text-decoration: underline; color: blue; cursor: pointer" @click="dialogFieldDetailsShow=!dialogFieldDetailsShow">details</span>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="dialogFieldShow=false">Cancel</v-btn>
        <v-btn @click="fieldSave()">Save</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogFieldDetailsShow">
    <v-card class="pa-2" min-width="250" max-width="550">
      <v-card-title class="justify-center">Field details</v-card-title>
      <v-checkbox v-model="field.key" label="Key field" color="green" density="compact"/>
      <v-select v-model="field.type" :items="['text', 'num', 'date', 'time', 'sublist']" density="compact" label="Type"/>
      <v-text-field v-model="field.length" density="compact" label="Length"/>
      <v-text-field v-model="field.formula" density="compact" label="Formula"/>
      <v-text-field v-model="field.widthDefault" density="compact" label="Default width"/>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="dialogFieldDetailsShow=false">Ok</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {db} from "@/db"
import {useObservable} from "@vueuse/rxjs"
import {liveQuery} from "dexie"
import { toRaw } from 'vue'

export default {
  name: "sl-list",
  props: ["listId"],
  data: function() {
    return {
      detailsShow: false,
      dialogFieldShow: false,
      dialogItemFieldShow: false,
      dialogFieldDetailsShow: false,
      fieldIndex: null,
      field: {
        id: "",
        name: "",
        key: false,
        type: "text",
        length: 0,
        formula: "",
        widthDefault: 0
      },
      itemField: {
        itemId: "",
        fieldId: "",
        value: ""
      },
      //list: "",
      //items: ""
    }
  },
  // async created() {
  //   let list = await db.lists.get(this.listId)
  //   this.fields = list.fields
  // },
  setup(props) {

    //const listId = toRef(props, 'listId')
    //const
    // eslint-disable-next-line no-debugger
    // debugger
    return {
      db,
      list: useObservable(
          // liveQuery(() => db.lists.get(props.listId).fields)
          liveQuery(() => db.lists.where("id").equals(props.listId).toArray())
      ),
      items: useObservable(
          // liveQuery(() => db.lists.get(props.listId).fields)
          liveQuery(() => db.items.where("listId").equals(props.listId).toArray())
      )
    };
  },
  // mounted() {
  //   this.list = useObservable(
  //       liveQuery(() => db.lists.where("id").equals(this.listId).toArray())
  //   )
  //   this.items = useObservable(
  //       liveQuery(() => db.items.where("listId").equals(this.listId).toArray())
  //   )
  // },
  computed: {
    fields(){
      let fields = []
      let l = this.list
      if ( l !== "" && l !== undefined) {
        //let l = toRaw(this.list)
        fields = l[0].fields
      }
      return fields
    },
    // list() {
    //   return useObservable(
    //       liveQuery(() => db.lists.where("id").equals(this.listId).toArray())
    //   )
    // },
    // items(){
    //   return useObservable(
    //       liveQuery(() => db.items.where("listId").equals(this.listId).toArray())
    //   )
    // }
  },
  // computed: {
  //   fields(){
  //     return useObservable(liveQuery(() => db.lists.get(this.listId).fields))
  //   }
  // },
  methods: {
    dialogFieldOpen(field){
      // eslint-disable-next-line no-debugger
      //debugger
      if(field === undefined){
        this.fieldSet()
        this.fieldIndex = null
      }
      else{
        this.fieldSet(toRaw(field.field))
        this.fieldIndex = field.i
      }
      this.dialogFieldShow = true
    },
    async fieldSave(){
      // let fields = []
      // for(let i = 0; i < this.fields.length; i++){
      //   fields.push(this.fields[i])
      // }
      // let field = {
      //   name: this.fieldName,
      //   key: this.fieldKey,
      //   type: this.fieldType,
      //   length: this.fieldLength,
      //   formula: this.fieldFormula,
      //   index: this.fieldIndex,
      //   widthDefault: this.fieldWidthDefault
      // }

      // if(this.fieldIndex === null){
      //   fields.push(field)
      // }
      // else{
      //   fields[this.fieldIndex] = field
      // }
      try {
        let that = this
        if(this.fieldIndex === null){
          //await db.lists.update(this.listId, {fields: fields})
          await db.lists.where('id').equals(this.listId).modify(function(list){list.fields.push(toRaw(that.field))})
        }
        else{
          await db.lists.where('id').equals(this.listId).modify(list => list.fields[that.fieldIndex] = toRaw(that.field))
        }
        this.fieldSet()
        this.fieldIndex = null
        this.dialogFieldShow = false
      } catch (error) {
        console.log(error)
      }
    },
    fieldSet(field){
      if(field === undefined){
        this.field.id = "f"+this.fields.length
        this.field.name = ""
        this.field.key = false
        this.field.type = "text"
        this.field.length = 0
        this.field.formula = ""
        this.field.widthDefault = ""
      }
      else{
        this.field.id = field.id
        this.field.name = field.name
        this.field.key = field.key
        this.field.type = field.type
        this.field.length = field.length
        this.field.formula = field.formula
        this.field.widthDefault = field.widthDefault
      }
    },
    async itemAdd(){
      const itemId = Date.now()
      try {
        await db.items.add({
          id: itemId,
          listId: this.listId,
          fields: {}
        })
      } catch (error) {
        console.log(error)
      }
      this.itemField.itemId = itemId // required if user creates row by clicking on field (not on plus button)
    },
    dialogItemFieldOpen(pl){
      this.itemField.itemId = pl.itemId
      this.itemField.fieldId = pl.fieldId
      this.itemField.value = pl.value
      this.dialogItemFieldShow = true
    },
    async itemFieldSave(){
      try {
        let that = this
        await db.items.where('id').equals(this.itemField.itemId).modify(listItem => listItem.fields[that.itemField.fieldId] = toRaw(that.itemField.value))
        this.itemField.itemId = ""
        this.itemField.fieldId = ""
        this.itemField.value = ""
        this.dialogItemFieldShow = false
      } catch (error) {
        console.log(error)
      }
    },
    itemAddAndEdit(fieldId){
      //const itemId = toRaw(this.itemAdd())
      // eslint-disable-next-line no-debugger
      //debugger
      this.itemAdd()
      const pl = {
        itemId: this.itemField.itemId,
        fieldId: fieldId,
        value: ""
      }
      this.dialogItemFieldOpen(pl)
    },
    async listItemDelete(itemId){
      try {
        await db.items.delete(itemId)
      } catch (error) {
        console.log(error)
      }
    }

  }
}
</script>

<style scoped>

</style>