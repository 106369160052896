<template>
<q-card align="center" flat>
  <q-card-section v-if="$q.platform.is.mobile">
    <sl-drawer-spaces :currentUserId="$store.state.currentUser.userId"/>
    <sl-spaces2/>
  </q-card-section>
  <q-card-section v-else>
    This is overview page
  </q-card-section>
</q-card>
</template>

<script>
import SlDrawerSpaces from "@/components/sl-drawer-spaces"
import SlSpaces2 from "@/components/sl-spaces2";
export default {
  name: "OverView",
  components: {SlSpaces2, SlDrawerSpaces},
  // inject: ["currentUserPhoneNumber"]
}
</script>

<style scoped>

</style>