<template>
  <q-icon name="circle_outlined" color="grey" v-if="item.id===''"/>
  <q-icon name="circle" color="green" v-else-if="item.status === 'updated'"/>
  <q-icon name="circle" color="blue-lighten-3" v-else-if="item.status === 'changing'"/>
  <q-icon name="circle" color="yellow" v-else-if="item.status === 'changed'"/>
  <q-icon name="circle" color="grey" v-else/>
</template>

<script>
export default {
  name: "sl-list-item-status",
  props: ["item"]
}
</script>

<style scoped>

</style>