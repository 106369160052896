<template>
  <q-card flat>
    <sl-list5 v-for="openedList in this.$store.state.openedLists.lists" :key="'list_'+openedList.key"
              :spaceId="openedList.spaceId" :listId="openedList.listId" :viewId="openedList.viewId"
              :drilldownFilter="openedList.drilldownFilter"
              v-show="openedList.key===$store.state.openedLists.currentKey"
    />

    <q-card-actions>
      <template v-for="(openedList, i) in this.$store.state.openedLists.lists" :key="'chip_'+openedList.key">
        <q-chip clickable @click="selectOpenedList(openedList.spaceId, openedList.listId, openedList.viewId)"
                :class="{
                  'bg-green': openedList.key===$store.state.openedLists.currentKey,
                  'text-white': openedList.key===$store.state.openedLists.currentKey
                }"
        >
          {{openedList.spaceName}} > {{openedList.listName}} > {{openedList.viewName}}
          <span class="q-mx-xs"></span>
          <q-icon name="cancel" size="sm" style="margin-right: -10px" @click="listRemove(openedList.spaceId, openedList.listId, openedList.viewId)"/>
        </q-chip>
        <span class="q-mx-md" v-if="i!==$store.state.openedLists.lists.length-1">|</span>
      </template>
    </q-card-actions>

  </q-card>
</template>

<script>
import {useQuasar} from "quasar";
import {useRoute, useRouter} from "vue-router/dist/vue-router";
import {db} from "@/db";
import SlList5 from "@/components/sl-list5"

export default {
  name: "List5View",
  components: {SlList5},
  data: () => {
    return {
      space: {},
      currentList: {
        key: "",
        spaceId: "",
        listId: "",
        viewId: ""
      }
    }
  },
  setup(){
    const $q = useQuasar()
    const route = useRoute()
    const router = useRouter()
    let spaceId = route.params.spaceId
    let listId = route.params.listId
    let viewId = route.params.viewId

    if(viewId === "" || viewId === undefined){
      db.lists.get(listId).then((list) => {
        let defaultViewId = ""
        if($q.platform.is.mobile){
          defaultViewId = list.data.defaultAdminPhoneViewId
        }
        else{
          defaultViewId = list.data.defaultAdminViewId
        }
        router.push({ name: 'list5', params: { spaceId: spaceId, listId: listId, viewId: defaultViewId } })

      })
      return
    }
    return {
      spaceId,
      listId,
      viewId
    }
  },
  async created(){
    // this.currentList.spaceId = this.spaceId
    // this.currentList.listId = this.listId
    // this.currentList.viewId = this.viewId
    let currentKey = this.spaceId+"_"+this.listId+"_"+this.viewId
    this.$store.commit("openedListCurrentKeySet", currentKey)

    let indx = this.$store.state.openedLists.lists.findIndex(el => {
      return el.spaceId === this.spaceId &&
        el.listId === this.listId &&
        el.viewId === this.viewId
    })
    if(indx === -1){
      let space = await db.table("spaces").get(this.spaceId)
      let list = await db.table("lists").get(this.listId)
      let view = list.data.views[this.viewId]
      this.$store.commit("openedListAdd", {
        key: this.spaceId+"_"+this.listId+"_"+this.viewId,
        spaceId: this.spaceId,
        spaceName: space.data.name,
        listId: this.listId,
        listName: list.data.name,
        viewId: this.viewId,
        viewName: view.data.name,
      })
    }
  },
  methods: {
    selectOpenedList(spaceId, listId, viewId){
      this.$store.commit("openedListCurrentKeySet", spaceId+"_"+listId+"_"+viewId)
      // this.currentList.spaceId = spaceId
      // this.currentList.listId = listId
      // this.currentList.viewId = viewId
      // this.$router.push({ name: 'list5', params: { spaceId: spaceId, listId: listId, viewId: viewId } })

      window.history.pushState(
        {},
        null,
        "/list5/"+spaceId+"/"+listId+"/"+viewId
      )
    },
    listRemove(spaceId, listId, viewId){
      let indx = this.$store.state.openedLists.lists.findIndex(el => el.spaceId === spaceId && el.listId === listId && el.viewId === viewId)
      this.$store.commit("openedListRemove", indx)
      let lists5 = this.$store.state.lists
      for(let list5Id in lists5){
        if(lists5[list5Id].space.id === spaceId && lists5[list5Id].listId === listId && lists5[list5Id].viewId === viewId){
          this.$store.commit("listsList5Clear", list5Id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>