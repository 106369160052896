<template>
  <q-list class="bg-white text-left">
<!--    space properties-->
<!--    <q-item dense>-->
<!--      <q-item-section side @click="goToSpaceProps()">-->
<!--        <q-icon name="settings" color="grey-4" size="xs" class="q-ml-xs"/>-->
<!--      </q-item-section>-->
<!--      <q-item-section @click="goToSpaceProps()">-->
<!--        space properties-->
<!--      </q-item-section>-->
<!--      <q-item-section side>-->
<!--        <q-icon name="more_vert" color="grey-6" size="xs" @click="goToSpaceProps()"/>-->
<!--      </q-item-section>-->
<!--    </q-item>-->
<!--    spaces-->
    <template  v-for="(folder, i) in folders" :key="i">
<!--      folder-->
      <q-item dense v-if="i!==0"
              :id="'folder_'+i"
              draggable="true"
              @dragstart="dragStart($event)"
              @dragover="$event.preventDefault()"
              @drop="dragDrop($event)"
              @mouseover="folder.isMenuVisible=true"
              @mouseout="folder.isMenuVisible=false"
      >
        <q-item-section side @click="goToList()">
          <q-btn flat round size="xs" _class="q-ml-xs"
                 :icon="folder.isOpened ? 'arrow_drop_down' : 'arrow_right'"
                 @click="folderToggle(i)"
          />
        </q-item-section>
        <q-item-section @click="folderToggle(i)" style="line-height: 1.2; padding: 4px; cursor: pointer;">
          <div>
            <span style="color: green; font-weight: bold">{{folder.name}}</span> <span style="color: grey">({{folder.listIds.length}})</span>
          </div>
        </q-item-section>
        <q-item-section side >
          <div>
            <q-icon name="more_vert" color="grey-6" size="xs"
                    style="cursor: pointer"
                    @click="folderFormOpen(i)"
                    v-show="folder.isMenuVisible"
            />
          </div>
        </q-item-section>
      </q-item>
<!--      lists-->
      <template v-if="lists && folder.isOpened">
        <template v-for="listId in folder.listIds" :key="listId">
          <q-item dense v-if="lists.findIndex(el=>el.id===listId)>=0"
                  style="cursor: pointer"
                  :id="'list_'+listId+'_'+i"
                  draggable="true"
                  @dragstart="dragStart($event)"
                  @dragover="$event.preventDefault()"
                  @drop="dragDrop($event)"
          >
            <q-item-section side @click="goToList(listId)">
              <q-icon name="table_rows" color="grey-4" size="xs" class="q-ml-xs"/>
            </q-item-section>
            <q-item-section @click="goToList(listId)" style="line-height: 1.2; margin: 4px">
              {{lists.find(el=>el.id===listId).data.name}}
            </q-item-section>
            <q-item-section side @click="goToList5(listId)" style="line-height: 1.2; margin: 4px">
              (L5)
            </q-item-section>
<!--            <q-item-section side>-->
<!--              <q-icon name="more_vert" color="grey-6" size="xs" @click="listFormOpen(list)"/>-->
<!--            </q-item-section>-->
          </q-item>
        </template>
      </template>
    </template>



    <div style="height: 25px"></div>
  </q-list>
  <div class="bg-white row justify-end" style="padding: 0px 14px 10px 5px;">
    <q-btn round icon="groups" size="sm" unelevated  color="grey-5" text-color="white" class="q-mr-sm" @click="$router.push({ name: 'zusers', params: { spaceId: spaceId } })"/>
    <q-btn round icon="create_new_folder" size="sm" unelevated  color="grey-5" text-color="white" class="q-mr-sm" @click="folderFormOpen()"/>
    <q-btn round icon="bookmark_border" size="sm" unelevated  color="grey-5" text-color="white" class="q-mr-sm" @click="goToSpaceProps()"/>
    <q-btn round icon="settings" size="sm" unelevated  color="grey-6" text-color="white" class="q-mr-sm" @click="goToSpaceProps()"/>
    <q-btn round icon="add" size="sm" unelevated color="orange-6" text-color="white" @click="listFormOpen()"></q-btn>
  </div>

  <q-dialog v-model="listFormShow" _style="min-width: 250px" >
    <q-card class="q-pa-md">
      <q-input v-model="listName" label="list name" dense/>
      <q-card-actions>
        <q-btn @click="listDelete()" v-if="listId!=0">Delete</q-btn>
        <q-btn @click="listFormShow=!listFormShow">Cancel</q-btn>
        <q-btn @click="listSave()">Save</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="folderFormShow">
    <q-card class="q-pa-md">
      <q-input v-model="folderName" label="folder name" dense/>
      <q-card-actions>
        <q-btn @click="folderDel()">Delete</q-btn>
        <q-btn @click="folderFormShow=false">Cancel</q-btn>
        <q-btn @click="folderSave()">Save</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <sl-msgs :msgs="msgs" v-if="msgs.length>0" @finished="msgs=[]"/>
</template>

<script>
import {currentUserId, db} from "@/db";
import {useObservable} from "@vueuse/rxjs/index";
import {liveQuery} from "dexie";
import {generateId} from "@/utils";
import {listCreate, objStatusFieldsSet} from "@/utils4";
import {toRaw} from "vue";
import SlMsgs from "@/components/sl-msgs"

export default {
  name: "sl-drawer-lists",
  components: [SlMsgs],
  props: ["spaceId", "space"],
  // inject: ["currentUserPhoneNumber"],
  data: () => {
    return {
      msgs: [],
      listId: 0,
      listName: "",
      listFolderIndex: null,
      listFormShow: false,
      folderIndex: null,
      folderName: "",
      folderFormShow: false,
      dragInitialEl: {},
      folders: [],
      currentUserId: currentUserId
    }
  },
  setup(props){
    return{
      db,
      lists: useObservable(
          liveQuery(() => db.lists.where("spaceId").equals(props.spaceId).toArray())
      )
    }
  },
  created() {
    let foldersOpened = localStorage.getItem("foldersOpened")
    if(foldersOpened) {
      foldersOpened = JSON.parse(foldersOpened)
    }
    else{
      foldersOpened = {}
    }
    if(!foldersOpened[this.space.id]) foldersOpened[this.space.id] = []
    localStorage.setItem("foldersOpened", JSON.stringify(foldersOpened))

    this.foldersMake()
  },
  watch: {
    space(){
      this.foldersMake()
    }
  },
  methods: {
    folderToggle(i){
      this.folders[i].isOpened = !this.folders[i].isOpened
      let foldersOpened = JSON.parse(localStorage.getItem("foldersOpened"))
      if(this.folders[i].isOpened) {
        let index = foldersOpened[this.space.id].findIndex(el=>el===i)
        if(index === -1) foldersOpened[this.space.id].push(i)
      }
      else {
        let index = foldersOpened[this.space.id].findIndex(el=>el===i)
        if(index >= 0) foldersOpened[this.space.id].splice(index, 1)
      }
      localStorage.setItem("foldersOpened", JSON.stringify(foldersOpened))
    },
    foldersMake(){
      if(!this.space.data.folders) return // from "create" foldersMake is called twice. In second call "this.space.data.folders" is undefined
      this.folders = JSON.parse(JSON.stringify(this.space.data.folders))
      let foldersOpened = JSON.parse(localStorage.getItem("foldersOpened"))
      foldersOpened = foldersOpened[this.space.id] ? foldersOpened[this.space.id] : []
      this.folders.forEach((folder, i) => {
        if(i === 0) {
          folder.isOpened = true
          folder.isMenuVisible = false
        }
        else {
          folder.isOpened = foldersOpened.findIndex(el=>el===i) >= 0 ? true : false
          folder.isMenuVisible = false
        }
      })
    },
    folderFormOpen(index){
      this.folderIndex = index ? index : null
      this.folderName = index ? this.space.data.folders[index].name : ""
      this.folderFormShow = true
    },
    folderSave(){
      db.spaces.where("id").equals(this.space.id).modify(space => {
        if(!space.data.folders) {
          space.data.folders = []
          space.data.folders.push({name: "root", listIds: []})
        }
        if(this.folderIndex === null) space.data.folders.push({name: this.folderName, listIds: []})
        else space.data.folders[this.folderIndex].name = this.folderName
        this.folderFormShow = false
      })
    },
    folderDel(){
      db.spaces.where("id").equals(this.space.id).modify(space => {
        debugger
        if(this.folderIndex !== null){
          if(space.data.folders[this.folderIndex].listIds.length > 0){
            this.msgs.push({type: "e", text: "Folder is not empty."})
          }
          else{
            space.data.folders.splice(this.folderIndex, 1)
          }
        }
        this.folderFormShow = false
      })
    },
    listFormOpen(list){
      if(list === undefined) {
        this.listId = 0
        this.listName = ""
      }
      else{
        this.listId = list.id
        this.listName = list.name
      }
      this.listFormShow = true
    },
    async listSave(){
      let that = this
      let pl = {
        currentUserId: that.currentUserId,
        spaceId: that.spaceId,
        listId: generateId(),
        // viewId: generateId(),
        listName: that.listName
      }
      listCreate(pl).then(()=>{
        debugger
        db.spaces.where("id").equals(this.space.id).modify(space => {
          objStatusFieldsSet(space)
          space.data.folders[0].listIds.push(pl.listId)
        })
        that.listFormShow = false
        // that.$router.push({ name: 'list', params: { spaceId: pl.spaceId, listId: pl.listId, viewId: pl.viewId } }) // viewId is being created inside listCreate, then viewId is being selected according to device - phone or desktop
        that.$router.push({ name: 'list', params: { spaceId: pl.spaceId, listId: pl.listId } })
      })
    },
    async listDelete(){
      let result = confirm("Do you really want to delete?")
      if (!result) return
      try {
        await db.lists.delete(this.listId)
        this.listFormShow = false
      } catch (error) {
        console.log(error)
      }
    },
    goToList(listId){
      if(listId){
        this.$router.push({ name: 'list', params: { spaceId: this.spaceId, listId: listId } })
      }
    },
    async goToList5(listId){
      if(this.$route.name !== "list5"){
        if(listId){
          this.$router.push({ name: 'list5', params: { spaceId: this.spaceId, listId: listId } })
        }
      }
      else{
        let list = await db.table("lists").get(listId)
        let currentKey = this.spaceId+"_"+listId+"_"+list.data.defaultAdminViewId
        this.$store.commit("openedListCurrentKeySet", currentKey)

        let indx = this.$store.state.openedLists.lists.findIndex(el => {
          return el.spaceId === this.spaceId &&
            el.listId === listId &&
            el.viewId === list.data.defaultAdminViewId
        })
        if(indx === -1){
          let space = await db.table("spaces").get(this.spaceId)
          let view = list.data.views[list.data.defaultAdminViewId]
          this.$store.commit("openedListAdd", {
            key: this.spaceId+"_"+listId+"_"+list.data.defaultAdminViewId,
            spaceId: this.spaceId,
            spaceName: space.data.name,
            listId: listId,
            listName: list.data.name,
            viewId: list.data.defaultAdminViewId,
            viewName: view.data.name,
          })
        }
        window.history.pushState(
          {},
          null,
          "/list5/"+this.spaceId+"/"+listId+"/"+list.data.defaultAdminViewId
        )
      }

    },
    goToSpaceProps(){
      this.$router.push({ name: 'space', params: { spaceId: this.spaceId } })
    },
    dragElGet(id){
      let idArr = id.split("_")
      let dragEl = {}
      dragEl.type = idArr[0] ? idArr[0] : ""
      if(dragEl.type === "list"){
        dragEl.listId = idArr[1] ? idArr[1] : ""
        dragEl.folderIndex = idArr[2] ? idArr[2] : ""
        dragEl.listIndex = this.space.data.folders[dragEl.folderIndex].listIds.findIndex(el=>el===dragEl.listId)
      }
      if(dragEl.type === "folder"){
        dragEl.folderIndex = idArr[1] ? idArr[1] : ""
      }
      return dragEl
    },
    dragStart(e){
      this.dragInitialEl = this.dragElGet(e.currentTarget.id)
    },
    dragDrop(e){
      let dragInitialEl = toRaw(this.dragInitialEl)
      let dragTargetEl = this.dragElGet(e.currentTarget.id)

      if(dragInitialEl.type === "list" && dragTargetEl.type === "folder"){
        db.spaces.where("id").equals(this.space.id).modify(space => {
          space.data.folders[dragInitialEl.folderIndex].listIds.splice(dragInitialEl.listIndex, 1)
          space.data.folders[dragTargetEl.folderIndex].listIds.unshift(dragInitialEl.listId)
          objStatusFieldsSet(space)
        })
      }
      if(dragInitialEl.type === "list" && dragTargetEl.type === "list"){
        db.spaces.where("id").equals(this.space.id).modify(space => {
          space.data.folders[dragInitialEl.folderIndex].listIds.splice(dragInitialEl.listIndex, 1)
          space.data.folders[dragTargetEl.folderIndex].listIds.splice(dragTargetEl.listIndex, 0, dragInitialEl.listId)
          objStatusFieldsSet(space)
        })
      }
      if(dragInitialEl.type === "folder" && dragTargetEl.type === "folder"){
        db.spaces.where("id").equals(this.space.id).modify(space => {
          let folderToMove = space.data.folders.splice(dragInitialEl.folderIndex, 1)
          space.data.folders.splice(dragTargetEl.folderIndex, 0, folderToMove[0])
          objStatusFieldsSet(space)
        })
      }
    }
  }
}
</script>

<style scoped>

</style>