/* eslint-disable */
import {generateId} from "@/utils"
import {currentUserId, db} from "@/db"
import {formulaCalculate, numFormat} from "@/utils3"
import {toRaw} from "vue"
import {formulaRun} from "@/db-sync";


export const obj = (object, type) => {
  // SPACE
  if (type === "space") {
    !object.hasOwnProperty('id') ? object.id = generateId() : "" //- id must be defined before this
    !object.hasOwnProperty('cryptoKey') ? object.cryptoKey = "" : ""
    !object.hasOwnProperty('status') ? object.status = "unpublished" : ""
    !object.hasOwnProperty('$data') ? object.$data = {} : ""
    !object.hasOwnProperty('data') ? object.data = {} : ""
    // subObjects
    obj(object.$data, "space$data")
    obj(object.data, "spaceData")
  }
  if (type === "space$data") {
    !object.hasOwnProperty('userIds') ? object.userIds = [] : ""
    !object.hasOwnProperty('adminIds') ? object.adminIds = [] : ""
    !object.hasOwnProperty('usersAuthsLists') ? object.usersAuthsLists = {} : ""
    !object.hasOwnProperty('usersAuthsItems') ? object.usersAuthsItems = {} : ""
    !object.hasOwnProperty('lastTS') ? object.lastTS = "" : ""
    !object.hasOwnProperty('lastUserId') ? object.lastUserId = "" : ""
    !object.hasOwnProperty('version') ? object.version = 0 : ""
    !object.hasOwnProperty('isEncrypted') ? object.isEncrypted = "" : ""
    !object.hasOwnProperty('encryptedData') ? object.encryptedData = "" : ""
    !object.hasOwnProperty('publicName') ? object.publicName = "" : ""
    !object.hasOwnProperty('rawData') ? object.rawData = "" : ""
  }
  if (type === "spaceData") {
    !object.hasOwnProperty('name') ? object.name = "" : ""
    !object.hasOwnProperty('avatarFileId') ? object.avatarFileId = "" : ""
    !object.hasOwnProperty('zListIds') ? object.zListIds = {} : ""
    !object.hasOwnProperty('folders') ? object.folders = [{name: 'root', listIds: []}] : ""
    !object.hasOwnProperty('uploadDataDelay') ? object.uploadDataDelay = "25" : ""
    // subObjects
    obj(object.zListIds, "spaceDataZListIds")
  }
  if (type === "spaceDataZListIds") {
    !object.hasOwnProperty('usersListId') ? object.usersListId = "" : ""
    !object.hasOwnProperty('authsListId') ? object.authsListId = "" : ""
    !object.hasOwnProperty('authGroupsListId') ? object.authGroupsListId = "" : ""
    !object.hasOwnProperty('tablesListId') ? object.tablesListId = "" : ""
  }

  // ITEM
  if (type === "item") {
    !object.hasOwnProperty('id') ? object.id = generateId() : ""
    !object.hasOwnProperty('status') ? object.status = "created" : ""
    !object.hasOwnProperty('spaceId') ? object.spaceId = "" : ""
    !object.hasOwnProperty('$data') ? object.$data = {} : ""
    !object.hasOwnProperty('data') ? object.data = {} : ""
    // subObjects
    obj(object.$data, "item$data")
    obj(object.data, "itemData")
  }
  if (type === "item$data") {
    !object.hasOwnProperty('lastTS') ? object.lastTS = "" : ""
    !object.hasOwnProperty('lastUserId') ? object.lastUserId = "" : ""
    !object.hasOwnProperty('version') ? object.version = 0 : ""
  }
  if (type === "itemData") {
    !object.hasOwnProperty('listId') ? object.listId = "" : ""
    !object.hasOwnProperty('fixedSelCriteria') ? object.fixedSelCriteria = [] : ""
    !object.hasOwnProperty('fields') ? object.fields = {} : ""
    !object.hasOwnProperty('fieldsS') ? object.fieldsS = {} : ""
    !object.hasOwnProperty('fieldsF') ? object.fieldsF = {} : ""
  }

  // TASK
  if (type === "task") {
    !object.hasOwnProperty('id') ? object.id = generateId() : ""
    !object.hasOwnProperty('spaceId') ? object.spaceId = "" : ""
    !object.hasOwnProperty('status') ? object.status = "" : ""
    !object.hasOwnProperty('$data') ? object.$data = {} : ""
    !object.hasOwnProperty('data') ? object.data = {} : ""
    // subObjects
    obj(object.$data, "task$data")
    obj(object.data, "taskData")
  }
  if (type === "task$data") {
    !object.hasOwnProperty('lastTS') ? object.lastTS = "" : ""
    !object.hasOwnProperty('lastUserId') ? object.lastUserId = "" : ""
    !object.hasOwnProperty('version') ? object.version = 0 : ""
  }
  if (type === "taskData") {
    !object.hasOwnProperty('listId') ? object.listId = "" : ""
    !object.hasOwnProperty('itemId') ? object.itemId = "" : ""
    !object.hasOwnProperty('name') ? object.name = "" : ""
    !object.hasOwnProperty('dueDate') ? object.dueDate = "" : ""
    !object.hasOwnProperty('dueTime') ? object.dueTime = "" : ""
    !object.hasOwnProperty('respUserId') ? object.respUserId = "" : ""
    !object.hasOwnProperty('status') ? object.status = "" : ""
    !object.hasOwnProperty('labour') ? object.labour = "0" : ""
  }



  if (type === "view") {
    // !obj.hasOwnProperty('id') ? obj.id = "" : "" - id must be defined before this
    !object.hasOwnProperty('data') ? object.data = {} : ""
    !object.hasOwnProperty('_data') ? object._data = {} : ""
    // data
    !object.data.hasOwnProperty('name') ? object.data.name = "Default view" : ""
    !object.data.hasOwnProperty('listId') ? object.data.listId = "" : ""
    !object.data.hasOwnProperty('fixedSelCriteria') ? object.data.fixedSelCriteria = {} : ""
    !object.data.hasOwnProperty('fields') ? object.data.fields = {} : ""
    !object.data.hasOwnProperty('fieldsOrder') ? object.data.fieldsOrder = [] : ""
    !object.data.hasOwnProperty('grandTotal') ? object.data.grandTotal = "none" : ""
    !object.data.hasOwnProperty('vBorders') ? object.data.vBorders = false : ""
    !object.data.hasOwnProperty('hBorders') ? object.data.hBorders = true : ""
    !object.data.hasOwnProperty('alternateRowColor') ? object.data.alternateRowColor = false : ""
    !object.data.hasOwnProperty('addonParams') ? object.data.addonParams = {addonId: "", params: {}, paramsIsOk: ""} : ""
  }
  if (type === "viewDataField") {
    // !obj.hasOwnProperty('id') ? obj.id = "" : "" - id must be defined before this
    !object.hasOwnProperty('headCellStyle') ? object.headCellStyle = {} : ""
    !object.hasOwnProperty('headNameStyle') ? object.headNameStyle = {} : ""
    !object.hasOwnProperty('cellStyle') ? object.cellStyle = {} : ""
    !object.hasOwnProperty('cellStringStyle') ? object.cellStringStyle = {} : ""
    if (object.id.charAt(0) === 'f') !object.hasOwnProperty('cellConditionalStyle') ? object.cellConditionalStyle = [] : ""
    // set values
    if (['z1', 'z2', 'z3'].includes(object.id)) !object.headCellStyle.hasOwnProperty('width') ? object.headCellStyle.width = "30px" : ""
    else if (['z4', 'z5', 'z6', 'z7'].includes(object.id)) !object.headCellStyle.hasOwnProperty('width') ? object.headCellStyle.width = "90px" : ""
    else !object.headCellStyle.hasOwnProperty('width') ? object.headCellStyle.width = "150px" : ""
    !object.headCellStyle.hasOwnProperty('position') ? object.headCellStyle.position = "sticky" : ""
    !object.headCellStyle.hasOwnProperty('top') ? object.headCellStyle.top = "0px" : ""
    !object.headCellStyle.hasOwnProperty('zIndex') ? object.headCellStyle.zIndex = 100 : ""
    if (['z1', 'z2', 'z3'].includes(object.id)) !object.headCellStyle.hasOwnProperty('textAlign') ? object.headCellStyle.textAlign = "center" : ""
    else !object.headCellStyle.hasOwnProperty('textAlign') ? object.headCellStyle.textAlign = "left" : ""
    !object.headCellStyle.hasOwnProperty('verticalAlign') ? object.headCellStyle.verticalAlign = "top" : ""
    if (['z1', 'z2', 'z3'].includes(object.id)) !object.cellStyle.hasOwnProperty('textAlign') ? object.cellStyle.textAlign = "center" : ""
    else !object.cellStyle.hasOwnProperty('textAlign') ? object.cellStyle.textAlign = "left" : ""
    // !object.cellStyle.hasOwnProperty('verticalAlign') ? object.cellStyle.verticalAlign = "top" : ""

  }
  if (type === "viewDataPhoneLayout") {
    !object.hasOwnProperty('rows') ? object.rows = [] : ""
  }
  if (type === "viewDataPhoneLayoutRow") {
    !object.hasOwnProperty('blocks') ? object.blocks = [] : ""
  }
  if (type === "viewDataPhoneLayoutBlock") {
    !object.hasOwnProperty('col') ? object.cols = 12 : ""
    !object.hasOwnProperty('fieldsClassJustify') ? object.fieldsClassJustify = "justify-center" : ""
    !object.hasOwnProperty('fieldsClassItems') ? object.fieldsClassItems = "items-center" : ""
    !object.hasOwnProperty('fieldsClassContent') ? object.fieldsClassContent = "content-center" : ""
    !object.hasOwnProperty('title') ? object.title = "" : ""
    !object.hasOwnProperty('titleColor') ? object.titleColor = "" : ""
    !object.hasOwnProperty('bgRadius') ? object.bgRadius = "" : ""
    !object.hasOwnProperty('bgShow') ? object.bgShow = "" : ""
    !object.hasOwnProperty('titleShow') ? object.titleShow = "" : ""
    !object.hasOwnProperty('fields') ? object.fields = [] : ""
  }
  if (type === "viewDataPhoneLayoutField") {
    !object.hasOwnProperty('id') ? object.id = "" : ""
    !object.hasOwnProperty('nameShow') ? object.nameShow = "" : ""
    !object.hasOwnProperty('nameColor') ? object.nameColor = "" : ""
    !object.hasOwnProperty('bgShow') ? object.bgShow = "" : ""
    !object.hasOwnProperty('bgColor') ? object.bgColor = "" : ""
    !object.hasOwnProperty('bgRadius') ? object.bgRadius = "" : ""
    !object.hasOwnProperty('width') ? object.width = "" : ""
    !object.hasOwnProperty('widthMeasure') ? object.widthMeasure = "fit" : ""
  }
  return object
}

/*
pl: {
    list: {}, view: {}, item: {}, fieldId: '', val: ''
}
*/

export const itemCreate = (pl) => {
  return new Promise((resolve) => {
    obj(pl.item, "item")
    objStatusFieldsSet(pl.item, pl.userId)
    db.items.add(pl.item).then(() => resolve(""))
  })
}


export const fieldValSave = (pl) => {

  if (pl.item.id === "") {
    let item = {
      id: generateId(),
      spaceId: pl.list.spaceId,
      status: "",
      $data: {
        version: 0,
        lastTS: 0,
        lastUserId: ""
      },
      data: {
        listId: pl.list.id,
        fields: {},
        fieldsS: {},
        fieldsF: {}
      }
    }
    objStatusFieldsSet(item, pl.userId)
    //pl.item.data.fields[pl.fieldId] = pl.val
    if (pl.list.data.fields[pl.fieldId].type === "sublist" && !pl.list.data.fields[pl.fieldId].isFixedSublist) {
      item.data.fieldsS[pl.fieldId] = pl.val
    } else {
      item.data.fields[pl.fieldId] = pl.val
    }

    // fill other fields in new item of Drilldown list (fields which are filled from upper list)
    // in most cases one field filled in fieldsS
    // debugger
    for (let fieldId in pl.list.data.fields){
      if(pl.item.data.fieldsS[fieldId]){
        item.data.fieldsS[fieldId] = pl.item.data.fieldsS[fieldId]
      }
    }

    db.items.add(item).then((itemId) => {
      for (let fieldId of objVal([pl.list.data, "formulaOrders", "onSave"], [])) {
        let calcVal = formulaCalculate(item, pl.list, fieldId)
        // pl.item.data.fields[fieldId] = calcVal
        db.items.where('id').equals(itemId).modify(item => {
          item.data.fields[fieldId] = calcVal
          objStatusFieldsSet(item, pl.userId)
        })
      }
    })
  } else {
    //pl.list.data.fields[pl.fieldId].type === "sublist" ? pl.item.data.fieldsS[pl.fieldId] = pl.val : pl.item.data.fields[pl.fieldId] = pl.val  // otherwise formula doesn't work
    if (pl.list.data.fields[pl.fieldId].type === "sublist" && !pl.list.data.fields[pl.fieldId].isFixedSublist) {
      pl.item.data.fieldsS[pl.fieldId] = pl.val
    } else {
      pl.item.data.fields[pl.fieldId] = pl.val
    }
    if (pl.list.data.fields[pl.fieldId].type === "fixedsublist") {
      pl.item.data.fieldsS[pl.fieldId] = pl.val
    }
    db.items.where('id').equals(pl.item.id).modify(item => {
      debugger
      if (pl.list.data.fields[pl.fieldId].type === "sublist" && !pl.list.data.fields[pl.fieldId].isFixedSublist) {
        item.data.fieldsS[pl.fieldId] = pl.val
      } else {
        item.data.fields[pl.fieldId] = pl.val
      }
      if (pl.list.data.fields[pl.fieldId].type === "fixedsublist") {
        item.data.fieldsS[pl.fieldId] = pl.val
      }
      objStatusFieldsSet(item, pl.userId)
    }).then(() => {
      for (let fieldId of objVal([pl.list.data, "formulaOrders", "onSave"], [])) {
        if (pl.list.data.fields[fieldId].onCreateOnly) continue
        debugger
        let calcVal = formulaCalculate(pl.item, pl.list, fieldId)
        // pl.item.data.fields[fieldId] = calcVal
        db.items.where('id').equals(pl.item.id).modify(item => {
          item.data.fields[fieldId] = calcVal
          objStatusFieldsSet(item, pl.userId)
        })
      }
    })
  }
}

export const listCreate = (pl) => {
  return new Promise((resolve) => {
    let defaultViewId = pl.defaultViewId ? pl.defaultViewId : generateId()
    let defaultPhoneViewId = pl.defaultPhoneViewId ? pl.defaultPhoneViewId : generateId()
    let list = {
      id: pl.listId,
      spaceId: pl.spaceId,
      status: "",
      $data: {
        version: 0,
        lastTS: 0,
        lastUserId: "",
        authReadGroups: ["*"],
        authWriteGroups: ["*"],
      },
      data: {
        name: pl.listName,
        defaultAdminViewId: defaultViewId,
        defaultAdminPhoneViewId: defaultPhoneViewId,
        fields: {
          z1: {id: "z1", name: "№"},
          z2: {id: "z2", name: "S"},
          z3: {id: "z3"},
          z4: {id: "z4", name: "Checklist"},
          z5: {id: "z5", name: "Comments"},
          z6: {id: "z6", name: "Approves"},
          z7: {id: "z7", name: "Changelog"},
          f1: {id: "f1", name: "Field name 1", type: "text"}
        },
        views: {}
      },
      // _data: {}
    }
    objStatusFieldsSet(list)

    //desktop view
    let view = obj({id: defaultViewId}, "view")
    // view.data.listId = pl.listId
    view.data.fields = {
      z1: obj({id: "z1"}, "viewDataField"),
      z2: obj({id: "z2"}, "viewDataField"),
      z3: obj({id: "z3"}, "viewDataField"),
      z4: obj({id: "z4"}, "viewDataField"),
      z5: obj({id: "z5"}, "viewDataField"),
      z6: obj({id: "z6"}, "viewDataField"),
      z7: obj({id: "z7"}, "viewDataField"),
      f1: obj({id: "f1"}, "viewDataField")
    }
    view.data.fieldsOrder = ['z1', 'z2', 'f1', 'z3']
    list.data.views[defaultViewId] = view

    //phone view
    view = obj({id: defaultPhoneViewId}, "view")
    // view.data.listId = pl.listId
    view.data.fields = {
      z1: obj({id: "z1"}, "viewDataField"),
      z2: obj({id: "z2"}, "viewDataField"),
      z3: obj({id: "z3"}, "viewDataField"),
      z4: obj({id: "z4"}, "viewDataField"),
      z5: obj({id: "z5"}, "viewDataField"),
      z6: obj({id: "z6"}, "viewDataField"),
      z7: obj({id: "z7"}, "viewDataField"),
      f1: obj({id: "f1"}, "viewDataField")
    }
    view.data.phoneLayout = obj({}, "viewDataPhoneLayout")
    view.data.phoneLayout.rows.push(obj({}, "viewDataPhoneLayoutRow"))
    view.data.phoneLayout.rows[0].blocks.push(obj({}, "viewDataPhoneLayoutBlock"))
    view.data.phoneLayout.rows[0].blocks[0].col = "10"
    view.data.phoneLayout.rows[0].blocks[0].fieldsClassJustify = "justify-start"
    view.data.phoneLayout.rows[0].blocks[0].fields.push(obj({id: "z1"}, "viewDataPhoneLayoutField"))
    view.data.phoneLayout.rows[0].blocks[0].fields.push(obj({id: "f1"}, "viewDataPhoneLayoutField"))
    view.data.phoneLayout.rows[0].blocks[0].fields[1].nameShow = "X"
    view.data.phoneLayout.rows[0].blocks[0].fields[1].nameColor = "grey"
    view.data.phoneLayout.rows[0].blocks[0].fields[1].bgShow = "X"
    view.data.phoneLayout.rows[0].blocks[0].fields[1].bgColor = "grey"
    view.data.phoneLayout.rows[0].blocks[0].fields[1].bgRadius = "3px"
    view.data.phoneLayout.rows[0].blocks[0].fields[1].width = "150"
    view.data.phoneLayout.rows[0].blocks[0].fields[1].widthMeasure = "px"
    view.data.phoneLayout.rows[0].blocks.push(obj({}, "viewDataPhoneLayoutBlock"))
    view.data.phoneLayout.rows[0].blocks[1].col = "2"
    view.data.phoneLayout.rows[0].blocks[1].fieldsClassJustify = "justify-end"
    view.data.phoneLayout.rows[0].blocks[1].fields.push(obj({id: "z3"}, "viewDataPhoneLayoutField"))
    list.data.views[defaultPhoneViewId] = view


    // db.transaction("rw", db.lists, async () => {
    //   await db.lists.add(list)
    //   // await db.views.add(view)
    // }).then(() => {
    //   resolve("")
    // })

    db.lists.add(list).then((l) => {
      resolve(l)
    })

  })
}

export const objVal = (props, failedType) => {
  // debugger
  let val = toRaw(props[0])
  if (!val) return failedType
  for (let i = 1; i < props.length; i++) {
    if (val[props[i]]) {
      val = val[props[i]]
    } else {
      return failedType
    }
  }
  return val
}

export const cellStringStyleForSublist = (sublistList, viewIdForSublist, fieldIdForSublist, sublistItemId, itemValue) => {
  let cellStringStyle = {}
  let cellStringStyleSublistFixed = {}
  let cellStringStyleSublistColumn = {}
  let cellStringStyleSublistCell = {}
  let type = objVal([sublistList, "data", "fields", fieldIdForSublist, "type"], "")
  let isFixedSublist = objVal([sublistList, "data", "fields", fieldIdForSublist, "isFixedSublist"], "")
  if (type === "sublist" && isFixedSublist === "X") {
    let fixedSiblistItem = sublistList.data.fields[fieldIdForSublist].fixedSublist.find(el => el.value === itemValue)
    if (fixedSiblistItem) cellStringStyleSublistFixed = fixedSiblistItem.style

  } else if (type !== "sublist") {
    cellStringStyleSublistColumn = objVal([
      sublistList, "data", "views", viewIdForSublist,
      "data", "fields", fieldIdForSublist, "cellStringStyle"], {}
    )

    cellStringStyleSublistCell = objVal([
      sublistList, "data", "views", viewIdForSublist,
      "data", "cellsProps", sublistItemId,
      fieldIdForSublist, "cellStringStyle"], {})
  }
  cellStringStyle = {
    ...cellStringStyleSublistFixed,
    ...cellStringStyleSublistColumn,
    ...cellStringStyleSublistCell
  }
  return cellStringStyle
}

export const objStatusFieldsSet = async (obj) => {
  if(!obj.status){
    obj.status = "created"
    obj.$data.version = 0
  }
  else if(obj.status === "updated"){
    obj.status = "changed"
    obj.$data.version =  parseInt(obj.$data.version, 10) + 1
  }
  else if(obj.status === "changing"){
    obj.status = "changed"
  }
  obj.$data.lastUserId = currentUserId
  obj.$data.lastTS = Date.now()

  // fixed selection criteria in items
  if (obj.data.hasOwnProperty('fixedSelCriteria')){
    obj.data.fixedSelCriteria = []
    for(let fieldId in obj.data.fieldsS){
      let val = obj.data.listId.slice(-5) + obj.data.fieldsS[fieldId].slice(-5)
      obj.data.fixedSelCriteria.push(val)
    }
  }

  //drillDownIndex
  if(obj?.data?.fields?.f2){
    obj.drillDownIndex = []
    // obj.drillDownIndex.push("f2_"+obj?.data?.fields?.f2+"_f5_"+obj?.data?.fields?.f5)
    // obj.drillDownIndex.push("f2_"+obj?.data?.fields?.f2+"_f6_"+obj?.data?.fields?.f6)
    obj.drillDownIndex.push(["f2_"+obj?.data?.fields?.f2, "f5_"+obj?.data?.fields?.f5])
    obj.drillDownIndex.push(["f2_"+obj?.data?.fields?.f2, "f6_"+obj?.data?.fields?.f6])

  }

  //drilldownFilter
  if(obj?.data?.fieldsS){
    obj.drilldownFilter = []
    for(let fieldId in obj.data.fieldsS){
      if(obj.data.fieldsS[fieldId] !== ""){
        obj.drilldownFilter.push(obj.data.listId+"_"+fieldId+"_"+obj.data.fieldsS[fieldId])
      }
    }
  }

  // formula run onSave
  // if(obj?.data?.fieldsS) {
  //   debugger
  //   let listId = obj.data.listId
  //   // let list = await db.lists.get(listId)
  //   db.table("lists").get(listId).then(list => {
  //     debugger
  //   })
  //   formulaRun(list.data, "onSave", obj.data.fields)
  // }

  return obj
}
export const itemStatusChangingSet = (itemId) => {
    db.items.where("id").equals(itemId).modify(item1 => {
      if(item1.status === "updated"){
        item1.status = "changing"
        item1.$data.version =  parseInt(item1.$data.version, 10) + 1
      }
      if(item1.status === "changed"){
        item1.status = "changing"
      }
      item1.$data.lastUserId = currentUserId
      // item1.$data.lastTS = Date.now() // it is necessary to exclude the change of lastTS because lastTS is used as part of key for cell. With new lastTS the cell id being rendered therefore cell cannot get the focus
    })
}

export const formulaOrderGen = (listData, formulaType) => {

  let formulaOrderLevelsArr = [[]]
  for (let fieldId in listData.fields) {
    if (listData.fields[fieldId].formula && listData.fields[fieldId].formulaType === formulaType) {
      formulaOrderLevelsArr[0].push(fieldId)
    }
  }
  let fun = function (level) {
    if(formulaOrderLevelsArr[level].length === 0) return
    formulaOrderLevelsArr.push([])
    for(let i = 0; i < formulaOrderLevelsArr[level].length; i++){
      let fieldId = formulaOrderLevelsArr[level][i]
      let regexp = /item.f\d+/gi
      let formulaFieldsArr = listData.fields[fieldId].formula.match(regexp)

      for (let itemField of formulaFieldsArr){
        let itemFieldId = itemField.substring(5)
        if(formulaOrderLevelsArr[level].includes(itemFieldId)){
          formulaOrderLevelsArr[level].splice(i, 1)
          formulaOrderLevelsArr[level+1].push(fieldId)
          i-- // reindex array formulaOrder[level] after deleting
          break
        }
      }
    }
    fun(level+1)
  }
  fun(0)
  let formulaOrder = []
  for(let formulaOrderLevelArr of formulaOrderLevelsArr){
    if (formulaOrderLevelArr.length === 0) continue
    formulaOrder = formulaOrder.concat(formulaOrderLevelArr)
  }
  return formulaOrder
}