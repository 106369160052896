<template>
  <q-list class="text-left">
    <q-expansion-item
        group="group"
        class="bg-white"
        v-model="slidesStatuses[space.id]"
        :header-class="slidesStatuses[space.id]?'_text-light-green-6 text-weight-bold':''"
        v-for="space in spaces" :key="space.id"
        style="border-top: 1px solid #e8e8e8"
    >
      <template v-slot:header>
        <q-item-section avatar>
          <q-avatar size="60px" color="grey-5" text-color="white" v-if="space.$data.isEncrypted && !space.cryptoKey">
            <q-icon name="lock" color="orange-lighten-5"/>
          </q-avatar>
          <q-avatar size="60px" color="grey-5" text-color="white" v-else-if="space.data.name">
            <img :src="space.data.avatarImg" v-if="space.data.avatarImg">
            <span v-else>{{space.data.name.charAt(0)}}</span>
          </q-avatar>
        </q-item-section>
        <q-item-section>
          <div v-if="(space.$data.isEncrypted && space.cryptoKey) || !space.$data.isEncrypted">
          {{ space.data.name }}
          </div>
          <div v-else-if="space.$data.isEncrypted && !space.cryptoKey">
            {{ space.$data.publicName }}
          </div>
        </q-item-section>
      </template>
        <sl-drawer-lists :spaceId="space.id" :space="space"/>
    </q-expansion-item>
  </q-list>
  <div style="border-top: 1px solid lightgray"></div>
  <div style="height: 100px"></div>

  <q-page-sticky position="bottom-right" :offset="[18, 18]">
    <q-btn round icon="add" size="md" color="light-green-6" glossy text-color="white" @click="spaceFormOpen()"></q-btn>
  </q-page-sticky>

  <q-dialog v-model="spaceFormShow">
    <q-card class="q-pa-md">
      <q-input v-model="spaceName" label="space name" dense/>
      <q-card-actions>
        <q-btn @click="spaceFormShow=!spaceFormShow">Cancel</q-btn>
        <q-btn @click="spaceSave()">Save</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import {db} from "@/db"
import SlDrawerLists from "@/components/sl-drawer-lists"
import {generateId} from "@/utils"
import {toRaw} from "vue"
import {obj} from "@/utils4"

export default {
  name: "sl-drawer-spaces",
  props: ["spaces"],
  components: { SlDrawerLists },
  data: () => {
    return {
      spaceName: "",
      spaceFormShow: false,
      slidesStatuses: {}
    }
  },
  watch: {
    spaces: {
      handler: function() {
        if(!this.spaces) return
        for(let i=0; i<toRaw(this.spaces).length; i++){
          if(this.slidesStatuses[this.spaces[i].id] === undefined){
            this.slidesStatuses[this.spaces[i].id] = false
          }
        }
      },
      deep: true
    }
  },
  methods: {
    spaceFormOpen(){
      this.spaceName = ""
      this.spaceFormShow = true
    },
    async spaceSave(){
      let space = obj({id: generateId()}, "space")
      space.data.name = this.spaceName
      space.$data.lastTS = Date.now()
      db.spaces.add(space)
      this.spaceFormShow = false
    }
  }
}
</script>