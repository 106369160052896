<template>
  <q-card>
    <q-card-section v-if="view.id !== ''">
      <div :style="cellProps.cellStyle">
        <span :style="cellProps.cellStringStyle">
          Sample
        </span>
      </div>
    </q-card-section>
    <q-scroll-area style="height: 250px; width: 250px;">
    <q-card-section>

      <q-expansion-item
          class="shadow-1 overflow-hidden"
          style="border-radius: 5px"
          label="Item cell style"
      >
        <q-card>
          <q-card-section>
            <sl-list-style-form :styleProps="cellProps.cellStyle" v-if="cellProps.cellStyle"/>
          </q-card-section>
        </q-card>
      </q-expansion-item>
      <q-expansion-item
          class="shadow-1 overflow-hidden"
          style="border-radius: 5px"
          label="Item string style"
      >
        <q-card>
          <q-card-section>
            <sl-list-style-form :styleProps="cellProps.cellStringStyle" v-if="cellProps.cellStringStyle"/>
          </q-card-section>
        </q-card>
      </q-expansion-item>

    </q-card-section>
    </q-scroll-area>



<!--    <q-card-section v-if="view.id === ''">-->
<!--      Select view-->
<!--    </q-card-section>-->
<!--    <q-card-actions class="justify-center" v-if="view.id === ''">-->
<!--      <q-btn flat @click="$emit('finished')">Cancel</q-btn>-->
<!--    </q-card-actions>-->


<!--    <q-card-section v-if="view.id !== ''">-->
<!--      <table>-->
<!--        <tr @click="colorPickerOpen('cellStyle', 'backgroundColor')">-->
<!--          <td style="text-align: right">Cell background color</td>-->
<!--          <td style="text-align: left">-->
<!--            <q-icon name="circle" :style="'color: '+cellProps.cellStyle.backgroundColor" size="sm" class="q-ma-sm"/>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr @click="colorPickerOpen('stringStyle', 'backgroundColor')">-->
<!--          <td style="text-align: right">Content background color</td>-->
<!--          <td style="text-align: left" >-->
<!--            <q-icon name="circle" :style="'border: 1px; color: '+cellProps.stringStyle.backgroundColor" size="sm" class="q-ma-sm"/>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr @click="colorPickerOpen('stringStyle', 'color')">-->
<!--          <td style="text-align: right">Font color</td>-->
<!--          <td style="text-align: left" >-->
<!--            <q-icon name="circle" :style="'color: '+cellProps.stringStyle.color" size="sm" class="q-ma-sm"/>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td style="text-align: right">Border radius</td>-->
<!--          <td style="text-align: left" >-->
<!--            <q-input v-model="cellProps.stringStyle.borderRadius" style="width: 100px" dense/>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td style="text-align: right">Bold</td>-->
<!--          <td style="text-align: left">-->
<!--            <q-checkbox v-model="cellProps.stringStyle.fontWeight" true-value="bold" false-value="normal"/>-->
<!--          </td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--          <td style="text-align: right">Padding</td>-->
<!--          <td style="text-align: left" >-->
<!--            <q-input v-model="cellProps.stringStyle.padding" style="width: 150px" dense/>-->
<!--          </td>-->
<!--        </tr>-->
<!--      </table>-->
<!--    </q-card-section>-->
<!--    <q-dialog v-model="colorPickerShow">-->
<!--      <q-card>-->
<!--        <q-color v-model="currentPropVal" no-footer default-view="palette" class="my-picker"/>-->
<!--        <v-card-actions>-->
<!--          <q-btn @click="colorSet()">ok</q-btn>-->
<!--        </v-card-actions>-->
<!--      </q-card>-->
<!--    </q-dialog>-->
    <q-card-actions class="justify-center" v-if="view.id !== ''">
      <q-btn flat @click="$emit('finished')">Cancel</q-btn>
      <q-btn flat @click="cellPropsSave()">Save</q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
// import {db} from "@/db";
import {toRaw} from "vue"
import SlListStyleForm from "@/components/sl-list-style-form"

export default {
  name: "sl-list-cell-prop-from",
  components: {SlListStyleForm},
  props: ["list", "view", "currentCell"],
  data: ()=>{
    return {
      colorPickerShow: false,
      currentPropVal: "",
      currentProp: "",
      currentPropObj: "",
      cellStyle: {},
      cellStringStyle: {},
      fieldPropsId: "",
      cellProps: {}
    }
  },
  created() {
    // this.fieldPropsId = "p" + this.currentCell.field.id.substring(1)
    // let cellViewsProps = this.currentCell.item.fields[this.fieldPropsId]
    // if (!cellViewsProps) cellViewsProps = {}
    // this.cellProps = cellViewsProps[this.view.id]

    if(!this.view._data.cellsProps) {
      this.cellProps = {}
    }
    else if(!this.view._data.cellsProps[this.currentCell.item.id]) {
      this.cellProps = {}
    }
    else if(!this.view._data.cellsProps[this.currentCell.item.id][this.currentCell.fieldId]) {
      this.cellProps = {}
    }
    else {
      this.cellProps = this.view._data.cellsProps[this.currentCell.item.id][this.currentCell.fieldId]
    }

    if (!this.cellProps.cellStyle) this.cellProps.cellStyle = {}
    if (!this.cellProps.cellStringStyle) this.cellProps.cellStringStyle = {}
    // if (!this.cellProps.cellStyle.backgroundColor) this.cellProps.cellStyle.backgroundColor = "white"
    // if (!this.cellProps.stringStyle.backgroundColor) this.cellProps.stringStyle.backgroundColor = "white"
    // if (!this.cellProps.stringStyle.color) this.cellProps.stringStyle.color = ""
    // if (!this.cellProps.stringStyle.borderWidth) this.cellProps.stringStyle.borderWidth = ""
    // if (!this.cellProps.stringStyle.borderColor) this.cellProps.stringStyle.borderColor = ""
    // if (!this.cellProps.stringStyle.borderRadius) this.cellProps.stringStyle.borderRadius = ""
    // if (!this.cellProps.stringStyle.padding) this.cellProps.stringStyle.padding = ""
  },
  computed: {
    sampleCellStyle(){
      let sampleCellStyle = this.cellProps.cellStyle
      sampleCellStyle.display = 'cell'
      return sampleCellStyle
    },
    sampleStringStyle(){
      let sampleStringStyle = this.cellProps.cellStringStyle
      return sampleStringStyle
    },
  },
  methods: {
    colorPickerOpen(obj, prop){
      this.currentPropObj = obj
      this.currentProp = prop
      this.currentPropVal = this[obj][prop]
      this.colorPickerShow = true
    },
    colorSet(){
      this.cellProps[this.currentPropObj][this.currentProp] = this.currentPropVal
      this.colorPickerShow = false
    },
    cellPropsSave(){

      for (let prop in this.cellProps.cellStyle) if(this.cellProps.cellStyle[prop] === "") delete this.cellProps.cellStyle[prop]
      debugger
      for (let prop in this.cellProps.cellStringStyle) {
        if(this.cellProps.cellStringStyle[prop] === "") delete this.cellProps.cellStringStyle[prop]
      }
      debugger
      let lView = this.view
      if(!lView._data.cellsProps) lView._data.cellsProps = {}
      if(!lView._data.cellsProps[this.currentCell.item.id]) lView._data.cellsProps[this.currentCell.item.id] = {}
      // if(!lView._data.cellsProps[this.currentCell.item.id][this.currentCell.fieldId]) lView._data.cellsProps[this.currentCell.item.id][this.currentCell.fieldId] = {}
      lView._data.cellsProps[this.currentCell.item.id][this.currentCell.fieldId] = toRaw(this.cellProps)
      lView._isChangedByUser = true
      // if(that.view.id === "") return
      // let dbItem = db.items.where('id').equals(this.currentCell.item.id)
      // if(!this.currentCell.item.data.fieldsProps) {
      //   dbItem.modify(item => item.data.fieldsProps = {
      //     [that.view.id]: {
      //       [that.currentCell.fieldId]: toRaw(that.cellProps)
      //     }
      //   })
      // }
      // else if(!this.currentCell.item.data.fieldsProps[that.view.id]) {
      //   dbItem.modify(item => item.data.fieldsProps[that.view.id] = {
      //       [that.currentCell.fieldId]: toRaw(that.cellProps)
      //   })
      // }
      // else {
      //   dbItem.modify(item => item.data.fieldsProps[that.view.id][that.currentCell.fieldId] = toRaw(that.cellProps))
      // }
      this.$emit('finished')
    },
  }
}
</script>

<style scoped>

</style>