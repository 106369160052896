<template>
  <q-dialog v-model="dialogShow">
    <q-card>
      <q-card-section>
        <div v-for="(msg, i) in lMsgs" :key="i"
            :style="msg.style"
        >
          {{msg.text}}
        </div>
      </q-card-section>
      <q-card-actions class="justify-center">
        <q-btn label="Ok" @click="$emit('finished')"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "sl-msgs",
  props: ["msgs"],
  data: () => {
    return {
      dialogShow: true,
      style: {
        borderRadius: '7px',
        margin: '5px',
        padding: '10px 20px 10px 20px',
        color: 'white'
      }
    }
  },
  computed: {
    lMsgs(){
      let lMsgs = this.msgs
      lMsgs.forEach(msg => {
        msg.style = this.style
        if(msg.type === "e") msg.style.backgroundColor = "#fd5050"
        if(msg.type === "s") msg.style.backgroundColor = "#3d9324"
        if(msg.type === "i") msg.style.backgroundColor = "#3085a2"
        if(msg.type === "w") msg.style.backgroundColor = "#dac202"
      })
      return lMsgs
    }
  }
}
</script>

<style scoped>

</style>