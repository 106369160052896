<template>
  <q-card class="no-shadow bg-grey-lighten-3" style="height: 100%">
    <q-card-section>
      <q-checkbox v-model="params.isTraceOn" label="Is tracer on" @update:model-value="save()" dense/>
    </q-card-section>
    <q-card-section>
    <q-card class="shadow-5" style="max-width: 500px">
      <q-card-section>
        <q-checkbox v-model="params.isConsoleTraceOn" label="Is console tracer on" @update:model-value="save()" dense
                    :color="params.isTraceOn?'green':'grey'"
        />
      </q-card-section>
      <q-card-section>
        Filter
      </q-card-section>
      <q-card-section>
        SpaceIds:
        <q-chip v-for="spaceId in params.filter.spaceIds" :key="spaceId" :label="spaceId"
                removable @remove="remove('spaceId', spaceId)"
                :color="params.isTraceOn ? 'green' : ''" :text-color="params.isTraceOn ? 'white' : ''"
        />
        <q-btn round @click="formOpen('spaceId')" icon="add" size="xs"/>
      </q-card-section>
      <q-card-section>
        ListIds:
        <q-chip v-for="listId in params.filter.listIds" :key="listId" :label="listId"
                removable @remove="remove('listId', listId)"
                :color="params.isTraceOn ? 'green' : ''" :text-color="params.isTraceOn ? 'white' : ''"
        />
        <q-btn round @click="formOpen('listId')" icon="add" size="xs"/>
      </q-card-section>
      <q-card-section>
        ItemIds:
        <q-chip v-for="itemId in params.filter.itemIds" :key="itemId" :label="itemId"
                removable @remove="remove('itemId', itemId)"
                :color="params.isTraceOn ? 'green' : ''" :text-color="params.isTraceOn ? 'white' : ''"
        />
        <q-btn round @click="formOpen('itemId')" icon="add" size="xs"/>
      </q-card-section>
    </q-card>
    </q-card-section>

    <q-dialog v-model="formShow">
      <q-card>
        <q-card-section>
          <q-input v-model="valToAdd" :label="typeToAdd" dense autofocus/>
        </q-card-section>
        <q-card-actions class="justify-center">
          <q-btn label="cancel" @click="formShow=true"/>
          <q-btn label="add" @click="add()"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>

</template>

<script>
export default {
  name: "TracerParams",
  data: () => {
    return {
      params: {
        isTraceOn: false,
        isConsoleTraceOn: false,
        filter: {
          spaceIds: [],
          listIds: [],
          itemIds: []
        }
      },
      formShow: false,
      typeToAdd: "",
      valToAdd: ""
    }
  },
  created() {
    let params = localStorage.getItem('tracerParams')
    if(params) this.params = JSON.parse(params)
  },
  methods: {
    formOpen(type){
      this.typeToAdd = type
      this.valToAdd = ""
      this.formShow = true
    },
    save(){
      localStorage.setItem('tracerParams', JSON.stringify(this.params))
    },
    add(){
      if(this.typeToAdd === "spaceId"){
        this.params.filter.spaceIds.push(this.valToAdd)
      }
      else if(this.typeToAdd === "listId"){
        this.params.filter.listIds.push(this.valToAdd)
      }
      if(this.typeToAdd === "itemId"){
        this.params.filter.itemIds.push(this.valToAdd)
      }
      this.save()
      this.formShow = false
    },
    remove(type, idToRemove){
      if(type === "spaceId"){
        let indx = this.params.filter.spaceIds.findIndex(el=>el===idToRemove)
        if( indx >- 1 ) this.params.filter.spaceIds.splice(indx, 1)
      }
      else if(type === "listId"){
        let indx = this.params.filter.listIds.findIndex(el=>el===idToRemove)
        if( indx >- 1 ) this.params.filter.listIds.splice(indx, 1)
      }
      else if(type === "itemId"){
        let indx = this.params.filter.itemIds.findIndex(el=>el===idToRemove)
        if( indx >- 1 ) this.params.filter.itemIds.splice(indx, 1)
      }
      this.save()
    }
  }
}
</script>

<style scoped>

</style>