<template>
  <sl-space :spaceId="spaceId" :key="spaceId" v-if="spaceId!==undefined"/>
</template>

<script>

import SlSpace from "@/components/sl-space";
export default {
  name: "SpaceView",
  components: {SlSpace},
  data: ()=>{
    return {
    }
  },
  computed: {
    spaceId(){
      return this.$route.params.spaceId
    }
  }
}
</script>

<style scoped>

</style>