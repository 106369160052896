<template>
  <div class="sl-footer bg-grey-lighten-5 row items-end"
       style="position: fixed; bottom: 0; right: 0; min-height: 60px; width: 100%; z-index: 100"
  >
    <div style="_height: 100%; margin-bottom: 15px">
      <q-btn round flat icon="cancel" color="red" @click="footerEditorClose()"/>
    </div>
    <q-input autofocus :type="fieldType==='num'?'number':''" outlined v-model="text" :autogrow="fieldType==='text'" class="bg-white q-ma-sm col-9"/>
    <div style="_height: 100%; margin-bottom: 15px">
      <q-btn round flat icon="send" color="green" @click="save()"/>
    </div>
  </div>
</template>

<script>
import {fieldValSave} from "@/utils4";

export default {
  name: "sl-footer-editor",
  data: () => {
    return {
      text: ""
    }
  },
  created(){
    let obj = this.$store.state.footerEditorObj
    if(obj.type === "item"){
      this.text = obj.item.data.fields[obj.fieldId]
    }

  },
  computed: {
    fieldType(){
      let fieldType = ""
      let obj = this.$store.state.footerEditorObj
      if(obj.type === "item"){
        fieldType = obj.list.data.fields[obj.fieldId].type
      }
      return fieldType
    }


  },
  methods: {
    footerEditorClose(){
      this.$store.commit("footerEditorClose")
    },
    save(){
      let obj = this.$store.state.footerEditorObj
      if(obj.type === "item"){
        fieldValSave({
          list: obj.list,
          item: obj.item,
          fieldId: obj.fieldId,
          val: this.text,
          userId: this.$store.state.footerEditorUserId
        })
      }
      this.footerEditorClose()
    }
  }
}
</script>

<style scoped>

</style>