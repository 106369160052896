<template>
  <q-card >
    <q-card-section class="text-center">
      <div v-for="(itemId, i) in selectedItemIds" :key="itemId" class="item">
        <div :style="listField.fixedSublist[itemId].style" @click="unselectItem(i)">
          {{listField.fixedSublist[itemId].value}}
        </div>
        <q-btn round size="xs" icon="keyboard_double_arrow_up" class="q-ma-xs"
               :disable="i===0" @click="move('up', i)"
        />
        <q-btn round size="xs" icon="keyboard_double_arrow_down" class="q-ma-xs"
               :disable="i===selectedItemIds.length-1" @click="move('down', i)"
        />
        <q-btn round size="xs" icon="clear" class="q-ma-xs text-red"
               @click="unselectItem(i)"
        />
      </div>
    </q-card-section>
    <q-card-section class="text-center">
      <div v-for="itemId in unselectedItemIds" :key="itemId" class="item">
        <div :style="listField.fixedSublist[itemId].style" @click="selectItem(itemId)">
          {{listField.fixedSublist[itemId].value}}
        </div>
      </div>
    </q-card-section>
    <q-card-actions>
      <q-btn @click="$emit('finished')">cancel</q-btn>
      <q-btn @click="clear()">clear</q-btn>
      <q-btn @click="save()" v-if="listField.isMultipleInput">save</q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import { db } from '@/db'
import {objStatusFieldsSet} from "@/utils4";

export default {
  name: "sl-list-cell-form-fixedsublist",
  props: ["list5Id", "itemId", "fieldId"],
  data: () => {
    return {
      listField: {},
      selectedItemIds: []
    }
  },
  created(){
    this.listField = this.$store.state.lists[this.list5Id].list.data.fields[this.fieldId]
    let item = this.$store.state.lists[this.list5Id].items.find(item => item.id === this.itemId)
    this.selectedItemIds = item.data.fieldsS[this.fieldId] ? item.data.fieldsS[this.fieldId].split(";") : []
  },
  computed: {
    unselectedItemIds(){
      let unselectedItemIds = []
      for(let itemId in this.listField.fixedSublist){
        if(!this.selectedItemIds.includes(itemId)) unselectedItemIds.push(itemId)
      }
      return unselectedItemIds
    },
    // listField(){
    //   return this.$store.state.lists[this.list5Id].list.data.fields[this.fieldId]
    // },
    // fixedSublist(){
    //   let fixedSublist = JSON.parse(JSON.stringify(this.listField.fixedSublist))
    //   let fixedSublistArr = []
    //   for (let prop in fixedSublist){
    //     fixedSublist[prop].isSelected = false
    //     fixedSublist[prop].id = prop
    //     if(this.selectedItemIds.includes(prop)) fixedSublist[prop].isSelected = true
    //     fixedSublistArr.push(fixedSublist[prop])
    //   }
    //   fixedSublistArr.sort((a, b) => {
    //     let res = 0
    //     if (a.isSelected === true && b.isSelected === false) res = -1
    //     if (a.isSelected === false && b.isSelected === true) res = 1
    //     return res
    //   })
    //   return fixedSublistArr
    // }
  },
  methods: {
    unselectItem(i){
      this.selectedItemIds.splice(i, 1)
    },
    selectItem(itemId){
      if(this.listField.isMultipleInput) {
        this.selectedItemIds.push(itemId)
      }
      else{
        this.selectedItemIds = [itemId]
        this.save()
      }
    },
    clear(){
      this.selectedItemIds = []
      this.save()
    },
    save(){
      debugger
      let saveValue = ""
      for(let i=0; i<this.selectedItemIds.length; i++){
        saveValue = saveValue + this.selectedItemIds[i]
        if(this.selectedItemIds.length > i+1) saveValue = saveValue + ";"
      }
      db.table("items").where("id").equals(this.itemId).modify(item => {
        delete item.data.fields[this.fieldId]
        item.data.fieldsS[this.fieldId] = saveValue
        objStatusFieldsSet(item)
      }).then(() => {
        this.$emit("finished")
      })
    },
    move(dir, i){
      // let indx = this.selectedItemIds.findIndex(el=>el===id)
      let el = this.selectedItemIds.splice(i, 1)
      if(dir === "up") this.selectedItemIds.splice(i-1, 0, el[0])
      if(dir === "down") this.selectedItemIds.splice(i+1, 0, el[0])
    }
  }
}
</script>

<style scoped>
.item {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

</style>