<template>
  <div :class="level === 0 ? 'sl-cell bg-yellow-2':'sl-cell bg-grey-'+(4 - level)"
       v-if="totalFields"
       :style="cellStyle"
  >
    <div v-if="fieldId === 'z1'" :style="{textAlign: 'left', paddingLeft: ((level-1)*10)+'px'}">
      <q-icon :name="expanded?'expand_more':'chevron_right'" size="sm"
              @click="groupExpand()" :style="{cursor: 'pointer'}" />
    </div>
    <span v-if="totalFields.fields[fieldId]!==undefined && fieldInitialType !== 'avatar'" class="font-weight-bold"
          :style="cellStringStyle"
          v-html="cellString"
    />
<!--    <div v-else-if="fieldInitialType === 'avatar' && avatarData"  style="_vertical-align: top; background-color: green; height: 30px; width: 100px; display: inline-block; _margin-top: -15px; _margin-bottom: -15px; _box-shadow: 0 0 8px lightgrey;">-->
<!--      <img :src="avatarData.src" style="display: inline-block; height: 30px; width: 30px; border-radius: 15px">-->
<!--      <div style="display: inline-block; background-color: #2196f3; margin-top: -40px">{{avatarData.name}}</div>-->
<!--    </div>-->

    <q-chip v-else-if="fieldInitialType === 'avatar' && avatarData" :color="'grey-'+(4 - level)" _dense  style="margin-top: -4px; margin-bottom: -4px; _box-shadow: 0 0 8px lightgrey">
      <q-avatar>
        <img :src="avatarData.src">
      </q-avatar>
      {{avatarData.name}}
    </q-chip>


    <span v-if="fieldId === upperGroupFieldId" class="text-grey-6 q-ml-sm">({{totalFields.count}})</span>
  </div>
</template>

<script>
import {numFormat} from "@/utils3";
import {cellStringStyleForSublist, objVal} from "@/utils4";

export default {
  name: "sl-list-cell-group",
  props: ["fieldId", "level", "totalFields", "view", "list", "upperGroupFieldId"],
  data: () => {
    return {
      expanded: false,
      checkboxVal: null,
      fieldInitialType: ""
    }
  },
  created() {
    this.expanded = this.level === 0 ? true : false
    if(this.list.data.fields[this.fieldId].type === "checkbox"){
      this.checkboxVal = this.totalFields.fields[this.fieldId] === "X" ? true : false
    }
    if(this.list.data.fields[this.fieldId].type === "sublist"){
      let listIdForSublist = this.list.data.fields[this.fieldId].listIdForSublist
      let fieldIdForSublist = this.list.data.fields[this.fieldId].fieldIdForSublist
      if(listIdForSublist && fieldIdForSublist){
        this.fieldInitialType = this.$store.state.sublists[listIdForSublist].list.data.fields[fieldIdForSublist].type
      }
    }
    else{
      this.fieldInitialType = this.list.data.fields[this.fieldId].type
    }
    // debugger
  },
  computed: {
    avatarData(){
      let avatarData = null
      let avatarList = null
      let avatarfieldId = null
      let avatarItem = null
      //debugger
      // if(this.list.data.fields[this.fieldId].type === "sublist"){
      //   debugger
      // }
      if(this.list.data.fields[this.fieldId].type === "avatar"){
        avatarList = this.list
        avatarItem = this.item
        avatarfieldId = this.fieldId
      }
      if(this.list.data.fields[this.fieldId].type === "sublist" && this.$store.state.sublists){
        if(!this.totalFields.fieldsS[this.fieldId]) return
        let listIdForSublist = this.list.data.fields[this.fieldId].listIdForSublist
        let fieldIdForSublist = this.list.data.fields[this.fieldId].fieldIdForSublist
        // let viewIdForSublist = this.list.data.fields[this.fieldId].viewIdForSublist
        let sublist = objVal([this.$store.state.sublists, listIdForSublist], {list: {}, items: []})
        let type = objVal([sublist.list, "data", "fields", fieldIdForSublist, "type"], "")
        if(type === "avatar"){
          avatarList = sublist.list
          avatarItem = sublist.items.find(el => el.id === this.totalFields.fieldsS[this.fieldId])
          avatarfieldId = fieldIdForSublist
        }
      }
      // debugger
      if(!avatarList) return
      let avatar = avatarList.data.fields[avatarfieldId]

      if(avatar.avatarImgFieldId && avatarItem && avatarItem.data.fields[avatar.avatarImgFieldId]){
        console.log("URL", avatarItem.data.fields[avatar.avatarImgFieldId])
        avatarData = {}
        avatarData.src = avatarItem.data.fields[avatar.avatarImgFieldId]
        avatarData.name = avatarItem.data.fields[avatar.avatarNameFieldId]
      }
      else if (avatarList.data.fields[avatarfieldId].type === "avatar") {
        avatarData = {}
        avatarData.src = require('../assets/defaultPictureUser.svg')
        avatarData.name = objVal([avatarItem, 'data', 'fields', avatar.avatarNameFieldId], "")
      }
      return avatarData
    },
    cellString(){
      if(this.totalFields[this.fieldId] === "" || this.totalFields.fields[this.fieldId] === undefined) return ""
      let cellString = this.totalFields.fields[this.fieldId]
      if(this.list.data.fields[this.fieldId].type === "num"){
        cellString = numFormat(cellString, this.list.data.fields[this.fieldId])
      }
      if(this.list.data.fields[this.fieldId].type === "date" && this.list.data.fields[this.fieldId].dateLocales !== undefined && this.list.data.fields[this.fieldId].dateLocales !== ""){
        cellString = new Date(this.totalFields.fields[this.fieldId]).toLocaleDateString(this.list.data.fields[this.fieldId].dateLocales)
      }
      if(this.list.data.fields[this.fieldId].type === "text" && this.list.data.fields[this.fieldId].showInSeparateWindow){
        cellString = ""
      }
      return cellString
    },
    cellStyle(){
      let cellStyle = {
        ...this.view._data.fields[this.fieldId].cellStyle,
        ...{}
      }
      // if (this.item.data.fieldsProps) {
      //   if (this.item.data.fieldsProps[this.view.id]) {
      //     if (this.item.data.fieldsProps[this.view.id][this.fieldId]) {
      //       cellStyle = {
      //         ...cellStyle,
      //         ...this.item.data.fieldsProps[this.view.id][this.fieldId].cellStyle
      //       }
      //     }
      //   }
      // }
      cellStyle.padding = '6px'
      cellStyle.overflow = 'hidden'
      cellStyle.whiteSpace = 'pre-wrap'
      cellStyle.display = !this.$q.platform.is.mobile ? 'table-cell' : ''

      return cellStyle
    },
    cellStringStyle(){
      if(this.totalFields.fields[this.fieldId] === "" || this.totalFields.fields[this.fieldId] === undefined) return {}
      let cellStringStyle
      if(this.list.data.fields[this.fieldId].type === "sublist" && this.totalFields.fieldsS[this.fieldId] && !this.list.data.fields[this.fieldId].isFixedSublist) {
        // cellStringStyle = this.view._sublists[this.fieldId][this.totalFields.fieldsS[this.fieldId]].style.cellStringStyle
        // cellStringStyle = objVal([
        //     this.list.data.views, this.view.id,
        //   "data", "cellProps", this.totalFields.fieldsS[this.fieldId],
        //   this.list.data.fields[this.fieldId].fieldIdForSublist, "cellStringStyle"], {})

        let sublistList = objVal([this.$store.state.sublists, this.list.data.fields[this.fieldId].listIdForSublist, "list"], {})
        let viewIdForSublist = this.list.data.fields[this.fieldId].viewIdForSublist
        let fieldIdForSublist = this.list.data.fields[this.fieldId].fieldIdForSublist
        let sublistItemId = this.totalFields.fieldsS[this.fieldId]
        let fixedValue = this.totalFields.fields[this.fieldId]
        cellStringStyle = cellStringStyleForSublist(sublistList, viewIdForSublist, fieldIdForSublist, sublistItemId, fixedValue)

      }
      else if(this.list.data.fields[this.fieldId].type === "sublist" && this.list.data.fields[this.fieldId].isFixedSublist){
        let fixedListEl = this.list.data.fields[this.fieldId].fixedSublist.find(el => el.value === this.totalFields.fields[this.fieldId])
        fixedListEl ? cellStringStyle = fixedListEl.style : cellStringStyle = {}
      }
      else{
        cellStringStyle = {
          ...this.view._data.fields[this.fieldId].cellStringStyle,
          ...{}
        }
        // if (this.view._data.cellsProps) {
        //   if (this.view._data.cellsProps[this.item.id]) {
        //     if (this.view._data.cellsProps[this.item.id][this.fieldId]) {
        //       cellStringStyle = {
        //         ...cellStringStyle,
        //         ...this.view._data.cellsProps[this.item.id][this.fieldId].cellStringStyle
        //       }
        //     }
        //   }
        // }
      }
      let s = JSON.parse(JSON.stringify(cellStringStyle))
      // if(this.view._data.fields[this.fieldId].group){
      //   s.opacity = "1"
      // }
      return s
    }
  },
  methods: {
    groupExpand(){

      if(this.expanded){
        this.expanded=false
        this.$emit("collapsed")
      }
      else{
        this.expanded=true
        this.$emit("expanded")
      }
    }
  }
}
</script>

<style scoped>
</style>