<template>
  <sl-list5-cell v-for="rFieldId in viewData.fieldsOrder"
                 :key="rFieldId+item.$data.lastTS"
                 :list5-id="list5Id"
                 :item-i="itemI"
                 :item-id="item.id"
                 :item-status="item.status"
                 :field-id="rFieldId"
                 :val="item?.data.fields[rFieldId]"
                 :valS="item?.data.fieldsS[rFieldId]"
                 :new-item="newItem"
  />
</template>

<script>
import SlList5Cell from "@/components/sl-list5-cell";
export default {
  name: "sl-list5-row",
  props: ["item", "list5Id", "itemI", "newItem"],
  components: {SlList5Cell},
  data: () => {
    return {
    }
  },
  computed: {
    viewData(){
      return this.$store.state.lists[this.list5Id]?.list?.data.views[this.$store.state.lists[this.list5Id]?.viewId].data
    },
    listData(){
      return this.$store.state.lists[this.list5Id]?.list?.data
    }
  }
}
</script>

<style scoped>

</style>