// import { db } from './db'
import store from './store.js'


export const resizableGrid = (table, view) => {

    let row = table.querySelector("thead>tr:last-child")  //in case upper row is a group of fields, only last row is used for resize
    let cols = row.children
    let isLastDiv = false
    let div
    for (let i = 1; i < cols.length; i++) {
        while (cols[i].querySelector("div")) {
            cols[i].removeChild(cols[i].querySelector("div"))
        }
        div = createDiv(isLastDiv)
        cols[i].prepend(div)
        cols[i].style.position = 'sticky'
        setListeners(div, isLastDiv)
        if (i === cols.length-1){
            isLastDiv = true
            div = createDiv(isLastDiv)
            cols[i].append(div)
            setListeners(div, isLastDiv)
        }
    }
    setListenersForRow(table)

    function setListenersForRow(table){

        table.querySelector("thead").removeEventListener('mouseover', function(){})
        table.querySelector("thead").removeEventListener('mouseout', function(){})

        table.querySelector("thead").addEventListener('mouseover', function(e) {
            let divs = e.currentTarget.querySelectorAll("div")
            divs.forEach(div=>{
                div.style.backgroundColor = "green"
            })
        })
        table.querySelector("thead").addEventListener('mouseout', function(e) {
            let divs = e.currentTarget.querySelectorAll("div")
            divs.forEach(div=>{
                div.style.backgroundColor = ""
            })
        })
    }

    function setListeners(div, isLastDiv) {
        let pageX, curCol, curColWidth, tableWidth

        div.removeEventListener('mousedown', function() {})
        div.addEventListener('mousedown', function(e) {
            tableWidth = table.offsetWidth
            curCol = isLastDiv ?  e.target.parentElement : e.target.parentElement.previousElementSibling
            pageX = e.pageX
            curColWidth = curCol.offsetWidth
        })

        // div.addEventListener('mouseover', function(e) {
        //     e.target.style.backgroundColor = "red"
        // })
        //
        // div.addEventListener('mouseout', function(e) {
        //     e.target.style.backgroundColor = ""
        // })

        document.removeEventListener('mousemove', function(){})
        document.addEventListener('mousemove', function(e) {
            if (curCol) {
                let diffX = e.pageX - pageX
                curCol.style.width = (curColWidth + diffX) + 'px'
                table.style.width = tableWidth + diffX + "px"
                // table.setAttribute("change", 'manuallyResized')
                // tableResized = !tableResized
            }
        })

        document.removeEventListener('mouseup', function(){})
        document.addEventListener('mouseup', function() {
            if(curCol){
                tableSizeUpdate(table, view, curCol)
                curCol = undefined
                pageX = undefined
                curColWidth = undefined
            }
        })
    }
    function createDiv(isLastDiv) {
        let div = document.createElement('div')
        div.style.top = 0
        div.style.left = isLastDiv ? '' : '-4px'
        div.style.right = isLastDiv ? '-4px' : ''
        div.style.width = '7px'
        div.style.borderRadius = '5px 5px 5px 5px'
        div.style.position = 'absolute'
        div.style.cursor = 'col-resize'
        div.style.userSelect = 'none'
        div.style.height = '100%'
        div.style.opacity = '0.5'
        return div
    }
}

function tableSizeUpdate(table, view, curCol){
    // db.views.where('id').equals(view.id).modify(v => {
    //     v.data.fields[curCol.id].headCellStyle.width = curCol.style.width
    // })
    let lView = view
    lView._data.fields[curCol.id].headCellStyle.width = curCol.style.width
    lView._isChangedByUser = true

    // let ths = table.querySelectorAll("thead>tr:last-child>th")
    // debugger
    // ths.forEach(th => {
    //     // if(th.offsetWidth !==parseInt(view.data.fields[th.id].headCellStyle.width)){
    //     if(th.style.width !== view.data.fields[th.id].headCellStyle.width){
    //         db.views.where('id').equals(view.id).modify(v => {
    //             v.data.fields[th.id].headCellStyle.width = th.clientWidth+"px"
    //         })
    //     }
    // })
}

export const resizableGrid2 = (table, list5Id) => {

    let row = table.querySelector("thead>tr")
    let cols = row.children
    let div
    for (let i = 0; i < cols.length; i++) {
        while (cols[i].querySelector("div")) {
            cols[i].removeChild(cols[i].querySelector("div"))
        }
        div = createDiv()
        cols[i].append(div)
        cols[i].style.position = 'sticky'
        setListeners(div)
    }
    setListenersForRow(table)

    function setListenersForRow(table){

        table.querySelector("thead").removeEventListener('mouseover', function(){})
        table.querySelector("thead").removeEventListener('mouseout', function(){})

        table.querySelector("thead").addEventListener('mouseover', function(e) {
            let divs = e.currentTarget.querySelectorAll("div")
            divs.forEach(div=>{
                div.style.backgroundColor = "green"
            })
        })
        table.querySelector("thead").addEventListener('mouseout', function(e) {
            let divs = e.currentTarget.querySelectorAll("div")
            divs.forEach(div=>{
                div.style.backgroundColor = ""
            })
        })
    }

    function setListeners(div) {
        let pageX, curCol, curColWidth //tableWidth

        div.removeEventListener('mousedown', function() {})
        div.addEventListener('mousedown', function(e) {
            // tableWidth = table.offsetWidth
            curCol = e.target.parentElement
            pageX = e.pageX
            curColWidth = curCol.offsetWidth
        })

        document.removeEventListener('mousemove', function(){})
        document.addEventListener('mousemove', function(e) {
            if (curCol) {
                let diffX = e.pageX - pageX
                // curCol.style.width = (curColWidth + diffX) + 'px'
                // table.style.width = tableWidth + diffX + "px"
                store.commit("viewHeadCellWidthUpdate", {
                    list5Id: list5Id,
                    fieldId: curCol.id,
                    cellWidth: (curColWidth + diffX) + 'px'
                })
            }
        })

        document.removeEventListener('mouseup', function(){})
        document.addEventListener('mouseup', function() {
            if(curCol){
                curCol = undefined
                pageX = undefined
                curColWidth = undefined
            }
        })
    }
    function createDiv() {
        let div = document.createElement('div')
        div.style.top = 0
        div.style.right = 0
        div.style.width = '7px'
        div.style.borderRadius = '5px 5px 5px 5px'
        div.style.position = 'absolute'
        div.style.cursor = 'col-resize'
        div.style.userSelect = 'none'
        div.style.height = '100%'
        div.style.opacity = '0.5'
        return div
    }
}


// export const resizableGrid = (table) => {
//     let row = table.querySelector("thead>tr:last-child")
//     let cols = row.children
//
//     for (let i = 0; i < cols.length; i++) {
//         let div = createDiv()
//         cols[i].append(div.div1)
//         cols[i].style.position = 'sticky'
//         if(i<cols.length-1){
//             cols[i+1].prepend(div.div2)
//             cols[i+1].style.position = 'sticky'
//         }
//         setListeners(div.div1, div.div2)
//     }
//
//     function setListeners(div1, div2) {
//         let pageX, curCol, curColWidth, tableWidth
//
//         div1.addEventListener('mousedown', function(e) {
//             tableWidth = table.offsetWidth
//             curCol = e.target.parentElement
//             pageX = e.pageX
//             curColWidth = curCol.offsetWidth
//         })
//         div2.addEventListener('mousedown', function(e) {
//             tableWidth = table.offsetWidth
//             curCol = e.target.parentElement.previousElementSibling
//             pageX = e.pageX
//             curColWidth = curCol.offsetWidth
//         })
//
//         div1.addEventListener('mouseover', function(e) {
//             e.target.style.backgroundColor = "red"
//             if(e.target.parentElement.nextElementSibling !== null) {
//                 e.target.parentElement.nextElementSibling.firstElementChild.style.backgroundColor = "red"
//             }
//         })
//         div2.addEventListener('mouseover', function(e) {
//             e.target.style.backgroundColor = "red"
//             e.target.parentElement.previousElementSibling.lastElementChild.style.backgroundColor = "red"
//
//         })
//
//         div1.addEventListener('mouseout', function(e) {
//             e.target.style.backgroundColor = ""
//             if(e.target.parentElement.nextElementSibling !== null) {
//                 e.target.parentElement.nextElementSibling.firstElementChild.style.backgroundColor = ""
//             }
//         })
//         div2.addEventListener('mouseout', function(e) {
//             e.target.style.backgroundColor = ""
//             e.target.parentElement.previousElementSibling.lastElementChild.style.backgroundColor = ""
//         })
//
//         document.addEventListener('mousemove', function(e) {
//             if (curCol) {
//                 let diffX = e.pageX - pageX
//                 curCol.style.width = (curColWidth + diffX) + 'px'
//                 table.style.width = tableWidth + diffX + "px"
//             }
//         })
//
//         document.addEventListener('mouseup', function() {
//             curCol = undefined
//             pageX = undefined
//             curColWidth = undefined
//         })
//     }
//     function createDiv() {
//         let div1 = document.createElement('div')
//         div1.style.top = 0
//         div1.style.right = '-5px'
//         div1.style.width = '8px'
//         div1.style.borderRadius = '5px 5px 5px 5px'
//         div1.style.position = 'absolute'
//         div1.style.cursor = 'col-resize'
//         div1.style.userSelect = 'none'
//         div1.style.height = '100%'
//         div1.style.opacity = '0.5'
//         let div2 = document.createElement('div')
//         div2.style.top = 0
//         div2.style.left = '-5px'
//         div2.style.width = '8px'
//         div2.style.borderRadius = '5px 5px 5px 5px'
//         div2.style.position = 'absolute'
//         div2.style.cursor = 'col-resize'
//         div2.style.userSelect = 'none'
//         div2.style.height = '100%'
//         div2.style.opacity = '0.5'
//         return { div1: div1, div2: div2 }
//     }
// }