<template>
  <tr v-for="(item, i) in paginatedItems" :key="item.id">
    <sl-list5-row :item="item" :list5Id="list5Id" :itemI="page*10+i"/>
  </tr>
  <tr v-if="page+1===pagesQnt" :key="'newItem_'+items.length">
    <sl-list5-row :item="newItem" :list5Id="list5Id" :itemI="''" :new-item="newItem"/>
  </tr>
  <tr>
    <td :colspan="fieldsQnt">
      <q-btn round icon="first_page" size="xs" class="q-ma-sm" @click="page=0"/>
      <q-btn round icon="navigate_before" size="xs" class="q-mr-sm" @click="page=page>0?page-1:page"/>
      {{page+1}} of {{pagesQnt}}
       (items: {{items.length}})
      <q-btn round icon="navigate_next" size="xs" class="q-ml-sm" @click="page=page<pagesQnt-1?page+1:page"/>
      <q-btn round icon="last_page" size="xs" class="q-ma-sm" @click="page=pagesQnt-1"/>
    </td>
  </tr>
</template>

<script>
import SlList5Row from "@/components/sl-list5-row";
import {obj} from "@/utils4";

export default {
  name: "sl-list5-rows",
  components: {SlList5Row},
  props: ["list5Id", "subtotalsFields", "subtotalsFieldsS", "subtotalsFieldsFS"],
  data: () => {
    return {
      page: 0
    }
  },
  computed: {
    items(){
      let listFields = this.$store.state.lists[this.list5Id]?.list.data.fields
      return this.$store.state.lists[this.list5Id]?.items.filter(item => {
        let res = true
        for(let fieldId in this.subtotalsFields){
          if(listFields[fieldId].type === "sublist"){
            if(this.subtotalsFieldsFS[fieldId]){
              if(item.data.fieldsFS[fieldId] !== this.subtotalsFieldsFS[fieldId]) res = false
            }
            else{
              if(item.data.fieldsS[fieldId] !== this.subtotalsFieldsS[fieldId]) res = false
            }
          }
          if(listFields[fieldId].type === "fixedsublist"){
            if(item.data.fieldsFS[fieldId] !== this.subtotalsFieldsFS[fieldId]) res = false
          }
          else{
            if(item.data.fields[fieldId] !== this.subtotalsFields[fieldId]) res = false
          }
        }
        return res
      })
    },
    newItem(){
      let list = this.$store.state.lists[this.list5Id].list
      let newItem = obj({}, 'item')
      newItem.id = "new"
      newItem.spaceId = list.spaceId
      newItem.data.listId = list.id

      for(let fieldId in this.subtotalsFields){
        newItem.data.fields[fieldId] = this.subtotalsFields[fieldId]
        newItem.data.fieldsS[fieldId] = this.subtotalsFieldsS[fieldId]
      }
      return newItem
    },
    pagesQnt(){
      return Math.trunc(this.items.length / 10) + 1
    },
    paginatedItems(){
      return this.items.slice(this.page * 10, this.page * 10 + 10)
    },
    fieldsQnt(){
      return this.$store.state.lists[this.list5Id]?.list.data.views[this.$store.state.lists[this.list5Id]?.viewId].data.fieldsOrder.length
    }
  }
}
</script>

<style scoped>

</style>