<template>
  <q-card style="width: 1000px; max-width: 80vw;">
    <q-card-section class="row justify-center">
      <q-scroll-area :style="editorStyle">
        <q-editor id="editorCell" class="cellEditor" v-model="value" :toolbar="toolbar"/>
      </q-scroll-area>
    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn @click="$emit('finished')">Cancel</q-btn>
      <q-btn @click="save()">Save</q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import {fieldValSave} from "@/utils4"

export default {
  name: "sl-list-editor",
  props: ["space", "list", "view", "item", "fieldId"],
  // inject: ["currentUserPhoneNumber"],
  data: () => {
    return {
      toolbar: [
        ['left', 'center', 'right', 'justify'],
        ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
        ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
        ['token', 'hr', 'link', 'custom_btn'],
        ['print', 'fullscreen'],
        ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
      ],
      value: "",
      editorStyle: {}
    }
  },
  created() {
    this.value = this.item.data.fields[this.fieldId]
    this.editorStyle = {width: '1000px', height: (window.innerHeight - 300) +'px'}
  },
  mounted() {
    setInterval(() => {
      let els = this.$el.querySelectorAll("td")
      els.forEach(el => {
        el.style.border = "1px solid grey"
        el.style.padding = "3px"
      })
    }, 1000)
  },
  methods: {
    save(){
      fieldValSave({
        list: this.list,
        view: this.view,
        item: this.item,
        fieldId: this.fieldId,
        val: this.value,
        userId: this.$store.state.currentUser.userId
      })
      this.$emit("finished")
    }
  }
}
</script>

<style scoped>

</style>