<template>
  <table style="_border-collapse: collapse; background-color: gray; width: 400px; height: 400px" separator="cell">
    <tr>
      <th style="background-color: white; position: sticky; top: 0; left: 0; z-index: 2; min-width: 100px">0</th>
      <th v-for="h in 5" :key="h" style="background-color: white; position: sticky; top: 0; min-width: 50px">{{h}}</th>
    </tr>
    <tr v-for="i in 3000" :key="i">
      <td style="background-color: yellow; position: sticky; left: 0">0</td>
      <test-cell1 v-for="j in 5" :key="j" :i="i" :j="j"/>
      <!--      <td v-for="j in 100" :key="j">{{i+":"+j}}</td>-->
    </tr>
  </table>
</template>

<script>
import TestCell1 from "@/components/test-cell1";
export default {
  name: "test-table1",
  components: {TestCell1},
  mounted() {
    console.log(this.$el)
    resizableGrid(this.$el)
  },
  methods: {
    showEvent(e){
      alert(e)
    }
  }
}


function resizableGrid(table) {
  var row = table.getElementsByTagName('tr')[0],
      cols = row ? row.children : undefined;
  if (!cols) return;

  //table.style.overflow = 'hidden';

  var tableHeight = table.offsetHeight;

  for (var i = 0; i < cols.length; i++) {
    var div = createDiv(tableHeight);
    cols[i].appendChild(div);
    cols[i].style.position = 'sticky';
    setListeners(div);
  }

  function setListeners(div) {
    // eslint-disable-next-line
    let pageX, curCol, nxtCol, curColWidth, nxtColWidth, tableWidth;

    div.addEventListener('mousedown', function(e) {

      //tableWidth = document.getElementById('tableId').offsetWidth;
      tableWidth = table.offsetWidth;
      curCol = e.target.parentElement;
      nxtCol = curCol.nextElementSibling;
      pageX = e.pageX;

      var padding = paddingDiff(curCol);

      curColWidth = curCol.offsetWidth - padding;
      //  if (nxtCol)
      //nxtColWidth = nxtCol.offsetWidth - padding;
    });

    div.addEventListener('mouseover', function(e) {
      e.target.style.borderRight = '2px solid grey';
    })

    div.addEventListener('mouseout', function(e) {
      e.target.style.borderRight = '';
    })

    document.addEventListener('mousemove', function(e) {
      if (curCol) {
        var diffX = e.pageX - pageX;

        // if (nxtCol)
        //nxtCol.style.width = (nxtColWidth - (diffX)) + 'px';

        curCol.style.width = (curColWidth + diffX) + 'px';
        table.style.width = tableWidth + diffX + "px"
      }
    });

    document.addEventListener('mouseup', function() {
      curCol = undefined;
      nxtCol = undefined;
      pageX = undefined;
      nxtColWidth = undefined;
      curColWidth = undefined
    });
  }

    function createDiv(height) {
    var div = document.createElement('div');
    div.style.top = 0;
    div.style.right = 0;
    div.style.width = '5px';
    div.style.position = 'absolute';
    div.style.cursor = 'col-resize';
    div.style.userSelect = 'none';
    div.style.height = height + 'px';
    return div;
  }

  function paddingDiff(col) {

    if (getStyleVal(col, 'box-sizing') == 'border-box') {
      return 0;
    }

    var padLeft = getStyleVal(col, 'padding-left');
    var padRight = getStyleVal(col, 'padding-right');
    return (parseInt(padLeft) + parseInt(padRight));

  }

  function getStyleVal(elm, css) {
    return (window.getComputedStyle(elm, null).getPropertyValue(css))
  }
}

</script>

<style scoped>

</style>