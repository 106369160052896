<template>
  <td
      style="white-space: pre-wrap; padding: 4px; position: relative; line-height: 1.2; z-index: 1; border-bottom: 1px solid lightgrey"
      @click="$store.commit('listCurrentCellSet', {list5Id: list5Id, itemId: itemId, fieldId: fieldId})"
      @dblclick="cellDblClick()"
      @blur="cellBlur()"
  >
    {{val}}
    <div style="border: 2px solid orange; position: absolute; top: 0; left: 0; z-index: -1; width: 100%; height: 100%;"
         v-if="isCurrentCell && !isEditable"
    />
  </td>
</template>

<script>
import { db } from '@/db'
import {objStatusFieldsSet} from "@/utils4";
export default {
  name: "sl-list5-cell",
  props: ['list5Id', 'itemId', 'fieldId', 'val'],
  data: () => {
    return {
      isEditable: false
    }
  },
  computed: {
    isCurrentCell(){
      let isCurrentCell = false
      // let currentCell = this.$store.state.lists[this.list5Id].currentCell
      // if(currentCell.itemId === this.itemId && currentCell.fieldId === this.fieldId) isCurrentCell = true
      return isCurrentCell
    }
  },
  methods: {
    cellDblClick(){
      this.isEditable = true
      this.$el.contentEditable = true
      this.$el.focus()
    },
    cellBlur(){
      this.isEditable = false
      this.$el.contentEditable = false
      console.log(this.$el.innerText)
      db.table("items").where("id").equals(this.itemId).modify(item => {
        item.data.fields[this.fieldId] = this.$el.innerText
        objStatusFieldsSet(item)
      })
    }
  }
}
</script>

<style scoped>

</style>