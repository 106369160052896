<template>
  <q-card flat>
    <q-card-section>
      <div v-for="(val, prop) in stylePropsList" :key="prop">
        <q-input
            v-model="lStyleProps[prop]"
            :label="prop"
            color="green"
            dense
            v-if="!stylePropsList[prop].values"
        >
          <template v-slot:append v-if="stylePropsList[prop].colorPickerRequired">
            <q-icon name="color_lens" :style="{color: lStyleProps[prop], boxShadow: '0 0 8px grey', borderRadius: '15px'}" class="cursor-pointer" @click="colorPickerOpen(prop)"/>
          </template>
        </q-input>
        <q-select v-model="lStyleProps[prop]" :options="stylePropsList[prop].values" v-if="stylePropsList[prop].values" dense :label="prop" color="green"/>
      </div>

    </q-card-section>

  </q-card>
  <q-dialog v-model="colorPickerShow">
    <q-color v-model="currentColorPickerVal" default-view="palette" no-header-tabs format-model="hex" @update:model-value="colorPickerSelected()"/>
  </q-dialog>
</template>

<script>
import {stylePropsListGet} from "@/utils3"

export default {
  name: "sl-list-style-form",
  props: ["styleProps"],
  data: () => {
    return {
      colorPickerShow: false,
      currentColorPickerProp: "",
      currentColorPickerVal: "",
      lStyleProps: null,
      stylePropsList: {}

    }
  },
  created() {
    this.stylePropsList = stylePropsListGet()
    this.lStyleProps = this.styleProps
    for (let prop in this.stylePropsList) {
      if(!this.lStyleProps[prop]) this.lStyleProps[prop] = ""
    }
  },
  methods: {
    colorPickerOpen(prop){
      this.currentColorPickerProp = prop
      this.currentColorPickerVal = this.lStyleProps[prop]
      this.colorPickerShow = true
    },
    colorPickerSelected(){
      this.lStyleProps[this.currentColorPickerProp] = this.currentColorPickerVal
      this.colorPickerShow = false
    },

  }
}
</script>

<style scoped>

</style>