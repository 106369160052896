<template>
  <q-card>
    <q-card-section>
      table: test6.
      Rows quantity: {{rowsQnt}}
    </q-card-section>
    <q-card-section>
      <q-chip  v-for="color in colors" clickable @click="selArr.push(color)" :key="color" :label="color" :color="selArr.includes(color)?'green-3':'grey-3'"/>
    </q-card-section>
    <q-card-section>
      <q-input v-model="newRowsQnt" label="quantity od rows to add"/>
      <q-btn icon="add_circle" @click="rowsAdd" class="q-ma-sm" round/>
      <q-btn icon="delete" @click="rowsDel" class="q-ma-sm" round/>
    </q-card-section>
    <q-card-section>
      <q-btn dense label="selectAnyOf" @click="selectAnyOf()"/>
      selected rows quantity is <span style="color: blue">{{rowsSelectedQQntAnyOf}}</span>
    </q-card-section>
    <q-card-section>
      <q-btn dense label="selectEachOf" @click="selectEachOf()"/>
      selected rows quantity is <span style="color: blue">{{rowsSelectedQQntEachOf}}</span>
    </q-card-section>
  </q-card>
  <q-dialog v-model="progressShow">
    <q-card>
      <q-card-section>
        <q-circular-progress
            indeterminate
            rounded
            size="50px"
            color="lime"
            class="q-ma-md"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
/* eslint-disable */

import {db} from "@/db"
export default {
  name: "test-t6",
  data: () => {
    return {
      progressShow: false,
      selArr: [],
      rowsQnt: 0,
      rowsSelectedQQntAnyOf: 0,
      rowsSelectedQQntEachOf: 0,
      newRowsQnt: 0,
      colorCombins: [
        ['red', 'green', 'blue'], //0
        ['yellow', 'black', 'pink', 'blue'], //1
        ['pink', 'green', 'yellow', 'white'], //2
        ['green', 'blue'], //3
        ['black', 'green', 'lime', 'orange', 'grey', 'brown'], //4
        ['red', 'violet', 'blue', 'brown'], //5
        ['red', 'lime', 'yellow'], //6
        ['red', 'white'], //7
        ['red', 'pink', 'blue', 'lime', 'orange'], //8
        ['red', 'yellow', 'black', 'green', 'blue'] //9
      ],
      colors: ['red', 'green', 'blue', 'yellow', 'black', 'pink', 'white', 'lime', 'orange', 'grey', 'brown', 'violet'],
      newRow: {
        id: "",
        selField: [],
        desc0: "description"
        // desc1: "Fairy Tail — сёнэн манга Хиро Масимы. Манга публиковалась в еженедельном журнале Weekly Shōnen Magazine издательства Kodansha с августа 2006 года по июль 2017 года. Позже главы были собраны в 63 тома танкобонов. История повествует о приключениях Нацу Драгнила, члена популярной гильдии волшебников.",
        // desc2: "Fairy Tail — сёнэн манга Хиро Масимы. Манга публиковалась в еженедельном журнале Weekly Shōnen Magazine издательства Kodansha с августа 2006 года по июль 2017 года. Позже главы были собраны в 63 тома танкобонов. История повествует о приключениях Нацу Драгнила, члена популярной гильдии волшебников.",
        // desc3: "Fairy Tail — сёнэн манга Хиро Масимы. Манга публиковалась в еженедельном журнале Weekly Shōnen Magazine издательства Kodansha с августа 2006 года по июль 2017 года. Позже главы были собраны в 63 тома танкобонов. История повествует о приключениях Нацу Драгнила, члена популярной гильдии волшебников.",
        // desc4: "Fairy Tail — сёнэн манга Хиро Масимы. Манга публиковалась в еженедельном журнале Weekly Shōnen Magazine издательства Kodansha с августа 2006 года по июль 2017 года. Позже главы были собраны в 63 тома танкобонов. История повествует о приключениях Нацу Драгнила, члена популярной гильдии волшебников.",
        // desc5: "Fairy Tail — сёнэн манга Хиро Масимы. Манга публиковалась в еженедельном журнале Weekly Shōnen Magazine издательства Kodansha с августа 2006 года по июль 2017 года. Позже главы были собраны в 63 тома танкобонов. История повествует о приключениях Нацу Драгнила, члена популярной гильдии волшебников.",
      }
    }
  },
  async created() {
    this.rowsQnt = await db.test6.count()
  },
  methods: {
    rowsAdd(){
      let bulkArr = []
      let colorIndex = 0
      let idPrefix = Date.now()
      for(let i=0; i<this.newRowsQnt; i++){
        colorIndex = Math.floor(Math.random() * 10)
        this.newRow.id = idPrefix+"-"+i
        this.newRow.selField = this.colorCombins[colorIndex]
        bulkArr.push(JSON.parse(JSON.stringify(this.newRow)))
      }
      this.progressShow = true
      db.test6.bulkPut(bulkArr).then(() => {
        this.progressShow = false
      })
    },
    rowsDel(){
      this.progressShow = true
      db.test6.clear().then(() => {
        this.progressShow = false
      })
    },
    async selectAnyOf(){
      // debugger
      // let rows = await db.test6.where("selField").anyOf(this.selArr).distinct().toArray()
      let rows = await db.test6.filter(row => {
        let result = false
        this.selArr.forEach(el => {
          if (!row.selField.includes(el)) result = true
        })
        return result
      }).toArray()
      this.rowsSelectedQQntAnyOf = rows.length
    },
    async selectEachOf(){
      // debugger
      let rows = await db.test6.filter(row => {
        let result = true
        this.selArr.forEach(el => {
          if (!row.selField.includes(el)) result = false
        })
        return result
      }).toArray()
      this.rowsSelectedQQntEachOf = rows.length
    }
  }
}
</script>

<style scoped>

</style>