<template>
  <tr @mouseover="buttonShow=true" @mouseout="buttonShow=false" :style="{backgroundColor: buttonShow?'#f6f6f6':''}">
    <td style="width: 25px; background-color: inherit; padding: 5px">
      <q-icon class="_q-ma-sm" color="red-10"
              :name="isOpened ? 'arrow_drop_down' : 'arrow_right'"
              round dense size="xs"
              v-if="valType==='array' || valType==='object'"
              @click="isOpened=!isOpened" style="cursor: pointer"
              />
    </td>
    <td style="_overflow: hidden; overflow-wrap: break-word; max-width: 500px; background-color: inherit; padding: 5px">
      <span style="margin-right: 10px; font-weight: bold">{{prop.toString()}}:</span>
      <span v-if="valType==='array'">[ ] ({{val.length}})</span>
      <span v-if="valType==='object'">{ } ({{Object.keys(val).length}})</span>
      <span v-if="valType==='string'" class="text-blue-8">{{valToDisplay}}</span>
      <q-btn class="q-ml-sm" icon="subject" color="orange" size="xs" round dense flat v-show="valType==='string' && val.length>100" @click="stringFormShow=true"/>
      <span v-if="valType==='other'" class="text-blue-8">{{val}}</span>
      <q-btn class="q-ml-lg" icon="edit" color="red-10" size="xs" round dense flat v-show="buttonShow&&valType==='string'" @click="valFormShow=true"/>
      <q-btn class="q-ml-lg" icon="add" color="red-10" size="xs" round dense flat v-show="buttonShow&&(valType==='object'||valType==='array')" @click="newElFormOpen()"/>
      <q-btn class="q-ml-sm" icon="delete" color="red-10" size="xs" round dense flat v-show="buttonShow" @click="del()"/>
      <q-btn class="q-ml-sm" icon="keyboard_double_arrow_up" color="red-10" size="xs" round dense flat v-show="buttonShow&&upperType==='array'"/>
      <q-btn class="q-ml-sm" icon="keyboard_double_arrow_down" color="red-10" size="xs" round dense flat v-show="buttonShow&&upperType==='array'"/>

    </td>
  </tr>
  <tr v-if="isOpened">
    <td colspan="2" class="q-pl-lg">
    <table border="0" style="width: 510px">
      <db-prop :val="v ? v : ''" :prop="p" v-for="(v, p) in val" :key="p"
               :upperType="valType"
               :propPath="[...propPath, prop]"
               :tableName="tableName"
               :rowId="rowId"
      />
    </table>
    </td>
  </tr>
  <q-dialog v-model="valFormShow">
    <q-card>
      <q-card-section>
        <q-input v-model="valFormValue" :label="'value (prop: '+prop+')'" dense autofocus/>
      </q-card-section>
      <q-card-actions class="justify-center">
        <q-btn flat @click="valFormShow=false">Cancel</q-btn>
        <q-btn flat @click="valSave()">Save</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="newElFormShow">
    <q-card>
      <q-card-section>
        <q-input v-model="newElFormProp" label="property" dense v-if="valType==='object'"/>
        <q-radio v-model="newElFormType" val="other" label="String/Num"/>
        <q-radio v-model="newElFormType" val="object" label="Object"/>
        <q-radio v-model="newElFormType" val="array" label="Array"/>
      </q-card-section>
      <q-card-actions class="justify-center">
        <q-btn flat @click="newElFormShow=false">Cancel</q-btn>
        <q-btn flat @click="newElSave()">Save</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="stringFormShow">
    <q-card>
      <q-card-section><q-scroll-area style="height: 200px; width: 300px;">
        <div class="text-blue-8" style="width: 300px; word-wrap: break-word">{{val}}</div>
      </q-scroll-area></q-card-section>
      <q-card-actions class="justify-center">
        <q-btn flat @click="stringFormShow=false" label="Ok"/>
      </q-card-actions>
    </q-card>

  </q-dialog>
</template>

<script>
import DbProp from "@/components/db-prop"
import { db } from '@/db'
import {objStatusFieldsSet} from "@/utils4";
export default {
  name: "db-prop",
  components: {DbProp},
  props: ["prop", "val", "upperType", "propPath", "tableName", "rowId"],
  data: () => {
    return {
      isOpened: false,
      buttonShow: false,
      valFormShow: false,
      valFormValue: "",
      newElFormShow: false,
      newElFormProp: "",
      newElFormType: "other",
      stringFormShow: false
    }
  },
  created() {
    this.valFormValue = this.val
  },
  computed: {
    valType(){
      let valType = "other"

      if(typeof this.val === 'object' && Array.isArray(this.val)){
        valType = "array"
      }
      else if(typeof this.val === 'object'){
        valType = "object"
      }
      else if(typeof this.val === 'string' || this.val instanceof String || typeof this.val === 'number'){
        valType = "string"
      }
      return valType
    },
    valToDisplay(){
      let valToDisplay = this.val
      if(typeof this.val === 'string' || this.val instanceof String){
        if(this.val.length > 100){
          valToDisplay = this.val.substring(0, 100)
        }
      }
      return valToDisplay
    }
  },
  methods: {
    valSave(){
      let that = this
      let table = db.table(this.tableName)
      table.where("id").equals(this.rowId).modify(row => {
        let prop = row
        that.propPath.forEach(el=>{
          prop=prop[el]
        })
        prop[that.prop] = that.valFormValue
        if(this.tableName === "spaces" || this.tableName === "lists" || this.tableName === "items"){
          debugger
          objStatusFieldsSet(row)
        }
      })

      this.valFormShow = false
    },
    del(){
      if(!confirm("Do you really want to delete?")) return
      let that = this
      debugger
      if(this.upperType === "object"){
        db.table(this.tableName).where("id").equals(this.rowId).modify(row => {
          let prop = row
          that.propPath.forEach(el=>{
            prop=prop[el]
          })
          delete prop[that.prop]
          if(this.tableName === "spaces" || this.tableName === "lists" || this.tableName === "items"){
            debugger
            objStatusFieldsSet(row)
          }
        })
      }
      if(this.upperType === "array"){
        db.table(this.tableName).where("id").equals(this.rowId).modify(row => {
          let prop = row
          that.propPath.forEach(el=>{
            prop=prop[el]
          })
          prop.splice(that.prop, 1)
          if(this.tableName === "spaces" || this.tableName === "lists" || this.tableName === "items"){
            debugger
            objStatusFieldsSet(row)
          }
        })
      }
    },
    newElFormOpen(){
      this.newElFormShow = true
      this.newElFormProp = ""
      this.newElFormType = "other"
    },
    newElSave(){
      if (this.valType === "object" && this.newElFormProp === "") return
      let that = this
      let val
      if(this.newElFormType === "other") val = ""
      if(this.newElFormType === "object") val = {}
      if(this.newElFormType === "array") val = []
      debugger
      if(this.valType === "object"){
        db.table(this.tableName).where("id").equals(this.rowId).modify(row => {
          let prop = row
          that.propPath.forEach(el=>{
            prop=prop[el]
          })
          debugger
          prop[that.prop][that.newElFormProp] = val
          if(this.tableName === "spaces" || this.tableName === "lists" || this.tableName === "items"){
            debugger
            objStatusFieldsSet(row)
          }
        })
      }
      if(this.valType === "array"){
        db.table(this.tableName).where("id").equals(this.rowId).modify(row => {
          let prop = row
          that.propPath.forEach(el=>{
            prop=prop[el]
          })
          prop[that.prop].push(val)
          if(this.tableName === "spaces" || this.tableName === "lists" || this.tableName === "items"){
            debugger
            objStatusFieldsSet(row)
          }
        })
      }
      this.newElFormShow = false
    }
  }
}
</script>

<style scoped>

</style>