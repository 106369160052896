<template>
  <div class="row items-center no-wrap" style="padding: 10px;">
    <div style="margin-right: 10px; margin-bottom: 10px" v-if="false">
      <q-avatar size="45px" color="grey-5" text-color="white" v-if="space.$data.isEncrypted && !space.cryptoKey">
        <q-icon name="lock" color="orange-lighten-5"/>
      </q-avatar>

      <q-avatar size="45px" color="grey-5" text-color="white" v-else-if="space.data.name">
        <img :src="space.data.avatarImg" v-if="space.data.avatarImg">
        <span v-else>{{ space.data.name.charAt(0) }}</span>
      </q-avatar>
    </div>
    <div class="row items-center justify-center">
      <div class="font-weight-bold">
        <q-btn flat dense round color="grey"
               @click="$emit('drawer')"
               aria-label="Menu"
               icon="menu"
        />
        <q-icon color="grey" name="arrow_right"/>
      </div>
      <div class="q-ma-xs font-weight-bold">
        {{ space.data.name }}
        <q-icon color="grey" name="arrow_right"/>
      </div>
      <div class="q-ma-xs">
        {{ list.data.name }}
        <q-icon color="grey" name="arrow_right"/>
      </div>
      <div class="q-ma-xs">
        {{ view.data.name }}
      </div>
<!--      <div class="q-ma-xs">-->
<!--        <span class="q-mx-sm"> </span>-->
<!--      </div>-->
      <div class="q-ma-xs q-mx-md">
        <q-btn icon="layers" round size="sm" class="q-ma-xs q-mr-sm" @click="viewsFormShow=true"
               text-color="grey-lighten-1"
        />
        <q-btn icon="settings" round size="sm" class="q-ma-xs q-mr-sm" @click="viewFormShow=true"
               text-color="grey-lighten-1"
        />
        <span v-if="view._isChangedByUser">
          <q-btn icon="save" round size="sm" dense flat class="q-ma-xs" @click="viewSave()" color="red"
                 text-color="grey-lighten-1"/>
          <!--        <span style="font-size: 10px; color: red"> or </span>-->
          <!--        <q-btn icon="cancel" round size="xs" dense flat class="q-ma-xs" @click="viewRefresh()" color="red"/>-->
        </span>
        <q-btn icon="file_download" round size="sm" class="q-ma-xs q-mr-sm" @click="uploadFileFormShow=true"
               text-color="grey-lighten-1"/>
        <q-btn :icon="outlinedAnalytics" round size="sm" class="q-ma-xs q-mr-sm rotate-90" text-color="grey-lighten-1"
               @click="$emit('addonSwitch')"
               v-if="view.data.addonParams?.addonId"
        />
        <q-btn :icon="outlinedFavorite" round size="sm" class="q-ma-xs q-mr-sm" text-color="grey-lighten-1"/>

        <q-btn _color="purple" icon="forum" round size="sm" _dense class="q-ma-xs q-mr-sm"
               text-color="grey-lighten-1">
          <q-badge floating rounded transparent>2</q-badge>
        </q-btn>
        <q-btn icon="list" round size="sm" class="q-ma-xs q-mr-sm" text-color="grey-lighten-1"
        @click="tasksOfObjShow=true">
          <q-badge floating rounded transparent>2</q-badge>
        </q-btn>

      </div>
    </div>


    <q-dialog v-model="viewsFormShow" v-if="viewsFormShow">
      <sl-list-header-viewslist :list="list" :viewId="view.id" @finished="viewsFormShow=false"/>
    </q-dialog>
    <q-dialog v-model="viewFormShow" :maximized="$q.platform.is.mobile">
      <sl-list-view-form :list="list" :view="view" @finished="viewFormShow=false"/>
    </q-dialog>
    <q-dialog v-model="viewsShow">
      <sl-list-views :list="list" @finished="viewsShow=false" v-if="viewsShow"/>
    </q-dialog>
    <q-dialog v-model="uploadFileFormShow">
      <sl-list-upload-form :list="list" :view="view" @finished="uploadFileFormShow=false" v-if="uploadFileFormShow"/>
    </q-dialog>
    <q-dialog v-model="tasksOfObjShow">
      <sl-tasks-of-obj :spaceId="space.id" :listId="list.id"/>
    </q-dialog>
  </div>
</template>


<!--      <span v-if="view!==undefined">{{view.data.name}}</span>-->
<!--      <span v-else> Default view</span>-->
<!--      <q-btn icon="arrow_drop_down" round size="xs" dense flat class="q-ma-xs">-->
<!--          <q-menu max-height="130px">-->
<!--            <q-list style="min-width: 100px">-->
<!--              <q-item class="font-weight-bold bg-yellow-2" dense>My views</q-item>-->
<!--              <q-item clickable dense v-for="userView in userViews" :key="userView.id" @click="viewSelect(userView.id)">{{userView.name}}</q-item>-->
<!--              <q-item class="font-weight-bold bg-yellow-2" dense>General views</q-item>-->
<!--              <q-item dense v-if="generalViews.length===0">Empty</q-item>-->
<!--              <q-item clickable dense v-for="genView in generalViews" :key="genView.id" @click="viewSelect(genView.id)">{{genView.name}}</q-item>-->
<!--              <q-item class="font-weight-bold bg-yellow-2" dense>Others</q-item>-->
<!--              <q-item clickable dense @click="viewSelect('')">All fields (unformatted view)</q-item>-->
<!--              <q-item clickable dense @click="viewsShow=true">Manage views...</q-item>-->

<!--            </q-list>-->
<!--          </q-menu>-->
<!--      </q-btn>-->
<script>
// import {db} from "@/db";
import {outlinedAnalytics, outlinedFavorite} from '@quasar/extras/material-icons-outlined'
import {useQuasar} from 'quasar'
import SlListViewForm from "@/components/sl-list-view-form";
import {paramsGet} from "@/utils";
import SlListViews from "@/components/sl-list-views";
import SlListUploadForm from "@/components/sl-list-upload-form";
import {currentUserId, db} from "@/db";
// import {objStatusFieldsSet} from "@/utils4";
import SlListHeaderViewslist from "@/components/sl-list-header-viewslist";
import {objStatusFieldsSet} from "@/utils4";
import SlTasksOfObj from "@/components/sl-tasks-of-obj";


export default {
  name: "sl-list-header",
  components: {SlTasksOfObj, SlListHeaderViewslist, SlListUploadForm, SlListViews, SlListViewForm},
  props: ["space", "list", "view", "isDrilldownList"],
  // inject: ["currentUserPhoneNumber"],
  emits: ["addonSwitch"],
  data: () => {
    return {
      outlinedAnalytics: null,
      outlinedFavorite: null,
      viewFormShow: false,
      viewFormFullscreen: false,
      user: null,
      // userViews: [],
      // generalViews: [],
      viewsShow: false,
      uploadFileFormShow: false,
      viewsFormShow: false,
      tasksOfObjShow: false
    }
  },
  setup() {
    const $q = useQuasar()

    $q.platform.is.mobile
  },
  async created() {
    this.outlinedAnalytics = outlinedAnalytics
    this.outlinedFavorite = outlinedFavorite
    // let that = this
    this.user = await paramsGet("user")
    // db.spaces.get(this.spaceId)
    //     .then((el)=>{
    //       that.space = el
    //     })
    // this.userViews = this.list.views.filter(el=>{ return el.owner===that.user.phoneNumber || el.owner===that.user.email } )
    // this.generalViews = this.list.views.filter(el=>{ return el.owner==='admin' } )
  },
  computed: {
    userViews() {
      if (!this.list.views) return []
      let that = this
      return this.list.views.filter(el => {
        return el.owner === that.user.phoneNumber || el.owner === that.user.email
      })
    },
    generalViews() {
      if (!this.list.views) return []
      return this.list.views.filter(el => {
        return el.owner === 'admin'
      })
    }
  },
  methods: {
    viewSelect(viewId) {
      if (viewId === "") {
        this.$router.push({name: 'list', params: {spaceId: this.space.id, listId: this.list.id}})
      }
      else {
        this.$router.push({name: 'list', params: {spaceId: this.space.id, listId: this.list.id, viewId: viewId}})
      }

    },
    viewSave() {
      // debugger
      let data = JSON.parse(JSON.stringify(this.view._data))
      // if view changes are saving for drilldown list filters must be excluded from saving
      if (this.isDrilldownList) {
        for (let fieldId in data.fields) {
          if (this.view.data.fields[fieldId].filter) {
            data.fields[fieldId].filter = JSON.parse(JSON.stringify(this.view.data.fields[fieldId].filter))
          }
          else if (data.fields[fieldId].filter) {
            delete data.fields[fieldId].filter
          }
        }
      }
      // db.views.where('id').equals(this.view.id).modify(v => {
      //     v.data = data
      // })

      let that = this
      db.lists.where('id').equals(this.list.id).modify(l => {
        // debugger
        l.data.views[that.view.id].data = data
        objStatusFieldsSet(l, currentUserId)
      })

      let lView = this.view
      lView._isChangedByUser = false
      this.$emit("refreshView")
    },
    viewRefresh() {
      this.$emit("refreshView")
      let lView = this.view
      lView._isChangedByUser = false
    }

  }
}
</script>

<style scoped>

</style>