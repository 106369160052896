<template>
<q-card>
  <q-card-actions >
    <div class="row" _style="display: inline">
    <q-input v-model="columns" label="columns" dense class="q-ma-xs" style="width: 100px"/>
    <q-input v-model="rows" label="rows" dense class="q-ma-xs" style="width: 100px"/>
    <q-input v-model="amount" label="amount" dense class="q-ma-xs" style="width: 100px"/>
    </div>
    <q-btn label="calculate 1" @click="calculate1()" size="md" dense/>
    <q-btn label="calculate 2" @click="calculate2()" size="md" dense/>
  </q-card-actions>
  <q-card-section>
    <q-circular-progress indeterminate rounded size="24px" color="lime" v-if="status==='calculating'"/>
    <span v-else style="font-size: 24px; font-weight: bold">{{result}}</span>
    <span style="font-size: 24px; color: grey; margin-left: 10px">({{duration}} sec.)</span>
  </q-card-section>

</q-card>
</template>

<script>
/* eslint-disable */
export default {
  name: "test-t5",
  data: () => {
    return {
      columns: 1,
      rows: 1,
      amount: 100,
      result: 0,
      duration: 0,
      status: ""
    }
  },
  created() {
    this.result = this.columns * this.rows * this.amount
  },
  methods: {
    calculate1(){
      let ts = Date.now()
      let result = 0
      this.status = "calculating"
      for(let n=0; n<this.columns; n++){
        for(let m=0; m<this.rows; m++){
          result = result + this.amount
        }
      }
      this.status = ""
      this.duration = ( Date.now() - ts ) / 1000
      this.result = result
    },
    calculate2(){
      let ts = Date.now()
      let result = 0
      let obj = {}
      this.status = "calculating"
      for(let n=0; n<this.columns; n++){
        obj[n] = {}
        for(let m=0; m<this.rows; m++){
          obj[n][m] = this.amount
        }
      }
      for(let n in obj){
        for(let m in obj[n]){
          result = result + obj[n][m]
        }
      }
      this.status = ""
      this.duration = ( Date.now() - ts ) / 1000
      this.result = result
    }
  }
}
</script>

<style scoped>

</style>