<template>
  <q-card style="width: 700px; max-width: 80vw;">
    <q-card-section>
      <q-input type="textarea" v-model="text"
               style="background-color: #f1f1f1"
               label="Write down your request, issue, idea"
      />
    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn @click="$emit('finished')">Cancel</q-btn>
      <q-btn @click="gotoMyRequests()">My requests</q-btn>
      <q-btn @click="saveNewRequest()">Send</q-btn>
    </q-card-actions>
    <sl-msgs :msgs="msgs" v-if="msgs.length>0" @finished="msgsClosed()"/>
  </q-card>
</template>

<script>
import SlMsgs from "@/components/sl-msgs"
import {obj, itemCreate} from "@/utils4"
import {slDateFormat, slTimeFormat} from "@/utils3";

export default {
  name: "sl-request-form",
  components: [SlMsgs],
  emits: ["finished"],
  // inject: ["currentUserPhoneNumber"],
  data: () => {
    return {
      msgs: [],
      text: "",
      reqSpaceId: "liyjvs4c8d2",
      reqListId: "liyu571vefi",
      reqIsSent: false
    }
  },
  created() {
    this.text = ""
  },
  methods: {
    saveNewRequest(){
      if(!this.$store.state.currentUser.userId){
        this.msgs.push({
          type: "e",
          text: "To send a request, please, login"
        })
      }
      if(!this.reqSpaceId || !this.reqListId){
        this.msgs.push({
          type: "e",
          text: "Please, check if space 'Smart lists support exists in your space list'"
        })
      }
      let currentTS = Date.now()
      let reqItem = obj({}, "item")
      reqItem.spaceId = this.reqSpaceId
      reqItem.data.listId = this.reqListId
      reqItem.data.fields.f1 = this.text
      reqItem.data.fields.f2 = slDateFormat(currentTS)
      reqItem.data.fields.f3 = slTimeFormat(currentTS)
      reqItem.data.fields.f4 = "new"
      reqItem.data.fields.f5 = this.$store.state.currentUser.userId

      itemCreate({item: reqItem, userId: this.$store.state.currentUser.userId}).then(() => {
        this.msgs.push({
          type: "s",
          text: "Thank you! Your request is sent. You can follow status of your request in space 'Smart list support' in list 'My requests'"
        })
        this.reqIsSent = true
      })

    },
    msgsClosed(){
      this.msgs=[]
      if(this.reqIsSent === true){
        this.text = ""
        this.reqIsSent = true
      }
    },
    gotoMyRequests(){
      this.$router.push({ name: 'list', params: { spaceId: this.reqSpaceId, listId: this.reqListId } })
    }
  }
}
</script>

<style scoped>

</style>