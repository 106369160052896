<template>
  <div>hello router</div>
  <table v-show="false">
    <thead>
      <td>ID</td><td>responsible</td><td>Module</td><td>Description</td>
    </thead>
    <tr v-for="line in $store.state.openIssues" :key="line.id">
      <td @click="edit(line.id, line.id, 'id', 'OpenIssues')">
        {{line.id}}
      </td>
      <td @click="edit(line.id, line.resp, 'resp', 'OpenIssues')">
        {{line.resp}}
      </td>
      <td @click="edit(line.id, line.module, 'module', 'OpenIssues')">
        {{line.module}}
      </td>
      <td @click="edit(line.id, line.des, 'des', 'OpenIssues')">
        {{line.des}}
      </td>
    </tr>
  </table>
  <v-dialog v-model="dialogShow">
    <v-card>
      <v-card-text>
        Table: {{currTable}},
        Line id: {{currLineId}},
        Field: {{currField}}
      </v-card-text>
      <v-textarea v-model="currValue" :autofocus="true"></v-textarea>
      <v-card-actions>
        <v-btn @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-card elevation="0" class="bg-grey-lighten-4 _d-flex">
    <v-card-title class="justify-center">Lists</v-card-title>
    <v-card class="ma-3 bg-grey-lighten-4" width="500" v-for="list in lists" :key="list.id" elevation="0" style="border-radius: 10px">
      <v-card-title class="justify-center" >
        <span style="font-size: 16px; cursor: pointer" @click="dialogListOpen(list)">{{list.name}}</span>
      </v-card-title>


      <sl-list :listId="list.id"/>
      <br/>
    </v-card>
    <v-card-title class="justify-center" >
      <v-btn class="mr-2 ml-2" icon size="x-small" color="green" @click="dialogListOpen()">
        <v-icon size="small" color="white">mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
  </v-card>
  <v-dialog v-model="dialogListShow">
    <v-card class="pa-2" min-width="250">
      <v-card-title class="justify-center">List</v-card-title>
      <v-text-field v-model="listId" :disabled="!listNew" :autofocus="listNew" label="List id"></v-text-field>
      <v-text-field v-model="listName" :autofocus="!listNew" label="List name"></v-text-field>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="listDelete(listId)">Delete</v-btn>
        <v-btn @click="dialogListShow=false">Cancel</v-btn>
        <v-btn @click="listSave()">Save</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {db} from "@/db"
import {useObservable} from "@vueuse/rxjs/index"
import {liveQuery} from "dexie"
import slList from "../components/sl-list"

export default {
  name: "TableView",
  components: {
    slList
  },
  data: function(){
    return {
      currTable: "OpenIssues",
      currLineId: "",
      currField: "",
      currValue: "",
      dialogShow: false,
      dialogListShow: false,
      listId: "",
      listName: "",
      listNew: true

    }
  },
  setup() {
    return {
      db,
      lists: useObservable(
          liveQuery(() => db.lists.toArray())
      )
    };
  },
  methods: {
    edit(lineId, value, field, table){
      this.currTable = table
      this.currLineId = lineId
      this.currField = field
      this.currValue = value
      this.dialogShow = true
    },
    save(){
      let p = {
        table: "openIssues",
        lineId: this.currLineId,
        field: this.currField,
        value: this.currValue
      }
      this.$store.commit("saveOI", p)
      this.currTable = ""
      this.currLineId = ""
      this.currField = ""
      this.currValue = ""
      this.dialogShow = false
    },
    dialogListOpen(list){
      if(list === undefined){
        this.listId = ""
        this.listName = ""
        this.listNew = true
      }
      else{
        this.listId = list.id
        this.listName = list.name
        this.listNew = false
      }
      this.dialogListShow = true
    },
    listSave(){
      // eslint-disable-next-line no-debugger
      // debugger
      if(this.listNew){
        this.listAdd()
      }
      else{
        this.listUpdate()
      }
    },
    async listAdd(){
      try {
        await db.lists.add({
          id: this.listId,
          name: this.listName,
          fields: []
        })
        this.listId = ""
        this.listName = ""
        this.dialogListShow = false
      } catch (error) {
        console.log(error)
      }
    },
    async listUpdate(){
      try {
        await db.lists.update(this.listId, {
          name: this.listName
        })
        this.listId = ""
        this.listName = ""
        this.dialogListShow = false
      } catch (error) {
        console.log(error)
      }
    },
    async listDelete(listId) {
      try {
        await db.lists.delete(listId)
      } catch (error) {
        console.log(error)
      }
    }
  },

}
</script>

<style scoped>

</style>