<template>
  <q-card class="q-my-sm">
    <q-card-section @mouseover="buttonShow=true" @mouseout="buttonShow=false" class="no-margin">
      <q-btn class="_q-ma-sm" :icon="isOpened ? 'arrow_drop_down' : 'arrow_right'" round dense size="xs" @click="isOpened=!isOpened"/>
      {{row.id}}
      <q-btn class="q-ml-lg" icon="add" color="red-10" size="xs" round dense flat v-show="buttonShow" @click="newElFormOpen()"/>
      <q-btn class="q-ml-sm" icon="delete" color="red-10" size="xs" round dense flat v-show="buttonShow" @click="del()"/>
    </q-card-section>
    <div v-if="isOpened" class="q-ml-lg">
      <table border="0" style="width: 510px">
        <db-prop v-for="(val, prop) in row" :key="prop"
                 :val="val" :prop="prop"
                 :tableName="tableName"
                 :propPath="[]"
                 :rowId="row.id"
                 :upperType="'object'"
        />
      </table>
      <br/>
    </div>
    <q-dialog v-model="newElFormShow">
      <q-card>
        <q-card-section>
          <q-input v-model="newElFormProp" label="property" dense/>
          <q-radio v-model="newElFormType" val="other" label="String/Num"/>
          <q-radio v-model="newElFormType" val="object" label="Object"/>
          <q-radio v-model="newElFormType" val="array" label="Array"/>
        </q-card-section>
        <q-card-actions class="justify-center">
          <q-btn flat @click="newElFormShow=false">Cancel</q-btn>
          <q-btn flat @click="newElSave()">Save</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import { db } from '@/db'
import DbProp from "@/components/db-prop";
import {objStatusFieldsSet} from "@/utils4";
export default {
  name: "db-table-row",
  components: {DbProp},
  props: ["row", "tableName"],
  data: () => {
    return {
      isOpened: false,
      buttonShow: false,
      newElFormShow: false,
      newElFormProp: "",
      newElFormType: "other"
    }
  },
  methods: {
    newElFormOpen(){
      this.newElFormShow = true
      this.newElFormProp = ""
      this.newElFormType = "other"
    },
    newElSave(){
      if (this.newElFormProp === "") return
      let that = this
      let val
      if(this.newElFormType === "other") val = ""
      if(this.newElFormType === "object") val = {}
      if(this.newElFormType === "array") val = []

      db.table(this.tableName).where("id").equals(this.row.id).modify(row => {
        row[that.newElFormProp] = val
        if(this.tableName === "spaces" || this.tableName === "lists" || this.tableName === "items"){
          debugger
          objStatusFieldsSet(row)
        }
      })
      this.newElFormShow = false
    },
    del(){
      db.table(this.tableName).where("id").equals(this.row.id).delete()
    }
  }
}
</script>

<style scoped>

</style>