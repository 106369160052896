<template>

    <q-list dense style="min-width: 100px">
<!--      filter-->
      <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnFilter')" @mouseover="showSubmenu('')">
        <q-item-section><q-item-label>Filter</q-item-label></q-item-section>
      </q-item>
<!--      sort-->
      <q-item clickable @mouseover="showSubmenu('sort')">
        <q-item-section><q-item-label>Sort</q-item-label></q-item-section>
        <q-item-section side>
          <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <q-menu v-model="sortSubmenuShow" anchor="top end" self="top start">
          <q-list dense style="min-width: 100px">
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnSortAsc')" :disable="view._data.fields[fieldId].sort==='asc'">
              <q-item-section><q-item-label>Sort</q-item-label></q-item-section>
              <q-item-section><q-item-label><q-icon name="arrow_downward"/></q-item-label></q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnSortDesc')" :disable="view._data.fields[fieldId].sort==='desc'">
              <q-item-section><q-item-label>Sort</q-item-label></q-item-section>
              <q-item-section><q-item-label><q-icon name="arrow_upward"/></q-item-label></q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnSortCancel')" :disable="!view._data.fields[fieldId].sort">
              <q-item-section><q-item-label>Sort</q-item-label></q-item-section>
              <q-item-section><q-item-label><q-icon name="clear"/></q-item-label></q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-item>
<!--      group-->
      <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnGroup')" @mouseover="showSubmenu('')" v-if="!view._data.fields[fieldId].group">
        <q-item-section><q-item-label>Group</q-item-label></q-item-section>
      </q-item>
      <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnUngroup')" @mouseover="showSubmenu('')" v-if="view._data.fields[fieldId].group">
        <q-item-section><q-item-label>Ungroup</q-item-label></q-item-section>
      </q-item>
<!--      calc-->
      <q-item clickable @mouseover="showSubmenu('calc')">
        <q-item-section><q-item-label>Calc</q-item-label></q-item-section>
        <q-item-section side>
          <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <q-menu v-model="calcSubmenuShow" anchor="top end" self="top start">
          <q-list dense style="min-width: 100px">
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnCalcSum')" :disable="view._data.fields[fieldId].calc==='sum'">
              <q-item-section><q-item-label>Sum</q-item-label></q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnCalcMax')" :disable="view._data.fields[fieldId].calc==='max'">
              <q-item-section><q-item-label>Max</q-item-label></q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnCalcMin')" :disable="view._data.fields[fieldId].calc==='min'">
              <q-item-section><q-item-label>Min</q-item-label></q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnCalcAvg')" :disable="view._data.fields[fieldId].calc==='avg'">
              <q-item-section><q-item-label>Average</q-item-label></q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnCalcCancel')" :disable="!view._data.fields[fieldId].calc">
              <q-item-section><q-item-label>Cancel</q-item-label></q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-item>
      <q-separator />
<!--      rename-->
      <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnRename')" @mouseover="showSubmenu('')">
        <q-item-section><q-item-label>Rename</q-item-label></q-item-section>
      </q-item>
<!--      style-->
      <q-item clickable @mouseover="showSubmenu('style')">
        <q-item-section><q-item-label>Style</q-item-label></q-item-section>
        <q-item-section side>
          <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <q-menu v-model="styleSubmenuShow" anchor="top end" self="top start">
          <q-list dense style="min-width: 100px">
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'headCellStyle')">
              <q-item-section><q-item-label>Head cell</q-item-label></q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'headNameStyle')">
              <q-item-section><q-item-label>Head name</q-item-label></q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'cellStyle')">
              <q-item-section><q-item-label>Cell</q-item-label></q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'cellStringStyle')">
              <q-item-section><q-item-label>Cell content</q-item-label></q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-item>
      <q-separator />
      <!--      macros-->
      <q-item clickable @mouseover="showSubmenu('macros')">
        <q-item-section><q-item-label>Macros</q-item-label></q-item-section>
        <q-item-section side>
          <q-icon name="keyboard_arrow_right" />
        </q-item-section>
        <q-menu v-model="macrosSubmenuShow" anchor="top end" self="top start">
          <q-list dense style="min-width: 100px">
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'macrosSet')">
              <q-item-section><q-item-label>Set macros</q-item-label></q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="$emit('menuSelected', 'macrosRun')">
              <q-item-section><q-item-label>Run macros</q-item-label></q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-item>
      <q-separator />
<!--      All properties-->
      <q-item clickable v-close-popup @click="$emit('menuSelected', 'columnProps')" @mouseover="showSubmenu('')">
        <q-item-section><q-item-label>All properties</q-item-label></q-item-section>
      </q-item>
    </q-list>
</template>

<script>

export default {
  name: "sl-list-column-context-menu",
  props: ["list", "view", "fieldId"],
  components: {},
  data: () => {
    return {
      sortSubmenuShow: false,
      calcSubmenuShow: false,
      styleSubmenuShow: false,
      macrosSubmenuShow: false
    }
  },
  created() {
  },
  methods: {
    showSubmenu(currentMenu){
      currentMenu === "sort" ? this.sortSubmenuShow=true : this.sortSubmenuShow=false
      currentMenu === "calc" ? this.calcSubmenuShow=true : this.calcSubmenuShow=false
      currentMenu === "style" ? this.styleSubmenuShow=true : this.styleSubmenuShow=false
      currentMenu === "macros" ? this.macrosSubmenuShow=true : this.macrosSubmenuShow=false
    }
  }
}
</script>

<style scoped>

</style>