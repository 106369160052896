<template>
  <sl-addon-params-view-form-gantt :list="list" :view="view" v-if="addonId==='gantt'"/>
</template>

<script>
import SlAddonParamsViewFormGantt from "@/components/sl-addon-params-view-form-gantt";
export default {
  name: "sl-addon-params-view-form",
  components: {SlAddonParamsViewFormGantt},
  props: ["list", "view", "addonId"],
  data: () => {
    return {
      addonParams: {}
    }
  },
  created() {
    this.addonParams = this.view.data.addonParams
    this.addonParams.addonId = this.addonId
  }
}
</script>

<style scoped>

</style>