<template>
  <q-card>
    <q-card-section>
      <q-chip label="Users" clickable @click="currentList='usersList'"
              :color="currentList === 'usersList' ? 'orange' : ''"
              :text-color="currentList === 'usersList' ? 'white' : ''"
      />
      <q-btn v-if="currentList==='usersList' && usersListId === ''" label="Create users list"
        @click="usersListCreate()"
      />
      <q-chip label="User authorizations" clickable @click="currentList='authsList'"
              :color="currentList === 'authsList' ? 'orange' : ''"
              :text-color="currentList === 'authsList' ? 'white' : ''"
      />
    </q-card-section>
    <q-card-section>
        <sl-list-init :space-id="spaceId" :list-id="usersListId" :view-id="usersListViewId"
                      v-if="currentList === 'usersList' && usersListId !== '' && usersListViewId !==''"/>
        <sl-list-init :space-id="spaceId" :list-id="authsListId" :view-id="authsListViewId"
                      v-if="currentList === 'authsList' && authsListId !== '' && authsListViewId !==''"/>

    </q-card-section>
  </q-card>
</template>

<script>
import {currentUserId, db} from "@/db";
import {useQuasar} from "quasar";
import SlListInit from "@/components/sl-list-init";
import {generateId} from "@/utils";
import {listCreate, obj, objStatusFieldsSet} from "@/utils4";

export default {
  name: "sl-z-users",
  components: {SlListInit},
  props: ["spaceId"],
  data: () => {
    return {
      currentList: "usersList",
      usersListId: "",
      authsListId: "",
      usersListViewId: "",
      authsListViewId: ""
    }
  },
  async mounted(){

    const $q = useQuasar()

    let space = await db.table("spaces").get(this.spaceId)

    this.usersListId = space.data.zListIds.usersListId
    let usersList = await db.table("lists").get(this.usersListId)
    this.usersListViewId = $q.platform.is.mobile ? usersList.data.defaultAdminPhoneViewId : usersList.data.defaultAdminViewId

    this.authsListId = space.data.zListIds.authsListId
    let authsList = await db.table("lists").get(this.authsListId)
    this.authsListViewId = $q.platform.is.mobile ? authsList.data.defaultAdminPhoneViewId : authsList.data.defaultAdminViewId

  },
  methods: {
    usersListCreate(){

      let usersListId = generateId()
      let usersListViewId = generateId()

      let that = this

      listCreate({
        spaceId: this.spaceId,
        listId: usersListId,
        defaultAdminViewId: usersListViewId,
        listName: "*Users",
        currentUserId: currentUserId
      }).then(()=>{

        db.lists.where("id").equals(usersListId).modify(list => {
          list.data.fields.z31 = {id: "z31", name: "Phone number", type: "text"}
          list.data.fields.z32 = {id: "z32", name: "User name", type: "text"}
          list.data.fields.z33 = {id: "z33", name: "User type", type: "sublist", isFixedSublist: "X", fixedSublist: [
              { value: "owner", style: {
                  backgroundColor: "#ffe4b6", color: "#e57c01", padding: "2px 7px", borderRadius: "15px", cursor: "pointer",
                  boxShadow:"0 0 8px lightgrey", display: "inline", whiteSpace: "nowrap"
                }
              },
              { value: "admin", style: {
                  backgroundColor: "#ccffcc", color: "#00a300", padding: "2px 7px", borderRadius: "15px", cursor: "pointer",
                  boxShadow:"0 0 8px lightgrey", display: "inline", whiteSpace: "nowrap"
                }
              },
              { value: "user", style: {
                  backgroundColor: "#ccffff", color: "#00a3a3", padding: "2px 7px", borderRadius: "15px", cursor: "pointer",
                  boxShadow:"0 0 8px lightgrey", display: "inline", whiteSpace: "nowrap"
                }
              }
            ]}  // admin  or user
          list.data.fields.z34 = {id: "z34", name: "Avatar image", type: "thumbnail"}
          list.data.fields.z35 = {id: "z35", name: "Display name", type: "text"}
          list.data.fields.z36 = {id: "z36", name: "Update name from user data", type: "checkbox"}
          list.data.fields.z37 = {id: "z37", name: "Update status", type: "text"} // text info: date, time and status
          list.data.fields.f1.name = "Details/comments"

          let listDefaultAdminView = list.data.views[list.data.defaultAdminViewId].data
          listDefaultAdminView.fields.z31 = obj({id: "z31"}, "viewDataField")
          listDefaultAdminView.fields.z32 = obj({id: "z32"}, "viewDataField")
          listDefaultAdminView.fields.z33 = obj({id: "z33"}, "viewDataField")
          listDefaultAdminView.fields.z34 = obj({id: "z34"}, "viewDataField")
          listDefaultAdminView.fields.z34.cellStringStyle.borderRadius = "50px"
          listDefaultAdminView.fields.z34.cellStringStyle.width = "50px"
          listDefaultAdminView.fields.z34.cellStringStyle.height = "50px"
          listDefaultAdminView.fields.z35 = obj({id: "z35"}, "viewDataField")
          listDefaultAdminView.fields.z35.cellStringStyle.fontWeight = "bold"
          listDefaultAdminView.fields.z36 = obj({id: "z36"}, "viewDataField")
          listDefaultAdminView.fields.z37 = obj({id: "z37"}, "viewDataField")
          listDefaultAdminView.fieldsOrder = ['z1', 'z2', 'z35', 'z36','z33', 'z34', 'z31', 'z32', 'z37', 'f1', 'z3']

          that.usersListId = list.id
          that.usersListViewId = list.data.defaultAdminViewId

        })
      })

      db.table("spaces").where("id").equals(this.spaceId).modify(space => {
        space.data.zListIds.usersListId = usersListId
        objStatusFieldsSet(space)
      })
    }
  }
}
</script>

<style scoped>

</style>