<template>
  <q-card>
    <q-card-section>
      <q-input type="number" v-model="params.width" label="Width (px)" />
      <q-select v-model="params.periods"
                :options="periodsOptions"
                option-value="id"
                option-label="val"
                emit-value
                map-options
                style="min-width: 250px; max-width: 300px"
                label="Periods"
      />
      <q-select v-model="params.taskNameFieldId"
                :options="fieldsOptions"
                option-value="id"
                option-label="val"
                emit-value
                map-options
                style="min-width: 250px; max-width: 300px"
                label="Field of task name"
      />
      <q-select v-model="params.startDateFieldId"
                :options="fieldsOptions"
                option-value="id"
                option-label="val"
                emit-value
                map-options
                style="min-width: 250px; max-width: 300px"
                label="Field of task start"
      />
      <q-select v-model="params.endDateFieldId"
                :options="fieldsOptions"
                option-value="id"
                option-label="val"
                emit-value
                map-options
                style="min-width: 250px; max-width: 300px"
                label="Field of task finish"
      />
      <q-select v-model="params.completionFieldId"
                :options="fieldsOptions"
                option-value="id"
                option-label="val"
                emit-value
                map-options
                style="min-width: 250px; max-width: 300px"
                label="Field of task completion (0 - 100)"
      />
    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn @click="finished()" label="Ok"/>
    </q-card-actions>
  </q-card>

  <sl-msgs :msgs="msgs" v-if="msgs.length>0" @finished="msgs=[]"/>

</template>

<script>
export default {
  name: "sl-addon-params-view-form-gantt",
  props: ["list", "view"],
  emits: ["finished"],
  data: () => {
    return {
      msgs: [],
      params: {},
      periodsOptions: [],
      fieldsOptions: []
    }
  },
  created() {
    let viewData = this.view.data
    if(!viewData.addonParams) viewData.addonParams = {}
    if(!viewData.addonParams.params) viewData.addonParams.params = {}
    viewData.addonParams.paramsIsOk = ""
    this.params = viewData.addonParams.params
    if(!this.params.width) this.params.width = 500
    if(!this.params.periods) this.params.periods = "weeks"
    if(!this.params.taskNameFieldId) this.taskNameFieldId = ""
    if(!this.params.startDateFieldId) this.startDateFieldId = ""
    if(!this.params.endDateFieldId) this.endDateFieldId = ""
    if(!this.params.completionFieldId) this.completionFieldId = ""

    // this.periodsOptions.push({id: "days", val: "Days"})
    this.periodsOptions.push({id: "weeks", val: "Weeks"})
    this.periodsOptions.push({id: "months", val: "Months"})
    // this.periodsOptions.push({id: "quarters", val: "Quarters"})
    // this.periodsOptions.push({id: "years", val: "Years"})

    this.list.data.views[this.view.id].data.fieldsOrder.forEach(fieldId => {
      this.fieldsOptions.push({
        id: fieldId,
        val: this.list.data.fields[fieldId].name + " (" + fieldId + ")"
      })
    })
  },
  methods: {
    finished(){
      if(!this.params.width ||
        !this.params.periods ||
        !this.params.taskNameFieldId ||
        !this.params.startDateFieldId ||
        !this.params.endDateFieldId ||
        !this.params.completionFieldId
      ){
        this.msgs.push({
          type: "e",
          text: "Fill all fields"
        })
        return
      }

      let addonParams = this.view.data.addonParams
      addonParams.paramsIsOk = "X"
      this.$emit('finished')
    }
  }
}
</script>

<style scoped>

</style>