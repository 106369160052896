<template>
  <q-card>
    <q-card-section>
      <q-file v-model="file" label="Standard" />
    </q-card-section>
    <q-card-actions>
      <q-btn @click="upload()">upload</q-btn>
    </q-card-actions>

  </q-card>
</template>

<script>
import readXlsxFile from 'read-excel-file'
import {currentUserId, db} from "@/db"
import {generateId} from "@/utils";
export default {
  name: "sl-list-upload-form",
  props: ["list", "view"],
  data: ()=> {
    return{
      file: null
    }
  },
  methods: {
    upload(){
      debugger
      readXlsxFile(this.file).then((rows) => {
        debugger
        // `rows` is an array of rows
        // each row being an array of cells.
        console.log(rows)

        let itemFields = {}
        let itemFieldsS = {}
        // create fields
        for(let a=0; a<=rows[0].length; a++){
          this.createField('f'+(a*1+1), rows[0][a])
          itemFields['f'+(a*1+1)] = ""
          // itemFieldsS['f'+(a*1+1)] = ""
        }

        let items = []
        for(let i=1; i<rows.length; i++){
          for(let j=0; j<rows[i].length; j++){
            itemFields['f'+(j*1+1)] = rows[i][j]
          }
          items.push({
              id: generateId(),
              spaceId: this.list.spaceId,
              status:  "created",
              $data: {
                version: 0,
                lastUserId: currentUserId,
                lastTS: Date.now(),
              },
              data: {
                listId: this.list.id,
                fields: JSON.parse(JSON.stringify(itemFields)),
                fieldsS: itemFieldsS
              }
          })
        }
        debugger
        let that = this
        db.items.bulkAdd(items).then(()=>{
          that.$emit("finished")
        })
      })
    },
    async createField(fieldId, fieldName){
      if(this.list.data.fields[fieldId]) return

      let field = {id: fieldId, name: fieldName, type: 'text'}
      await db.lists.where('id').equals(this.list.id).modify(list => {
        if(list.data.fields[fieldId]){
          list.data.fields[fieldId].name = fieldName
          list.data.fields[fieldId].type = "text"
        }
        else{
          list.data.fields[fieldId] = field
        }
        list.data.views[this.view.id].data.fields[fieldId] = JSON.parse(JSON.stringify(list.data.views[this.view.id].data.fields["f1"]))
        list.data.views[this.view.id].data.fields[fieldId].id = fieldId //change "id: f1" to "id: fxx"
        list.data.views[this.view.id].data.fieldsOrder.push(fieldId)
      })
    }
  }
}
</script>

<style scoped>

</style>