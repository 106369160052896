<template>
  <q-card flat square>
    <q-card-section>
      <q-chip>Test 1</q-chip>
      <q-chip>Test 2</q-chip>
    </q-card-section>
    <q-card-section>
      <div>{{}}</div>
    </q-card-section>
    <q-card-section>

    </q-card-section>


  Table: 123
    <div style="width: 300px">
      <q-input v-model="formula" dense label="formula"/>
    </div>
    sadfa=
      {{execFunction(formula)}}
    <div>
  <q-scroll-area :style="'height: '+scrollAreaHeight+'px; width: 100%; padding: 5px'" v-if="false">
    <test-table1 v-if="false"/>
    <test-table2 v-if="false" @focusMoved="scrollAreaMove" />
  </q-scroll-area>
      <sl-list4 v-if="false"/>
    </div>
  </q-card>

</template>

<script>



import { db } from '../db'
import TestTable1 from "@/components/test-table1"
import TestTable2 from "@/components/test-table2";
import SlList4 from "@/components/sl-list4";
export default {
  name: "TableTest1View",
  components: {SlList4, TestTable2, TestTable1},
  data: () => {
    return {
      tests: [],
      currentCell: "",
      d: [],
      status: "",
      quantityRows: 0,
      action: "",
      arr: [],
      scrollAreaHeight: 0,
      formula: ""

    }
  },
  mounted() {
    for(let j=0; j<1000;j++){
      this.arr.push({id: j, name: "Name", num: Math.random() })
    }
    let a = this.$el.querySelector('div').getBoundingClientRect()
    this.scrollAreaHeight = window.innerHeight - a.top
  },
  methods: {
    resizable(){
      console.log(this.$el)
      //this.$el.querySelector("span").innerText = "324"
      //resizableGrid()
    },
    execFunction(formula){
      console.log(formula)
    },
    scrollAreaMove(){
      //console.log(pl)
    },
    showQuantityRows(){
      let that = this
      db.testTable.count().then((q)=>{
        that.quantityRows = q
      })
    },
    insertRows(){
      let that = this
      this.status = "processing..."
      let id = Date.now()
      let data = {
        name: "name AAA",
        description: "description AAA, description AAA, description AAA, description AAA"
      }

      let drops = []
      for (let i=0;i<100000;i++) {
        drops.push({id: id+"-"+i, data: data})
      }
      debugger
      console.log(drops)
      db.testTable.bulkAdd(drops).then(function() {
        console.log("Done adding 100,000 raindrops all over the place")
        that.status = "done"
      })

    }


  }
}
</script>

<style scoped>
td {
  border: 0px solid;
}
</style>