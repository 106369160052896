<template>
  <td :ref="i+'_'+j" :id="i+'_'+j"
      :contenteditable="ceditable"
      style="width: 50px; height: 50px; padding: 5px; background-color: white"
  >

    <div v-if="j==3" class="text-center">
      <q-slider
        name="speed"
        v-model="speed"
        track-size="5px"
        color="green"
        _label-always
        :min="0"
        :max="100"
        :step="1"
        thumb-color="orange"
    />

      <q-icon name="arrow_left" size="lg" color="grey" @click="speed--"/>
      {{speed}}
      <q-icon name="arrow_right" size="lg" color="grey" @click="speed++"/>
    </div>
    <q-knob
        show-value
        font-size="12px"
        reverse
        v-model="val1"
        size="40px"
        :thickness="0.32"
        :color="val1_color"
        track-color="grey-3"
        class="q-ma-md"
        v-else-if="j==4"
    >
      {{ val1 }}
    </q-knob>
    <div v-else>
      {{i + " : "+ j}}
      <span>asdf</span>
    </div>

    <q-menu
        touch-position
        context-menu
    >

      <q-list dense style="min-width: 100px">
        <q-item clickable v-close-popup>
          <q-item-section @click="alrt(i,j)">Open...</q-item-section>
        </q-item>
        <q-item clickable v-close-popup>
          <q-item-section @click="setfocus">editable</q-item-section>
        </q-item>
        <q-separator />
        <q-item clickable>
          <q-item-section>Preferences</q-item-section>
          <q-item-section side>
            <q-icon name="keyboard_arrow_right" />
          </q-item-section>

          <q-menu anchor="top end" self="top start">
            <q-list>
              <q-item
                  v-for="n in 3"
                  :key="n"
                  dense
                  clickable
              >
                <q-item-section>Submenu Label</q-item-section>
                <q-item-section side>
                  <q-icon name="keyboard_arrow_right" />
                </q-item-section>
                <q-menu auto-close anchor="top end" self="top start">
                  <q-list>
                    <q-item
                        v-for="n in 3"
                        :key="n"
                        dense
                        clickable
                    >
                      <q-item-section>3rd level Label</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-item>
            </q-list>
          </q-menu>

        </q-item>
        <q-separator />
        <q-item clickable v-close-popup>
          <q-item-section>Quit</q-item-section>
        </q-item>
      </q-list>

    </q-menu>
  </td>
</template>

<script>
// function t(i, j){
//   console.log(document.getElementById(i+'_'+j))
// }
export default {
  name: "test-cell1",
  props: ["i", "j"],
  data: () => {
    return {
      ceditable: false,
      speed: 10,
      val1: 10
    }
  },
  computed: {
    val1_color(){
      let val1_color = ""
      if(this.val1>0 && this.val1<=30) val1_color = "red"
      if(this.val1>30 && this.val1<=70) val1_color = "yellow"
      if(this.val1>70 && this.val1<=100) val1_color = "green"
      return val1_color
    }
  },
  methods: {
    alrt(i, j){
      console.log(i+":"+j)
    },

    setfocus(){
      this.ceditable=!this.ceditable
      this.$el.querySelector("span").innerText = "324"
      this.$el.style.backgroundColor = "blue"
      //this.$refs[this.i+'_'+this.j].focus();
      //console.log(this.$refs[this.i+'_'+this.j])
     //document.getElementById(this.i+'_'+this.j).focus()
      //t(this.i, this.j)
      // let t = this.$el.querySelector("span").innerText
      // console.log(t)
      // console.log(this.$el)
      // this.$el.focus

    }
  }

}
</script>

<style scoped>

</style>