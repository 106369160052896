<template>
  <sl-list6 :space="space" :listId="listId" v-if="space.id && listId"/>
</template>

<script>
import {db} from '@/db'
import SlList6 from "@/components/sl-list6"

export default {
  name: "test-t12",
  components: {SlList6},
  data: () => {
    return {
      space: {},
      listId: ""
    }
  },
  async created(){
    this.listId = "ltghulgou2m" // ltju196s1aa ltghulgou2m
    this.space = await db.table("spaces").get("ltgfvtmzi6w")
  }
}
</script>

<style scoped>

</style>