<template>
  <q-card>
    <q-date v-model="dateVal" color="green-4"
            first-day-of-week="1"
            v-if="type==='date'"
    >
      <div class="row items-center justify-center q-gutter-sm">
        <q-btn label="Cancel" @click="$emit('finished')"/>
        <q-btn label="OK" @click="save()"/>
      </div>
    </q-date>
    <q-time v-model="timeVal" color="green-4"
            format24h
            v-if="type==='time'"
    >
      <div class="row items-center justify-center q-gutter-sm">
        <q-btn label="Cancel" @click="$emit('finished')"/>
        <q-btn label="OK" @click="save()"/>
      </div>
    </q-time>
  </q-card>
</template>

<script>
import {db} from "@/db";
import {objStatusFieldsSet} from "@/utils4";
import {generateId} from "@/utils";

export default {
  name: "sl-list-cell-form-datetime",
  props: ["list5Id", "itemId", "fieldId", "newItem"],
  data: () => {
    return {
      type: "",
      dateVal: "",
      timeVal: ""
    }
  },
  created() {
    this.type = this.$store.state.lists[this.list5Id].list.data.fields[this.fieldId].type
    let item = this.$store.state.lists[this.list5Id].items.find(item => item.id === this.itemId)
    let val = item?.data.fields[this.fieldId]
    if(this.type === "date"){
      if(val){
        this.dateVal = val
      }
      else{
        let currentDate = new Date()
        let curY = currentDate.getFullYear()
        let curM = currentDate.getMonth()+1
        let curD = currentDate.getDate()
        if(curM<10) curM = "0"+curM
        if(curD<10) curD = "0"+curD
        this.dateVal = curY + "/" + curM + "/" + curD
        debugger
      }
    }
    if(this.type === "time"){
      if(val){
        this.timeVal = val
      }
      else{
        let currentDate = new Date()
        let curH = currentDate.getHours()
        let curM = currentDate.getMinutes()
        if(curH<10) curH = "0"+curH
        if(curM<10) curM = "0"+curM
        this.timeVal = curH + ":" + curM
        debugger
      }
    }

  },
  methods: {
    save(){
      debugger
      let val = this.type === "date" ? this.dateVal : this.timeVal
      if(this.itemId === "new"){
        let newItemPut = this.newItem
        newItemPut.id = generateId()
        let listFields = this.$store.state.lists[this.list5Id].list.data.fields
        for(let fieldId in listFields){
          if(listFields[fieldId].type === "fixedsublist" || listFields[fieldId].type === "sublist"){
            delete newItemPut.data.fields[fieldId]
            if(listFields[fieldId].sublistBasedOnFieldId) delete newItemPut.data.fieldsS[fieldId]
          }
          else{
            delete newItemPut.data.fieldsS[fieldId]
          }
        }
        db.table("items").put(newItemPut).then(itemId => {
          db.table("items").where("id").equals(itemId).modify(item => {
            item.data.fields[this.fieldId] = val
            objStatusFieldsSet(item)
            this.$emit("finished")
          })
        })
      }
      else {
        db.table("items").where("id").equals(this.itemId).modify(item => {
          item.data.fields[this.fieldId] = val
          objStatusFieldsSet(item)
          this.$emit("finished")
        })
      }
    }
  }
}
</script>

<style scoped>

</style>