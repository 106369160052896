import { createRouter, createWebHistory } from 'vue-router'
import ListView from './views/ListView.vue'
import List5View from './views/List5View.vue'
import ZUsersView from './views/ZUsersView.vue'
import TableView from './views/TableView.vue'
import TableTest1View from './views/TableTest1View.vue'
import SpaceView from './views/SpaceView.vue'
import UserAuthFinish from './views/UserAuthFinish.vue'
import OverView from "@/views/OverView"
import DbViewer from "@/views/DbViewer"
import TracerParams from "@/views/TracerParams"
import TestsOverview from "@/views/TestsOverview"

//import HelloWorld from './views/HelloWorld.vue'

const routes = [
    {
        path: '/',
        name: 'overview',
        component: OverView
    },
    {
        path: '/space/:spaceId',
        name: 'space',
        component: SpaceView
    },
    {
        path: '/zusers/:spaceId',
        name: 'zusers',
        component: ZUsersView
    },
    {
        path: '/list/:spaceId/:listId/:viewId?',
        name: 'list',
        component: ListView
    },
    {
        path: '/list5/:spaceId/:listId/:viewId?',
        name: 'list5',
        component: List5View
    },
    {
        path: '/table',
        name: 'table',
        component: TableView
    },
    {
        path: '/testsOverview',
        name: 'testsOverview',
        component: TestsOverview
    },
    {
        path: '/table_test1',
        name: 'table_test1',
        component: TableTest1View
    },
    {
        path: '/userAuthFinish',
        name: 'userAuthFinish',
        component: UserAuthFinish
    },
    {
        path: '/dbviewer',
        name: 'dbviewer',
        component: DbViewer
    },
    {
        path: '/tracerParams',
        name: 'tracerParams',
        component: TracerParams
    },
    // {
    //     path: '/',
    //     name: 'helloWorld',
    //     component: HelloWorld
    // },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ './views/AboutView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router