import {createStore} from 'vuex'
import {getFirestore, collection, query, where, getDocs, setDoc, doc} from "firebase/firestore"
import {currentUserId, db} from './db';
import 'dexie-observable';


// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
// import {obj} from "@/utils4";
// import {decrypt} from "@/utils";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDq7oeMHMZjXdfXPGdB1o517ebGI3t9_9M",
  authDomain: "slists-e213b.firebaseapp.com",
  projectId: "slists-e213b",
  storageBucket: "slists-e213b.appspot.com",
  messagingSenderId: "264683204633",
  appId: "1:264683204633:web:d6c93e8d83b8b7ae67ba88",
  measurementId: "G-X9RMPBNNV5"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const app = initializeApp(firebaseConfig)
// Initialize Cloud Firestore and get a reference to the service
const fs = getFirestore(app);

// const listeners = {}

export default createStore({
  state() {
    return {
      // currentUserId: "",
      currentUser: null,
      authUser: null,
      connectionStatus: "",
      screen: { width: 0, height: 0, mode: "" },
      // initialSpacesListenerDone: false,
      lists: {},
      openedLists: {currentKey: "", lists: []},
      sublists: {},
      footerEditorIsOpen: false,
      footerEditorObj: {},
      footerEditorUserId: "",
      footerEditorTS: 0,


      //
      // delayForDB: 5000,
      // count: 0,

    }
  },
  mutations: {
    viewHeadCellWidthUpdate(state, pl){
      state.lists[pl.list5Id].list.data.views[state.lists[pl.list5Id].viewId].data.fields[pl.fieldId].headCellStyle.width = pl.cellWidth
      state.lists[pl.list5Id].isListChanged = true
    },
    listsIsListChangedReset(state, list5Id){
      state.lists[list5Id].isListChanged = false
    },
    listsList5Add(state, pl){
      state.lists[pl.list5Id] = pl.list5
    },
    listsList5Clear(state, list5Id) {
      delete state.lists[list5Id]
    },
    listsList5SetProp(state, pl){
      state.lists[pl.list5Id][pl.propName] = pl.propVal
    },
    listsList5ListFieldUpdate(state, pl){
      state.lists[pl.list5Id].list.data.fields[pl.listField.id] = pl.listField
    },
    listsList5ViewFieldUpdate(state, pl){
      state.lists[pl.list5Id].list.data.views[state.lists[pl.list5Id].viewId].data.fields[pl.viewField.id] = pl.viewField
    },
    listsListUpdate(state, pl){
      state.lists[pl.list5Id].list = pl.list
    },
    listsItemUpdate(state, pl){
      let indx = state.lists[pl.list5Id].items.findIndex(el => el.id === pl.item.id)
      if(indx > -1) state.lists[pl.list5Id].items[indx] = pl.item
      else state.lists[pl.list5Id].items.push(pl.item)
    },
    sublistAddList(state, pl){
      state.lists[pl.list5Id].sublists[pl.list.id] = { list: pl.list, items: {}}
    },
    sublistAddSubitem(state, pl){
      state.lists[pl.list5Id].sublists[pl.subitem.data.listId].items[pl.subitem.id] = pl.subitem
    },
    listCurrentCellSet(state, pl){
      state.lists[pl.list5Id].currentCell.itemId = pl.itemId
      state.lists[pl.list5Id].currentCell.fieldId = pl.fieldId
    },

    openedListAdd(state, list){
      state.openedLists.lists.push(list)
      state.openedLists.currentKey = list.key
    },
    openedListCurrentKeySet(state, key){
      state.openedLists.currentKey = key
    },
    openedListRemove(state, listIndx){

      let listToRemove = state.openedLists.lists[listIndx]
      if(listToRemove.spaceId+"_"+listToRemove.listId+"_"+listToRemove.viewId === state.openedLists.currentKey){
        if(listIndx === 0){
          state.openedLists.currentKey = state.openedLists.lists[listIndx+1].key
        }
        else{
          state.openedLists.currentKey = state.openedLists.lists[listIndx-1].key
        }
      }
      state.openedLists.lists.splice(listIndx, 1)
    },
    connectionStatusSet(state, status){
      state.connectionStatus = status
    },
    screenWidthSet(state, width){
      state.screen.width = width
    },
    screenHeightSet(state, height){
      state.screen.height = height
    },
    screenModeSet(state, mode){
      state.screen.mode = mode
    },
    footerEditorOpen(state, pl){
      state.footerEditorIsOpen = true
      state.footerEditorObj = pl.obj
      state.footerEditorUserId = pl.userId
      state.footerEditorTS = Date.now()
    },
    footerEditorClose(state){
      state.footerEditorIsOpen = false
      state.footerEditorObj = {}
      state.footerEditorUserId = ""
    },
    sublistAdd(state, sublist) {
      state.sublists[sublist.listId] = sublist
    },
    sublistInitialize(state) {
      state.sublists = {}
    },
    // currentUserPhoneNumberSet(state, currentUserPhoneNumber){
    //   state.currentUser.phoneNumber = currentUserPhoneNumber
    // },
    currentUserSet(state, user) {
      state.currentUser = user
    },
    currentUserDataSet(state, data) {
      state.currentUser.data = data
    },
    authUserSet(state, user) {
      state.authUser = user
    },
    currentTableSet(state, el) {
      state.currentTable = el
    },
    // initialSpacesListenerDoneSet(state, val) {
    //   state.initialSpacesListenerDone = val
    // },
    // currentUserIdSet(state, userId) {
    //   state.currentUserId = userId
    // },
    // userUpdate(state, user) {
    //   if (user === null) {
    //     state.user.displayName = null
    //     state.user.email = null
    //     state.user.phoneNumber = null
    //     state.user.photoURL = null
    //   } else {
    //     if (user.displayName !== undefined) state.user.displayName = user.displayName
    //     if (user.email !== undefined) state.user.email = user.email
    //     if (user.phoneNumber !== undefined) state.user.phoneNumber = user.phoneNumber
    //     if (user.photoURL !== undefined) state.user.photoURL = user.photoURL
    //   }
    // },
    // delayForDBSetZero(state) {
    //   state.delayForDB = 0
    // },
    // // open issues
    // saveOI(state, p) {
    //   let indx = state.openIssues.findIndex(el => el.id == p.lineId)
    //   if (indx >= 0) {
    //     state.openIssues[indx][p.field] = p.value
    //   }
    // }
  },
  actions: {
    // authUserListenerSet(context, authUserPhoneNumber) {
    //   listeners.authUser = onSnapshot(doc(fs, "users", authUserPhoneNumber), (doc) => {
    //     if (doc.data()) {
    //       db.users.put({id: authUserPhoneNumber, data: doc.data()})
    //     }
    //   })
    // },
    //
    // spacesListenerSet(context, userId) {
    //
    //   debugger
    //
    //   const q = query(
    //     collection(fs, "spaces"),
    //     where("userIds", "array-contains", userId),
    //     orderBy("lastTS")
    //   )
    //   listeners.spaces = onSnapshot(q, (snapshot) => {
    //     snapshot.docChanges().forEach((change) => {
    //       if (change.type === "added" || change.type === "modified") {
    //         let docData = change.doc.data()
    //         db.spaces.get(change.doc.id).then(space => {
    //           if (space) {
    //             db.spaces.where("id").equals(change.doc.id).modify(space2 => {
    //               debugger
    //               if ((
    //                   docData.version === space.$data.version &&
    //                   docData.lastUserId !== space.$data.lastUserId
    //                 ) || (
    //                   space.status === "changed" &&
    //                   docData.lastUserId !== space.$data.lastUserId
    //                 )
    //               ) {
    //                 space2.status = "collision"
    //                 space2.collisionData = {$data: docData, data: {}}
    //                 if (!space2.$data.isEncrypted) {
    //                   space2.collisionData.data = JSON.parse(docData.data)
    //                 }
    //                 else if (space2.secretKey !== "") {
    //                   space2.collisionData.data = decrypt(docData.data, space2.cryptoKey)
    //                   console.log(space2.collisionData.data)
    //                 }
    //               }
    //               else if ((
    //                   docData.lastUserId === space.$data.lastUserId &&
    //                   docData.lastTS === space.$data.lastTS &&
    //                   space.status === "changed"
    //                 ) ||
    //                 space.status === "updated"
    //               ) {
    //                 debugger
    //                 space2.status = "updated"
    //                 space2.$data = docData
    //                 space2.data = {}
    //                 if (!space2.$data.isEncrypted) {
    //                   space2.data = JSON.parse(docData.data)
    //                 }
    //                 else if (space2.secretKey !== "") {
    //                   // debugger
    //                   space2.data = decrypt(docData.data, space2.cryptoKey)
    //                 }
    //               }
    //             })
    //           }
    //           else {
    //             let newSpace = {$data: {}, data: {}}
    //             newSpace.id = change.doc.id
    //             newSpace.status = "updated"
    //             newSpace.cryptoKey = ""
    //             newSpace.$data = docData
    //             newSpace.data = {}
    //             if (!newSpace.$data.isEncrypted && newSpace.$data.data) {
    //               newSpace.data = JSON.parse(newSpace.$data.data)
    //               newSpace.$data.data = {}
    //             }
    //             db.spaces.put(newSpace)
    //           }
    //         })
    //       }
    //       if (change.type === "removed") {
    //         db.spaces.where("id").equals(change.doc.id).delete()
    //         // db.lists.where("spaceId").equals(change.doc.id).delete()
    //         // db.views.where("spaceId").equals(change.doc.id).delete()
    //         // db.items.where("spaceId").equals(change.doc.id).delete()
    //         // db.checklists.where("spaceId").equals(change.doc.id).delete()
    //         // db.comments.where("spaceId").equals(change.doc.id).delete()
    //         // db.changelog.where("spaceId").equals(change.doc.id).delete()
    //       }
    //     })
    //     context.commit("initialSpacesListenerDoneSet", true)
    //   })
    // },
    // spacesListenerCancel(context) {
    //   if (listeners.spaces) listeners.spaces()
    //   context.commit("initialSpacesListenerDoneSet", true)
    // },
    // authUserListenerCancel() {
    //   if (listeners.authUser) listeners.authUser()
    // },
    userDataGet(context, spaceId) {
      db.spaces.get(spaceId).then(async space => {
        let usersListId = space.data.zListIds.usersListId
        let users = await db.items.where("data.listId").equals(usersListId).toArray()
        users.forEach(user => {
          // debugger
          let userPhone = user.data.fields.z31 ? user.data.fields.z31 : ""
          const q = query(collection(fs, "users"), where("phoneNumber", "==", userPhone))
          getDocs(q).then(querySnapshot => {
            debugger
            let thisUser = {
              name: "",
              displayName: "",
              avatarImg: "",
              updateStatus: ""
            }
            let thisMoment = new Date()
            let thisMomentDateString = thisMoment.getUTCDate() + "/" + thisMoment.getUTCMonth() + "/" + thisMoment.getUTCFullYear()
            thisMomentDateString = thisMomentDateString + ", " + thisMoment.getUTCHours() + ":" + thisMoment.getUTCMinutes() + ":" + thisMoment.getUTCSeconds()

            if (querySnapshot.empty) {
              thisUser.updateStatus = thisMomentDateString + ", The user is not registered yet."
            } else {
              let userData = querySnapshot.docs[0].data()
              thisUser.name = userData.name
              thisUser.displayName = user.data.fields.z36 === "X" ? userData.name : user.data.fields.z35
              thisUser.avatarImg = userData.avatarImg
              thisUser.updateStatus = thisMomentDateString + ", The user is updated from cloud."

              // querySnapshot.forEach(async (doc) => {
              //     let userData = doc.data()
              //     // debugger
              //     let name = userData.name
              //     let displayName = user.data.fields.z36 === "X" ? userData.name : user.data.fields.z35
              //     let avatarImg = userData.avatarImg
              //     // await db.items.update(user.id, {
              //     //     //"data.fields.z31": userData.phoneNumber,
              //     //     "data.fields.z32": name,
              //     //     "data.fields.z35": displayName,
              //     //     "data.fields.z34": avatarImg
              //     // })
              //     db.items.where("id").equals(user.id).modify(item => {
              //         //"data.fields.z31": userData.phoneNumber,
              //         debugger
              //         item.data.fields.z32 = name
              //         item.data.fields.z35 = displayName
              //         item.data.fields.z34 = avatarImg
              //     })
              // })
            }
            db.items.where("id").equals(user.id).modify(item => {
              //"data.fields.z31": userData.phoneNumber,
              debugger
              if (thisUser.name) item.data.fields.z32 = thisUser.name
              if (thisUser.name && item.data.fields.z36) item.data.fields.z35 = thisUser.name
              if (thisUser.avatarImg) item.data.fields.z34 = thisUser.avatarImg
              if (thisUser.updateStatus) item.data.fields.z37 = thisUser.updateStatus
            })
          })
        })
      })
    },
    userUpload(context, pl) {
      let user = {}
      user.phoneNumber = pl.phoneNumber
      user.name = pl.name
      if (pl.avatarImg !== "") user.avatarImg = pl.avatarImg
      // let reader = new FileReader();
      // reader.readAsDataURL(pl.avatarBlob); // converts the blob to base64 and calls onload

      // reader.onload = function() {
      //     user.avatarBlob = reader.result; // data url
      return new Promise((resolve, reject) => {
        setDoc(doc(fs, 'users', pl.phoneNumber), user, {merge: true}).then(() => {
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
      // };


    },
    userNameAvatarUpload(context, pl) {
      return new Promise((resolve, reject) => {
        setDoc(doc(fs, 'users', currentUserId), {name: pl.name, avatarImg: pl.avatarImg}, {merge: true})
          .then(() => {
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
})
