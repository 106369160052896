<template>
  <div class="table-container" ref="tableContainer">
    <div class="table" :style="{ width: tableWidth + 'px' }">
      <div class="table-row header">
        <div
          v-for="(field, index) in fields"
          :key="field"
          class="table-cell"
          :style="{ width: columnWidths[index] + 'px' }"
          @mousedown="onColumnResizeStart($event, index)"
          @mousemove="onColumnResize($event, index)"
          @mouseup="onColumnResizeEnd"
          @mouseleave="onColumnResizeEnd"
        >
          {{ field }}
          <div
            v-if="isResizing && resizeIndex === index"
            class="resize-indicator"
            @mousemove.stop
          ></div>
        </div>
      </div>
      <div v-for="person in people" :key="person.id" class="table-row">
        <div
          v-for="(field, index) in fields"
          :key="field"
          class="table-cell"
          :style="{ width: columnWidths[index] + 'px' }"
        >
          {{ person[field] }}
        </div>
      </div>
    </div>
  </div>

  div inside div
  <div style="width: 100px; _height: 70px; background-color: #2196f3; border: 2px solid orange">
    <div style="width: 100%; height: 90%; background-color: #83898d; border: 2px solid red">
        asdfasdf
    </div>
  </div>
  ---
  <table>
    <tr>
  <td style="background-color: #2196f3; border: 2px solid orange">
    <div style="background-color: #83898d; border: 2px solid red">
      asdfasdf <br/>fsadfsad
    </div>
  </td>
      <td style="background-color: #2196f3; border: 2px solid orange; position: relative; padding: 5px;">
        <div style="border: 2px solid red; position: absolute; top: 0; left: 0; z-index: 2; width: 100%; height: 100%;">

        </div>
        asdfasdf
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {
      fields: ['name', 'age', 'email'],
      people: [
        { id: 1, name: 'John Doe', age: 25, email: 'john@example.com' },
        { id: 2, name: 'Jane Smith', age: 30, email: 'jane@example.com' },
        { id: 3, name: 'Bob Johnson', age: 35, email: 'bob@example.com' },
      ],
      columnWidths: [],
      tableWidth: 0,
      isResizing: false,
      resizeIndex: null,
      resizeStartX: null,
    };
  },
  mounted() {
    this.calculateColumnWidths();
    this.calculateTableWidth();
    window.addEventListener('resize', this.calculateTableWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateTableWidth);
  },
  methods: {
    onColumnResizeStart(event, index) {
      this.isResizing = true;
      this.resizeIndex = index;
      this.resizeStartX = event.clientX;
    },
    onColumnResize(event, index) {
      if (this.isResizing && this.resizeIndex === index) {
        const offsetX = event.clientX - this.resizeStartX;
        const newWidth = this.columnWidths[index] + offsetX;
        if (newWidth > 0) {
          this.columnWidths[index] = newWidth;
        }
        this.resizeStartX = event.clientX;
      }
    },
    onColumnResizeEnd() {
      this.isResizing = false;
      this.resizeIndex = null;
      this.resizeStartX = null;
    },
    calculateColumnWidths() {
      this.columnWidths = this.fields.map(() => 100); // Изначальная ширина столбцов
    },
    calculateTableWidth() {
      const tableContainerWidth = this.$refs.tableContainer.offsetWidth;
      this.tableWidth = tableContainerWidth;
    },
  },
};
</script>

<style>
.table-container {
  overflow-x: auto;
}

.table {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  table-layout: fixed;
  min-width: 100%;
}

.table-row {
  display: flex;
}

.table-cell {
  flex-shrink: 0;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.header {
  font-weight: bold;
  background-color: #f0f0f0;
}

.resize-indicator {
  position: absolute;
  top: 0;
  right: -4px;
  width: 8px;
  height: 100%;
  cursor: col-resize;
  background-color: #ddd;
  opacity: 0.5;
  z-index: 1;
}
</style>
