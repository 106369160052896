<template>
  <q-card>
    <q-card-section>
      <q-input v-model="spaceId" label="spaceId"/>
      <q-input v-model="listId" label="listId" type="text"/>
    </q-card-section>
    <q-card-section>{{message}}</q-card-section>
    <q-card-actions>
      <q-btn @click="convert()">convert</q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import {db} from '@/db'

export default {
  name: "test-t13",
  components: {},
  data: () => {
    return {
      spaceId: "",
      listId: "",
      message: "message"
    }
  },
  async created(){

  },
  methods:{
    convert(){
      debugger
      this.message = "start..."
      db.table("items").where("data.listId").equals(this.listId).modify(item => {
        item.dbIndex = []
        item.dbIndex.push(item.spaceId+"_"+item.data.listId)
        item.dbIndex.push(item.spaceId+"_"+item.data.listId+"_f3_"+item.data.fields['f3'])
        item.dbIndex.push(item.spaceId+"_"+item.data.listId+"_f4_"+item.data.fields['f4'])
        item.dbIndex.push(item.spaceId+"_"+item.data.listId+"_f3_"+item.data.fields['f3']+"_f4_"+item.data.fields['f4'])
      }).then(() => {
        this.message = "finished"
      })
    }
  }

}
</script>

<style scoped>

</style>