<template>
  <q-card flat square v-if="$q.platform.is.mobile" >
    <div v-if="!upperListData && isDataPrepared">
      <div style="position: sticky; top: 0; background-color: white; z-index: 10; border-bottom: 1px solid lightgrey">
        <sl-list-header
          :space="space"
          :list="list"
          :view="view"
          v-if="space && list && view"
          :isDrilldownList="upperListData !== undefined"
          @refreshView="sublistsGet()"
        />
      </div>
      <sl-list4 :space="space" :list="list" :view="view" :isEditable="isEditable"
                @pItemsLength="pItemsLength=$event"
                @groupFieldsToAddonReady="groupFieldsToAddon = $event"
                :aggregatorsItems="aggregatorsItems"
      />
    </div>
  </q-card>

  <q-card flat square v-else>
    <q-card-section>
    <q-splitter
      v-model="splitterModel"
      horizontal
      :limits="[0, 100]"
      separator-style="background-color: yellow"
      :style="{height: tableScrollAreaHeightPX+'px'}"
      v-if="upperListData"
    >
      <template v-slot:before>
<!--        {{ upperListData.list.data.name }}-->
        <q-scroll-area
          :style="{height: beforeTableScrollAreaHeightPX+'px', width: '100%', padding: '5px', borderBottom: '1px solid grey'}"
          :thumb-style="{right: '2px',
                     borderRadius: '5px',
                     backgroundColor: 'lightgrey',
                     width: '5px',opacity: '0.75'}"
        >
        <table :style="upperListTableStyle">
          <tr
            :class="{'tr-head-s1': upperListData.view._data.hBorders, 'tr-head-s2': upperListData.view._data.vBorders}">
            <template v-for="fieldId_h in upperListData.view._data.fieldsOrder" :key="fieldId_h">
              <th :style="upperListData.view._data.fields[fieldId_h].headCellStyle" v-if="fieldId_h.charAt(0) === 'f'">
                <span :style="upperListData.view._data.fields[fieldId_h].headNameStyle">
                  {{ upperListData.list.data.fields[fieldId_h].name }}
                </span>
              </th>
            </template>
          </tr>
          <tr>
            <template v-for="fieldId in upperListData.view._data.fieldsOrder" :key="fieldId">
              <sl-list-cell :fieldId="fieldId"
                            :list="upperListData.list"
                            :item="upperListData.item"
                            :view="upperListData.view"
                            v-if="fieldId.charAt(0) === 'f'"
              />
            </template>
          </tr>
        </table>
        </q-scroll-area>
      </template>
      <template v-slot:separator>
        <q-icon name="drag_handle" />
      </template>
      <template v-slot:after>
        <div v-if="isDataPrepared">
        <br/>
        <sl-list-header
          :space="space"
          :list="list"
          :view="view"
          v-if="space && list && view"
          :isDrilldownList="upperListData !== undefined"
          @refreshView="sublistsGet()"
        />
          <q-scroll-area
            :style="{height: afterTableScrollAreaHeightPX+'px', width: '100%', padding: '5px', borderBottom: '1px solid grey'}"
            :thumb-style="{right: '2px',
                     borderRadius: '5px',
                     backgroundColor: 'lightgrey',
                     width: '5px',opacity: '0.75'}"
          >
        <sl-list4 :space="space" :list="list"
                  :upperListDataForDrilldown="upperListData"
                  :view="view"
                  :isEditable="isEditable"
                  @pItemsLength="pItemsLength=$event"
                  :aggregatorsItems="aggregatorsItems"></sl-list4>
        <br/>
        <q-page-sticky position="bottom-right" :offset="[18, 18]">
          <q-btn :icon="isEditable?'visibility':'edit'" color="accent" dense round size="sm"
                 @click="isEditable=!isEditable"/>
        </q-page-sticky>
          </q-scroll-area>
        </div>
      </template>
      {{ pItemsLength }}
    </q-splitter>
    </q-card-section>
<!--    <q-card-section v-if="upperListData">-->
<!--      {{ upperListData.list.data.name }}-->
<!--      <q-scroll-area :style="'height: 150px; width: 100%; padding: 10px; border-bottom: 1px solid grey'" :thumb-style="{right: '2px',-->
<!--            borderRadius: '5px',-->
<!--            backgroundColor: 'lightgrey',-->
<!--            width: '5px',-->
<!--            opacity: '0.75'}"-->
<!--      >-->
<!--        <table :style="upperListTableStyle">-->
<!--          <tr-->
<!--            :class="{'tr-head-s1': upperListData.view._data.hBorders, 'tr-head-s2': upperListData.view._data.vBorders}">-->
<!--            <template v-for="fieldId_h in upperListData.view._data.fieldsOrder" :key="fieldId_h">-->
<!--              <th :style="upperListData.view._data.fields[fieldId_h].headCellStyle" v-if="fieldId_h.charAt(0) === 'f'">-->
<!--                <span :style="upperListData.view._data.fields[fieldId_h].headNameStyle">-->
<!--                  {{ upperListData.list.data.fields[fieldId_h].name }}-->
<!--                </span>-->
<!--              </th>-->
<!--            </template>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <template v-for="fieldId in upperListData.view._data.fieldsOrder" :key="fieldId">-->
<!--              <sl-list-cell :fieldId="fieldId"-->
<!--                            :list="upperListData.list"-->
<!--                            :item="upperListData.item"-->
<!--                            :view="upperListData.view"-->
<!--                            v-if="fieldId.charAt(0) === 'f'"-->
<!--              />-->
<!--            </template>-->
<!--          </tr>-->
<!--        </table>-->
<!--      </q-scroll-area>-->
<!--    </q-card-section>-->

    <q-card-section v-if="!upperListData && isDataPrepared">
      <sl-list-header
        :space="space"
        :list="list"
        :view="view"
        v-if="space && list && view"
        :isDrilldownList="upperListData !== undefined"
        @refreshView="sublistsGet()"
        @addonSwitch="addonSwitchOn=!addonSwitchOn"
      />
      <br/>

      <q-scroll-area
        :style="{height: tableScrollAreaHeightPX+'px', width: '100%', padding: '5px', borderBottom: '1px solid grey'}"
        :thumb-style="{right: '2px',
                     borderRadius: '5px',
                     backgroundColor: 'lightgrey',
                     width: '5px',opacity: '0.75'}"
      >
        <div v-show="addonSwitchOn">
          <sl-addon-init :data="groupFieldsToAddon" :view="view" v-if="groupFieldsToAddon?.lineData?.totalFields"/>
        </div>
        <div v-show="!addonSwitchOn">
        <sl-list4 :space="space" :list="list" :view="view" :isEditable="isEditable"
                  @pItemsLength="pItemsLength=$event"
                  @groupFieldsToAddonReady="groupFieldsToAddon = $event"
                  :aggregatorsItems="aggregatorsItems"
        />
        <br/>
        <q-page-sticky position="bottom-right" :offset="[18, 18]">
          <q-btn :icon="isEditable?'visibility':'edit'" color="accent" dense round size="sm"
                 @click="isEditable=!isEditable"/>
        </q-page-sticky>
        </div>
      </q-scroll-area>
      {{ pItemsLength }}
    </q-card-section>
  </q-card>
</template>

<script>
import SlList4 from "@/components/sl-list4"
import SlListHeader from "@/components/sl-list-header"
import {db} from "@/db";
import {useObservable} from "@vueuse/rxjs/index"
import {liveQuery} from "dexie"
import SlListCell from "@/components/sl-list-cell";
import {ref} from "vue";
import SlAddonInit from "@/components/sl-addon-init";
// import {shallowRef} from "vue";
// import {toRaw} from "vue";
// import { useRoute, useRouter } from "vue-router"

export default {
  name: "sl-list-init",
  props: ["spaceId", "listId", "viewId", "upperListData", "filter"],
  data: function () {
    return {
      windowHeight: window.innerHeight,
      splitterModel: ref(20),
      isEditable: false,
      pItemsLength: 0,
      view: null,
      isDataPrepared: false,
      argItems: [],
      aggregatorsItems: [],
      groupFieldsToAddon: {},
      addonSwitchOn: false,
    }
  },
  components: {SlAddonInit, SlListCell, SlListHeader, SlList4},
  setup(props) {
    return {
      db,

      space: useObservable(
        liveQuery(() => db.spaces.get(props.spaceId))
      ),
      list: useObservable(
        liveQuery(() => db.lists.get(props.listId))
      )
    }
  },
  async created() {
    let that = this
    // let agrItems = {}
    let list = await db.lists.get(this.listId)
    let agrListIds = []
    for (let fieldId in list.data.fields) {
      if (list.data.fields[fieldId].aggregator) {
        agrListIds.push(list.data.fields[fieldId].aggregator.listId2)
        // let agrListId = props.list.data.fields[fieldId].aggregator.listId2
        // if(agrItems[props.list.data.fields[fieldId].aggregator.listId2]) continue
        // agrItems[props.list.data.fields[fieldId].aggregator.listId2] = useObservable(
        //     liveQuery(() => db.items.where("data.listId").equals(props.list.data.fields[fieldId].aggregator.listId2).toArray())
        // )
      }
    }
    // this.agrItems = useObservable(
    //     liveQuery(() => db.items.where("data.listId").anyOf(agrListIds).toArray())
    // )


    // this.agrItems = await db.items.where("data.listId").anyOf(agrListIds).toArray()
    // this.aggregatorsItemsGet()

    await db.items.where("data.listId").anyOf(agrListIds).toArray().then(agrItems => {
      that.agrItems = agrItems
      that.aggregatorsItemsGet()
    })


    db.on('changes', function (changes) {
      changes.forEach(function (change) {
        if (change.table === "items" && agrListIds.some(el => el === change.obj.data.listId)) {
          db.items.where("data.listId").anyOf(agrListIds).toArray().then(agrItems => {
            that.agrItems = agrItems
            that.aggregatorsItemsGet()
          })
        }
      })
    })


    this.view = this.list.data.views[this.viewId]
    // this.view = await db.views.get(this.viewId)
    // if(!this.list.data.views) {
    //   let viewTemp = JSON.parse(JSON.stringify(toRaw(this.view)))
    //   db.lists.where("id").equals(this.list.id).modify(l => {
    //     l.data.views = {}
    //     l.data.views[that.viewId] = viewTemp
    //   })
    // }

    await this.viewEnrich()
    await this.sublistsGet()
    if (this.filter !== undefined) {
      for(let i=0; i<this.filter.length; i++) {
        let field = this.filter[i]
        if (this.view._data.fields[field.fieldId]) {
          if (this.view._data.fields[field.fieldId].filter) {
            if (!this.view._data.fields[field.fieldId].filter.arr.includes(field.val)) {
              this.view._data.fields[field.fieldId].filter.arr.push(field.val)
            }
          }
        }
        else {
          this.view._data.fields[field.fieldId] = {}
        }
        if (!this.view._data.fields[field.fieldId].filter) {
          this.view._data.fields[field.fieldId].filter = {arr: []}
        }
        this.view._data.fields[field.fieldId].filter.arr.push(field.val)
      }
    }
    this.isDataPrepared = true
    addEventListener("resize", (event) => {
      console.log(event)
      that.windowHeight = event.currentTarget.innerHeight
      debugger
    })
    // let list  = await db.lists.get(this.listId)
    // let view = {}
    // let that = this
    // if(this.viewId === "" || this.viewId === undefined){
    //   view.id = ""
    //   view.name = "All fields (unformatted view)"
    //   view.owner = ""
    //   view.fields = []
    //   list.fields.forEach(f=>{
    //     view.fields.push({id: f.id})
    //   })
    // }
    // else {
    //   view = list.views.find(el=>el.id===that.viewId)
    // }
    // // debugger
    // for(let i=0; i<view.fields.length; i++){
    //   view.fields[i].props = list.fields.find(el=>el.id===view.fields[i].id)
    //   if(list.fields.find(el=>el.id===view.fields[i].id).type === "sublist"){
    //     view.fields[i].sublistItems = {}
    //     let sublist = await db.lists.get(list.fields.find(el=>el.id===view.fields[i].id).listForDropdown)
    //     let fieldForDropdown = sublist.fields.find(el=>el.fieldForDropdown === true)
    //     if(fieldForDropdown) {
    //       let fieldIdForDropdown = fieldForDropdown.id
    //       let sublistItems = await db.items.where("listId").equals(sublist.id).toArray()
    //       for(let j=0; j<sublistItems.length; j++){
    //         view.fields[i].sublistItems[sublistItems[j].id] = {}
    //         view.fields[i].sublistItems[sublistItems[j].id].fieldForDropdown = sublistItems[j].fields[fieldIdForDropdown]
    //         view.fields[i].sublistItems[sublistItems[j].id].fields = sublistItems[j].fields
    //       }
    //     }
    //   }
    // }
    // this.view = view
  },
  computed: {
    tableScrollAreaHeightPX() {
      return (this.windowHeight - 250)
    },
    afterTableScrollAreaHeightPX() {
      let afterTableScrollAreaHeightPX = ((100 - this.splitterModel) / 100 ) * this.tableScrollAreaHeightPX
      afterTableScrollAreaHeightPX = afterTableScrollAreaHeightPX - 100
      return afterTableScrollAreaHeightPX
    },
    beforeTableScrollAreaHeightPX() {
      let beforeTableScrollAreaHeightPX = ((this.splitterModel) / 100 ) * this.tableScrollAreaHeightPX
      return beforeTableScrollAreaHeightPX
    },
    upperListTableWidth() {
      let width = 0
      for (let fieldId of this.upperListData.view._data.fieldsOrder) {
        if (fieldId.charAt(0) === "f") {
          if (this.upperListData.view._data.fields[fieldId].headCellStyle.width) width = width + parseInt(this.upperListData.view._data.fields[fieldId].headCellStyle.width, 10)
        }
      }
      return width
    },
    upperListTableStyle() {
      return {width: this.upperListTableWidth + "px", tableLayout: 'fixed'}
    },


    // view(){
    //   let that = this
    //   let view = {}
    //   db.transaction('rw', db.lists, db.items, async ()=>{
    //     let list  = await db.lists.get(this.listId)
    //     if(this.viewId === "" || this.viewId === undefined){
    //       view.id = ""
    //       view.name = "All fields (unformatted view)"
    //       view.owner = ""
    //       view.fields = []
    //       list.fields.forEach(f=>{
    //         view.fields.push({id: f.id})
    //       })
    //     }
    //     else {
    //       view = list.views.find(el=>el.id===that.viewId)
    //     }
    //     // debugger
    //     for(let i=0; i<view.fields.length; i++) {
    //       view.fields[i].props = list.fields.find(el => el.id === view.fields[i].id)
    //       if (list.fields.find(el => el.id === view.fields[i].id).type === "sublist") {
    //         view.fields[i].sublistItems = {}
    //         let sublist = await db.lists.get(list.fields.find(el => el.id === view.fields[i].id).listForDropdown)
    //         let fieldForDropdown = sublist.fields.find(el => el.fieldForDropdown === true)
    //         if (fieldForDropdown) {
    //           let fieldIdForDropdown = fieldForDropdown.id
    //           let sublistItems = await db.items.where("listId").equals(sublist.id).toArray()
    //           for (let j = 0; j < sublistItems.length; j++) {
    //             view.fields[i].sublistItems[sublistItems[j].id] = {}
    //             view.fields[i].sublistItems[sublistItems[j].id].fieldForDropdown = sublistItems[j].fields[fieldIdForDropdown]
    //             view.fields[i].sublistItems[sublistItems[j].id].fields = sublistItems[j].fields
    //           }
    //         }
    //       }
    //     }
    //     debugger
    //     return view
    //   })
    //   return undefined


    // }


    // view(){
    // let view = {}
    // let that = this
    // if(this.viewId === "" || this.viewId === undefined){
    //   view.id = ""
    //   view.name = "All fields (unformatted view)"
    //   view.owner = ""
    //   view.fields = []
    //   this.list.fields.forEach(f=>{
    //     view.fields.push({id: f.id})
    //   })
    // }
    // else if(this.list) {
    //   view = this.list.views.find(el=>el.id===that.viewId)
    // }
    // debugger
    // for(let i=0; i<view.fields.length; i++){
    //   if(this.list.fields.find(el=>el.id===view.fields[i].id).type === "sublist"){
    //     view.fields[i].sublistItems = {}
    //     let sublist = rowGet("lists", this.list.id)
    //     let fieldForDropdown = sublist.fields.find(el=>el.fieldForDropdown === 'true')
    //     if(fieldForDropdown) {
    //       let fieldIdForDropdown = fieldForDropdown.id
    //       let sublistItems = db.items.where("listId").equals(this.list.id).toArray()
    //       for(let j=0; j<sublistItems.length; j++){
    //         view.fields[i].sublistItems[sublistItems[j].id] = sublistItems[j].fields[fieldIdForDropdown]
    //       }
    //     }
    //
    //   }
    // }
    // return view
    // }
  },
  watch: {
    // list(newVal, oldVal){
    //   console.log(newVal)
    //   if(oldVal){
    //     debugger
    //     this.isDataPrepared = false
    //     this.sublistsGet()
    //     this.isDataPrepared = true
    //   }
    //
    // },
    // view(){
    //   this.viewEnrich()
    //   this.view._isChangedByUser = false
    // this.view._isChangedInDB = true
    // },
    // 'view._data': {
    //   handler(){
    //     debugger
    //     if(this.view._isChangedInDB === true){
    //       this.view._isChangedInDB = false
    //     }
    //     else{
    //       this.view._isChangedByUser = true
    //     }
    //   },
    //   deep: true
    // }
  },
  methods: {
    async sublistsGet() {
      // debugger
      // let sublists = {}
      // let sublist = {}
      // if(!this.upperListData) {
        // this.$store.commit("sublistInitialize")
      // }

      for (let fieldId of this.list.data.views[this.viewId].data.fieldsOrder) {
        if (this.list.data.fields[fieldId].type !== "sublist") continue
        if (this.list.data.fields[fieldId].isFixedSublist) continue
        if (this.list.data.fields[fieldId].sublistBasedOnFieldId) continue
        let listIdForSublist = this.list.data.fields[fieldId].listIdForSublist
        let viewIdForSublist = this.list.data.fields[fieldId].viewIdForSublist
        await this.sublistSaveInStore(listIdForSublist, viewIdForSublist)

        // sublists[listIdForSublist] = {
        //   listId: listIdForSublist,
        //   list: await db.lists.get(listIdForSublist),
        //   items: await db.items.where("data.listId").equals(listIdForSublist).toArray()
        // }
        // sublists[listIdForSublist] = sublist

      }
      // this.list.sublists = sublists


    },
    async sublistSaveInStore(listId, viewId) {
      // if (this.$store.state.sublists[listId]) return // if item changed and in drilldown list the item is useed in sublist - changed val doesn't appear in sublist form - so $store.sublists must be updated. This obviously requires optimization.
      let list = await db.lists.get(listId)
      for (let fieldId of list.data.views[viewId].data.fieldsOrder) {
        if (list.data.fields[fieldId].type !== "sublist") continue
        if (list.data.fields[fieldId].isFixedSublist) continue
        if (list.data.fields[fieldId].sublistBasedOnFieldId) continue
        let listIdForSublist = list.data.fields[fieldId].listIdForSublist
        let viewIdForSublist = list.data.fields[fieldId].viewIdForSublist
        await this.sublistSaveInStore(listIdForSublist, viewIdForSublist)
      }

      let items = await db.items.where("data.listId").equals(listId).toArray()
      for (let item of items) {
        for (let fieldId of list.data.views[viewId].data.fieldsOrder) {
          if (list.data.fields[fieldId].type === "sublist" && !list.data.fields[fieldId].isFixedSublist) {
            let listIdForSublist = list.data.fields[fieldId].listIdForSublist
            let fieldIdForSublist = list.data.fields[fieldId].fieldIdForSublist
            let sublistBasedOnFieldId = list.data.fields[fieldId].sublistBasedOnFieldId
            let itemIdOfSublistItem = sublistBasedOnFieldId ? item.data.fieldsS[sublistBasedOnFieldId] : item.data.fieldsS[fieldId]
            let indexOfSublistItem = this.$store.state.sublists[listIdForSublist].items.findIndex(el => el.id === itemIdOfSublistItem)
            if (indexOfSublistItem >= 0) {
              item.data.fields[fieldId] = this.$store.state.sublists[listIdForSublist].items[indexOfSublistItem].data.fields[fieldIdForSublist]
              if (this.$store.state.sublists[listIdForSublist].list.data.fields[fieldIdForSublist].type === "sublist") {
                item.data.fieldsS[fieldId] = this.$store.state.sublists[listIdForSublist].items[indexOfSublistItem].data.fieldsS[fieldIdForSublist]
              }
            }
          }
        }
      }

      let sublist = {
        listId: listId,
        list: list,
        items: items
      }
      this.$store.commit("sublistAdd", Object.freeze(sublist))
    },


    async viewEnrich() {

      if (!this.view) return
      // console.log(this.view)
      this.view._data = JSON.parse(JSON.stringify(this.view.data)) // user can do changes in view with no changes to db, after user save changes in view _data overwrites data
      this.view._isChangedByUser = false


      // this.view._sublists = {}
      // for(let fieldId of this.view._data.fieldsOrder){
      //   if(this.list.data.fields[fieldId].type === "sublist" && this.list.data.fields[fieldId].isFixedSublist){
      //     this.view._sublists[fieldId] = {}
      //     for (let fixedSublistItemId in this.list.data.fields[fieldId].fixedSublist) {
      //       this.view._sublists[fieldId][fixedSublistItemId] = {}
      //       this.view._sublists[fieldId][fixedSublistItemId].id = fixedSublistItemId
      //       this.view._sublists[fieldId][fixedSublistItemId].value = this.list.data.fields[fieldId].fixedSublist[fixedSublistItemId].value
      //       this.view._sublists[fieldId][fixedSublistItemId].style = {}
      //       this.view._sublists[fieldId][fixedSublistItemId].style.cellStringStyle = this.list.data.fields[fieldId].fixedSublist[fixedSublistItemId].style
      //       this.view._sublists[fieldId][fixedSublistItemId].style.cellStyle = {}
      //     }
      //   }
      //   if(this.list.data.fields[fieldId].type === "sublist" && !this.list.data.fields[fieldId].isFixedSublist){
      //     let listIdForSublist = this.list.data.fields[fieldId].listIdForSublist
      //     let fieldIdForSublist = this.list.data.fields[fieldId].fieldIdForSublist
      //     let viewIdForSublist = this.list.data.fields[fieldId].viewIdForSublist
      //     let viewForSublist = await this.list.data.views[viewIdForSublist]
      //     let sublistItems = await db.items.where("data.listId").equals(listIdForSublist).toArray()
      //
      //     this.view._sublists[fieldId] = {}
      //     for (let sublistItem of sublistItems) {
      //       this.view._sublists[fieldId][sublistItem.id] = {}
      //       this.view._sublists[fieldId][sublistItem.id].id = sublistItem.id
      //       this.view._sublists[fieldId][sublistItem.id].value = sublistItem.data.fields[fieldIdForSublist]
      //       this.view._sublists[fieldId][sublistItem.id].style = {cellStyle: {}, cellStringStyle: {}}
      //       // fields props
      //       if(viewForSublist.data.fields){
      //         if(viewForSublist.data.fields[fieldIdForSublist]){
      //           if(viewForSublist.data.fields[fieldIdForSublist].cellStyle) {
      //             this.view._sublists[fieldId][sublistItem.id].style.cellStyle = viewForSublist.data.fields[fieldIdForSublist].cellStyle
      //           }
      //           if(viewForSublist.data.fields[fieldIdForSublist].cellStringStyle) {
      //             this.view._sublists[fieldId][sublistItem.id].style.cellStringStyle = viewForSublist.data.fields[fieldIdForSublist].cellStringStyle
      //           }
      //         }
      //       }
      //       // cell props
      //       if (viewForSublist.data.cellsProps){
      //         if (viewForSublist.data.cellsProps[sublistItem.id]){
      //           if (viewForSublist.data.cellsProps[sublistItem.id][fieldIdForSublist]){
      //             if (viewForSublist.data.cellsProps[sublistItem.id][fieldIdForSublist].cellStyle) {
      //               this.view._sublists[fieldId][sublistItem.id].style.cellStyle = {
      //                 ... this.view._sublists[fieldId][sublistItem.id].style.cellStyle,
      //                 ... viewForSublist.data.cellsProps[sublistItem.id][fieldIdForSublist].cellStyle
      //               }
      //             }
      //             if (viewForSublist.data.cellsProps[sublistItem.id][fieldIdForSublist].cellStringStyle) {
      //               this.view._sublists[fieldId][sublistItem.id].style.cellStringStyle = {
      //                 ... this.view._sublists[fieldId][sublistItem.id].style.cellStringStyle,
      //                 ... viewForSublist.data.cellsProps[sublistItem.id][fieldIdForSublist].cellStringStyle
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    },
    aggregatorsItemsGet() {
      // debugger
      let aggregatorsItems = {}
      for (let fieldId in this.list.data.fields) {
        if (this.list.data.fields[fieldId].aggregator) {
          let aggregator = this.list.data.fields[fieldId].aggregator
          if (!this.agrItems) continue
          let agrItems = this.agrItems.filter(el => el.data.listId === aggregator.listId2)
          aggregatorsItems[fieldId] = []
          // sorting
          agrItems.sort((a, b) => {
            let result = 0
            let itemA = ""
            let itemB = ""
            for (let i = 0; i < aggregator.filterMap.length; i++) {
              if (a.data.fieldsS[aggregator.filterMap[i].fieldId2]) itemA = a.data.fieldsS[aggregator.filterMap[i].fieldId2]
              else if (a.data.fields[aggregator.filterMap[i].fieldId2]) itemA = a.data.fields[aggregator.filterMap[i].fieldId2]
              else itemA = ""
              if (b.data.fieldsS[aggregator.filterMap[i].fieldId2]) itemB = b.data.fieldsS[aggregator.filterMap[i].fieldId2]
              else if (b.data.fields[aggregator.filterMap[i].fieldId2]) itemB = b.data.fields[aggregator.filterMap[i].fieldId2]
              else itemB = ""
              result = itemA.localeCompare(itemB)
              if (result !== 0) break
            }
            return result
          })

          // aggregating
          let agrItem = {}
          let nextCombination
          for (let i = 0; i < agrItems.length; i++) {
            if (i === 0) {
              aggregator.filterMap.forEach(map => {
                agrItem[map.fieldId2] = agrItems[i].data.fields[map.fieldId2] ? agrItems[i].data.fields[map.fieldId2] : ""
              })
              agrItem[aggregator.fieldId2] = agrItems[i].data.fields[aggregator.fieldId2] ? agrItems[i].data.fields[aggregator.fieldId2] : 0
              agrItem.count = 1
              aggregatorsItems[fieldId].push(JSON.parse(JSON.stringify(agrItem)))
              continue
            }
            nextCombination = false
            aggregator.filterMap.forEach(map => {
              let agrValue = agrItems[i].data.fields[map.fieldId2] ? agrItems[i].data.fields[map.fieldId2] : ""
              let aggregatorValue = aggregatorsItems[fieldId][aggregatorsItems[fieldId].length - 1][map.fieldId2] ? aggregatorsItems[fieldId][aggregatorsItems[fieldId].length - 1][map.fieldId2] : ""
              if (agrValue !== aggregatorValue) {
                nextCombination = true
              }
            })
            if (nextCombination) {
              aggregator.filterMap.forEach(map => {
                agrItem[map.fieldId2] = agrItems[i].data.fields[map.fieldId2] ? agrItems[i].data.fields[map.fieldId2] : ""
              })
              agrItem[aggregator.fieldId2] = agrItems[i].data.fields[aggregator.fieldId2] ? agrItems[i].data.fields[aggregator.fieldId2] : 0
              agrItem.count = 1
              aggregatorsItems[fieldId].push(JSON.parse(JSON.stringify(agrItem)))
            }
            else {
              let agrValue = agrItems[i].data.fields[aggregator.fieldId2] ? agrItems[i].data.fields[aggregator.fieldId2] : 0
              if (aggregator.operation === "sum" || aggregator.operation === "avg") {
                aggregatorsItems[fieldId][aggregatorsItems[fieldId].length - 1][aggregator.fieldId2] = aggregatorsItems[fieldId][aggregatorsItems[fieldId].length - 1][aggregator.fieldId2] + agrValue
              }
              if (aggregator.operation === "min") {
                if (aggregatorsItems[fieldId][aggregatorsItems[fieldId].length - 1][aggregator.fieldId2] > agrItems[i].data.fields[aggregator.fieldId2]) {
                  aggregatorsItems[fieldId][aggregatorsItems[fieldId].length - 1][aggregator.fieldId2] = agrItems[i].data.fields[aggregator.fieldId2]
                }
              }
              if (aggregator.operation === "max") {
                if (aggregatorsItems[fieldId][aggregatorsItems[fieldId].length - 1][aggregator.fieldId2] < agrItems[i].data.fields[aggregator.fieldId2]) {
                  aggregatorsItems[fieldId][aggregatorsItems[fieldId].length - 1][aggregator.fieldId2] = agrItems[i].data.fields[aggregator.fieldId2]
                }
              }
              aggregatorsItems[fieldId][aggregatorsItems[fieldId].length - 1].count = aggregatorsItems[fieldId][aggregatorsItems[fieldId].length - 1].count + 1
            }
          }
        }
      }
      this.aggregatorsItems = aggregatorsItems
    },


  }
}
</script>

<style scoped>

</style>