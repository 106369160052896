<template>

  <div v-if="fieldId === 'z1'" :style="item.id === '' ? 'color: lightgrey; display: inline-block' : 'display: inline-block'">
      {{itemNumber}}
  </div>
  <span v-if="fieldId === 'z2'" style="cursor: pointer">
    <sl-list-item-status :item="item"/>
<!--    <q-icon name="circle_outlined" color="grey" v-if="item.id===''"/>-->
<!--    <q-icon name="circle" color="green" v-else-if="item.status === 'updated'"/>-->
<!--    <q-icon name="circle" color="blue-lighten-3" v-else-if="item.status === 'changing'"/>-->
<!--    <q-icon name="circle" color="yellow" v-else-if="item.status === 'changed'"/>-->
<!--    <q-icon name="circle" color="grey" v-else/>-->
  </span>
  <span v-if="fieldId === 'z3'">
      <q-btn icon="keyboard_arrow_right" round size="xs" dense v-if="item.id!==''"/>
    </span>
  <span v-if="list.data.fields[fieldId].type === 'drilldown'">
      <q-btn icon="content_paste_go" round size="sm" dense v-if="item.id!==''"
             @click="$emit('drilldownListSelected', {fieldId: fieldId, item: item})"
      />
    </span>
  <q-icon name="article" size="xs" style="cursor: pointer"
          v-if="list.data.fields[fieldId].showInSeparateWindow"
          :color="item.data.fields[fieldId] === '' || item.data.fields[fieldId] === undefined ? 'grey' : 'green'"/>

  <q-img :src="cellString" :style="cellStringStyle" v-if="list.data.fields[fieldId].type === 'thumbnail' && item.id !== ''" no-spinner/>

  <q-icon :name="item.data.fields[fieldId] ? 'check_box' : 'check_box_outline_blank'"
          v-else-if="list.data.fields[fieldId].type === 'checkbox'"
          :color="item.data.fields[fieldId] ? 'green' : 'grey'"
          size="sm"
  />

<!--  <q-checkbox dense v-model="checkboxVal" color="green"-->
<!--              v-else-if="list.data.fields[fieldId].type === 'checkbox' && this.item.id !== ''"-->
<!--              @update:model-value="$emit('checkboxUpdated', $event)"-->
<!--              @dblclick="checkboxVal = !checkboxVal"-->
<!--  />-->

  <q-chip v-else-if="fieldInitialType === 'avatar' && avatarData" color="white" _dense  style="margin-top: -1px; margin-bottom: -1px; _box-shadow: 0 0 8px lightgrey">
    <q-avatar>
      <img :src="avatarData.src">
    </q-avatar>
    {{avatarData.name}}
  </q-chip>
  <div :style="cellStringStyle" v-else-if="cellString!==' '" v-html="cellString" _class="q-mx-xs"></div>
<!--  &nbsp;-->


</template>

<script>
import {cellStringStyleForSublist, objVal} from "@/utils4";
// import {numFormat} from "@/utils3";
import SlListItemStatus from "@/components/sl-list-item-status";
import {numFormat} from "@/utils3";

export default {
  name: "sl-list-cell-content",
  components: {SlListItemStatus},
  props: ["fieldId", "item", "view", "list", "itemNumber", "currentCell", "upperGroupFields", "upperGroupFieldId", "subitemIndex"],
  emits: ["checkboxUpdated", "drilldownListSelected"],
  data: () => {
    return {
      // checkboxVal: null,
      fieldInitialType: ""
    }
  },
  created() {
    // debugger
    // if(this.list.data.fields[this.fieldId].type === "checkbox"){
    //   this.checkboxVal = this.item.data.fields[this.fieldId] === "X" ? true : false
    // }
    if(this.list.data.fields[this.fieldId].type === "sublist"){
      let listIdForSublist = this.list.data.fields[this.fieldId].listIdForSublist
      let fieldIdForSublist = this.list.data.fields[this.fieldId].fieldIdForSublist
      if(listIdForSublist && fieldIdForSublist){
        // this.fieldInitialType = this.list.sublists[listIdForSublist].list.data.fields[fieldIdForSublist].type
        this.fieldInitialType = this.$store.state.sublists[listIdForSublist]?.list.data.fields[fieldIdForSublist].type
      }
    }
    else{
      this.fieldInitialType = this.list.data.fields[this.fieldId].type
    }
  },
  computed: {
    sublistItem(){
      let sublistItem = ""
      let sublistItems = []
      if(this.list.data.fields[this.fieldId].type === "sublist" && this.list.data.fields[this.fieldId].isFixedSublist && this.item.data.fields[this.fieldId]){
        sublistItems = this.item.data.fields[this.fieldId].split(";")
      }
      if(this.list.data.fields[this.fieldId].type === "sublist" && !this.list.data.fields[this.fieldId].isFixedSublist && this.item.data.fieldsS[this.fieldId]){
        sublistItems = this.item.data.fieldsS[this.fieldId].split(";")
      }
      sublistItem = sublistItems[this.subitemIndex]
      return sublistItem
    },
    avatarData(){
      let avatarData = null
      let avatarList = null
      let avatarfieldId = null
      let avatarItem = null
      // debugger
      // if(this.list.data.fields[this.fieldId].type === "sublist"){
      //   debugger
      // }
      if(this.list.data.fields[this.fieldId].type === "avatar"){
        avatarList = this.list
        avatarItem = this.item
        avatarfieldId = this.fieldId
      }
      // if(this.list.data.fields[this.fieldId].type === "sublist" && this.list.sublists){
      if(this.list.data.fields[this.fieldId].type === "sublist" && this.$store.state.sublists){
        if(!this.item.data.fieldsS[this.fieldId]) return
        let listIdForSublist = this.list.data.fields[this.fieldId].listIdForSublist
        let fieldIdForSublist = this.list.data.fields[this.fieldId].fieldIdForSublist
        // let viewIdForSublist = this.list.data.fields[this.fieldId].viewIdForSublist
        // let sublist = objVal([this.list.sublists, listIdForSublist], {list: {}, items: []})
        let sublist = objVal([this.$store.state.sublists, listIdForSublist], {list: {}, items: []})
        let type = objVal([sublist.list, "data", "fields", fieldIdForSublist, "type"], "")
        if(type === "avatar"){
          avatarList = sublist.list
          avatarItem = sublist.items.find(el => el.id === this.sublistItem)
          avatarfieldId = fieldIdForSublist
        }
      }
      // debugger
      if(!avatarList) return
      let avatar = avatarList.data.fields[avatarfieldId]

      if(avatar.avatarImgFieldId && avatarItem && avatarItem.data.fields[avatar.avatarImgFieldId]){
        // console.log("URL", avatarItem.data.fields[avatar.avatarImgFieldId])
        avatarData = {}
        // avatarData.src = URL.createObjectURL(avatarItem.data.fields[avatar.avatarImgFieldId])
        avatarData.src = avatarItem.data.fields[avatar.avatarImgFieldId]
        avatarData.name = avatarItem.data.fields[avatar.avatarNameFieldId]
      }
      else if (this.item.id !== '' && avatarList.data.fields[avatarfieldId].type === "avatar") {
        avatarData = {}
        avatarData.src = require('../assets/defaultPictureUser.svg')
        avatarData.name = objVal([avatarItem, 'data', 'fields', avatar.avatarNameFieldId], "")
      }
      return avatarData
    },
    cellString(){
      let cellString = ""
      if(this.list.data.fields[this.fieldId].type === "thumbnail"){
        if(this.item.data.fields[this.fieldId]){
          // console.log(this.item.data.fields[this.fieldId])
          //cellString = URL.createObjectURL(this.item.data.fields[this.fieldId])
          cellString = this.item.data.fields[this.fieldId]
        }
        else if (this.item.id !== '') {
          cellString = require('../assets/defaultPictureUser.svg')
        }
        return cellString
      }
      if(this.item.data.fields[this.fieldId] === "" || this.item.data.fields[this.fieldId] === undefined) return " "
      if(this.list.data.fields[this.fieldId].type !== "num" && this.list.data.fields[this.fieldId].type !== "text"){
        cellString = this.item.data.fields[this.fieldId]
      }
      if(this.list.data.fields[this.fieldId].type === "date" && this.list.data.fields[this.fieldId].dateLocales !== undefined && this.list.data.fields[this.fieldId].dateLocales !== ""){
        cellString = new Date(this.item.data.fields[this.fieldId]).toLocaleDateString(this.list.data.fields[this.fieldId].dateLocales)
      }
      if(this.list.data.fields[this.fieldId].type === "num"){
        cellString = numFormat(cellString, this.list.data.fields[this.fieldId])
      }
      if(this.list.data.fields[this.fieldId].type === "text"){
        cellString = this.item.data.fields[this?.fieldId]
      }
      if(this.list.data.fields[this.fieldId].type === "text" && this.list.data.fields[this.fieldId].showInSeparateWindow){
        cellString = " "
      }
      if(this.list.data.fields[this.fieldId].type === "sublist" && this.list.data.fields[this.fieldId].isFixedSublist){
        cellString = this.sublistItem
      }
      if(this.list.data.fields[this.fieldId].type === "sublist" && !this.list.data.fields[this.fieldId].isFixedSublist){
        let listIdForSublist = this.list.data.fields[this.fieldId].listIdForSublist
        let fieldIdForSublist = this.list.data.fields[this.fieldId].fieldIdForSublist
        let sublist = objVal([this.$store.state.sublists, listIdForSublist], {list: {}, items: []})
        let type = objVal([sublist.list, "data", "fields", fieldIdForSublist, "type"], "")
        if(type !== "avatar"){
          let item = sublist.items.find(el => el.id === this.sublistItem)
          cellString = item ? item.data.fields[fieldIdForSublist] : ""
        }
      }
      return cellString
    },




    cellStringStyle(){
      // debugger
      if(this.view._data.fields[this.fieldId].group){
        // return {}
      }
      if(this.list.data.fields[this.fieldId].type === "thumbnail" && this.item.id !== ''){
        return this.view._data.fields[this.fieldId].cellStringStyle
      }
      if(this.item.data.fields[this.fieldId] === "" || this.item.data.fields[this.fieldId] === undefined) return {}
      let cellStringStyle
      if(this.list.data.fields[this.fieldId].type === "sublist" && !this.list.data.fields[this.fieldId].isFixedSublist) {
        // cellStringStyle = this.view._sublists[this.fieldId][this.item.data.fieldsS[this.fieldId]].style.cellStringStyle
        // let sublistList = objVal([this.list, "sublists", this.list.data.fields[this.fieldId].listIdForSublist, "list"], {})
        let sublistList = objVal([this.$store.state.sublists, this.list.data.fields[this.fieldId].listIdForSublist, "list"], {})
        let viewIdForSublist = this.list.data.fields[this.fieldId].viewIdForSublist
        let fieldIdForSublist = this.list.data.fields[this.fieldId].fieldIdForSublist
        let sublistItemId = this.item.data.fieldsS[this.fieldId]
        let fixedValue = this.item.data.fields[this.fieldId]
        cellStringStyle = cellStringStyleForSublist(sublistList, viewIdForSublist, fieldIdForSublist, sublistItemId, fixedValue)
      }
      else if(this.list.data.fields[this.fieldId].type === "sublist" && this.list.data.fields[this.fieldId].isFixedSublist){
        let fixedListEl = this.list.data.fields[this.fieldId].fixedSublist.find(el => el.value === this.item.data.fields[this.fieldId])
        fixedListEl ? cellStringStyle = fixedListEl.style : cellStringStyle = {}
      }
      else{
        cellStringStyle = {
          ...this.view._data.fields[this.fieldId].cellStringStyle,
          ...{}
        }
        if (this.view._data.cellsProps) {
          if (this.view._data.cellsProps[this.item.id]) {
            if (this.view._data.cellsProps[this.item.id][this.fieldId]) {
              cellStringStyle = {
                ...cellStringStyle,
                ...this.view._data.cellsProps[this.item.id][this.fieldId].cellStringStyle
              }
            }
          }
        }
      }
      cellStringStyle = JSON.parse(JSON.stringify(cellStringStyle))
      // if(this.view._data.fields[this.fieldId].group){
      //   s.opacity = "0.1"
      // }

      cellStringStyle.display = "inline"
      //cellStringStyle.margin = "1px 2px 1px 2px"

      return cellStringStyle
    },
  },
  methods: {
    // checkboxValUpdate(){
    //   this.checkboxVal = !this.checkboxVal
    //   this.$emit('checkboxUpdated', this.checkboxVal)
    // }
  }
}
</script>

<style scoped>

</style>