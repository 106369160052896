<template>
  <div>
<table>
  <thead>
    <td v-for="z in cols" :key="z" :id="rows.length+1+'_'+z" class="_bg-grey" contenteditable="true"
        :style="ci==i && cj==j ? 'border: 2px solid blue' : 'padding: 5px'">
      dsgf
    </td>
  </thead>

  <tr v-for="i in rows" :key="i">
    <td v-for="j in cols" :key="j" :id="i+'_'+j" :class="ci==i && cj==j ? '_bg-green-3' : ''" contenteditable="true"
    :style="ci==i && cj==j ? 'border: 2px solid blue' : 'padding: 5px'">
      dsgf
    </td>
  </tr>

  <tr>
    <td v-for="z in cols" :key="z" :id="rows.length+1+'_'+z" :class="ci==i && cj==j ? '_bg-green-3' : ''" contenteditable="true"
        :style="ci==i && cj==j ? 'border: 2px solid blue' : 'padding: 5px'">
      dsgf
    </td>
  </tr>
</table>
  key: {{key}}
  ci: {{ci}}
  cj: {{cj}}
  </div>
</template>

<script>
export default {
  name: "test-table2",
  data: () => {
    return {
      key: "",
      rows: 10,
      cols: 10,
      ci: 1,
      cj: 1
    }
  },
  mounted() {
    // let that = this
    //this.$el.querySelectorAll('tr')[this.ci-1].querySelectorAll('td')[this.cj-1].focus()
    window.addEventListener("keydown", (event) => {
      if (event.defaultPrevented) {
        return; // Do nothing if the event was already processed
      }
      this.key = event.key

      if(this.$el.querySelector('td:focus') === null){
        if (event.key === 'ArrowLeft' && this.cj > 1) this.cj--
        if (event.key === 'ArrowRight' && this.cj < this.cols) this.cj++
        if (event.key === 'ArrowUp' && this.ci > 1) this.ci--
        if (event.key === 'ArrowDown' && this.ci < this.rows) this.ci++
        event.preventDefault()
      }
      if (event.key === 'F2') {
        if(this.$el.querySelector('td:focus') === null){
          this.$el.querySelectorAll('tr')[this.ci-1].querySelectorAll('td')[this.cj-1].focus()
        }
        else{
          this.$el.querySelector('td:focus').blur()
        }
      }
      let buttons = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab', 'F2']
      if(buttons.includes(event.key)) {
        //event.preventDefault()
      }

      let ArrowButtons = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']
      let currentCell = {}
      if(ArrowButtons.includes(event.key)) {

        currentCell.width = this.$el.querySelectorAll('tr')[this.ci-1].querySelectorAll('td')[this.cj-1].clientWidth
        currentCell.height = this.$el.querySelectorAll('tr')[this.ci-1].querySelectorAll('td')[this.cj-1].clientHeight
        currentCell.left = this.$el.querySelectorAll('tr')[this.ci-1].querySelectorAll('td')[this.cj-1].offsetLeft
        currentCell.top = this.$el.querySelectorAll('tr')[this.ci-1].querySelectorAll('td')[this.cj-1].offsetTop

        let scrollArea = this.$parent
        let scrollInfo = scrollArea.getScroll()
        console.log("---")
        console.log(scrollInfo)
        console.log(currentCell)
        if(currentCell.top+currentCell.height > scrollInfo.verticalPosition+scrollInfo.verticalContainerSize){
          scrollArea.setScrollPosition('vertical', currentCell.top+currentCell.height-scrollInfo.verticalContainerSize+5, 300)
        }
        if(currentCell.top < scrollInfo.verticalPosition){
          scrollArea.setScrollPosition('vertical', currentCell.top-5, 300)
        }
        if(currentCell.left+currentCell.width > scrollInfo.horizontalPosition+scrollInfo.horizontalContainerSize){
          scrollArea.setScrollPosition('horizontal', currentCell.left+currentCell.width-scrollInfo.horizontalContainerSize+5, 300)
        }
        if(currentCell.left < scrollInfo.horizontalPosition){
          scrollArea.setScrollPosition('horizontal', currentCell.left-5, 300)
        }
        // let scrollAreaPositionTop = scrollInfo.verticalPosition
        // let scrollAreaPositionBottom = scrollAreaPositionTop + scrollInfo.verticalContainerSize
        // if(scrollAreaPositionBottom < currentCellOffsetBottom){
        //   scrollArea.setScrollPosition('vertical', scrollAreaPositionTop+currentCellOffsetBottom-scrollInfo.verticalContainerSize, 300)
        // }



        // let currentCellOffsetRight = currentCellOffsetLeft + currentCellWidth
        // let currentCellOffsetBottom = currentCellOffsetTop + currentCellHeight

        // let scrollAreaOffsetRight = scrollAreaOffsetLeft + scrollAreaWidth
        // let scrollAreaOffsetBottom = scrollAreaOffsetTop + scrollAreaHeight

        // let scrollAreaWidth = this.$el.closest('.q-scrollarea').clientWidth
        // let scrollAreaHeight = this.$el.closest('.q-scrollarea').clientHeight
        // let scrollAreaOffsetLeft = this.$el.closest('.q-scrollarea').offsetLeft
        // let scrollAreaOffsetTop = this.$el.closest('.q-scrollarea').offsetTop
        // let scrollArea = this.$el.closest('.q-scrollarea')




        // let verticalOffset
        // debugger
        // if(scrollAreaOffsetBottom < currentCellOffsetBottom){
        //   verticalOffset = currentCellOffsetBottom - currentCellHeight - scrollAreaHeight
        //   scrollArea.value.setScrollPosition('vertical', verticalOffset, 300)
        // }
        // console.log(this.$parent.getScroll())
        // console.log(scrollAreaOffsetRight)
        //
        // console.log('scrollAreaOffsetRight: '+scrollAreaOffsetRight)
        // console.log('currentCellHeight: '+currentCellHeight)
        // console.log('scrollAreaHeight: '+scrollAreaHeight)
        // console.log('scrollAreaOffsetBottom: '+scrollAreaOffsetBottom)
        // console.log('currentCellOffsetBottom: '+currentCellOffsetBottom)




        this.$emit("focusMoved", {
          // currentCellOffsetLeft: currentCellOffsetLeft,
          // currentCellOffsetTop: currentCellOffsetTop,
          // currentCellOffsetRight: currentCellOffsetRight,
          // currentCellOffsetBottom: currentCellOffsetBottom
        })
      }


    }, true);
  },
  methods: {
    pressed(){
      //console.log(this.$el.querySelector('#'+this.ci+'_'+this.cj))
      console.log(this.$el.querySelectorAll("tr")[2].querySelectorAll("td")[2])
    }
  }
}
</script>

<style scoped>
table, th, td {
  border: 0px;

}
thead > td {
  border-bottom: 2px solid grey;
}
td, th {
  background-color: white;
  _border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding: 3px;
}
tr:last-of-type > td {
  _border-right: 0px;
  border-bottom: 0px;
}
table {
  border-spacing: 0px;
  background-color: #ccd0c7
}

</style>