<template>
  <sl-z-users :spaceId="spaceId"/>
</template>

<script>
import SlZUsers from "@/components/sl-z-users";
import {useRoute} from "vue-router/dist/vue-router";
export default {
  name: "ZUsersView",
  components: {SlZUsers},
  setup(){
    const route = useRoute()
    let spaceId = route.params.spaceId
    return {
      spaceId
    }
  }
  }
</script>

<style scoped>

</style>