<template>
  <q-card>
    <q-card-section>Drilldown lists</q-card-section>
    <q-card-section>
      <q-list>
        <q-item v-for="(drilldownList, i) in drilldownLists" :key="i">
          <q-item-section v-if="lists.find(list => list.id === drilldownList.listId)">
            {{drilldownList.name}}
            <div v-for="(field, j) in drilldownList.fields" :key="j">
              ({{field.id}}) {{ field.id === 'id' ? 'ID' : list.data.fields[field.id].name }}
              :
              ({{field.ddlId}}) {{lists.find(list => list.id === drilldownList.listId).data.fields[field.ddlId].name}}
              <q-icon name="cancel" @click="drilldownList.fields.splice(j, 1)"/>
            </div>
            <q-icon name="add" @click="fieldsMappingFormOpen(i)"/>
          </q-item-section>
        </q-item>
      </q-list>
      <q-btn icon="add" round @click="drilldownListAddFormShow=true"/>
    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn flat @click="$emit('finished')">Cancel</q-btn>
      <q-btn flat @click="drilldownListsSave()">Save</q-btn>
    </q-card-actions>
    <q-dialog v-model="fieldsMappingFormShow">
      <q-card>
        <q-card-section>Fields mapping</q-card-section>
        <q-card-section>
          <q-select v-model="fieldsOptionsFieldId"
                    :options="fieldsOptions"
                    option-value="id"
                    option-label="name"
                    emit-value
                    map-options
                    label="List field"
          />
          <q-select v-model="ddlFieldsOptionsFieldId"
                    :options="ddlListFieldsOptions"
                    option-value="id"
                    option-label="name"
                    emit-value
                    map-options
                    label="Drilldown list field"
          />
        </q-card-section>
        <q-card-actions class="justify-center">
          <q-btn flat @click="fieldsMappingFormShow=false">Cancel</q-btn>
          <q-btn flat @click="addMapping()">Add</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="drilldownListAddFormShow">
      <q-card>
        <q-card-section>Add drilldown list</q-card-section>
        <q-card-section>
          <q-input v-model="newDrilldownListName" label="Drilldown list name"/>
          <q-select v-model="ddlListsOptionsListId"
                    :options="ddlListsOptions"
                    option-value="id"
                    option-label="name"
                    emit-value
                    map-options
                    label="Drilldown list"
          />
          <q-select v-model="ddlViewsOptionsViewId"
                    :options="ddlViewsOptions"
                    option-value="id"
                    option-label="name"
                    emit-value
                    map-options
                    label="Drilldown view"
          />
        </q-card-section>
        <q-card-actions class="justify-center">
          <q-btn flat @click="fieldsMappingFormShow=false">Cancel</q-btn>
          <q-btn flat @click="addDrilldownList()">Add</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

  </q-card>
</template>

<script>
import {db} from "@/db"
import {toRaw} from "vue";
import {generateId} from "@/utils";
export default {
  name: "sl-list-drilldown-form",
  props: ["list"],
  data: () => {
    return {
      drilldownLists: {},
      lists: [],
      views: [],
      ddlListId: "",
      fieldsOptionsFieldId: "",
      ddlFieldsOptionsFieldId: "",
      fieldsMappingFormShow: false,
      currentDrilldownListIndex: null,
      drilldownListAddFormShow: false,
      ddlListsOptionsListId: "",
      ddlViewsOptionsViewId: "",
      newDrilldownListName: ""

    }
  },
  async created() {
    if(this.list.data.drilldownLists) this.drilldownLists = this.list.data.drilldownLists
    this.lists = await db.lists.where("spaceId").equals(this.list.spaceId).toArray()
  },
  watch: {
    async ddlListsOptionsListId(newVal){
      // this.views = await db.views.where("data.listId").equals(newVal).toArray()
      let list = this.lists.find(list => list.id === newVal)
      if(list) this.views = list.data.views

    }
  },
  computed: {
    fieldsOptions(){
      let fields = []
      fields.push({id: "id", name: "ID"})
      for(let fieldId in this.list.data.fields){
        if(fieldId.charAt(0) === "f")  fields.push({id: fieldId, name: this.list.data.fields[fieldId].name})
      }
      return fields
    },
    ddlListFieldsOptions(){
      let ddlListFields = []
      let fields = this.lists.find(el=>el.id===this.ddlListId).data.fields
      for(let fieldId in fields){
        if(fieldId.charAt(0) === "f") ddlListFields.push({id: fieldId, name: fields[fieldId].name})
      }
      return ddlListFields
    },
    ddlListsOptions(){
      let ddlListsOptions = []
      for(let list of this.lists){
        ddlListsOptions.push({id: list.id, name: list.data.name})
      }
      return ddlListsOptions
    },
    ddlViewsOptions(){
      let ddlViewsOptions = []
      for(let viewId in this.views){
        ddlViewsOptions.push({id: this.views[viewId].id, name: this.views[viewId].data.name})
      }
      return ddlViewsOptions
    }
  },
  methods: {
    addMapping(){
      this.drilldownLists[this.currentDrilldownListIndex].fields.push({id: this.fieldsOptionsFieldId, ddlId: this.ddlFieldsOptionsFieldId})
      this.fieldsMappingFormShow = false
    },
    fieldsMappingFormOpen(currentDrilldownListIndex){
      this.fieldsOptionsFieldId = ""
      this.ddlFieldsOptionsFieldId = ""
      this.currentDrilldownListIndex = currentDrilldownListIndex
      this.ddlListId = this.drilldownLists[currentDrilldownListIndex].listId
      this.fieldsMappingFormShow = true
    },
    addDrilldownList(){
      if(this.newDrilldownListName === "" || this.ddlListsOptionsListId === "") return
      let ddlId = generateId()
      this.drilldownLists[ddlId] = {
        id: ddlId,
        name: this.newDrilldownListName,
        spaceId: this.list.spaceId,
        listId: this.ddlListsOptionsListId,
        viewId: this.ddlViewsOptionsViewId,
        fields: []
      }
      this.drilldownListAddFormShow = false
    },
    drilldownListsSave(){
      debugger
      db.lists.where('id').equals(this.list.id).modify(list => list.data.drilldownLists = toRaw(this.drilldownLists))
          .then(() => {
            this.$emit("finished")
          })
    }
  }
}
</script>

<style scoped>

</style>