<template>
  <div style="max-width: 600px">
    <div class="tmpl-item" v-for="(item, i) in items" :key="i">
<!--      first row-->
      <div class="tmpl-row row justify-left items-center">

        <div class="tmpl-block col-12">
          <div class="tpl-blockName">
          </div>
          <div class="tpl-blockVal" contenteditable="true">
<!--            <q-avatar size="90px">-->
              <q-img src="https://cdn.quasar.dev/img/parallax2.jpg" width="100%" fit="cover" style="border-radius: 15px 15px 15px 15px"/>
<!--            </q-avatar>-->
          </div>
        </div>



        <div class="tmpl-block" >
          <div class="tpl-blockName">
            Country
          </div>
          <div class="tpl-blockVal" contenteditable="true">
            {{ item.country }}
          </div>
        </div>
        <div class="tmpl-block">
          <div class="tpl-blockName" style="max-width: 100px">
            Capital Capital Capital Capital Capital Capital
          </div>
          <div class="tpl-blockVal" _contenteditable="true">
            {{ item.capital }}
          </div>
        </div>
        <div class="tmpl-block">
          <div class="tpl-blockName">
            Description
          </div>
          <div class="tpl-blockVal" contenteditable="true" style="text-align: left">
            {{ item.desc }}
          </div>
        </div>
        <div class="tmpl-block">
          <div class="tpl-blockName">
            Dates
          </div>
          <div class="tpl-blockVal" contenteditable="true">
            <div class="row">
              <div>{{ item.startDate }}</div> -
              <div>{{ item.endDate }}</div>
            </div>
          </div>
        </div>
      </div>

      <!--    Second line-->
      <div class="bg-grey-1 row justify-right" style="padding: 4px">
        <q-btn flat round size="sm" icon="add_circle"/>
        <q-btn flat round size="sm" icon="add_alarm"/>
        <q-btn flat round size="sm" icon="av_timer"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "test-t10",
  data: () => {
    return {
      template: {
        divs: [],
        classes: ['row']
      },
      items: [
        {
          country: "Russia", capital: "Moscow",
          desc: "The biggest country in the world with more then 10 timezones",
          startDate: "2023/06/12", endDate: "2023/08/23"
        },
        {
          country: "Moldova", capital: "Chisinau",
          desc: "Beautiful sunny country",
          startDate: "2023/06/12", endDate: "2023/08/23"
        },
        {
          country: "Ukraine", capital: "Kiev",
          desc: "Native country",
          startDate: "2023/06/12", endDate: "2023/08/23"
        },
        {
          country: "Belarus", capital: "Minsk",
          desc: "Slavik country",
          startDate: "2023/06/12", endDate: "2023/08/23"
        },
      ]
    }
  },
  methods: {
    siblingWidthGet(el){
      console.log(el)
    }
  }
}
</script>

<style scoped>

_div {
  margin: 4px;
  padding: 4px
}

.fieldName {
  z-index: 10;
  position: relative;


}

.tpl-blockName {
  position: absolute;
  margin: -8px 0 -3px 0px;
  padding-left: 1px;
  font-size: 9px;
  font-weight: BOLD;
  color: #bb000e;
  _background-color: #2196f3;
  white-space: nowrap;
  overflow: hidden
}

.tpl-blockVal {
  position: relative;
  _background-color: yellow;
  margin-top: 4px;
  padding-top: 1px;
  line-height: 1.1;
}

.tmpl-block {
  margin: 6px;
  padding: 2px;
  _background-color: green;
}
.tmpl-item {
  margin: 5px;
  padding-top: 10px;
  border-bottom: 1px solid lightgray;
}
.tmpl-item:first-of-type {
  border-top: 1px solid lightgray;
}

</style>