<template>
<q-card>
  <q-card-section>
    <q-chip v-for="(test, i) in tests" :key="i" :label="test.name" @click="testSelect(i)" clickable/>
  </q-card-section>
  <q-card-section v-if="testSelectedIndex!==null">
    {{tests[testSelectedIndex].description}}
    <q-btn dense class="q-ma-sm" label="run" @click="testRunIndex=testSelectedIndex" size="sm"/>
  </q-card-section>
  <q-card-section v-if="testRunIndex!==null">
    <component :is="tests[testRunIndex].component"/>
  </q-card-section>
</q-card>
</template>

<script>
import TestT5 from "@/components/test-t5"
import TestT6 from "@/components/test-t6"
import TestT7 from "@/components/test-t7"
import TestT8 from "@/components/test-t8"
import TestT9 from "@/components/test-t9"
import TestT10 from "@/components/test-t10"
import TestT11 from "@/components/test-t11"
import TestT12 from "@/components/test-t12"
import TestT13 from "@/components/test-t13"
import TestT999 from "@/components/test-t999"
export default {
  name: "TestsOverview",
  components: { TestT5, TestT6, TestT7, TestT8, TestT9, TestT10, TestT11, TestT12, TestT13, TestT999 },
  data: () => {
    return {
      tests: [],
      testSelectedIndex: null,
      testRunIndex: null
    }
  },
  created(){
    this.tests.push({
      name: "test 5",
      description: "Performance test: total amount calculation of N columns and M rows",
      component: "test-t5"
    })
    this.tests.push({
      name: "test 6",
      description: "Performance test: table with 1M records, field for select is array. Select all records where field has any of array",
      component: "test-t6"
    })
    this.tests.push({
      name: "test 7",
      description: "Mass processing: Show items without $data.lastTS. Add lastTS to $data with value = 0",
      component: "test-t7"
    })
    this.tests.push({
      name: "test 8",
      description: "Gant",
      component: "test-t8"
    })
    this.tests.push({
      name: "test 9",
      description: "Table using tag div",
      component: "test-t9"
    })
    this.tests.push({
      name: "test 10",
      description: "Item template for mobile",
      component: "test-t10"
    })
    this.tests.push({
      name: "test 11",
      description: "sl-list5",
      component: "test-t11"
    })
    this.tests.push({
      name: "test 12",
      description: "sl-list6",
      component: "test-t12"
    })
    this.tests.push({
      name: "test 13",
      description: "dbIndex",
      component: "test-t13"
    })
    this.tests.push({
      name: "test 999",
      description: "mini tests",
      component: "test-t999"
    })
  },
  methods: {
    testSelect(i){
      this.testSelectedIndex = i
      this.testRunIndex = null
    }
  }
}
</script>

<style scoped>

</style>