/* eslint-disable */
import {currentUserId, db} from './db'
import 'dexie-observable'
import {initializeApp} from "firebase/app"
import store from './store.js'
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where
} from "firebase/firestore"
import {
  doc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  updateDoc,
  orderBy
} from "firebase/firestore"
import { getFunctions, httpsCallable } from "firebase/functions"

import {
  decryptShared,
  encryptShared,
  // generalParamsGet,
  paramsGet,
  generateId,
  // generateKey,
  generateKeyPair,
  sharedKeys, encrypt, decrypt
} from "@/utils"
import {consoleLog} from "@/utils3";
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyDq7oeMHMZjXdfXPGdB1o517ebGI3t9_9M",
  authDomain: "slists-e213b.firebaseapp.com",
  projectId: "slists-e213b",
  storageBucket: "slists-e213b.appspot.com",
  messagingSenderId: "264683204633",
  appId: "1:264683204633:web:d6c93e8d83b8b7ae67ba88",
  measurementId: "G-X9RMPBNNV5"
}

const app = initializeApp(firebaseConfig)
const fdb = getFirestore(app)
const auth = getAuth()

const functions = getFunctions(app)
// const fbf_test1 = httpsCallable(functions, 'test1');
const fbFunc_objSet = httpsCallable(functions, 'objSet')

// let userId = ""
// db.params.get("currentUser").then((currentUser) => {
//   userId = currentUser.userId
// })

const listeners = {}

const TIMEOUT = 25 * 1000
const timeoutGet = (space) => {
  let timeoutGet = TIMEOUT
  if(space.data.uploadDataDelay){
    timeoutGet = space.data.uploadDataDelay * 1000
  }
  return timeoutGet
}

export const syncSet = () => {

  db.on('changes', (changes) => {
    console.log("changes: ", changes)
    changes.forEach(async function (change) {
      consoleLog({text: "db-sync.js > syncSet() > db.on()",
        params: [["change", change]],
        filter: {
          spaceId: change.table === "spaces" ? change.obj.id: "",
          listId: change.table === "lists" ? change.obj.id: "",
          itemId: change.table === "items" ? change.obj.id: ""
        }
      })

      // drilldownFilter generation
      if(change.table === "lists"){
        let isRequiredGen = false
        for(let fieldId in change.obj.data.fields){
          if(change.obj.data.fields[fieldId].type !== "sublist") continue
          if(!change.oldObj.data.fields[fieldId]){
            isRequiredGen = true
            break
          }
          if(change.obj.data.fields[fieldId].fieldIdForSublist !== change.oldObj.data.fields[fieldId].fieldIdForSublist ||
            change.obj.data.fields[fieldId].listIdForSublist !== change.oldObj.data.fields[fieldId].listIdForSublist ||
            change.obj.data.fields[fieldId].sublistBasedOnFieldId !== change.oldObj.data.fields[fieldId].sublistBasedOnFieldId
          ){
            isRequiredGen = true
            break
          }
        }
        if(isRequiredGen) drilldownFilterGen(change.obj.spaceId, change.obj.id)
      }

      // store lists
      if(change.table === "lists"){
        for (const list5Id in store.state.lists) {
          if(store.state.lists[list5Id].listId === change.obj.id) {
            store.commit("listsListUpdate", {list: change.obj, list5Id: list5Id})
          }
        }
      }

      if(change.table === "items"){
        for (const list5Id in store.state.lists) {
          let viewData = store.state.lists[list5Id].list.data.views[store.state.lists[list5Id].viewId]
          if(store.state.lists[list5Id].listId === change.obj.data.listId) {
            // is fit to fixedSelCriteria?
            let isFit = true
            for(let fieldId in viewData.fixedSelCriteria){
              let indx = viewData.fixedSelCriteria[fieldId].findIndex(el => el===change.obj.data.fieldsS[fieldId])
              if(indx === -1) isFit = false
            }
            if(isFit) {
              await sublistSubstitute(list5Id, change.obj)
              formulaRun(store.state.lists[list5Id].list.data, "beforeDisplay", change.obj.data.fields)
              debugger
              store.commit("listsItemUpdate", {item: change.obj, list5Id: list5Id})
              listSortSubtotal(list5Id)
            }
          }
        }
      }

      // previous filter
      let tablesToUpdate = ['spaces', 'lists', 'items', 'tasks', 'comments']
      if(!tablesToUpdate.includes(change.table)) return
      if(change.obj.status !== "created" && change.obj.status !== "changed") return

      // save mods
      if(change.obj.status === "changed") {
        let modKey = change.table + "_" + change.obj.id + "_" + change.obj.$data.version + "_" + change.obj.$data.lastTS
        await db.table('mods').put({
          id: modKey,
          table: change.table,
          recordId: change.obj.id,
          version: change.obj.$data.version,
          mods: change.mods
        })
      }

      // stop uploading if not authorized or offline mode
      if(!auth.currentUser) return
      if(!window.navigator.onLine) return

      // uploading to fb
      let spaceId = change.table === "spaces" ? change.obj.id : change.obj.spaceId

      db.table("spaces").get(spaceId).then(space => {

        // don't upload to fb, if space is unpublished
        if (space.status === "unpublished") return

        // updoad to fb after timeout
        setTimeout(() => {

          db.table(change.table).get(change.obj.id).then( (obj) => {
            if (change.obj.$data.lastTS === obj.$data.lastTS) {
              objUploadToFb({
                obj: obj,
                space: space,
                table: change.table
              })
            }
          })
        }, timeoutGet(space))
      })
    })
  })
}

export const changedSpacesUpdate = async () => {
  console.log("changedSpacesUpdate started")

  consoleLog({text: "db-sync.js > changedSpacesUpdate()"})

  // get spaces array
  let spacesArr = await db.table("spaces")
      .filter(obj => obj.status === "changed" || obj.status === "created")
      .toArray()

  if(spacesArr.length === 0) {
    await spacesListenerSet()
    return
  }

  // create array of promises of objUploadToFb function
  let spacesArrPromises = []
  for (const space of spacesArr) {
    consoleLog({text: "db-sync.js > changedSpacesUpdate()",
      params: [["space", space]],
      filter: {spaceId: space.id}
    })

    // upload all objs to fb
    spacesArrPromises.push(objUploadToFb({
      obj: space,
      space: space,
      table: "spaces"
    }))
  }

  // when all changed spaces have already uploaded to fb - set spaces listener
  Promise.all(spacesArrPromises).then(() => {
    console.log("Promise.all is done")
      spacesListenerSet()
  })
}
export const changedObjsUpdate = async (objName, space) => {

  console.log("changedObjsUpdate started", objName, space.id)
  consoleLog({text: "db-sync.js > changedObjsUpdate()",
    params: [["objName", objName]]
  })

  // get objs array
  let objsArr = await db.table(objName)
      .filter(obj => (obj.status === "changed" || obj.status === "created") && obj.spaceId === space.id)
      .toArray()

  console.log("objsArr", objName, objsArr)
  // create array of promises of objUploadToFb function
  let objsArrPromises = []
  for (const obj of objsArr) {
    consoleLog({text: "db-sync.js > changedSpacesUpdate()",
      params: [["objName", objName]],
      filter: {objId: obj.id}
    })

    // upload all objs to fb
    objsArrPromises.push(objUploadToFb({
      obj: obj,
      space: space,
      table: objName
    }))
  }

  // when all changed objs have already uploaded to fb - set spaces listener
  Promise.all(objsArrPromises).then(() => {
    console.log("Promise.all is done")
    objsListenerSet(objName, space)
  })
}

const objUploadToFb = (pl) => {
  return new Promise(async (resolve, reject) => {
    let objToUpload = { $data: pl.obj.$data }
    objToUpload.spaceId = pl.space.id
    objToUpload.table = pl.table
    objToUpload.id = pl.obj.id
    let mods = await db.table("mods").where({
      table: pl.table,
      recordId: pl.obj.id,
      version: pl.obj.$data.version
    }).toArray()
    if (pl.space.$data.isEncrypted) {
      objToUpload.$data.data = encrypt(pl.obj.data, pl.space.cryptoKey)
      objToUpload.mods = encrypt(mods, pl.space.cryptoKey)
    }
    else {
      objToUpload.$data.data = JSON.stringify(pl.obj.data)
      objToUpload.mods = JSON.stringify(mods)
    }

    console.log("запуск записи в fb")

    fbFunc_objSet(objToUpload)
      .then((result) => {
        console.log("function result: ", result)
        resolve(result)
      })
      .catch((error) => {
        console.log("function error: ", error)
        reject(error)
      })
  })
}


export const authUserListenerSet = () => {
  listeners.authUser = onSnapshot(doc(fdb, "users", currentUserId), (doc) => {
    if (doc.data()) {
      db.table("params").put({id: "currentUser", data: doc.data()})
    }
  })
}
export const spacesListenerSet = async (reload) => {
  console.log("spacesListenerSet started")
  // get spaces array
  let spaces = await db.table("spaces").where("status").equals("updated").sortBy("$data.lastTS")

  // get last lastTS
  let lastLastTS = 0
  if (spaces.length > 0) lastLastTS = spaces[0].$data.lastTS
  if(!lastLastTS) lastLastTS = 0
  if(reload) lastLastTS = 0

  consoleLog({text: "db-sync.js > spacesListenerSet() > lastLastTS is defined",
    params: [["lastLastTS", lastLastTS], ["spaces", spaces]]
  })
  let q = query(
      collection(fdb, "spaces"),
      where("userIds", "array-contains", currentUserId),
      where("lastTS", ">", lastLastTS),
      orderBy("lastTS")
    )

  listeners.spaces = onSnapshot(q, async (snapshot) => {
    let changes = await snapshot.docChanges()
    for (const change of changes) {
      consoleLog({text: "sl: listener is updating (db-sync.js > spacesListenerSet > onSnapshot())",
        params: [["spaceId", change.doc.id], ["objData", change.doc.data()]],
        filter: {spaceId: change.doc.id}
      })
      let docData = change.doc.data()

      // obj added or modified
      if (change.type === "added" || change.type === "modified") {
        let spaceCollection = db.table("spaces").where("id").equals(change.doc.id)
        if(await spaceCollection.count() === 1) {
          spaceCollection.modify(space => {
            if (space.status === "changed" && space.$data.version <= docData.version && space.$data.lastUserId !== docData.lastUserId) {
              space.status = "collision"
              space.collisionData = {$data: docData, data: {}}
              if (!space.$data.isEncrypted) {
                space.collisionData.data = JSON.parse(docData.data)
              }
              else if (space.secretKey) {
                space.collisionData.data = decrypt(docData.data, space.cryptoKey)
                consoleLog({
                  text: "collisionData (db-sync.js > spacesListenerSet > onSnapshot)",
                  params: [["obj.collisionData.data", space.collisionData.data]]
                })
              }
            }
            else {
              space.status = "updated"
              space.$data = docData
              space.data = {}
              if (!space.$data.isEncrypted) {
                space.data = JSON.parse(docData.data)
              }
              else if (space.secretKey) {
                space.data = decrypt(docData.data, space.cryptoKey)
              }
            }
          })
          // let space = await spaceCollection.toArray()[0]
          // if (!space.$data.isEncrypted || space.$data.isEncrypted && space.cryptoKey) {
          //   await changedObjsUpdate("lists", space)
          //   await changedObjsUpdate("items", space)
          //   await changedObjsUpdate("comments", space)
          //   await changedObjsUpdate("tasks", space)
          // }
        }
        else if(await spaceCollection.count() === 0) {
            let newSpace = {$data: {}, data: {}}
            newSpace.id = change.doc.id
            newSpace.status = "updated"
            newSpace.cryptoKey = ""
            newSpace.$data = docData
            newSpace.data = {}
            if (!newSpace.$data.isEncrypted && newSpace.$data.data) {
              newSpace.data = JSON.parse(newSpace.$data.data)
            }
            db.table("spaces").put(newSpace).then(() => {
              consoleLog({
                text: "listener created new space (db-sync.js > spacesListenerSet > onSnapshot() > put())",
                params: [["newSpace", newSpace]],
                filter: {spaceId: newSpace.id}
              })
            })
            if (!newSpace.isEncrypted) {
              await objsListenerSet("lists", newSpace)
              await objsListenerSet("items", newSpace)
              await objsListenerSet("comments", newSpace)
              await objsListenerSet("tasks", newSpace)
            }
          }
      }

      // obj removed
      if (change.type === "removed") {
        db.table("spaces").where("id").equals(change.doc.id).delete()
      }

      // mods clear
      db.table("mods").where({
        table: "spaces",
        recordId: change.doc.id,
        version: docData.version
      }).delete()

    }
  })
  console.log("spacesListenerSet")

  // set listeners for obj of each space
  db.table("spaces").filter(obj => obj.status !== "unpublished").toArray().then(spaces => {
    spaces.forEach(async space => {
      if (!space.isEncrypted || ( space.isEncrypted && space.cryptoKey ) ) {
        await changedObjsUpdate("lists", space)
        await changedObjsUpdate("items", space)
        await changedObjsUpdate("comments", space)
        await changedObjsUpdate("tasks", space)

      }
    })
  })
}
export const objsListenerSet = async (objName, space, reload) => {

  // get objs array
  let objs = await db.table(objName).where("status").equals("updated").and(obj => obj.$data.spaceId === space.id)
    .sortBy("$data.lastTS")

  // get last lastTS
  let lastLastTS = 0
  if (objs.length > 0) lastLastTS = objs[0].$data.lastTS
  if(!lastLastTS) lastLastTS = 0
  if(reload) lastLastTS = 0

  consoleLog({text: "db-sync.js > objsListenerSet() > lastLastTS is defined",
    params: [["lastLastTS", lastLastTS], ["objs", objs]]
  })
  let q = query(
      collection(fdb, "spaces/" + space.id + "/" + objName),
      where("lastTS", ">", lastLastTS),
      orderBy("lastTS")
    )

  listeners[space.id+"-"+objName] = onSnapshot(q, async (snapshot) => {
    let changes = await snapshot.docChanges()
    for (const change of changes) {
      consoleLog({text: "sl: listener is updating (db-sync.js > objsListenerSet > onSnapshot())",
        params: [["spaceId", space.id], ["objData", change.doc.data()]],
        filter: {spaceId: space.id}
      })
      let docData = change.doc.data()

      // obj added or modified
      if (change.type === "added" || change.type === "modified") {
        let objCollection = db.table(objName).where("id").equals(change.doc.id)
        if(await objCollection.count() === 1) {
          objCollection.modify(obj => {
            if (obj.status === "changed" && obj.$data.version <= docData.version && obj.$data.lastUserId !== docData.lastUserId) {
              obj.status = "collision"
              obj.collisionData = {$data: docData, data: {}}
              if (!space.$data.isEncrypted) {
                obj.collisionData.data = JSON.parse(docData.data)
              }
              else if (obj.secretKey) {
                obj.collisionData.data = decrypt(docData.data, space.cryptoKey)
                consoleLog({
                  text: "collisionData (db-sync.js > objsListenerSet > onSnapshot)",
                  params: [["obj.collisionData.data", obj.collisionData.data]]
                })
              }
            }
            else {
              obj.status = "updated"
              obj.$data = docData
              obj.data = {}
              if (!space.$data.isEncrypted) {
                obj.data = JSON.parse(docData.data)
              }
              else if (space.secretKey) {
                obj.data = decrypt(docData.data, space.cryptoKey)
              }
            }
            obj.drilldownFilter = drilldownFilterItemGen(obj)
          })
        }
        else if(await objCollection.count() === 0) {
          let newObj = {$data: {}, data: {}}
          newObj.id = change.doc.id
          newObj.spaceId = space.id
          newObj.status = "updated"
          newObj.cryptoKey = ""
          newObj.$data = docData
          newObj.data = {}
          if (!space.$data.isEncrypted && space.$data.data) {
            newObj.data = JSON.parse(newObj.$data.data)
          }
          newObj.drilldownFilter = drilldownFilterItemGen(newObj)
          db.table(objName).put(newObj).then(() => {
            consoleLog({
              text: "listener created new space (db-sync.js > objsListenerSet > onSnapshot() > put())",
              params: [["newObj", newObj]],
              filter: {objId: newObj.id}
            })
          })
        }
      }

      // obj removed
      if (change.type === "removed") {
        db.table(objName).where("id").equals(change.doc.id).delete()
      }

      // mods clear
      db.table("mods").where({
        table: objName,
        recordId: change.doc.id,
        version: docData.version
      }).delete()

    }
  })
  console.log("objsListenerSet", space.id, objName)
}
export const listenersCancel = () => {
  console.log("listeners: ", listeners)
  for(let listener in listeners){
    // listeners[listener]()
    delete listeners[listener]
  }
  console.log("listeners canceled", listeners)
}



// LIST PREPARE
export const sublistSubstitute = async (list5Id, item) => {
  //
  let listFields = store.state.lists[list5Id].list.data.fields
  let listFieldsForSubst = store.state.lists[list5Id].listFieldsForSubst
  let viewFields = store.state.lists[list5Id].list.data.views[store.state.lists[list5Id].viewId].data.fields

  item.data.fieldsFS = {}

  let funSublistFieldSubstitute = async function(initFieldId, currentItem, listField){
    let sublistField = store.state.lists[list5Id].sublists[listField.listIdForSublist].list.data.fields[listField.fieldIdForSublist]
    let itemValSs = ""
    if(listField.sublistBasedOnFieldId) {
      itemValSs = currentItem.data.fieldsS[listField.sublistBasedOnFieldId]
    }
    else{
      itemValSs = currentItem.data.fieldsS[listField.id]
    }

    if(itemValSs !== "" && itemValSs !== undefined){
      let ValSs = itemValSs.split(";")
      for(let valS of ValSs){
        let subitem = store.state.lists[list5Id].sublists[listField.listIdForSublist].items[valS]
        if(!subitem) {
          subitem = await db.table("items").get(valS)
          store.commit("sublistAddSubitem", {list5Id: list5Id, subitem: subitem})
        }

        if(sublistField.type === "sublist"){
          await funSublistFieldSubstitute(initFieldId, subitem, sublistField)
        }
        else if(sublistField.type === "fixedsublist"){
          if(item.data.fieldsS[initFieldId]){
            item.data.fieldsS[initFieldId] = item.data.fieldsS[initFieldId]+";"+subitem.id
          }
          else{
            item.data.fieldsS[initFieldId] = subitem.id
          }

          let valSIds = item.data.fieldsS[initFieldId].split(";")
          for (let valSId of valSIds) {
            let fValS = store.state.lists[list5Id].sublists[listField.listIdForSublist].items[valSId].data.fieldsS[listField.fieldIdForSublist]
            let fValSIds = fValS ? fValS.split(";") : []
            for (let fValSId of fValSIds) {
              if(item.data.fields[initFieldId]){
                item.data.fields[initFieldId] = item.data.fields[initFieldId] + sublistField.fixedSublist[fValSId]?.value
                item.data.fieldsFS[initFieldId] = item.data.fieldsFS[initFieldId] + ";" + fValSId
              }
              else{
                item.data.fields[initFieldId] = sublistField.fixedSublist[fValSId]?.value
                item.data.fieldsFS[initFieldId] = fValSId
              }
            }
          }
        }
        else{
          if(item.data.fields[initFieldId]){
            item.data.fields[initFieldId] = item.data.fields[initFieldId]+" "+subitem.data.fields[listField.fieldIdForSublist]
            item.data.fieldsS[initFieldId] = item.data.fieldsS[initFieldId]+";"+subitem.id
          }
          else{
            item.data.fields[initFieldId] = subitem.data.fields[listField.fieldIdForSublist]
            item.data.fieldsS[initFieldId] = subitem.id
          }
        }
      }
    }
  }

  // fields order for substitution
  let fieldsOrderSubst = []
  let funInsertLinkedFields = function(fieldId){
    for(let linkedFieldId in listFields) {
      if(listFields[linkedFieldId].type === "sublist" && listFields[linkedFieldId].sublistBasedOnFieldId === fieldId){
        let indx = fieldsOrderSubst.findIndex(el => el === fieldId)
        fieldsOrderSubst.splice(indx+1, 0, linkedFieldId)
        funInsertLinkedFields(linkedFieldId)
      }
    }
  }
  for(let fieldId in listFields) {
    if(listFields[fieldId].type === "sublist" && !listFields[fieldId].sublistBasedOnFieldId){
      fieldsOrderSubst.push(fieldId)
      funInsertLinkedFields(fieldId)
    }
  }

  for(let fieldId of fieldsOrderSubst){
    if(listFields[fieldId].type === "sublist"){
      let currentItem = JSON.parse(JSON.stringify(item))
      currentItem.data.fields[fieldId] = ""
      await funSublistFieldSubstitute(fieldId, currentItem, listFields[fieldId])
    }
  }

  // fixsublists
  for(let fieldId in listFieldsForSubst){
    if(listFieldsForSubst[fieldId].type === "fixedsublist"){
      if(item.data.fieldsS[fieldId]){
        item.data.fieldsFS[fieldId] = item.data.fieldsS[fieldId]
        let subItemIds = item.data.fieldsS[fieldId].split(";")
        let val = ""
        for(let i=0; i<subItemIds.length; i++){
          val = val + listFieldsForSubst[fieldId].fixedSublist[subItemIds[i]]?.value
        }
        item.data.fields[fieldId] = val
      }
    }

    if(viewFields[fieldId]?.sort){
      if(item.data.fields[fieldId] === undefined) item.data.fields[fieldId] = ""
      if(item.data.fieldsS[fieldId] === undefined) item.data.fieldsS[fieldId] = ""
    }
  }
}
export const formulaRun = (listData, formulaType, itemFields) => {
  debugger
  let formulaOrder = listData.formulaOrders[formulaType]
  if(!formulaOrder) return
  let item = itemFields
  for(let fieldId in listData.fields){
    if(fieldId.charAt(0) === "f"){
      if(listData.fields[fieldId].type === "num" && !item[fieldId]) item[fieldId] = 0
      if(listData.fields[fieldId].type === "text" && !item[fieldId]) item[fieldId] = ""
      if(listData.fields[fieldId].type === "sublist" && !item[fieldId]) item[fieldId] = ""
    }
  }
  for(let fieldId of formulaOrder){
    itemFields[fieldId] = eval(listData.fields[fieldId].formula)
  }
}

export const listSortSubtotal = (list5Id) => {
  console.log("list5", store.state.lists[list5Id])
  let items = store.state.lists[list5Id].items
  let listData = store.state.lists[list5Id].list.data
  let viewData = store.state.lists[list5Id].list.data.views[store.state.lists[list5Id].viewId].data
// sorting
//   debugger
  let sorting = []
  viewData.fieldsOrder.forEach(fieldId => {
    if (viewData.fields[fieldId].sort) sorting.push({
      fieldId: viewData.fields[fieldId].id,
      sort: viewData.fields[fieldId].sort
    })
  })
console.log("sort: ", sorting)
  if (sorting.length > 0) {
    items.sort((a, b) => {     // state is changed after sorting even without commit - surprise
      let result = 0
      let itemA = ""
      let itemB = ""
      for (let i = 0; i < sorting.length; i++) {
        itemA = a.data.fields[sorting[i].fieldId] ? a.data.fields[sorting[i].fieldId] : ""
        itemB = b.data.fields[sorting[i].fieldId] ? b.data.fields[sorting[i].fieldId] : ""
        if (sorting[i].sort === 'asc') {
          result = itemA.localeCompare(itemB)
          if (result !== 0) break
        }
        if (sorting[i].sort === 'desc') {
          result = itemB.localeCompare(itemA)
          if (result !== 0) break
        }
        // if(result !== 0) return
      }
      return result
    })
  }



  // subtotals
  let groupFields = []
  let calcFields = []
  let funCalc = function(subTotals, itemFields, listData, viewData){
    // debugger
    if(itemFields.qnt){
      subTotals.vals.qnt = subTotals.vals.qnt + itemFields.qnt // for subTotal0
    }
    else{
      subTotals.vals.qnt++ // for subTotals
    }

    for(let fieldId of calcFields){
      let itemVal =  ""
      if(!itemFields[fieldId]){
        if(listData.fields[fieldId].type === "num") itemVal = 0
      }
      else{
        itemVal = itemFields[fieldId]
      }
      if(listData.fields[fieldId].type === "num"){
        if(viewData.fields[fieldId].calc === "sum") subTotals.vals[fieldId] = subTotals.vals[fieldId] * (1) + itemVal * (1)
        if(viewData.fields[fieldId].calc === "avg") subTotals.vals[fieldId] = subTotals.vals[fieldId] * (1) + itemVal * (1)
        if(viewData.fields[fieldId].calc === "min") subTotals.vals[fieldId] = subTotals.vals[fieldId] < itemVal ? subTotals.vals[fieldId] : itemVal
        if(viewData.fields[fieldId].calc === "max") subTotals.vals[fieldId] = subTotals.vals[fieldId] > itemVal ? subTotals.vals[fieldId] : itemVal
      }
      else if(listData.fields[fieldId].type === "date" || listData.fields[fieldId].type === "time"){
        itemVal = itemVal.replaceAll(" ", "")
        if(subTotals.vals[fieldId] === "") {
          subTotals.vals[fieldId] = itemVal
          continue
        }
        if(itemVal === "") {
          continue
        }
        if(viewData.fields[fieldId].calc === "max" && itemVal !== "") subTotals.vals[fieldId] = subTotals.vals[fieldId].localeCompare(itemVal) > 0 ? subTotals.vals[fieldId] : itemVal
        if(viewData.fields[fieldId].calc === "min" && itemVal !== "") subTotals.vals[fieldId] = subTotals.vals[fieldId].localeCompare(itemVal) < 0 ? subTotals.vals[fieldId] : itemVal
      }
      else{
        if(viewData.fields[fieldId].calc === "max") subTotals.vals[fieldId] = subTotals.vals[fieldId].localeCompare(itemVal) > 0 ? subTotals.vals[fieldId] : itemVal
        if(viewData.fields[fieldId].calc === "min") subTotals.vals[fieldId] = subTotals.vals[fieldId].localeCompare(itemVal) < 0 ? subTotals.vals[fieldId] : itemVal
      }
    }
  }

  sorting.forEach(field => {
    if(viewData.fields[field.fieldId].group) groupFields.push(field.fieldId)
  })

  let subTotals0 = {fields: {}, fieldsS: {}, fieldsFS: {}, vals: {}, subTotals0Arr: [], level: 0}

  if(groupFields.length > 0){
    let listFields = listData.fields
    for(let fieldId in listFields){
      if(viewData.fields[fieldId]?.calc) calcFields.push(fieldId)
    }
    let subTotals= {fields: {}, fieldsS: {}, fieldsFS: {}, vals: {}}
    let subTotalsArr = []
    // debugger
    items.forEach(item => {
      // debugger
      let newSubtotalRequired = false
      for(let fieldId of groupFields){
        // if(item.data.fields[fieldId] === undefined) item.data.fields[fieldId] = ""
        if(item.data.fields[fieldId] !== subTotals.fields[fieldId]) {
          newSubtotalRequired = true
          break
        }
      }
      if(newSubtotalRequired){
        subTotals= {fields: {}, fieldsS: {}, fieldsFS: {}, vals: {}}
        subTotalsArr.push(subTotals)
        for(let fieldId of groupFields){
          subTotals.fieldsS[fieldId] = item.data.fieldsS[fieldId] ? item.data.fieldsS[fieldId] : ""
          subTotals.fieldsFS[fieldId] = item.data.fieldsFS[fieldId] ? item.data.fieldsFS[fieldId] : ""
          subTotals.fields[fieldId] = item.data.fields[fieldId] ? item.data.fields[fieldId] : ""
        }
        subTotals.vals.qnt = 1
        for(let fieldId of calcFields){
          subTotals.vals[fieldId] = item.data.fields[fieldId] ? item.data.fields[fieldId] : ""
        }
      }
      else{
        funCalc(subTotals, item.data.fields, listData, viewData)
      }
    })
    for(let subTotals of subTotalsArr){
      for(let fieldId in subTotals.vals){
        if(viewData.fields[fieldId]?.calc === "avg") {
          subTotals.vals[fieldId] = subTotals.vals[fieldId] / subTotals.vals.qnt
        }
      }
    }
    console.log("subTotalsArr: ", subTotalsArr)

    subTotals0.vals.qnt = 0
    for(let fieldId of calcFields){
      subTotals0.vals[fieldId] = ""
      if(listData.fields[fieldId].type === "num"){
        subTotals0.vals[fieldId] = 0
      }
    }
    subTotals0.subTotals0Arr.push({key: "", fields: {}, fieldsS: {}, fieldsFS: {}, vals: {qnt: 0}, subTotals0Arr: []}) // technical

    subTotalsArr.forEach(line => {
      let subTotalsCurrent = subTotals0
      // debugger
      funCalc(subTotalsCurrent, line.vals, listData, viewData)
      // subTotalsCurrent.vals.qnt = subTotalsCurrent.vals.qnt + line.vals.qnt
      let level = 1
      for(let i = 0; i < level; i++){
        // debugger
        if(line.fields[groupFields[i]] === subTotalsCurrent.subTotals0Arr[subTotalsCurrent.subTotals0Arr.length-1]?.fields[groupFields[i]]){
          subTotalsCurrent = subTotalsCurrent.subTotals0Arr[subTotalsCurrent.subTotals0Arr.length-1]
          // debugger
          funCalc(subTotalsCurrent, line.vals, listData, viewData)
          if(level < groupFields.length) level++
          continue
        }
        else{
          subTotalsCurrent.subTotals0Arr.push({key: "", lastFieldId: "", fields: {}, fieldsS: {}, fieldsFS: {}, vals: {qnt: 0}, subTotals0Arr: [], level: level})
          subTotalsCurrent = subTotalsCurrent.subTotals0Arr[subTotalsCurrent.subTotals0Arr.length-1]
          for(let j=0; j<=i; j++){
            subTotalsCurrent.fields[groupFields[j]] = line.fields[groupFields[j]]
            subTotalsCurrent.fieldsS[groupFields[j]] = line.fieldsS[groupFields[j]]
            subTotalsCurrent.fieldsFS[groupFields[j]] = line.fieldsFS[groupFields[j]]
            if(subTotalsCurrent.fieldsFS[groupFields[j]]){
              subTotalsCurrent.key = subTotalsCurrent.fields[groupFields[j]] + subTotalsCurrent.fieldsFS[groupFields[j]]
            }
            else if(subTotalsCurrent.fieldsS[groupFields[j]]){
              subTotalsCurrent.key = subTotalsCurrent.fields[groupFields[j]] + subTotalsCurrent.fieldsS[groupFields[j]]
            }
            else{
              subTotalsCurrent.key = subTotalsCurrent.fields[groupFields[j]]
            }

          }
          subTotalsCurrent.lastFieldId = groupFields[i] // is to show quantity near last grouping field
          for(let fieldId of calcFields){
            subTotalsCurrent.vals[fieldId] = line.vals[fieldId]
          }
          subTotalsCurrent.vals.qnt = line.vals.qnt
          if(level < groupFields.length) level++
          continue
        }
      }
    })
    subTotals0.subTotals0Arr.shift()
    console.log("subTotals0: ", subTotals0)
  }

  store.commit("listsList5SetProp", {list5Id: list5Id, propName: "subTotals", propVal: subTotals0})
  return subTotals0

}

export const drilldownFilterGen = (spaceId, listId) => {
  db.table("items").where({spaceId: spaceId, 'data.listId': listId}).modify(item => {
    item.drilldownFilter = []
    for(let fieldId in item.data.fieldsS){
      if(item.data.fieldsS[fieldId] !== ""){
        item.drilldownFilter.push(item.data.listId+"_"+fieldId+"_"+item.data.fieldsS[fieldId])
      }
    }
  })
}
export const drilldownFilterItemGen = (item) => {
  let drilldownFilter = []
  for(let fieldId in item.data.fieldsS) {
    if (item.data.fieldsS[fieldId] !== "") {
      drilldownFilter.push(item.data.listId + "_" + fieldId + "_" + item.data.fieldsS[fieldId])
    }
  }
  return drilldownFilter
}




//
//
// export const changedSpacesUpdate_ = async () => {
//   consoleLog({text: "db-sync.js > changedSpacesUpdate()"})
//
//   // get spaces array
//   let spacesArr = await db.table("spaces").filter(space => space.status === "changed" || space.status === "created").toArray()
//
//   // create array of promises of objUploadToFb function
//   let spacesArrPromises = []
//   spacesArr.forEach(space => {
//     consoleLog({text: "db-sync.js > changedSpacesUpdate()",
//       params: [["space", space]],
//       filter: {spaceId: space.id}
//     })
//     spacesArrPromises.push(objUploadToFb({
//       obj: space,
//       space: space,
//       table: "spaces"
//     }))
//   })
//
//   // when all changed spaces have already uploaded to fb - set spaces listener
//   Promise.all(spacesArrPromises).then(() => {
//     console.log("Promise.all is done")
//     spacesListenerSet()
//   })
// }
// export const changedListsUpdate = (space) => {
//   consoleLog({text: "db-sync.js > changedListsUpdate()",
//     params: [["spaceId", space.id]],
//     filter: {spaceId: space.id}
//   })
//   db.lists.where("spaceId").equals(space.id)
//     .and(list => list.status === "changed" || list.status === "created")
//     .each(list => {
//       consoleLog({text: "starting update of changed list before setDoc (db-sync.js > changedListsUpdate)",
//         params: [["list", list]],
//         filter: {spaceId: space.id, listId: list.id}
//       })
//       let data = list.$data
//       data.lastTS = Date.now()//serverTimestamp().toMillis() - if we use serverTimestamp() - it saves as string , but we require as int
//       // debugger
//       data.data = space.$data.isEncrypted ? encrypt(list.data, space.cryptoKey) : JSON.stringify(list.data)
//       setDoc(doc(fdb, "spaces", space.id, "lists", list.id), data).then(() => {
//         consoleLog({text: "changed list updated in firebase (db-sync.js > changedListsUpdate > setDoc > then)",
//           params: [["space", space], ["list", list]],
//           filter: {spaceId: space.id, listId: list.id}
//         })
//       }).catch(err => {
//         consoleLog({text: "changed list not updated in firebase (db-sync.js > changedListsUpdate > setDoc > catch)",
//           params: [["space", space], ["list", list], ["err", err]],
//           filter: {spaceId: space.id, listId: list.id}
//         })
//       })
//     })
// }
// export const changedItemsUpdate = (space) => {
//   consoleLog({text: "starting db-sync > changedItemsUpdate())",
//     params: [["spaceId", space.id]],
//     filter: { spaceId: space.id }
//   })
//   db.items.where("spaceId").equals(space.id)
//     .and(item => item.status === "changed" || item.status === "created")
//     .each(item => {
//       consoleLog({text: "starting db-sync > changedItemsUpdate() > db.items.where...each())",
//         params: [["spaceId", space.id], ["item", item]],
//         filter: { spaceId: space.id, listId: item.data.listId, itemId: item.id }
//       })
//       let data = item.$data
//       data.lastTS = Date.now()//serverTimestamp().toMillis() - if we use serverTimestamp() - it saves as string , but we require as int
//       data.data = space.$data.isEncrypted ? encrypt(item.data, space.cryptoKey) : JSON.stringify(item.data)
//       setDoc(doc(fdb, "spaces", space.id, "items", item.id), data).then(() => {
//         consoleLog({
//           text: "starting db-sync > changedItemsUpdate() > db.items.where...each() > setDoc() > then()",
//           params: [["space", space], ["item", item]],
//           filter: { spaceId: space.id, listId: item.data.listId, itemId: item.id }
//         })
//       }).catch(err => {
//         consoleLog({text: "starting db-sync > changedItemsUpdate() > db.items.where...each() > setDoc() > catch()",
//           params: [["space", space], ["item", item], ["err", err]],
//           filter: { spaceId: space.id, listId: item.data.listId, itemId: item.id }
//         })
//       })
//     })
// }
//
export const uploadAllSpaceData = (spaceId) => {
//   db.spaces.get(spaceId).then(space => {
//     let data = space.$data
//     data.data = space.$data.isEncrypted ? encrypt(space.data, space.cryptoKey) : JSON.stringify(space.data)
//     setDoc(doc(fdb, "spaces", space.id), data).then(() => {
//       db.lists.where("spaceId").equals(spaceId).each(list => {
//         let data = list.$data
//         data.data = space.$data.isEncrypted ? encrypt(list.data, space.cryptoKey) : JSON.stringify(list.data)
//         setDoc(doc(fdb, "spaces", space.id, "lists", list.id), data)
//       })
//       db.items.where("spaceId").equals(spaceId).each(item => {
//         let data = item.$data
//         data.data = space.$data.isEncrypted ? encrypt(item.data, space.cryptoKey) : JSON.stringify(item.data)
//         setDoc(doc(fdb, "spaces", space.id, "items", item.id), data)
//       })
//     })
//   })
}
//
// export const spacesListenerSet_ = (reload) => {
//
//   // set listener for space
//   db.table("spaces").where("status").equals("updated").sortBy("$data.lastTS").then(spaces => {
//
//     // get last lastTS
//     let lastLastTS = 0
//     if (spaces.length > 0) lastLastTS = spaces[0].$data.lastTS
//     if(!lastLastTS) lastLastTS = 0
//     if(reload) lastLastTS = 0
//
//     consoleLog({text: "db-sync.js > spacesListenerSet() > lastLastTS is defined",
//       params: [["lastLastTS", lastLastTS], ["spaces", spaces]]
//     })
//     const q = query(
//       collection(fdb, "spaces"),
//       where("userIds", "array-contains", currentUserId),
//       where("lastTS", ">", lastLastTS),
//       orderBy("lastTS")
//     )
//     listeners.spaces = onSnapshot(q, async (snapshot) => {
//       let changes = await snapshot.docChanges()
//       changes.forEach((change) => {
//         consoleLog({text: "sl: listener is updating (db-sync.js > spacesListenerSet > onSnapshot())",
//           params: [["spaceId", change.doc.id], ["spaceData", change.doc.data()]],
//           filter: {spaceId: change.doc.id}
//         })
//         let docData = change.doc.data()
//         if (change.type === "added" || change.type === "modified") {
//           db.spaces.get(change.doc.id).then(space => {
//             if (space) {
//               db.spaces.where("id").equals(change.doc.id).modify(space2 => {
//                 if ((
//                   docData.version === space.$data.version &&
//                   docData.lastUserId !== space.$data.lastUserId
//                 ) || (
//                   space.status === "changed" &&
//                   docData.lastUserId !== space.$data.lastUserId
//                 )
//                 ) {
//                   space2.status = "collision"
//                   space2.collisionData = {$data: docData, data: {}}
//                   if (!space2.$data.isEncrypted) {
//                     space2.collisionData.data = JSON.parse(docData.data)
//                   }
//                   else if (space2.secretKey) {
//                     debugger
//                     space2.collisionData.data = decrypt(docData.data, space2.cryptoKey)
//                     // console.log(space2.collisionData.data)
//                     consoleLog({text: "collisionData (db-sync.js > spacesListenerSet > onSnapshot)",
//                       params: [["space2.collisionData.data", space2.collisionData.data]]
//                     })
//                   }
//                 }
//                 else if ((
//                     docData.lastUserId === space.$data.lastUserId &&
//                     space.status === "changed"
//                   ) ||
//                   space.status === "updated"
//                 ) {
//                   space2.status = "updated"
//                   space2.$data = docData
//                   space2.data = {}
//                   if (!space2.$data.isEncrypted) {
//                     space2.data = JSON.parse(docData.data)
//                   }
//                   else if (space2.secretKey) {
//                     debugger
//                     space2.data = decrypt(docData.data, space2.cryptoKey)
//                   }
//                 }
//               }).then((space)=>{
//                 consoleLog({text: "space listener updated (db-sync.js > spacesListenerSet > onSnapshot > modify)",
//                   params: [["space", change.doc]],
//                   filter: {spaceId: change.doc.id}
//                 })
//               })
//               if (!space.$data.isEncrypted || space.$data.isEncrypted && space.cryptoKey) {
//                 spaceObjsListenersSet(space)
//               }
//             }
//             else {
//               let newSpace = {$data: {}, data: {}}
//               newSpace.id = change.doc.id
//               newSpace.status = "updated"
//               newSpace.cryptoKey = ""
//               newSpace.$data = docData
//               newSpace.data = {}
//               if (!newSpace.$data.isEncrypted && newSpace.$data.data) {
//                 newSpace.data = JSON.parse(newSpace.$data.data)
//               }
//               db.spaces.put(newSpace).then(()=>{
//                 consoleLog({text: "listener created new space (db-sync.js > spacesListenerSet > onSnapshot() > put())",
//                   params: [["newSpace", newSpace]],
//                   filter: { spaceId: newSpace.id }
//                 })
//               })
//               if (!newSpace.isEncrypted) {
//                 spaceObjsListenersSet(newSpace)
//               }
//             }
//           })
//         }
//         if (change.type === "removed") {
//           db.spaces.where("id").equals(change.doc.id).delete()
//         }
//
//         // mods clear
//         db.table("mods").where({
//           table: "spaces",
//           recordId: change.doc.id,
//           version: docData.version
//         }).delete()
//
//       })
//     })
//     console.log("spacesListenerSet", listeners.spaces)
//   })
//
//   // set listeners for obj of each space
//   db.table("spaces").toArray().then(spaces => {
//     spaces.forEach(space => {
//       if (!space.isEncrypted || ( space.isEncrypted && space.cryptoKey ) ) {
//         spaceObjsListenersSet(space, reload)
//       }
//     })
//   })
// }
// export const listsListenerSet = (space, reload) => {
//   // debugger
//   db.lists.where("spaceId").equals(space.id)
//     .and(list => list.status !== "changed" && list.status !== "created").sortBy("$data.lastTS").then(lists => {
//       let lastLastTS = 0
//       if (lists.length > 0) lastLastTS = lists[0].$data.lastTS
//       if(!lastLastTS) lastLastTS = 0
//       if(reload) lastLastTS = 0
//       // console.log("sl: starting db-sync -> listsListenerSet(), spaceId: ", space.id, ", lastTS: ", lastLastTS)
//       consoleLog({text: "starting db-sync.js > listsListenerSet()",
//         params: [["space", space], ["lastTS", lastLastTS]],
//         filter: {spaceId: space.id}
//       })
//     const q = query(
//       collection(fdb, "spaces", space.id, "lists"),
//       where("lastTS", ">", lastLastTS),
//       orderBy("lastTS")
//     )
//     listeners.lists = onSnapshot(q, (snapshot) => {
//       snapshot.docChanges().forEach((change) => {
//         if (change.type === "added" || change.type === "modified") {
//           let docData = change.doc.data()
//           db.lists.get(change.doc.id).then(list => {
//             if (list) {
//               db.lists.where("id").equals(change.doc.id).modify(list2 => {
//                 // debugger
//                 if ((
//                   docData.version === list.$data.version &&
//                   docData.lastUserId !== list.$data.lastUserId
//                 ) || (
//                   list.status === "changed" &&
//                   docData.lastUserId !== list.$data.lastUserId
//                 )
//                 ) {
//                   list2.status = "collision"
//                   list2.collisionData = {$data: docData, data: {}}
//                   if (!space.$data.isEncrypted) {
//                     list2.collisionData.data = JSON.parse(docData.data)
//                   }
//                   else if (space.secretKey !== "") {
//                     list2.collisionData.data = decrypt(docData.data, space.cryptoKey)
//                   }
//                 }
//                 else if ((
//                     docData.lastUserId === list.$data.lastUserId &&
//                     space.status === "changed"
//                   ) ||
//                   space.status === "updated"
//                 ) {
//                   list2.status = "updated"
//                   list2.$data = docData
//                   list2.data = {}
//                   if (!space.$data.isEncrypted) {
//                     list2.data = JSON.parse(docData.data)
//                   }
//                   else if (space.secretKey !== "") {
//                     // debugger
//                     list2.data = decrypt(docData.data, space.cryptoKey)
//                   }
//                 }
//               })
//             }
//             else {
//               let newList = {$data: {}, data: {}}
//               newList.id = change.doc.id
//               newList.status = "updated"
//               newList.spaceId = space.id
//               newList.$data = docData
//               newList.data = {}
//               if (!space.$data.isEncrypted && newList.$data.data) {
//                 newList.data = JSON.parse(newList.$data.data)
//                 newList.$data.data = {}
//               }
//               else if (space.secretKey !== "") {
//                 // debugger
//                 newList.data = decrypt(docData.data, space.cryptoKey)
//                 newList.$data.data = {}
//               }
//               db.lists.put(newList)
//             }
//           })
//         }
//         if (change.type === "removed") {
//           db.lists.where("id").equals(change.doc.id).delete()
//         }
//       })
//       if (!listenerInitialCompleted.lists[space.id]) {
//         changedListsUpdate(space)
//         listenerInitialCompleted.lists[space.id] = "X"
//       }
//     })
//   })
// }
// export const itemsListenerSet = (space, reload) => {
//   consoleLog({text: "db-sync.js > itemsListenerSet()",
//     params: [["space", space]],
//     filter: {spaceId: space.id}
//   })
//
//   db.items.where("spaceId").equals(space.id)
//     .and(item => item.status !== "changed" && item.status !== "created").reverse()
//     .sortBy("$data.lastTS").then(items => {
//     let lastLastTS = 0
//     if (items.length > 0) lastLastTS = items[0].$data.lastTS
//     if(!lastLastTS) lastLastTS = 0
//     if(reload) lastLastTS = 0
//     consoleLog({text: "db-sync.js > itemsListenerSet() > lastLastTS is defined",
//       params: [["space", space], ["lastLastTS", lastLastTS]],
//       filter: {spaceId: space.id}
//     })
//     const q = query(
//       collection(fdb, "spaces", space.id, "items"),
//       where("lastTS", ">", lastLastTS),
//       orderBy("lastTS")
//     )
//     listeners.items = onSnapshot(q, (snapshot) => {
//       snapshot.docChanges().forEach((change) => {
//         if (change.type === "added" || change.type === "modified") {
//           let docData = change.doc.data()
//           db.items.get(change.doc.id).then(item => {
//             if (item) {
//               db.items.where("id").equals(change.doc.id).modify(item2 => {
//                 if ((
//                   docData.version === item.$data.version &&
//                   docData.lastUserId !== item.$data.lastUserId
//                 ) || (
//                   item.status === "changed" &&
//                   docData.lastUserId !== item.$data.lastUserId
//                 )
//                 ) {
//                   item2.status = "collision"
//                   item2.collisionData = {$data: docData, data: {}}
//                   if (!space.$data.isEncrypted) {
//                     item2.collisionData.data = JSON.parse(docData.data)
//                   }
//                   else if (space.secretKey !== "") {
//                     item2.collisionData.data = decrypt(docData.data, space.cryptoKey)
//                   }
//                 }
//                 else if ((
//                     docData.lastUserId === item.$data.lastUserId &&
//                     item.status === "created"
//                   ) || (
//                     docData.lastUserId === item.$data.lastUserId &&
//                     item.status === "changed"
//                   ) || (
//                   item.status === "updated"
//                 )) {
//                   item2.status = "updated"
//                   item2.$data = docData
//                   item2.data = {}
//                   if (!space.$data.isEncrypted) {
//                     item2.data = JSON.parse(docData.data)
//                   }
//                   else if (space.secretKey !== "") {
//                     // debugger
//                     item2.data = decrypt(docData.data, space.cryptoKey)
//                   }
//                 }
//               })
//             }
//             else {
//               let newItem = {$data: {}, data: {}}
//               newItem.id = change.doc.id
//               newItem.status = "updated"
//               newItem.spaceId = space.id
//               newItem.$data = docData
//               newItem.data = {}
//               if (!space.$data.isEncrypted && newItem.$data.data) {
//                 newItem.data = JSON.parse(newItem.$data.data)
//                 newItem.$data.data = {}
//               }
//               else if (space.secretKey !== "") {
//                 // debugger
//                 newItem.data = decrypt(docData.data, space.cryptoKey)
//                 newItem.$data.data = {}
//               }
//               db.items.put(newItem)
//             }
//           })
//         }
//         if (change.type === "removed") {
//           db.items.where("id").equals(change.doc.id).delete()
//         }
//       })
//       if (!listenerInitialCompleted.items[space.id]) {
//         changedItemsUpdate(space)
//         listenerInitialCompleted.items[space.id] = "X"
//       }
//     })
//   })
// }
// export const spaceObjsListenersSet = (space, reload) => {
//   if(space.$data.isEncrypted && !space.cryptoKey) return
//   listsListenerSet(space, reload)
//   itemsListenerSet(space, reload)
// }
//
