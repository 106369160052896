<template>
  <q-card class="justify-center fit row q-pa-sm">
<!--  <q-layout style="_max-width: 500px">-->

  <q-card class="justify-between" flat>
    <q-card-actions>
      <span class="text-grey">My todo</span>
      <q-space/>
      <q-icon name="cancel" color="grey" size="sm" @click="$emit('formClose')"/>
    </q-card-actions>
    <q-card-actions>
      <q-space/>
      <q-chip dense color="orange" size="md" text-color="white">{{previousOpenTasksQnt}}</q-chip>
      <q-btn icon="chevron_left" round color="grey-3" text-color="grey" @click="moveCurrentDate('left')">
      </q-btn>

      <div class="text-center q-mx-lg">
        <div class="text-h5 font-weight-bold">Today</div>
        <div class="text-grey">{{currentDate}}</div>

      </div>
      <q-btn icon="chevron_right" round color="grey-3" text-color="grey" @click="moveCurrentDate('right')"/>
      <q-chip dense color="grey" size="md" text-color="white">{{nextOpenTasksQnt}}</q-chip>
      <q-space/>
    </q-card-actions>
  </q-card>
    <q-list separator>
      <q-item dense class="bg-grey-2">
        <q-item-section side>
          <q-icon name="filter_list" class="q-my-sm"/>
        </q-item-section>
        <q-item-section class="text-left">
          (by status)
          <div v-if="false">
            <q-chip icon="radio_button_unchecked" text-color="grey" color="grey-3" dense clickable @click="itemsSelect('done')">
              <span style="font-size: 12px" class="text-grey-5">(2)</span>
            </q-chip>
            <q-chip icon="check_circle_outline" text-color="green" color="green-2" dense clickable @click="itemsSelect('done')">
              <span style="font-size: 12px; color: black; font-weight: bold">(2)</span>
            </q-chip>
            <q-chip icon="pause_circle_outline" text-color="purple" color="purple-1" dense clickable @click="itemsSelect('done')">
              <span style="font-size: 12px; color: grey">(2)</span>
            </q-chip>
          </div>
        </q-item-section>
        <q-item-section side>
          <span class="font-weight-bold">{{labourTotal}}</span>
        </q-item-section>

      </q-item>
    </q-list>
    <q-scroll-area :style="'width: '+$store.state.screen.width+'px; height: '+scrollHeight+'px'">
      <table style="width: 98%" border="0">
        <tr v-for="currentTask in currentTasksSorted" :key="currentTask.id">
          <td style="width: 50px; vertical-align: top; border-bottom: 1px solid grey" class="text-center q-pa-sm">
            <q-icon name="lens" :color="statusColor(currentTask.data.status)" size="sm" @dblclick="taskFormOpen(currentTask.id)"/>
          </td>
          <td style="width: 300px; vertical-align: top; border-bottom: 1px solid grey" class="text-left q-pa-sm">
            <div class="bg-grey-lighten-3"
                 style="width: 280px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap"
                 @dblclick="tasksOpen(currentTask.spaceId, currentTask.data.listId, currentTask.data.itemId)"
            >
              {{ currentTask?.space?.data.name }} >
              {{ currentTask?.data.list?.data.name }} >
              {{ currentTask?.data.item?.data.fields["f1"] }}
            </div>
            <div class="q-my-sm">
              {{ currentTask.data.name }}
            </div>
          </td>
          <td style="width: 50px; vertical-align: top; border-bottom: 1px solid grey" class="text-right q-pa-sm">
            <div class="text-weight-bold">{{ currentTask.data.dueTime }}</div>
            <div>{{ currentTask.data.labour }}</div>
          </td>
        </tr>
      </table>

    </q-scroll-area>




<!--    <q-footer class="bg-white">-->
<!--      <div style="border-radius: 20px" class="bg-grey-2 q-px-xs q-ma-sm">-->
<!--      <q-input rounded v-model="message" dense borderless autogrow>-->

<!--        <template v-slot:before>-->
<!--          <q-btn round dense flat icon="event" />-->
<!--        </template>-->
<!--        <template v-slot:after>-->
<!--          <q-btn round dense flat icon="send" />-->
<!--        </template>-->
<!--      </q-input>-->
<!--      </div>-->
<!--    </q-footer>-->

<!--  </q-layout>-->
  </q-card>
  <q-dialog v-model="taskFormShow">
    <sl-task-form :task-id="selectedTaskId" @finished="taskFormShow=false"/>
  </q-dialog>
  <q-dialog v-model="tasksShow">
    <sl-tasks-of-obj :space-id="selectedTaskSpaceId" :list-id="selectedTaskListId" :item-id="selectedTaskItemId" @finished="tasksShow=false"/>
  </q-dialog>
<!--  <q-dialog v-model="userCryptoKeyFormShow">-->
<!--    <q-card>-->
<!--      <q-card-section>-->
<!--        <q-input v-model="userCryptoKeyInput" :disable="userCryptoKeyDisable"/>-->
<!--      </q-card-section>-->
<!--      <q-card-actions>-->
<!--        <btn @click="cryptoKeySave">Save</btn>-->
<!--        <btn @click="cryptoKeyGenerate">Generate crypto key</btn>-->
<!--        <btn @click="userCryptoKeyFormShow=false">Ok</btn>-->
<!--      </q-card-actions>-->
<!--    </q-card>-->
<!--  </q-dialog>-->
</template>

<script>
import {currentUserId, db} from "@/db";
import {useObservable} from "@vueuse/rxjs/index";
import {liveQuery} from "dexie";
import SlTaskForm from "@/components/sl-task-form";
import SlTasksOfObj from "@/components/sl-tasks-of-obj";
// import {generateKey} from "@/utils";

export default {
  name: "sl-checklist",
  components: {SlTasksOfObj, SlTaskForm},
  props: [ "date"],
  // setup(){
  //   return{
  //     db,
  //     userCryptoKey: useObservable(
  //         liveQuery(() => db.params.get("userCryptoKey"))
  //     ),
  //     userCryptoKeyInput: "",
  //     userCryptoKeyChecked: false,
  //     userCryptoKeyDisable: true,
  //     userCryptoKeyFormShow: false,
  //
  //   }
  // },
  data: ()=>{
    return{
      selectedTaskId: "",
      currentTasks: [],
      openTasks: [],
      previousOpenTasksQnt: 0,
      nextOpenTasksQnt: 0,
      currentTS: 0,
      currentDate: "",
      taskFormShow: false,
      tasksShow: false,
      selectedTaskSpaceId: "",
      selectedTaskListId: "",
      selectedTaskItemId: "",
      winHeight: window.innerHeight,

    }
  },
  async created(){
    this.currentDate = this.TStoDate(Date.now())
    this.openTasks = useObservable( liveQuery(() => db.table("tasks")
        .where(["data.respUserId+data.status"]).anyOf([[currentUserId, "new"], [currentUserId, "processing"]])
        .toArray()
      )
    )

  },
  mounted() {
    window.addEventListener('resize', ()=>{this.winHeight = window.innerHeight})
  },
  watch: {
    // userCryptoKey(newVal){
    //   this.userCryptoKeyInput = newVal
    // },
    // userCryptoKeyInput(newVal){
    //   if(this.userCryptoKey === newVal){
    //     this.userCryptoKeyChecked = true
    //   }
    //   else{
    //     this.userCryptoKeyChecked = false
    //   }
    // },
    currentDate(newVal){
      this.currentTasks = useObservable( liveQuery(() => db.table("tasks")
            .where({"data.respUserId": currentUserId, "data.dueDate": newVal})
            .toArray()
          )
        )
      if(this.openTasks) this.openTasksCalc()
    },
    openTasks(newVal){
      if(newVal) this.openTasksCalc()
    }
  },
  computed: {
    // currentTasks(){
    //   debugger
    //   let currentTasks =
    //     useObservable(
    //         liveQuery(() =>
    //           db.table("tasks")
    //           .where({"data.respUserId": currentUserId, "data.status": "new", "data.dueDate": this.currentDate})
    //           .toArray()
    // )
    //       )
    //   return currentTasks ? currentTasks : []
    // },
    scrollHeight(){
      return this.winHeight-300
    },
    labourTotal(){
      let labourTotal = 0
      if(!this.currentTasks) return labourTotal

      this.currentTasks.forEach(currentTask => {
        if(currentTask.data.labour){
          labourTotal = labourTotal + currentTask.data.labour * 1
        }
      })
      return labourTotal
    },
    currentTasksSorted(){
      if(!this.currentTasks) return []
      let currentTasksSorted = this.currentTasks
      currentTasksSorted.sort((a, b) => a.data.dueTime.localeCompare(b.data.dueTime))
      currentTasksSorted.forEach(async task => {
        task.space = await db.table("spaces").get(task.spaceId)
        task.data.list = await db.table("lists").get(task.data.listId)
        task.data.item = await db.table("items").get(task.data.itemId)
      })
      return currentTasksSorted
    }
    // messageLines(){
    //   return this.message.split("\n").length
    // }
  },
  methods: {
    tasksOpen(spaceId, listId, itemId){
      this.selectedTaskSpaceId = spaceId
      this.selectedTaskListId = listId
      this.selectedTaskItemId = itemId
      this.tasksShow = true
    },
    taskFormOpen(taskId){
      this.selectedTaskId = taskId
      this.taskFormShow = true
    },
    openTasksCalc(){
      let previousOpenTasks = this.openTasks.filter(task => {
        if(task.data.dueDate < this.currentDate) return true
        else return false
      })
      this.previousOpenTasksQnt = previousOpenTasks.length
      let nextOpenTasks = this.openTasks.filter(task => {
        if(task.data.dueDate > this.currentDate) return true
        else return false
      })
      this.nextOpenTasksQnt = nextOpenTasks.length
    },
    // cryptoKeySave(){
    //   db.params.put({
    //     id: "userCryptoKey",
    //     value: this.userCryptoKeyInput
    //   })
    // },
    // cryptoKeyGenerate(){
    //   this.userCryptoKeyInput = generateKey()
    // },
    moveCurrentDate(dir){
      let TS = this.dateToTS(this.currentDate)
      if(dir === "left"){
        TS = TS - 24 * 60 * 60 * 1000
      }
      if(dir === "right"){
        TS = TS + 24 * 60 * 60 * 1000
      }
      this.currentDate = this.TStoDate(TS)
    },
    TStoDate(TS){
      let date = new Date(TS)
      let curY = date.getFullYear()
      let curM = date.getMonth()+1
      let curD = date.getDate()
      if(curM<10) curM = "0"+curM
      if(curD<10) curD = "0"+curD
      return curY + "/" + curM + "/" + curD
    },
    dateToTS(d){
      let dateArr = d.split("/")
      let Y = parseInt(dateArr[0], 10)
      let M = parseInt(dateArr[1], 10) - 1
      let D = parseInt(dateArr[2], 10)
      let date = new Date(Y, M, D)
      return date.getTime()
    },
    statusColor(status){
      let statusColor = ""
      if(status === "new") statusColor = "grey"
      if(status === "processing") statusColor = "yellow"
      if(status === "done") statusColor = "green"
      if(status === "postponed") statusColor = "brown"
      return statusColor
    }
  }
}
</script>

<style scoped>

</style>