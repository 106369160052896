<template>

    <td v-if="rFieldId === 'z1'" :style="{borderBottom: '1px solid lightgrey'}" :class="bgClass">
      <q-icon :name="isOpened?'arrow_drop_down':'arrow_right'" round size="sm" class="ma-1 text-grey-darken-2" style="cursor: pointer"
              @click="$emit('openCloseClk')"
      />
    </td>
    <td v-else-if="subtotals.vals[rFieldId]" :style="{borderBottom: '1px solid lightgrey'}" :class="bgClass">
      {{subtotals.vals[rFieldId]}}
    </td>
    <td v-else-if="subtotals.fields[rFieldId]" :style="{borderBottom: '1px solid lightgrey'}" :class="bgClass">
      <span v-for="(fieldVal, key) in fieldVals" :key="key" :style="fieldVal.style">
        {{fieldVal.val}}
      </span>
      <span class="text-grey" v-if="rFieldId === subtotals.lastFieldId">
        ({{subtotals.vals.qnt}})
      </span>
    </td>
    <td v-else :style="{borderBottom: '1px solid lightgrey'}" :class="bgClass">
      <span class="text-grey" v-if="rFieldId === subtotals.lastFieldId">
        ({{subtotals.vals.qnt}})
      </span>
    </td>

</template>

<script>
export default {
  name: "sl-list5-subtotal-cell",
  props: ["subtotals", "rFieldId", "isOpened", "list5Id"],
  data: () => {
    return {
      listField: {},
      listFieldsForSubst: {},
      fieldVals: [],
      bgClass: ""
    }
  },
  created(){
    this.listField = this.$store.state.lists[this.list5Id].list.data.fields[this.rFieldId]
    this.listFieldForSubst = this.$store.state.lists[this.list5Id].listFieldsForSubst[this.rFieldId]
    if(this.subtotals.level > 0) this.bgClass = 'bg-grey-lighten-'+(this.subtotals.level*1+1)

    if(this.listFieldForSubst.type === "fixedsublist" ){
      let valSIds = this.subtotals.fieldsS[this.rFieldId] ? this.subtotals.fieldsS[this.rFieldId].split(";") : []
      for(let valSId of valSIds){
        this.fieldVals.push({
          val: this.listFieldForSubst.fixedSublist[valSId]?.value,
          valS: valSId,
          style: this.listFieldForSubst.fixedSublist[valSId]?.style
        })
      }
    }
    else{
      this.fieldVals = [{
        val: this.subtotals.fields[this.rFieldId],
        valS: "",
        style: ""
      }]
    }
  }
}
</script>

<style scoped>

</style>