<template>
  <q-card>
    <q-card-section>
    <table>
      <thead>
      <tr>
        <td v-for="hFieldId in view?.data?.fieldsOrder" :key="hFieldId" style="border-bottom: 2px solid grey; padding: 5px 15px 5px 5px">
          {{list?.data?.fields[hFieldId].name}} ({{hFieldId}})
        </td>
      </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
            <sl-list6-cell v-for="rFieldId in view?.data?.fieldsOrder" :key="rFieldId"
                           :item-id="item.id"
                           :field-id="rFieldId"
                           :val="item?.data.fields[rFieldId]"
            />
        </tr>
        <sl-list6-subtotal-row :subtotals="subTotals" :list="list" :view="view"/>
      </tbody>
    </table>
    </q-card-section>
  </q-card>
</template>

<script>
import { db } from '@/db'
import SlList6Cell from "@/components/sl-list6-cell"
import {resizableGrid2} from "@/utils2";
import SlList6SubtotalRow from "@/components/sl-list6-subtotal-row";
import { useObservable } from "@vueuse/rxjs";
import { liveQuery } from "dexie";

export default {
  name: "sl-list6",
  components: {SlList6SubtotalRow, SlList6Cell},
  props: ["space", "listId", "viewId"],
  data: () => {
    return {
      list5Id: "id_" + Date.now(),
      rawItems: [],
      subTotals: {},
      items: [],
      list: {},
      view: {}
    }
  },
  async mounted() {
    setTimeout(() => {
      let tableEl = this.$el.querySelector("table")
      resizableGrid2(tableEl)
    }, 500)
  },
  async created() {

    this.rawItems = useObservable(
      liveQuery(async () => {
        return await db.table("items").where({spaceId: this.space.id, 'data.listId': this.listId}).toArray()
      })
    )
    this.list = await db.table("lists").get(this.listId)
    this.view = this.list.data.views[this.list.data.defaultAdminViewId]


    window.addEventListener("keydown", (event) => {
      if (event.defaultPrevented) return // Do nothing if the event was already processed

      let buttons = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab', 'F2']
      if (buttons.includes(event.key)) {
        event.preventDefault()

      }
    })
  },

  watch: {
    rawItems: async function (newVal){
      if(!newVal) return
      if(newVal.length === 0) return
      await this.listPrepare()
      this.listSortSubTotal()
    }
  },

  methods: {
    async listPrepare(){
      if(!this.list.data) return
      if(!this.rawItems) return
      let list = this.list
      // sublists
      let sublistFields = []
      for (let fieldId in list.data.fields){
        if ( list.data.fields[fieldId].type === "sublist" &&
          !list.data.fields[fieldId].isFixedSublist &&
          !list.data.fields[fieldId].sublistBasedOnFieldId ){
          sublistFields.push({
            fieldId: fieldId,
            listIdForSublist: list.data.fields[fieldId].listIdForSublist,
            fieldIdForSublist: list.data.fields[fieldId].fieldIdForSublist,
            otherFields: []
          })
          this.$store.commit("sublistAddList", await db.table("lists").get(list.data.fields[fieldId].listIdForSublist))
        }
      }
      for (let sublistField of sublistFields){
        for (let fieldId in list.data.fields){
          if(list.data.fields[fieldId].sublistBasedOnFieldId === sublistField.fieldId){
            sublistField.otherFields.push({
              fieldId: fieldId,
              fieldIdForSublist: list.data.fields[fieldId].fieldIdForSublist
            })
            let sublist = this.$store.state.sublists[list.data.fields[fieldId].listIdForSublist]
            if(!sublist){
              this.$store.commit("sublistAddList", await db.table("lists").get(list.data.fields[fieldId].listIdForSublist))
            }
            sublist = this.$store.state.sublists[list.data.fields[fieldId].listIdForSublist]
            let sublistFieldId = list.data.fields[fieldId].fieldIdForSublist
            if(sublist && sublist.list.data.fields[sublistFieldId].type === "sublist" && !sublist.list.data.fields[sublistFieldId].isFixedSublist){
              sublistFields.push({
                fieldId: fieldId,
                listIdForSublist: sublist.list.data.fields[sublistFieldId].listIdForSublist,
                fieldIdForSublist: sublist.list.data.fields[sublistFieldId].fieldIdForSublist,
                otherFields: []
              })
              this.$store.commit("sublistAddList", await db.table("lists").get(sublist.list.data.fields[sublistFieldId].listIdForSublist))
            }
          }

        }
      }
      console.log(sublistFields)

      // formula fields order
      let formulaOrderLevelsArr = [[]]
      for (let fieldId in list.data.fields) {
        if (list.data.fields[fieldId].type === "formula") formulaOrderLevelsArr[0].push(fieldId)
      }
      let fun = function (level) {
        if(formulaOrderLevelsArr[level].length === 0) return
        formulaOrderLevelsArr.push([])
        for(let i = 0; i < formulaOrderLevelsArr[level].length; i++){
          let fieldId = formulaOrderLevelsArr[level][i]
          let regexp = /item.f\d+/gi
          let formulaFieldsArr = list.data.fields[fieldId].formula.match(regexp)

          for (let itemField of formulaFieldsArr){
            let itemFieldId = itemField.substring(5)
            if(formulaOrderLevelsArr[level].includes(itemFieldId)){
              formulaOrderLevelsArr[level].splice(i, 1)
              formulaOrderLevelsArr[level+1].push(fieldId)
              i-- // reindex array formulaOrder[level] after deleting
              break
            }
          }
        }
        fun(level+1)
      }
      fun(0)
      let formulaOrder = []
      for(let formulaOrderLevelArr of formulaOrderLevelsArr){
        if (formulaOrderLevelArr.length === 0) continue
        formulaOrder = formulaOrder.concat(formulaOrderLevelArr)
      }
      console.log("formula order", formulaOrder)

      let funRun = function(item, formulaOrder){
        for(let fieldId in list.data.fields){
          if(fieldId.charAt(0) === "f"){
            if(list.data.fields[fieldId].type === "num" && !item[fieldId]) item[fieldId] = 0
            if(list.data.fields[fieldId].type === "text" && !item[fieldId]) item[fieldId] = ""
            if(list.data.fields[fieldId].type === "sublist" && !item[fieldId]) item[fieldId] = ""
          }
        }
        for(let fieldId of formulaOrder){
          item[fieldId] = eval(list.data.fields[fieldId].formula)
        }

      }

      //   for (let fieldId in list.data.fields){
      //   if ( list.data.fields[fieldId].type === "formula"){
      //
      //   }
      // }


      // sublists substitution in items
      let items = []
      for(let item of this.rawItems) {

        for (let sublistField of sublistFields){

          if(!item.data.fieldsS[sublistField.fieldId]) continue

          let subitem = this.$store.state.sublists[sublistField.listIdForSublist].items[item.data.fieldsS[sublistField.fieldId]]
          if(!subitem){
            subitem = await db.table("items").get(item.data.fieldsS[sublistField.fieldId])
            this.$store.commit("sublistAddSubitem", subitem)
          }
          item.data.fields[sublistField.fieldId] = subitem.data.fields[sublistField.fieldIdForSublist]
          for(let otherField of sublistField.otherFields){
            if(subitem.data.fields[otherField.fieldIdForSublist]){
              item.data.fields[otherField.fieldId] = subitem.data.fields[otherField.fieldIdForSublist]
            }
            if(subitem.data.fieldsS[otherField.fieldIdForSublist]){
              item.data.fieldsS[otherField.fieldId] = subitem.data.fieldsS[otherField.fieldIdForSublist]
            }
          }
        }

        funRun(item.data.fields, formulaOrder)
        items.push(item)
      }



      console.log(this.$store.state.sublists)

      // this.$store.commit("listsList5Add", {
      //   list5Id: this.list5Id,
      //   list5: {
      //     listId: list.id,
      //     list: list,
      //     view: view,
      //     items: items,
      //     currentCell: {itemId: "", fieldId: ""},
      //     isActive: true
      //   }
      // })

      this.items = items


    },
    listSortSubTotal(){
      if(!this.list.data) return
      if(!this.view.data) return
      // sorting
      let sorting = []
      this.view.data.fieldsOrder.forEach(fieldId => {
        if (this.view.data.fields[fieldId].sort) sorting.push({
          fieldId: this.view.data.fields[fieldId].id,
          sort: this.view.data.fields[fieldId].sort
        })
      })

      if (sorting.length === 0) return

      this.items.sort((a, b) => {     // state is changed after sorting even without commit - surprise
        let result = 0
        let itemA = ""
        let itemB = ""
        for (let i = 0; i < sorting.length; i++) {
          itemA = a.data.fields[sorting[i].fieldId] ? a.data.fields[sorting[i].fieldId] : ""
          itemB = b.data.fields[sorting[i].fieldId] ? b.data.fields[sorting[i].fieldId] : ""
          if (sorting[i].sort === 'asc') {
            result = itemA.localeCompare(itemB)
            if (result !== 0) break
          }
          if (sorting[i].sort === 'desc') {
            result = itemB.localeCompare(itemA)
            if (result !== 0) break
          }
          // if(result !== 0) return
        }
        return result
      })

      // subtotals
      let groupFields = []
      let calcFields = []
      let funCalc = function(subTotals, itemFields, listData, viewData){
        // debugger
        subTotals.vals.qnt++
        for(let fieldId of calcFields){
          let itemVal =  ""
          if(!itemFields[fieldId]){
            if(listData.fields[fieldId].type === "num") itemVal = 0
          }
          else{
            itemVal = itemFields[fieldId]
          }
          if(listData.fields[fieldId].type === "num"){
            if(viewData.fields[fieldId].calc === "sum") subTotals.vals[fieldId] = subTotals.vals[fieldId] * (1) + itemVal * (1)
            if(viewData.fields[fieldId].calc === "avg") subTotals.vals[fieldId] = subTotals.vals[fieldId] * (1) + itemVal * (1)
            if(viewData.fields[fieldId].calc === "min") subTotals.vals[fieldId] = subTotals.vals[fieldId] < itemVal ? subTotals.vals[fieldId] : itemVal
            if(viewData.fields[fieldId].calc === "max") subTotals.vals[fieldId] = subTotals.vals[fieldId] > itemVal ? subTotals.vals[fieldId] : itemVal
          }
          else if(listData.fields[fieldId].type === "date" || listData.fields[fieldId].type === "time"){
            itemVal = itemVal.replaceAll(" ", "")
            if(subTotals.vals[fieldId] === "") {
              subTotals.vals[fieldId] = itemVal
              continue
            }
            if(itemVal === "") {
              continue
            }
            if(viewData.fields[fieldId].calc === "max" && itemVal !== "") subTotals.vals[fieldId] = subTotals.vals[fieldId].localeCompare(itemVal) > 0 ? subTotals.vals[fieldId] : itemVal
            if(viewData.fields[fieldId].calc === "min" && itemVal !== "") subTotals.vals[fieldId] = subTotals.vals[fieldId].localeCompare(itemVal) < 0 ? subTotals.vals[fieldId] : itemVal
          }
          else{
            if(viewData.fields[fieldId].calc === "max") subTotals.vals[fieldId] = subTotals.vals[fieldId].localeCompare(itemVal) > 0 ? subTotals.vals[fieldId] : itemVal
            if(viewData.fields[fieldId].calc === "min") subTotals.vals[fieldId] = subTotals.vals[fieldId].localeCompare(itemVal) < 0 ? subTotals.vals[fieldId] : itemVal
          }
        }
      }
      sorting.forEach(field => {
        if(this.view.data.fields[field.fieldId].group) groupFields.push(field.fieldId)
      })
      let listFields = this.list.data.fields
      for(let fieldId in listFields){
        if(this.view.data.fields[fieldId].calc) calcFields.push(fieldId)
      }
      let subTotals= {fields: {}, vals: {}}
      let subTotalsArr = []
      let listData = this.list.data
      let viewData = this.view.data
      this.items.forEach(item => {
        // debugger
        let newSubtotalRequired = false
        for(let fieldId of groupFields){
          if(item.data.fields[fieldId] !== subTotals.fields[fieldId]) {
            newSubtotalRequired = true
            break
          }
        }
        if(newSubtotalRequired){
          subTotals= {fields: {}, vals: {}}
          subTotalsArr.push(subTotals)
          for(let fieldId of groupFields){
            subTotals.fields[fieldId] = item.data.fields[fieldId]
          }
          subTotals.vals.qnt = 1
          for(let fieldId of calcFields){
            subTotals.vals[fieldId] = item.data.fields[fieldId] ? item.data.fields[fieldId] : ""
          }
        }
        else{
          funCalc(subTotals, item.data.fields, listData, viewData)
        }
      })
      for(let subTotals of subTotalsArr){
        for(let fieldId in subTotals.vals){
          if(viewData.fields[fieldId]?.calc === "avg") {
            subTotals.vals[fieldId] = subTotals.vals[fieldId] / subTotals.vals.qnt
          }
        }
      }
      console.log("subTotalsArr: ", subTotalsArr)

      let subTotals0 = {fields: {}, vals: {}, subTotals0Arr: [], level: 0}
      subTotals0.vals.qnt = 0
      for(let fieldId of calcFields){
        subTotals0.vals[fieldId] = ""
        if(this.list.data.fields[fieldId].type === "num"){
          subTotals0.vals[fieldId] = 0
        }
      }
      subTotals0.subTotals0Arr.push({fields: {}, vals: {}, subTotals0Arr: []}) // technical

      subTotalsArr.forEach(line => {
        let subTotalsCurrent = subTotals0
        // debugger
        funCalc(subTotalsCurrent, line.vals, listData, viewData)
        let level = 1
        for(let i = 0; i < level; i++){
          // debugger
          if(line.fields[groupFields[i]] === subTotalsCurrent.subTotals0Arr[subTotalsCurrent.subTotals0Arr.length-1]?.fields[groupFields[i]]){
            subTotalsCurrent = subTotalsCurrent.subTotals0Arr[subTotalsCurrent.subTotals0Arr.length-1]
            funCalc(subTotalsCurrent, line.vals, listData, viewData)
            if(level < groupFields.length) level++
            continue
          }
          else{
            subTotalsCurrent.subTotals0Arr.push({fields: {}, vals: {}, subTotals0Arr: [], level: level})
            subTotalsCurrent = subTotalsCurrent.subTotals0Arr[subTotalsCurrent.subTotals0Arr.length-1]
            for(let j=0; j<=i; j++){
              subTotalsCurrent.fields[groupFields[j]] = line.fields[groupFields[j]]
            }
            for(let fieldId of calcFields){
              subTotalsCurrent.vals[fieldId] = line.vals[fieldId]
            }
            subTotalsCurrent.vals.qnt = 1
            if(level < groupFields.length) level++
            continue
          }
        }
      })
      subTotals0.subTotals0Arr.shift()
      console.log("subTotals0: ", subTotals0)

      this.subTotals = subTotals0
    }
  }

}
</script>

<style scoped>

</style>