<template>
  <q-card class="full-width" v-if="currentView">
    <q-card-section class="text-center">
      <div>View</div>
      <div class="font-weight-bold">{{currentView._data.name}}</div>
    </q-card-section>
    <q-card-section>
    <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="green"
        indicator-color="green"
        align="center"
        narrow-indicator
        inline-label
    >
      <q-tab name="general" label="General" />
      <q-tab name="fields" label="Fields" />
      <q-tab name="addon" label="addon" />
    </q-tabs>
    <q-separator />
    <q-tab-panels v-model="tab" animated style="height: 380px">
      <q-tab-panel name="general" >
        <div>
          <q-input v-model="currentView._data.name" label="view name" :rules="[val => !!val || 'Field is required']" dense color="green" />
        </div>
        <div class="q-my-md">
          View
          <q-radio v-model="currentView._data.owner" :val="userPhoneNumber" label="Mine" color="green"/>
          <q-radio v-model="currentView._data.owner" val="admin" label="General" color="green"/>
          &nbsp;
          <q-checkbox v-model="isDefaultView" dense color="green" label="Default view"/>
        </div>
        <div class="q-my-md">
          Grand total
          <q-radio v-model="currentView._data.grandTotal" :val="'onTop'" label="On top" color="green"/>
          <q-radio v-model="currentView._data.grandTotal" :val="'onBottom'" label="On bottom" color="green"/>
        </div>
        <div class="q-my-md">
          Show borders
          <q-checkbox v-model="currentView._data.hBorders" dense color="green" label="horizontal"/>&nbsp;
          <q-checkbox v-model="currentView._data.vBorders" dense color="green" label="vertical"/>
        </div>
        <div class="q-my-md">
          Alternate rows color
          <q-checkbox v-model="currentView._data.alternateRowsColor" dense color="green"/>&nbsp;
        </div>
      </q-tab-panel>
      <q-tab-panel name="fields">
        <div class="q-ma-md">
        <q-checkbox v-model="currentView._data.isVerticalView" dense color="green" label="Vertical fields view"/>
        </div>
        <q-scroll-area style="height: 300px; ">
          <q-list separator>
            <q-item v-for="fieldId in currentView._data.fieldsOrder" :key="fieldId">
              <q-item-section>
                <q-item-label>
                  {{list.data.fields[fieldId].name}}
                  <span class="text-grey">({{fieldId}})</span>
                </q-item-label>
                <q-item-label>
                  <q-chip dense clickable @click="moveFieldUp(fieldId)"><q-icon name="keyboard_double_arrow_up"/></q-chip>
                  <q-chip dense clickable @click="moveFieldDown(fieldId)"><q-icon name="keyboard_double_arrow_down"/></q-chip>
                <q-chip dense clickable
                        :icon-right="currentView._data.fields[fieldId].sort==='desc'?'arrow_downward':currentView._data.fields[fieldId].sort==='asc'?'arrow_upward':''"
                        :color="currentView._data.fields[fieldId].sort?'green':''"
                        :text-color="currentView._data.fields[fieldId].sort?'white':''"
                        @click="currentView._data.fields[fieldId].sort==='desc'?currentView._data.fields[fieldId].sort='asc':currentView._data.fields[fieldId].sort==='asc'?currentView._data.fields[fieldId].sort='':currentView._data.fields[fieldId].sort='desc'"
                >
                  <q-icon name="filter_list"></q-icon>
                </q-chip>
                <q-chip dense clickable
                        :color="currentView._data.fields[fieldId].filter?'green':''"
                        :text-color="currentView._data.fields[fieldId].filter?'white':''"
                        @click="filterFormOpen(fieldId)"
                >
                  <q-icon name="filter_alt_outlined"></q-icon>
                </q-chip>
                <q-chip dense clickable
                        :color="currentView._data.fields[fieldId].group?'green':''"
                        :text-color="currentView._data.fields[fieldId].group?'white':''"
                        @click="currentView._data.fields[fieldId].group=!currentView._data.fields[fieldId].group"
                >
                  <q-icon name="list"></q-icon>
                </q-chip>
                <q-chip dense clickable
                        :color="currentView._data.fields[fieldId].calc?'green':''"
                        :text-color="currentView._data.fields[fieldId].calc?'white':''"
                        @click="calcChange(fieldId)"
                >
                  {{currentView._data.fields[fieldId].calc?currentView._data.fields[fieldId].calc:'+/-'}}
                </q-chip>
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn round size="sm" flat dense @click="removeField(fieldId)"><q-icon color="red" name="highlight_off"/></q-btn>
              </q-item-section>
            </q-item>
            <q-btn round icon="add" @click="unselectedFieldsFormShow=true"/>
            <q-dialog v-model="unselectedFieldsFormShow">
              <q-card>
                <q-card-section>
                  <q-chip clickable v-for="unselectedField in unselectedFields" :key="unselectedField.id" @click="addField(unselectedField.id)">{{unselectedField.name}} ({{unselectedField.id}})</q-chip>
                </q-card-section>
                <q-card-actions class="justify-center">
                  <q-btn flat @click="unselectedFieldsFormShow=false">ok</q-btn>
                </q-card-actions>
              </q-card>
            </q-dialog>
<!--            <q-separator />-->
<!--            <q-item v-for="uField in unselectedFields" :key="uField.id">-->
<!--              <q-item-section>-->
<!--                <q-item-label>-->
<!--                  {{list.fields.find(el=>el.id===uField.id).name}}-->
<!--                  <span class="text-grey">({{uField.id}})</span>-->
<!--                </q-item-label>-->
<!--              </q-item-section>-->
<!--              <q-item-section side>-->
<!--                <q-btn round size="sm" flat dense @click="addField(uField.id)"><q-icon color="green" name="control_point"/></q-btn>-->
<!--              </q-item-section>-->
<!--            </q-item>-->
          </q-list>
        </q-scroll-area>
      </q-tab-panel>
      <q-tab-panel name="addon">
        <q-select v-model="addonId"
                  :options="addonOptions"
                  :option-value="'id'"
                  :option-label="'val'"
                  emit-value
                  map-options
                  style="min-width: 250px; max-width: 300px" label="Select addon"
        />
        <q-btn class="q-ma-sm" @click="addonParamsFormShow=true" label="Configure addon"
               :icon="!currentView.data?.addonParams?.paramsIsOk ? 'error_outline' : 'check_circle_outline'"
               :text-color="!currentView.data?.addonParams?.paramsIsOk ? 'pink' : 'green'"
               v-if="addonId"/>
        <q-btn class="q-ma-sm" @click="addonSwitchOff()" label="Switch off addon" v-if="addonId"/>

        <q-dialog v-model="addonParamsFormShow" persistent>
          <sl-addon-params-view-form :list="list" :view="currentView" :addonId="addonId" @finished="addonParamsFormShow=false"/>
        </q-dialog>

      </q-tab-panel>
    </q-tab-panels>

    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn flat @click="$emit('finished')">Cancel</q-btn>
      <q-btn flat @click="viewSave()">Save</q-btn>
      <q-btn flat @click="viewSave('asNew')">Save as new</q-btn>
    </q-card-actions>
    <q-dialog v-model="filterFormShow">
      <sl-list-filter-form :list="list" :view="currentView" :fieldId="filterFormFieldId" v-if="filterFormShow" @finished="filterFormShow=false"/>
    </q-dialog>
  </q-card>
</template>

<script>
import {generateId, paramsGet} from "@/utils";
import {db} from "@/db";
// import {toRaw} from "vue";
import SlListFilterForm from "@/components/sl-list-column-filter-form";
import {toRaw} from "vue";
import {obj, objStatusFieldsSet} from "@/utils4";
import SlAddonParamsViewForm from "@/components/sl-addon-params-view-form";

export default {
  name: "sl-list-view-form",
  components: {SlAddonParamsViewForm, SlListFilterForm},
  props: ["list", "view"],
  data: () => {
    return {
      tab: "general",
      currentView: null,
      viewId: "",
      viewName: "",
      viewOwner: "",
      gradTotalDisplay: "",
      isDefaultView: false,
      defaultViews: [],
      fields: [],
      // unselectedFields: [],
      userPhoneNumber: "",
      filterFormShow: false,
      filterFormFieldId: "",
      unselectedFieldsFormShow: false,
      addonOptions: [],
      addonId: "",
      addonParamsFormShow: false
    }
  },
  async created() {
    let user = await paramsGet("user")
    console.log(user)
    // this.userPhoneNumber = user.phoneNumber
    let tView = JSON.parse(JSON.stringify(this.view))
    tView.data = tView._data
    this.currentView = obj(tView, "view")

    this.addonOptions.push({
      id: "gantt",
      val: "Gantt diagram"
    })
    this.addonId = this.currentView.data?.addonParams?.addonId

    // if(this.currentView.id === ""){
    //   this.currentView.id = generateId()
    //   this.currentView._data.viewOwner = this.userPhoneNumber
    //   this.currentView._data.grandTotal = ""
    // }
    // else{
    //   this.list.fields.forEach(el=>{
    //     if(this.currentView.fields.findIndex(f=>f.id===el.id)===-1){
    //       this.unselectedFields.push({id: el.id, name: el.name})
    //     }
    //   })
    // }
    // if(this.view.id === ""){
    //   this.viewId = generateId()
    //   this.viewName = ""
    //   this.fields = this.view.fields
    //   this.viewOwner = this.userPhoneNumber
    //   this.grandTotal = "no"
    // }
    // else{
    //   this.viewId = this.view.id
    //   this.viewName = this.view.name
    //   this.viewOwner = this.view.owner
    //   this.gradTotalDisplay = this.view.gradTotalDisplay
    //   this.defaultViews = this.view.defaultViews
    //   this.fields = this.view.fields
    //   this.list.fields.forEach(el=>{
    //     if(this.fields.findIndex(f=>f.id===el.id)===-1){
    //       this.unselectedFields.push({id: el.id, name: el.name})
    //     }
    //   })
    // }
  },
  computed: {
    unselectedFields(){
      let that = this
      let unselectedFields = []
      Object.entries(this.list.data.fields).forEach(([, val]) => {
        if(!that.currentView._data.fieldsOrder.includes(val.id)) unselectedFields.push({id: val.id, name: val.name})
      })
      return unselectedFields
    }
  },
  methods: {
    addField(fieldId){
      this.currentView._data.fieldsOrder.push(fieldId)
    },
    removeField(fieldId){
      let indx = this.currentView._data.fieldsOrder.findIndex(el=>el === fieldId)
      this.currentView._data.fieldsOrder.splice(indx, 1)
    },
    calcChange(fieldId){
      let calc = this.currentView._data.fields[fieldId].calc
      if(calc === "" || calc === undefined) calc = "sum"
      else if(calc === "sum") calc = "avg"
      else if(calc === "avg") calc = "min"
      else if(calc === "min") calc = "max"
      else if(calc === "max") calc = ""
      this.currentView._data.fields[fieldId].calc = calc
    },
    moveFieldUp(fieldId){
      let index = this.currentView._data.fieldsOrder.findIndex(el=>el===fieldId)
      if (index === 0) return
      let el = this.currentView._data.fieldsOrder[index]
      this.currentView._data.fieldsOrder.splice(index, 1)
      this.currentView._data.fieldsOrder.splice(index-1, 0, el)

    },
    moveFieldDown(fieldId){
      let index = this.currentView._data.fieldsOrder.findIndex(el=>el===fieldId)
      if (index === this.currentView._data.fieldsOrder.length) return
      let el = this.currentView._data.fieldsOrder[index]
      this.currentView._data.fieldsOrder.splice(index, 1)
      this.currentView._data.fieldsOrder.splice(index+1, 0, el)
    },
    viewSave(p){
      let that = this
      // checks
      this.msgs = []
      if(this.currentView._data.name === ""){
        this.msgs.push("View name is required. Fill view name")
      }
      if(this.msgs.length>0) return
      // save
      if(p === "asNew"){
        // let newView = {
        //   id: generateId(),
        //   // spaceId: this.currentView.spaceId,
        //   data: toRaw(this.currentView._data),
        //   // $data: toRaw(this.currentView.$data),
        //   // status: "1"
        // }
        let newViewId = generateId()
        db.lists.where("id").equals(this.list.id).modify(l => {
          l.data.views[newViewId] = {
            id: newViewId,
            data: toRaw(this.currentView._data),
            _data: {}
          }
          objStatusFieldsSet(l)
        }).then(()=>{
          that.$router.push({ name: 'list', params: { spaceId: that.list.spaceId, listId: that.list.id, viewId: newViewId } })
        })
        // db.views.add(newView).then(()=>{
        //   that.$router.push({ name: 'list', params: { spaceId: that.list.spaceId, listId: that.list.id, viewId: newView.id } })
        // })
      }
      else{
        db.lists.where('id').equals(this.list.id).modify(l => {
          l.data.views[that.currentView.id].data = toRaw(that.currentView._data)
          objStatusFieldsSet(l)
        }).then(()=>{
          window.location.reload()
        })
        // db.views.where('id').equals(this.currentView.id).modify(view => view.data = toRaw(that.currentView._data)).then(()=>{
        //   window.location.reload()
        // })
      }
      // let view = {
      //   id: this.viewId,
      //   name: this.viewName,
      //   owner: this.viewOwner,
      //   gradTotalDisplay: this.gradTotalDisplay,
      //   defaultViews: toRaw(this.defaultViews),
      //   fields: toRaw(this.fields)
      // }
      // if(this.list.views === undefined){
      //   db.lists.where('id').equals(this.list.id).modify(list => list.views = [].push(that.currentView))
      // }
      // else{
      //   let indx = this.list.views.findIndex(el=>el.id===that.currentView.id)
      //   if(indx === -1){
      //     db.lists.where('id').equals(this.list.id).modify(list => list.views.push(that.currentView))
      //   }
      //   else{
      //     db.lists.where('id').equals(this.list.id).modify(list => list.views[indx] = that.currentView)
      //   }
      // }
      // this.$emit("finished")
      // window.location.reload()

    },
    filterFormOpen(fieldId){
      this.filterFormFieldId = fieldId
      this.filterFormShow = true
    },
    addonSwitchOff(){
      this.currentView.data.addonParams.addonId = ""
      this.currentView.data.addonParams.params = {}
      this.currentView.data.addonParams.paramsIsOk = ""
      this.addonId = ""
    }
  }
}
</script>

<style scoped>

</style>