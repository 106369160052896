<template>
  <template v-if="$q.platform.is.mobile">
    <div v-if="((level===0 && view.data.grandTotal==='onTop')||level!==0)&&totalFields"
         style="border-bottom: 1px solid lightgray"
         :class="level === 0 ? 'row items-center bg-yellow-2':'row items-center bg-grey-'+(4 - level)"
    >
      <sl-list-cell-group v-for="fieldId in view._data.fieldsOrder" :key="fieldId"
                          :level="level"
                          :fieldId="fieldId"
                          :totalFields="totalFields"
                          :upperGroupFieldId="upperGroupFieldId"
                          :view="view"
                          :list="list"
                          @expanded="expanded=true"
                          @collapsed="expanded=false"
      />
    </div>
      <sl-list-items
        v-for="(group, a) in groupsFields" :key="a"
        :list="list"
        :view="view"
        :items="group.items"
        :fields="fields"
        :currentCell="currentCell"
        :upperGroupCount="group.count"
        :upperGroupFields="group.fields"
        :upperGroupFieldsS="group.fieldsS"
        :upperGroupFieldId="currentGroupFieldId"
        :groupFieldId="nextGroupFieldId"
        :level="level+1"
        :upperListDataForDrilldown="upperListDataForDrilldown"
        @cellClick="(pl)=>{$emit('cellClick', pl)}"
        @cellDblclick="(pl)=>{$emit('cellDblclick', pl)}"
        @cellBlur="(pl)=>{$emit('cellBlur', pl)}"
        @cellContextmenu="(pl)=>{$emit('cellContextmenu', pl)}"
        @cellMousedown="(pl)=>{$emit('cellMousedown', pl)}"
        @checkboxUpdated="(pl)=>{$emit('checkboxUpdated', pl)}"
        @drilldownListSelected="$emit('drilldownListSelected', $event)"
        @groupFieldsToAddonReady="(groupFields) => groupFieldsToAddonSend(groupFields, a)"

      />
      <div class="sl-item" v-for="(item, h) in subItems[cPage]" :key="item.id" :id="item.id"
           v-touch-swipe.left="itemFormOpen"
           v-touch-hold="itemButtonsShow"
           style="border-bottom: 1px solid lightgrey;"
      >
        <div style="height: 0px; position: relative" class="row" v-if="item.id!==''">
          <sl-list-item-status :item="item" style="position: absolute; right:  10px; top: 13px" v-if="item.status!=='updated'"/>
        </div>
        <div class="row items-center" style=" border-radius: 0px; _box-shadow: 0 0 8px lightgray; overflow: hidden;" >
          <div class="layout-row row items-center col-12" v-for="(row, i) in view._data?.phoneLayout.rows" :key="i" style="width: 100%; padding-top: 10px; padding-bottom: 10px">
            <div :class="['layout-block row', block.fieldsClassJustify, block.fieldsClassItems, block.fieldsClassContent, 'col-'+block.col]"
                 style="width: 100%"
                 v-for="(block, j) in row.blocks" :key="j"
            >
              <div class="layout-field row items-center justify-center"
                   :style="{width: field.width+field.widthMeasure, padding: '2px', minWidth: '40px'}"
                   v-for="(field, f) in block.fields" :key="f"
              >
                <sl-list-cell
                  :fieldId="field.id"
                  :list="list"
                  :item="item"
                  :view="view"
                  :itemNumber="itemsPerPage*cPage+h+1"
                  :currentCell="currentCell"
                  :upperGroupFields="upperGroupFields"
                  :upperGroupFieldId="upperGroupFieldId"
                  :phone-layout-field="field"
                  @cellClick="cellEvent($event, 'cellClick', item, field.id)"
                  @cellDblclick="cellEvent($event, 'cellDblclick', item, field.id)"
                  @cellBlur="cellEvent($event, 'cellBlur', item, field.id)"
                  @cellContextmenu="cellEvent($event, 'cellContextmenu', item, field.id)"
                  @cellMousedown="cellEvent($event, 'cellMousedown', item, field.id)"
                  @checkboxUpdated="cellEvent($event, 'checkboxUpdated', item, field.id)"
                  @drilldownListSelected="$emit('drilldownListSelected', $event)"
                />
              </div>
            </div>
          </div>

        </div>
        <q-slide-transition>
          <div class="text-center" style="width: 100%;" v-if="item.id===itemIdTouchedHold">
            <div style="padding: 10px 0 20px 0">
              <q-btn round icon="arrow_drop_up" dense size="md" class="text-red q-ma-xs" @click="itemIdTouchedHold=''"/>
              <q-btn round icon="content_copy" dense size="md" class="text-grey q-ma-xs"/>
              <q-btn round icon="delete" dense size="md" class="text-grey q-ma-xs"/>
              <q-btn round icon="view_quilt" dense size="md" class="text-grey q-ma-xs" @click="viewMobileFormOpen(item)"/>
              <q-btn round icon="settings" dense size="md" class="text-grey q-ma-xs"/>
            </div>
          </div>
        </q-slide-transition>
      </div>
      <q-dialog v-model="viewMobileFormShow" maximized>
        <sl-view-mobile-form :list="list" :viewId="view.id" :item="currentItem" @finished="viewMobileFormShow=false"/>
      </q-dialog>


      <div v-if="items.length > itemsPerPage && subItems.length > 0">
          <div class="text-center q-ma-xl">
            <q-btn dense size="sm" class="q-ma-xs" round icon="first_page" @click="goTo('first')"></q-btn>
            <q-btn dense size="sm" class="q-ma-xs" round icon="chevron_left" @click="goTo('left')"></q-btn>
            {{cPage+1}} (of {{subItems.length}})
            <q-btn dense size="sm" class="q-ma-xs" round icon="chevron_right" @click="goTo('right')"></q-btn>
            <q-btn dense size="sm" class="q-ma-xs" round icon="last_page" @click="goTo('last')"></q-btn>
          </div>
      </div>

    <q-dialog v-model="itemFormShow" position="right" maximized :transition-duration="500">
      <q-card :style="{width: phoneWidth+'px'}" v-touch-swipe.right="itemFormClose">
        <q-card-section class="column">
          <div>
            list Id: {{currentItem.data.listId}}
            item Id: {{currentItem.id}}
          </div>
          <sl-list-cell
            v-for="itemField in list.data.fields" :key="itemField.id"
            :fieldId="itemField.id"
            :list="list"
            :item="currentItem"
            :view="view"
            :itemNumber="''"
            :currentCell="currentCell"
            :upperGroupFields="upperGroupFields"
            :upperGroupFieldId="upperGroupFieldId"
            :phone-layout-field="''"
          />
        </q-card-section>

      </q-card>
    </q-dialog>

  </template>
  <template v-else>
  <tr :class="{'tr-group-s1': view._data.hBorders, 'tr-group-s2': view._data.vBorders}"
      style="height:100%"
      v-if="((level===0 && view.data.grandTotal==='onTop')||level!==0)&&totalFields"
  >
    <sl-list-cell-group v-for="fieldId in view._data.fieldsOrder" :key="fieldId"
                        :level="level"
                        :fieldId="fieldId"
                        :totalFields="totalFields"
                        :upperGroupFieldId="upperGroupFieldId"
                        :view="view"
                        :list="list"
                        @expanded="expanded=true"
                        @collapsed="expanded=false"
    />
  </tr>

  <sl-list-items
      v-for="(group, a) in groupsFields" :key="a"
      :list="list"
      :view="view"
      :items="group.items"
      :fields="fields"
      :currentCell="currentCell"
      :upperGroupCount="group.count"
      :upperGroupFields="group.fields"
      :upperGroupFieldsS="group.fieldsS"
      :upperGroupFieldId="currentGroupFieldId"
      :groupFieldId="nextGroupFieldId"
      :level="level+1"
      :upperListDataForDrilldown="upperListDataForDrilldown"
      @cellClick="(pl)=>{$emit('cellClick', pl)}"
      @cellDblclick="(pl)=>{$emit('cellDblclick', pl)}"
      @cellBlur="(pl)=>{$emit('cellBlur', pl)}"
      @cellContextmenu="(pl)=>{$emit('cellContextmenu', pl)}"
      @cellMousedown="(pl)=>{$emit('cellMousedown', pl)}"
      @checkboxUpdated="(pl)=>{$emit('checkboxUpdated', pl)}"
      @drilldownListSelected="$emit('drilldownListSelected', $event)"
      @groupFieldsToAddonReady="(groupFields) => groupFieldsToAddonSend(groupFields, a)"

  />
    <tr :class="{'tr-row-s1': view._data.hBorders, 'tr-row-s2': view._data.vBorders}"
        style="height:100%"
        v-for="(item, i) in subItems[cPage]" :key="item.id"
    >
      <sl-list-cell v-for="fieldId in view._data.fieldsOrder" :key="item.id+'-'+fieldId+'-'+item.$data?.lastTS"
                    :fieldId="fieldId"
                    :list="list"
                    :item="item"
                    :view="view"
                    :itemNumber="itemsPerPage*cPage+i+1"
                    :currentCell="currentCell"
                    :upperGroupFields="upperGroupFields"
                    :upperGroupFieldId="upperGroupFieldId"
                    @cellClick="cellEvent($event, 'cellClick', item, fieldId)"
                    @cellDblclick="cellEvent($event, 'cellDblclick', item, fieldId)"
                    @cellBlur="cellEvent($event, 'cellBlur', item, fieldId)"
                    @cellContextmenu="cellEvent($event, 'cellContextmenu', item, fieldId)"
                    @cellMousedown="cellEvent($event, 'cellMousedown', item, fieldId)"
                    @checkboxUpdated="cellEvent($event, 'checkboxUpdated', item, fieldId)"
                    @drilldownListSelected="$emit('drilldownListSelected', $event)"
      />
    </tr>
    <tr v-if="items.length > itemsPerPage && subItems.length > 0" style="height:100%">
      <td :colspan="view._data.fieldsOrder.length" class="_d-flex" style="height: 40px; _background-color: white; border-bottom: 0px; padding: 5px">
        <div>
          <q-btn dense size="xs" class="q-ma-xs" round icon="first_page" @click="goTo('first')"></q-btn>
          <q-btn dense size="xs" class="q-ma-xs" round icon="chevron_left" @click="goTo('left')"></q-btn>
          {{cPage+1}} (of {{subItems.length}})
          <q-btn dense size="xs" class="q-ma-xs" round icon="chevron_right" @click="goTo('right')"></q-btn>
          <q-btn dense size="xs" class="q-ma-xs" round icon="last_page" @click="goTo('last')"></q-btn>

        </div>
      </td>
    </tr>

    <tr :class="{'tr-group-s1': view._data.hBorders, 'tr-group-s2': view._data.vBorders}" style="height:100%"
    v-if="level===0 && view.data.grandTotal==='onBottom'"
    >
    <sl-list-cell-group v-for="fieldId in view._data.fieldsOrder" :key="fieldId"
                        :level="level"
                        :fieldId="fieldId"
                        :totalFields="totalFields"
                        :upperGroupFieldId="upperGroupFieldId"
                        :view="view"
                        :list="list"
                        @expanded="expanded=true"
                        @collapsed="expanded=false"
    />
    </tr>
  </template>
</template>

<script>
import SlListCell from "@/components/sl-list-cell";
import SlListCellGroup from "@/components/sl-list-cell-group";
import SlViewMobileForm from "@/components/sl-view-mobile-form";
import SlListItemStatus from "@/components/sl-list-item-status";
export default {
  name: "sl-list-items",
  components: {SlListItemStatus, SlViewMobileForm, SlListCell, SlListCellGroup},
  props: [
    "list", "items", "view", "fields",
    "upperGroupCount",  "upperGroupFields", "upperGroupFieldsS", "upperGroupFieldId",
    "groupFieldId", "gradTotalDisplay", "level", "currentCell",
    "upperListDataForDrilldown"
  ],
  emits: ["cellClick", "cellDblclick", "cellBlur", "cellContextmenu", "cellMousedown", "checkboxUpdated", "drilldownListSelected", "groupFieldsToAddonReady"],  // without declaration vue gives warning
  data: ()=>{
    return {
      subGroupFieldId: "",
      subGroupsCals: [],
      expanded: false,
      cPage: 0,
      itemsPerPage: 10,
      groupFieldsToAddon: {
        lowerGroupsFields: [],
        items: [],
        lineData: {
          totalFields: {},
          expanded: null,
          userAction: "",
          level: null,
          groupFieldId: ""
        }
      },
      viewMobileFormShow: false,
      currentItem: {},
      itemFormShow: false,
      phoneWidth: 0,
      itemIdTouchedHold: ""
    }
  },
  created() {
    this.groupFieldsToAddonSend()
    this.expanded = this.level === 0 ? true : false
  },
  computed: {
    currentGroupFieldId(){
      let currentGroupFieldId = ""
      if(this.level === 0) {
        for(let fieldId of this.view._data.fieldsOrder){
          if(this.fields[fieldId].group) {
            currentGroupFieldId = fieldId
            break
          }
        }
      }
      else{
        let flag = false
        for(let fieldId of this.view._data.fieldsOrder){
          if(this.upperGroupFieldId === fieldId) {
            flag = true
            continue
          }
          if(flag && this.fields[fieldId].group) {
            currentGroupFieldId = fieldId
            break
          }
        }
      }
      return currentGroupFieldId
    },
    nextGroupFieldId(){
      let flag = false
      let nextGroupFieldId = ""
      for(let fieldId of this.view._data.fieldsOrder){
        if(fieldId === this.groupFieldId) {
          flag = true
          continue
        }
        if(flag === true && this.fields[fieldId].group === true) {
          nextGroupFieldId = fieldId
          break
        }
      }
      return nextGroupFieldId
    },
    hasCalculatedFields() {
      let hasCalculatedFields = false
      for (let fieldId of this.view._data.fieldsOrder) {
        if (this.fields[fieldId].calc !== "") {
          hasCalculatedFields = true
          break
        }
      }
      return hasCalculatedFields
    },
    subItems() {
      // debugger
      let subItems = []
      let newLine = {id: "", listId: this.list.id, data: {fields: {}, fieldsS: {}}}

      for(let fieldId of this.view._data.fieldsOrder){
        newLine.data.fields[fieldId] = ""
      }
      // filling key fields in drilldown list
      // debugger
      if(this.upperListDataForDrilldown){
        for (let fieldId in this.list.data.fields){
          if(this.list.data.fields[fieldId].type === "sublist" && !this.list.data.fields[fieldId].isFixedSublist){
            if(this.list.data.fields[fieldId].listIdForSublist === this.upperListDataForDrilldown.list.id){
              let fieldIdForSublist = this.list.data.fields[fieldId].fieldIdForSublist
              newLine.data.fields[fieldId] = this.upperListDataForDrilldown.item.data.fields[fieldIdForSublist]
              newLine.data.fieldsS[fieldId] = this.upperListDataForDrilldown.item.id
            }
          }
        }
      }

      if (this.currentGroupFieldId === "" && this.expanded && this.items.length > 0) {
        //subItems = this.items
        let pageQnt = this.items.length / this.itemsPerPage
        if ( pageQnt % 1 > 0 ) pageQnt++
        pageQnt = Math.trunc(pageQnt)
        for(let j=0; j<pageQnt; j++){
          subItems.push(this.items.slice(j*this.itemsPerPage,j*this.itemsPerPage+this.itemsPerPage))
        }
        subItems[subItems.length-1].push(newLine)
        //subItems = subItems.slice(this.currentPage, this.currentPage+100)
            //subItems = subItems.slice(this.cPage*100,this.cPage*100+100)
      } else {
        subItems = []
      }
      if(this.items.length === 0 && this.level===0){
        subItems.push([])
        subItems[0].push(newLine)
      }
      return subItems
    },
    // subItemsPage(){
    //   let subItemsPage = []
    //   if(this.subItems.length>100) subItemsPage = this.subItems.slice(this.currentPage, this.currentPage+100)
    //   return subItemsPage
    // },
    totalFields() {
      let totalFields = null
      if (this.upperGroupFields !== "") {
        totalFields = {}
        totalFields.fields = this.upperGroupFields
        totalFields.fieldsS = this.upperGroupFieldsS
        totalFields.count = this.upperGroupCount
      } else if (this.level === 0 && this.hasCalculatedFields) {
        totalFields = this.groupsFields1[0]
      }
      return totalFields
    },
    groupsFields(){
      if(this.expanded) {
        return this.groupsFields1.slice(1)
      }
      else{
        return []
      }
    },
    groupsFields1() {
      //if (this.currentGroupFieldId === "" && this.topLevel && !this.hasCalculatedFields) return []
      // if (this.currentGroupFieldId === "" ) return []
      //groupsFields
      let groupsFields = []
      for (let i = 0; i < this.items.length; i++) {
        if (i === 0) {
          groupsFields.push({count: 1, fields: {}, fieldsS: {}})
          for (let fieldId of this.view._data.fieldsOrder) {
            if (this.fields[fieldId].calc === "" || this.fields[fieldId].calc === undefined) continue
            groupsFields[0].fields[fieldId] = null
          }
          // groupsFields[0].fields[this.currentGroupFieldId] = "*"
        } else {
          groupsFields[0].count++
        }
        if((this.currentGroupFieldId !== "" && this.currentGroupFieldId !== undefined) || this.level === 0){

          if (this.items[i].data.fields[this.currentGroupFieldId] !== groupsFields[groupsFields.length - 1].fields[this.currentGroupFieldId]) {
            groupsFields.push({count: 1, fields: {}, fieldsS: {}})
            let groupFlag = true
            for (let fieldId of this.view._data.fieldsOrder) {
              if(groupFlag && this.view._data.fields[fieldId].group) {
                groupsFields[groupsFields.length - 1].fields[fieldId] = this.items[i].data.fields[fieldId]
                groupsFields[groupsFields.length - 1].fieldsS[fieldId] = this.items[i].data.fieldsS[fieldId]
              }
              if(this.currentGroupFieldId === fieldId) groupFlag = false
              if (this.fields[fieldId].calc === "" || this.fields[fieldId].calc === undefined) continue
              groupsFields[groupsFields.length - 1].fields[fieldId] = null
            }
          } else if (this.currentGroupFieldId !== "") {
            groupsFields[groupsFields.length - 1].count++
          }


          // if (this.items[i].data.fields[this.currentGroupFieldId] !== groupsFields[groupsFields.length - 1].fields[this.currentGroupFieldId]) {
          //   groupsFields.push({count: 1, fields: {}, fieldsS: {}})
          //   groupsFields[groupsFields.length - 1].fields[this.currentGroupFieldId] = this.items[i].data.fields[this.currentGroupFieldId]
          //   groupsFields[groupsFields.length - 1].fieldsS[this.currentGroupFieldId] = this.items[i].data.fieldsS[this.currentGroupFieldId]
          //   for (let fieldId of this.view._data.fieldsOrder) {
          //     if (this.fields[fieldId].calc === "" || this.fields[fieldId].calc === undefined) continue
          //     groupsFields[groupsFields.length - 1].fields[fieldId] = 0
          //   }
          // } else if (this.currentGroupFieldId !== "") {
          //   groupsFields[groupsFields.length - 1].count++
          // }
        }
        for (let fieldId of this.view._data.fieldsOrder) {
          if (this.fields[fieldId].calc === "" || this.fields[fieldId].calc === undefined) continue
          if(this.items[i].data.fields[fieldId] === undefined) continue

          // debugger
          if ((this.fields[fieldId].calc === "sum" || this.fields[fieldId].calc === "avg") && ( this.currentGroupFieldId !== "" || this.level === 0)) {
            if(this.list.data.fields[fieldId].type === "num" && this.items[i].data.fields[fieldId]){
              if(groupsFields[groupsFields.length - 1].fields[fieldId] === null){
                groupsFields[groupsFields.length - 1].fields[fieldId] = this.items[i].data.fields[fieldId]*1
                groupsFields[0].fields[fieldId] = this.items[i].data.fields[fieldId]*1
              }
              else{
                groupsFields[groupsFields.length - 1].fields[fieldId] = groupsFields[groupsFields.length - 1].fields[fieldId]*1 + this.items[i].data.fields[fieldId]*1
                groupsFields[0].fields[fieldId] = groupsFields[0].fields[fieldId]*1 + this.items[i].data.fields[fieldId]*1
              }
            }
          }
          // min
          else if (this.fields[fieldId].calc === "min" && (this.currentGroupFieldId !== "" || this.level === 0)) {
            if(this.list.data.fields[fieldId].type === "num" && this.items[i].data.fields[fieldId]){
              if(groupsFields[groupsFields.length - 1].fields[fieldId] === null ||
                groupsFields[groupsFields.length - 1].fields[fieldId] > this.items[i].data.fields[fieldId]*1
              ) {
                groupsFields[groupsFields.length - 1].fields[fieldId] = this.items[i].data.fields[fieldId] * 1
              }
              if(groupsFields[0].fields[fieldId] === null || groupsFields[0].fields[fieldId] > this.items[i].data.fields[fieldId]*1) {
                groupsFields[0].fields[fieldId] = this.items[i].data.fields[fieldId] * 1
              }
            }
            if((this.list.data.fields[fieldId].type === "date" || this.list.data.fields[fieldId].type === "time") && this.items[i].data.fields[fieldId]){
              if(groupsFields[groupsFields.length - 1].fields[fieldId] === null ||
                groupsFields[groupsFields.length - 1].fields[fieldId].localeCompare(this.items[i].data.fields[fieldId]) === 1
              ) {
                groupsFields[groupsFields.length - 1].fields[fieldId] = this.items[i].data.fields[fieldId]
              }
              if(groupsFields[0].fields[fieldId] === null || groupsFields[0].fields[fieldId].localeCompare(this.items[i].data.fields[fieldId]) === 1) {
                groupsFields[0].fields[fieldId] = this.items[i].data.fields[fieldId]
              }
            }
          }
          // max
          else if (this.fields[fieldId].calc === "max" && ( this.currentGroupFieldId !== "" || this.level === 0)) {
            if(this.list.data.fields[fieldId].type === "num" && this.items[i].data.fields[fieldId]){
              if(groupsFields[groupsFields.length - 1].fields[fieldId] === null ||
                groupsFields[groupsFields.length - 1].fields[fieldId] < this.items[i].data.fields[fieldId]*1
              ) {
                groupsFields[groupsFields.length - 1].fields[fieldId] = this.items[i].data.fields[fieldId] * 1
              }
              if(groupsFields[0].fields[fieldId] === null || groupsFields[0].fields[fieldId] < this.items[i].data.fields[fieldId]*1) {
                groupsFields[0].fields[fieldId] = this.items[i].data.fields[fieldId] * 1
              }
            }
            if((this.list.data.fields[fieldId].type === "date" || this.list.data.fields[fieldId].type === "time") && this.items[i].data.fields[fieldId]){
              if(groupsFields[groupsFields.length - 1].fields[fieldId] === null ||
                groupsFields[groupsFields.length - 1].fields[fieldId].localeCompare(this.items[i].data.fields[fieldId]) === -1
              ) {
                groupsFields[groupsFields.length - 1].fields[fieldId] = this.items[i].data.fields[fieldId]
              }
              if(groupsFields[0].fields[fieldId] === null || groupsFields[0].fields[fieldId].localeCompare(this.items[i].data.fields[fieldId]) === -1) {
                groupsFields[0].fields[fieldId] = this.items[i].data.fields[fieldId]
              }
            }
          }
        }
      }
      for (let k = 1; k < groupsFields.length; k++) {
        groupsFields[k].items = this.items.filter(el => el.data.fields[this.currentGroupFieldId] === groupsFields[k].fields[this.currentGroupFieldId])
      }
      for (let n = 0; n < groupsFields.length; n++) {
        for (let fieldId of this.view._data.fieldsOrder) {
          if (this.fields[fieldId].calc === "" || this.fields[fieldId].calc === undefined) continue
          if (this.fields[fieldId].calc === "avg") {
            groupsFields[n].fields[fieldId] = groupsFields[n].fields[fieldId] / groupsFields[n].count
          }
          if(groupsFields[n].fields[fieldId] % 1 !== 0 && this.list.data.fields[fieldId].type === "num"){
            groupsFields[n].fields[fieldId] = parseFloat(groupsFields[n].fields[fieldId]).toFixed(2)
          }

        }
      }
      return groupsFields
    },

  },
  watch: {
    totalFields(){
      // debugger
        this.groupFieldsToAddonSend()
    },
    expanded(){
      this.groupFieldsToAddonSend()
    },
    "groupFieldsToAddon.lineData.userAction": function(newVal){
      if(newVal === "expand") this.expanded = true
      if(newVal === "collapse") this.expanded = false

    }
  },
  methods: {
    goTo(x){
      if(x==="left" && this.cPage>0) this.cPage--
      if(x==="first") this.cPage = 0
      if(x==="right" && this.cPage<this.subItems.length-1) this.cPage++
      if(x==="last") this.cPage = this.subItems.length-1
    },
    // goToSwipe(evt){
    //   if(evt.direction === "left") this.goTo("right")
    //   if(evt.direction === "right") this.goTo("left")
    // },
    cellEvent(event, currentCellEvent, item, fieldId){
      let pl = {
        e: event,
        upperGroupField: this.upperGroupFields[this.upperGroupFieldId],
        item: item,
        fieldId: fieldId
      }
      this.$emit(currentCellEvent, pl)
    },
    // cellDbClick(event, item, field){
    //   this.$emit('currentCelldblClick', { e: event, upperGroupField: this.upperGroupFields[this.upperGroupFieldId], item: item, field: field })
    // },
    // cellBlur(event, item, field){
    //   this.$emit('currentCellblur', { e: event, upperGroupField: this.upperGroupFields[this.upperGroupFieldId], item: item, field: field })
    // },
    // cellContextmenu(event, item, field){
    //   this.$emit('currentCellContextmenu', { e: event, upperGroupField: this.upperGroupFields[this.upperGroupFieldId], item: item, field: field })
    //   // this.$emit('contextmenu')
    // },
    // cellMousedown(event, item, field){
    //   debugger
    //   this.$emit('currentCellMousedown', { e: event, upperGroupField: this.upperGroupFields[this.upperGroupFieldId], item: item, field: field })
    // },
    groupFieldsToAddonSend(lowerGroupFields, index){
      // debugger
      this.groupFieldsToAddon.lineData.totalFields = this.totalFields
      this.groupFieldsToAddon.lineData.expanded = this.expanded
      this.groupFieldsToAddon.lineData.level = this.level
      this.groupFieldsToAddon.lineData.groupFieldId = this.upperGroupFieldId

      if(lowerGroupFields && index !== undefined){
        this.groupFieldsToAddon.lowerGroupsFields[index] = lowerGroupFields
      }
      if(this.expanded && this.groupsFields.length === 0){
        this.groupFieldsToAddon.items = this.items
      }
      else{
        this.groupFieldsToAddon.items = []
      }
      if(!this.expanded){
        this.groupFieldsToAddon.lowerGroupsFields = []
      }
      if(this.expanded && this.groupFieldsToAddon.lowerGroupsFields.length === this.groupsFields.length){
        this.$emit("groupFieldsToAddonReady", this.groupFieldsToAddon)
      }
      if(!this.expanded){
        this.$emit("groupFieldsToAddonReady", this.groupFieldsToAddon)
      }
    },
    viewMobileFormOpen(item){
      this.currentItem = item
      this.viewMobileFormShow = true
    },
    itemFormOpen(evt){

      console.log(evt.evt.currentTarget.closest(".sl-item"))
      let itemEl = evt.evt.currentTarget.closest(".sl-item")
      if(itemEl.id === "") return

      this.currentItem = this.items.find(el => el.id===itemEl.id)
      this.phoneWidth = window.innerWidth
      this.itemFormShow = true
    },
    itemFormClose(){
      this.itemFormShow = false
    },
    itemButtonsShow(evt){
      console.log(evt.evt.srcElement.closest(".sl-item"))
      let itemEl = evt.evt.srcElement.closest(".sl-item")
      this.itemIdTouchedHold = itemEl.id
    }
  }
}
</script>

<style scoped>

</style>