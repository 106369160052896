<template>
  <div>
    <div class="space"
         v-for="space in spaces" :key="space.id"
         style="border-radius: 5px; background-color: white; margin: 5px; padding: 5px; box-shadow: 0 0 10px lightgray"
    >
      <div class="space-header row items-center">
        <q-img class="space-avatar col-3"
          style="width: 60px; height: 60px; border-radius: 60px;"
          :src="space.data.avatarImg"
          v-if="space.data.avatarImg"
               @click="spaceToggle(space.id)"
        />
        <div class="space-name col-5 text-left"
             :style="{fontWeight: isExpanded[space.id] ? 'bold': ''}"
             @click="spaceToggle(space.id)">
          {{space.data.name}}
        </div>
        <q-space/>
        <div class="col-2" style="margin: 10px">
          <q-chip dense color="grey" text-color="white">
            345
            <q-badge color="red" rounded floating />
          </q-chip>
        </div>
      </div>
      <q-slide-transition duration="500">
        <div class="lists" v-show="this.isExpanded[space.id]">
          <br/>
          <sl-drawer-lists :spaceId="space.id" :space="space" />
        </div>
      </q-slide-transition>
    </div>
  </div>

</template>

<script>
import {useObservable} from "@vueuse/rxjs/index";
import {liveQuery} from "dexie";
import {db} from "@/db";
import SlDrawerLists from "@/components/sl-drawer-lists";
// import SlDrawerLists from "@/components/sl-drawer-lists"

export default {
  name: "sl-spaces2",
  components: {SlDrawerLists},
  // components: [SlDrawerLists],
  // inject: ["currentUserPhoneNumber"],
  data: () => {
    return {
      spaces: [],
      isExpanded: {}
    }
  },
  created() {
    if(this.$store.state.currentUser.userId && this.spaces.length === 0){
     this.spacesObserverSet()
    }
  },
  watch: {
    currentUserId(){
      this.spacesObserverSet()
    }
  },
  methods: {
    spacesObserverSet(){
      this.spaces = useObservable(
        liveQuery(() => {
          return db.spaces.where("$data.userIds").equals(this.$store.state.currentUser.userId).or("status").equals("unpublished").toArray()
        })
      )
    },
    spaceToggle(spaceId){
      if(this.isExpanded[spaceId]){
        this.isExpanded[spaceId] = false
      }
      else{
        this.isExpanded[spaceId] = true
      }
    }
  }
}
</script>

<style scoped>
.space-name {
  margin-left: 10px;
  line-height: 1.2
}

</style>