<template>
  <q-card>
    <q-card-section class="column items-center">
      <q-avatar size="250px">
        <img :src="newAvatarImg">
      </q-avatar>
      <q-dialog v-model="cropperShow">
        <q-card>
          <q-card-section>
            <cropper
                ref="avatarCropper"
                class="cropper"
                :src="avatarFileSrc"
                :stencil-props="{ aspectRatio: 1, handlers: {}, movable: false, resizable: false }"
                :resize-image="{ adjustStencil: false }"
                :stencil-size="{ width: 200, height: 200 }"
                :canvas="{ height: 90, width: 90 }"
                image-restriction="stencil"
            />
          </q-card-section>
          <q-card-actions class="justify-center">
            <q-btn flat label="Cancel" @click="cropperShow=false"/>
            <q-btn flat label="Ok" @click="avatarCrop()"/>
          </q-card-actions>
        </q-card>
      </q-dialog>
      <q-file v-model="avatarFile"  @update:model-value="file=>avatarFileSelected(file)" accept="image/*" label="Photo" dense clearable color="green"/>
    </q-card-section>
    <q-card-section>
      <q-input v-model="newName" label="Name" dense color="green"/>
    </q-card-section>
    <q-card-actions class="justify-center">
      <q-btn flat @click="$emit('finished')">Cancel</q-btn>
      <q-btn flat @click="save()">Save</q-btn>
    </q-card-actions>
  </q-card>
  <sl-msgs :msgs="msgs" v-if="msgs.length>0" @finished="msgs=[]"/>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import SlMsgs from "@/components/sl-msgs"
export default {
  name: "sl-user-edit-form",
  emits: ["finished"],
  props: ["name", "avatarImg"],
  components: {
    SlMsgs,
    Cropper,
  },
  data(){
    return {
      msgs: [],
      cropperShow: false,
      avatarFile: null,
      avatarFileSrc: null,
      newAvatarImg: null,
      newName: ""
    }
  },
  created() {
    this.newName = this.name
    this.newAvatarImg = this.avatarImg
  },
  methods: {
    avatarCrop(){
      let that = this
      const { canvas } = this.$refs.avatarCropper.getResult()
      canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = function() {
          that.newAvatarImg = reader.result
          that.cropperShow = false
        }
      })
    },
    async save() {
      if(this.newName === ""){
        this.msgs.push({
          type: "e",
          text: "Enter name"
        })
      }
      if(!window.navigator.onLine){
        this.msgs.push({
          type: "e",
          text: "Go online to edit your name and avatar"
        })
      }
      if(this.msgs.length > 0) return

      let that = this
      let userNameAvatar = {
        avatarImg: this.newAvatarImg,
        name: this.newName
      }
      this.$store.dispatch("userNameAvatarUpload", userNameAvatar).then(() => {
        that.$emit("finished")
      })
    },
    avatarFileSelected(file){
      if(file){
        this.avatarFileSrc = URL.createObjectURL(this.avatarFile)
        this.cropperShow = true
      }
      else{
        this.avatarFileSrc = null
        this.newAvatarImg = this.avatarImg
      }
    },
  },
}
</script>

<style scoped>
.cropper {
  height: 250px;
  width: 250px;
  background: #DDD;
}
</style>