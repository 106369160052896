<template>
  <q-card>
    <q-card-section class="text-center font-weight-bold">
      Layout props
    </q-card-section>
    <q-tabs
      v-model="layoutTabs"
      inline-label
      class="bg-green text-white shadow-2"
    >
      <q-tab name="blocks" label="Blocks" no-caps/>
      <q-tab name="fields" label="Fields" no-caps/>
    </q-tabs>

    <q-separator />

<!--    <q-card-actions class="justify-center">-->
<!--      <q-btn round icon="grid_view" @click="custMode='blocks'"/>-->
<!--      <q-btn round icon="margin" @click="custMode='fields'"/>-->
<!--    </q-card-actions>-->
    <q-card-section>
      <div class="layout-sketch">
        <div class="layout-row row items-center" v-for="(row, i) in layoutSketch.rows" :key="i">
          <div class="row justify-center items-center col-1" v-if="layoutTabs==='blocks'">
            <q-btn round dense size="xs" icon="delete" style="margin: 5px"
                   :color="i===0 ? 'grey-lighten-4' : 'grey'"
                   @click="rowDelete(i)"
            />
          </div>
          <div :class="['layout-blocks row justify-left items-stratch', layoutTabs==='blocks'?'col-10':'col-12']">
            <template v-if="layoutTabs==='blocks'">
              <div :class="['layout-block column justify-around bg-grey-lighten-2', 'col-'+block.col]"
                   style="min-height: 70px; border: 2px solid white"
                   v-for="(block, j) in row.blocks" :key="j"
              >
                <div class="row justify-between">
                  <q-icon round size="xs" dense name="arrow_circle_right_outlined"
                          :color="j===0||block.col===2 ? 'grey-lighten-4' : 'grey'"
                          @click="blockResize(i, j, 'right')"
                  />
                  <div/>
                  <q-icon round size="xs" dense name="arrow_circle_left_outlined"
                          :color="j===row.blocks.length-1||block.col===2 ? 'grey-lighten-4' : 'grey'"
                          @click="blockResize(i, j, 'left')"
                  />
                </div>
                <div class="row justify-center">
                  <q-icon round size="xs" dense name="delete"
                          :color="j===0 ? 'grey-lighten-4' : 'grey'"
                          @click="blockDelete(i, j)"
                  />
                </div>
              </div>
            </template>
            <template v-if="layoutTabs==='fields'">
              <div :class="'column ' + 'col-'+block.col" v-for="(block, j) in row.blocks" :key="j">
                <div :class="['layout-block row bg-grey-lighten-2', blockAuxClassesGet(block)]">
                  <div class="layout-field row items-center justify-center bg-grey"
                       :style="{width: field.width+field.widthMeasure}"
                       v-for="(field, f) in block.fields" :key="f"
                  >
                  <span style="text-decoration: underline" @click="fieldSettingsFormOpen(i, j, f)">
                    {{ field.id }}
                  </span>
                  </div>
                </div>
                <div class="row justify-center items-center" style="min-height: 30px">
                  <q-icon class="q-ml-xs" size="xs" name="add_circle" color="grey" @click="fieldAddFormOpen(i, j)"/>
                  <q-icon class="q-ml-xs" size="xs" name="settings" color="grey" @click="blockSettingsFormOpen(i, j)"/>
                </div>
              </div>
            </template>
          </div>
          <div class="row justify-center items-center col-1" v-if="layoutTabs==='blocks'">
            <q-btn round dense size="xs" icon="add" style="margin: 5px" @click="blockAdd(i)"/>
          </div>
        </div>
        <div class="layout-row-footer row justify-left items-top" v-if="layoutTabs==='blocks'">
          <div class="row justify-center items-center col-1">
          </div>
          <div class="row justify-center items-center col-10 bg-white">
            <q-btn round dense size="xs" icon="add" style="margin: 10px" @click="rowAdd()"/>
          </div>
          <div class="row justify-center items-center col-1 bg-white">
          </div>
        </div>
      </div>
    </q-card-section>
    <q-dialog v-model="fieldAddFormShow">
      <q-card>
        <q-card-section>
          <q-chip v-for="field in unsignedFieldIds" :key="field.id"
                  :label="field.id+' - '+field.name"
                  color="green" text-color="white"
                  clickable
                  @click="fieldAdd(field.id)"
          />
        </q-card-section>
        <q-card-actions class="justify-center">
          <q-btn label="Ok" @click="fieldAddFormShow=false"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="fieldSettingsFormShow" maximized>
      <q-card>
        <q-card-section class="text-center font-weight-bold">
          Field props
        </q-card-section>
        <q-tabs
          v-model="fieldTabs"
          inline-label
          class="bg-green text-white shadow-2"
        >
          <q-tab name="width" label="Width" no-caps/>
          <q-tab name="name" label="Name" no-caps/>
          <q-tab name="bg" label="Background" no-caps/>
        </q-tabs>
        <q-separator />

        <q-tab-panels v-model="fieldTabs" animated>
          <q-tab-panel class="justify-center text-center align-center" name="width" style="height: 300px; min-width: 300px">
            <br/>
            <q-chip v-for="measure in ['fit', 'px', '%']" :key="measure"
                    :label="measure"
                    clickable
                    :color="currentFieldGet().widthMeasure===measure?'green':''"
                    :text-color="currentFieldGet().widthMeasure===measure?'white':''"
                    @click="currentFieldGet().widthMeasure=measure"
            />
            <br/><br/>
            <span class="text-weight-bold text-green" v-if="currentFieldGet().widthMeasure!=='fit'">
              {{currentFieldGet().width + currentFieldGet().widthMeasure}}
            </span>
            <br/><br/>
            <q-slider v-model="currentFieldGet().width"
                      :min="currentFieldGet().widthMeasure==='%' ? 5 : 25"
                      :max="currentFieldGet().widthMeasure==='%' ? 100 : 500"
                      :step="currentFieldGet().widthMeasure==='%' ? 5 : 25"
                      :label-value="currentFieldGet().width + currentFieldGet().widthMeasure"
                      v-if="currentFieldGet().widthMeasure!=='fit'"
            />
          </q-tab-panel>
          <q-tab-panel class="justify-center text-center align-center" name="name" style="height: 300px; min-width: 300px">
            <div>
              <q-checkbox class="q-ma-sm" v-model="currentFieldGet().nameShow"
                          :true-value="'X'" :false-value="''" label="Show field name"
              />
              <div class="row justify-center">
                <div v-for="color in colors" :key="color"
                     :style="{borderRadius: '8px', border: currentFieldGet().nameColor===color ? '3px solid green' : '3px solid white'}"
                     @click="currentFieldGet().nameColor=color"
                >
                  <div style="width:30px; height: 30px; margin: 2px; border-radius: 5px" :class="'bg-'+color+'-9'"/>
                </div>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel class="justify-center text-center align-center" name="bg" style="height: 300px; min-width: 300px">
            <div>
              <q-checkbox class="q-ma-sm" v-model="currentFieldGet().bgShow"
                          :true-value="'X'" :false-value="''" label="Show background"
              />
              <div class="row justify-center">
                <div v-for="color in colors" :key="color"
                     :style="{borderRadius: '8px', border: currentFieldGet().bgColor===color ? '3px solid green' : '3px solid white'}"
                     @click="currentFieldGet().bgColor=color"
                >
                  <div style="width:30px; height: 30px; margin: 2px; border-radius: 5px" :class="'bg-'+color+'-1'"/>
                </div>
              </div>

              <div class="q-mt-md">
                Background radius
                <span class="text-weight-bold text-green">{{currentFieldGet().bgRadius}}px</span>
              </div>
              <br/>
              <q-slider v-model="currentFieldGet().bgRadius" :min="0" :max="30" :step="1"
                        :label-value="currentFieldGet().bgRadius + 'px'"
              />
            </div>
          </q-tab-panel>
        </q-tab-panels>
        <q-card-actions class="justify-center">
          <q-btn label="Remove" @click="fieldRemove()"/>
          <q-btn label="Ok" @click="fieldSettingsFormShow=false"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="blockSettingsFormShow" maximized>
      <q-card>
        <q-card-section class="text-center font-weight-bold">
          Block props
        </q-card-section>
        <q-tabs
          v-model="blockTabs"
          inline-label
          class="bg-green text-white shadow-2"
        >
          <q-tab name="align" label="Align" no-caps/>
          <q-tab name="title" label="Title" no-caps/>
          <q-tab name="bg" label="Background" no-caps/>
        </q-tabs>
        <q-separator />

        <q-tab-panels v-model="blockTabs" animated style="height: 400px">
          <q-tab-panel class="justify-center text-center align-center" name="align" style="min-width: 300px">
            <div class="text-center">
              <br/>
              <div>justify</div>
              <q-chip class="" clickable
                      v-for="jClass in justifyClasses" :key="jClass"
                      :label="jClass"
                      :color="currentBlockGet().fieldsClassJustify==='justify-'+jClass ? 'green' : 'lightgrey'"
                      :text-color="currentBlockGet().fieldsClassJustify==='justify-'+jClass ? 'white' : 'grey'"
                      @click="currentBlockGet().fieldsClassJustify='justify-'+jClass"
              />
              <br/><br/>
              <div>items</div>
              <q-chip class="" clickable
                      v-for="iClass in itemsClasses" :key="iClass"
                      :label="iClass"
                      :color="currentBlockGet().fieldsClassItems==='items-'+iClass ? 'green' : 'lightgrey'"
                      :text-color="currentBlockGet().fieldsClassItems==='items-'+iClass ? 'white' : 'grey'"
                      @click="currentBlockGet().fieldsClassItems='items-'+iClass"
              />
              <br/><br/>
              <div>content</div>
              <q-chip class="" clickable
                      v-for="cClass in contentClasses" :key="cClass"
                      :label="cClass"
                      :color="currentBlockGet().fieldsClassContent==='content-'+cClass ? 'green' : 'lightgrey'"
                      :text-color="currentBlockGet().fieldsClassContent==='content-'+cClass ? 'white' : 'grey'"
                      @click="currentBlockGet().fieldsClassContent='content-'+cClass"
              />
            </div>
          </q-tab-panel>
          <q-tab-panel class="justify-center text-center align-center" name="title" style="min-width: 300px">
            <div>
              <q-checkbox class="q-ma-sm" v-model="currentBlockGet().titleShow"
                          :true-value="'X'" :false-value="''" label="Show block title"
              />
              <q-input v-model="currentBlockGet().title" label="Title" dense/>
              <br/><br/>
              <div class="row justify-center">
                <div v-for="color in colors" :key="color"
                     :style="{borderRadius: '8px', border: currentBlockGet().titleColor===color ? '3px solid green' : '3px solid white'}"
                     @click="currentBlockGet().titleColor=color"
                >
                  <div style="width:30px; height: 30px; margin: 2px; border-radius: 5px" :class="'bg-'+color+'-9'"/>
                </div>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel class="justify-center text-center align-center" name="bg" style="min-width: 300px">
            <div>
              <q-checkbox class="q-ma-sm" v-model="currentBlockGet().bgShow"
                          :true-value="'X'" :false-value="''" label="Show background"
              />
              <div class="row justify-center">
                <div v-for="color in colors" :key="color"
                     :style="{borderRadius: '8px', border: currentBlockGet().bgColor===color ? '3px solid green' : '3px solid white'}"
                     @click="currentBlockGet().bgColor=color"
                >
                  <div style="width:30px; height: 30px; margin: 2px; border-radius: 5px" :class="'bg-'+color+'-1'"/>
                </div>
              </div>

              <div class="q-mt-md">
                Background radius
                <span class="text-weight-bold text-green">{{currentBlockGet().bgRadius}}px</span>
              </div>
              <br/>
              <q-slider v-model="currentBlockGet().bgRadius" :min="0" :max="30" :step="1"
                        :label-value="currentBlockGet().bgRadius + 'px'"
              />
            </div>
          </q-tab-panel>
        </q-tab-panels>
        <q-card-actions class="justify-center">
          <q-btn label="Ok" @click="blockSettingsFormShow=false"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-card>
      <q-card-section class="text-center">
        Live preview
      </q-card-section>
      <div class="" style="border-top: 1px solid grey; border-bottom: 1px solid grey;">
        <div class="row items-center" v-for="(row, i) in layoutSketch.rows" :key="i">
          <div :class="['row', blockAuxClassesGet(block), 'col-'+block.col]"
               v-for="(block, j) in row.blocks" :key="j"
          >
            <div class="row items-center justify-center"
                 :style="{width: field.width+field.widthMeasure}"
                 v-for="(field, f) in block.fields" :key="f"
            >
              <sl-list-cell
                            :fieldId="field.id"
                            :list="list"
                            :item="item"
                            :view="list.data.views[viewId]"
                            :itemNumber="item.data.fields.z1"
                            :phoneLayoutField="field"
                            :currentCell="''"
                            :upperGroupFields="''"
                            :upperGroupFieldId="''"
              />
            </div>
          </div>
        </div>
      </div>
    </q-card>

    <q-card-actions class="justify-center">
      <q-btn label="Ok" @click="phoneLayoutOk()"/>
    </q-card-actions>
  </q-card>

</template>

<script>
import SlListCell from "@/components/sl-list-cell";
export default {
  name: "sl-view-mobile-form",
  components: {SlListCell},
  props: ["list", "viewId", "item"],
  data: () => {
    return {
      layoutTabs: "blocks",
      fieldTabs: "width",
      blockTabs: "align",
      // custMode: 'blocks',
      layoutSketch: {},
      currentEl: {
        rowIndex: null,
        blockIndex: null,
        fieldIndex: null
      },
      newBlockObj: {
        col: '2',
        fieldsClassJustify: 'justify-start',
        fieldsClassItems: 'items-start',
        fieldsClassContent: 'content-start',
        title: "",
        titleShow: "",
        titleColor: "",
        bgShow: "",
        bgColor: "",
        bgRadius: "",
        fields: []
      },
      newFieldObj: {
        id: "",
        nameShow: "",
        nameColor: "",
        bgShow: "",
        bgColor: "",
        bgRadius: "",
        width: "",
        widthMeasure: ""
      },
      fieldAddFormShow: false,
      blockSettingsFormShow: false,
      fieldSettingsFormShow: false,
      justifyClasses: ['start', 'end', 'center', 'between', 'around', 'evenly'],
      itemsClasses: ['start', 'end', 'center', 'stretch', 'around', 'evenly'],
      contentClasses: ['start', 'end', 'center', 'stretch', 'between', 'around'],
      colors: ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'grey', 'blue-grey'],
      bgColorNum: "1",
      titleColorNum: "10"
    }
  },
  created() {
    let view_data = this.list.data.views[this.viewId]._data
    if (!view_data.phoneLayout) {
      let newBlockObj = JSON.parse(JSON.stringify(this.newBlockObj))
      newBlockObj.col = 12
      view_data.phoneLayout = {rows: [{blocks: [newBlockObj]}]}
    }
    this.layoutSketch = view_data.phoneLayout
  },
  computed: {
    unsignedFieldIds() {
      let unsignedFieldIds = JSON.parse(JSON.stringify(this.list.data.fields))
      this.layoutSketch.rows.forEach(row => {
        row.blocks.forEach(block => {
          block.fields.forEach(field => {
            if (unsignedFieldIds[field.id]) delete unsignedFieldIds[field.id]
          })
        })
      })
      return unsignedFieldIds
    }
  },
  methods: {
    blockAuxClassesGet(block) {
      return block.fieldsClassJustify + " " + block.fieldsClassItems + " " + block.fieldsClassContent
    },
    currentRowGet() {
      return this.layoutSketch.rows[this.currentEl.rowIndex]
    },
    currentBlockGet() {
      return this.currentRowGet().blocks[this.currentEl.blockIndex]
    },
    currentFieldGet() {
      return this.currentBlockGet().fields[this.currentEl.fieldIndex]
    },
    rowAdd() {
      let newBlockObj = JSON.parse(JSON.stringify(this.newBlockObj))
      newBlockObj.col = 12
      this.layoutSketch.rows.push({blocks: [newBlockObj]})
    },
    rowDelete(rowIndex) {
      this.layoutSketch.rows.splice(rowIndex, 1)
    },
    blockAdd(rowIndex) {
      let blocks = this.layoutSketch.rows[rowIndex].blocks
      let lastBlockCol = parseInt(blocks[blocks.length - 1].col)
      if (lastBlockCol > 2) {
        blocks[blocks.length - 1].col = lastBlockCol - 2
        blocks.push(JSON.parse(JSON.stringify(this.newBlockObj)))
      }


      // let totalCol = 0
      // this.layoutSketch.rows[rowIndex].blocks.forEach(block => {
      //   totalCol = totalCol + parseInt(block.col)
      // })
      // if(totalCol < 12){
      //   this.layoutSketch.rows[rowIndex].blocks.push({col: 12 - totalCol})
      // }
    },
    blockDelete(rowIndex, blockIndex) {
      if (blockIndex === 0) return
      let blocks = this.layoutSketch.rows[rowIndex].blocks
      let currentBlockCol = parseInt(blocks[blockIndex].col)
      let previousBlockCol = parseInt(blocks[blockIndex - 1].col)
      blocks[blockIndex - 1].col = currentBlockCol + previousBlockCol
      blocks.splice(blockIndex, 1)

    },
    blockResize(rowIndex, blockIndex, dir) {
      let blocks = this.layoutSketch.rows[rowIndex].blocks
      let col = parseInt(blocks[blockIndex].col)
      if (dir === "left") {
        if (col === 2) return
        if (blockIndex === blocks.length - 1) return
        blocks[blockIndex].col = parseInt(blocks[blockIndex].col) - 2
        blocks[blockIndex + 1].col = parseInt(blocks[blockIndex + 1].col) + 2
      }
      if (dir === "right") {
        if (col === 2) return
        if (blockIndex === 0) return
        blocks[blockIndex].col = parseInt(blocks[blockIndex].col) - 2
        blocks[blockIndex - 1].col = parseInt(blocks[blockIndex - 1].col) + 2
      }
    },
    fieldAddFormOpen(rowIndex, blockIndex) {
      this.currentEl.rowIndex = rowIndex
      this.currentEl.blockIndex = blockIndex
      this.fieldAddFormShow = true
    },
    fieldSettingsFormOpen(rowIndex, blockIndex, fieldIndex) {
      this.currentEl.rowIndex = rowIndex
      this.currentEl.blockIndex = blockIndex
      this.currentEl.fieldIndex = fieldIndex
      this.fieldSettingsFormShow = true
    },
    blockSettingsFormOpen(rowIndex, blockIndex) {
      this.currentEl.rowIndex = rowIndex
      this.currentEl.blockIndex = blockIndex
      this.blockSettingsFormShow = true
    },
    fieldAdd(fieldId) {
      let currentBlock = this.layoutSketch.rows[this.currentEl.rowIndex].blocks[this.currentEl.blockIndex]
      let newFieldObj = JSON.parse(JSON.stringify(this.newFieldObj))
      newFieldObj.id = fieldId
      currentBlock.fields.push(newFieldObj)
    },
    // fieldDelete(fieldIndex) {
    //   let currentBlock = this.layoutSketch.rows[this.currentEl.rowIndex].blocks[this.currentEl.blockIndex]
    //   currentBlock.splice(fieldIndex, 1)
    // },
    phoneLayoutOk(){
      let lView = this.list.data.views[this.viewId]
      lView._isChangedByUser = true
      this.$emit("finished")
    },
    tst(m){
      debugger
      let field = this.currentFieldGet()
      field.widthMeasure=m
    },
    fieldRemove(){
      this.currentBlockGet().fields.splice(this.currentEl.fieldIndex, 1)
      this.fieldSettingsFormShow=false
    }
  }
}
</script>

<style scoped>
.view-block {
  border: 1px solid grey;
  background-color: lightgrey;
  min-height: 45px;
}

.layout-block {
  min-height: 70px;
  border: 2px solid white
}

.layout-field {
  min-height: 30px;
  min-width: 30px;
  border: 2px solid lightgrey
}
</style>