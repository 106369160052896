// eslint-disable-next-line
// import * as formulajs from "@formulajs/formulajs";
import {db} from "./db"

export const numFormat = (val, listField) =>{
    val = val * 1
    val = val.toLocaleString("en-US", {
        maximumFractionDigits: listField.maximumFractionDigits,
        minimumFractionDigits: listField.minimumFractionDigits
    })
    return val
}

export const paramsInit = async () => {
    let user = await db.params.get("user")
    if(!user) user = {
        id: "user",
        currentUserId: ""
    }
    // if(!user.phoneNumber) user.phoneNumber = ""
    // if(!user.email) user.email = ""
    // if(!user.displayName) user.displayName = ""
    // if(!user.photoURL) user.photoURL = ""
    // user.isAuthorized = false
    // user.prefs = {
    //     defaultUser: [],
    //
    // }
    await db.params.put(user, "user")
}

export const stylePropsListGet = () => {
    let props = {
        border: {},
        borderWidth: {},
        borderColor: { colorPickerRequired: true },
        borderRadius: {},
        backgroundColor: { colorPickerRequired: true },
        color: { colorPickerRequired: true },
        textAlign: {values: ["left", "right", "center"]},
        verticalAlign: {values: ["top", "middle", "bottom"]},
        padding: {},
        margin: {},
        fontWeight: {values: ["bold", "normal"]},
        fontSize: {},
        display: { values: ["block", "inline", "inline-block"]},
        boxShadow: {},
        width: {},
        height: {}
    }
    return props
}
export const formulaCalculate = (item, list, fieldId, isMacros) => {
    // eslint-disable-next-line
    let row = item.data.fields
    // debugger
    let formulaStringTemp = isMacros ? list.data.fields[fieldId].macrosFormula : list.data.fields[fieldId].formula
    let formulaString = formulaStringTemp + ""
    let calcVal
    try {
        calcVal = eval(formulaString)
    } catch (e) {
        console.log(e)
        calcVal = "formula error"
    }

    return calcVal
}

// eslint-disable-next-line
const func = (pl) => {
    return pl.n1 * pl.n2
}

// eslint-disable-next-line
const func2 = (pl) => {
    return pl.n1
}

// eslint-disable-next-line
const substr = (pl) => {
    let res
    res = pl.string.substr(pl.start, pl.length)
    return res

}


export const consoleLog = (pl) => {

    // read user filter for tracer (filter is set on .../tracerparams)
    let params = JSON.parse(localStorage.getItem("tracerParams"))
    if ( !params ) return
    if ( !params.isTraceOn ) return

    // apply filter for tracer
    // debugger
    if(pl.filter){
        if( params.filter.spaceIds.length > 0 && params.filter.spaceIds.findIndex(el=>el===pl?.filter?.spaceId) === -1) return
        if( params.filter.listIds.length > 0 && params.filter.listIds.findIndex(el=>el===pl?.filter?.listId) === -1) return
        if( params.filter.itemIds.length > 0 && params.filter.itemIds.findIndex(el=>el===pl?.filter?.itemId) === -1) return
    }

    // output tracer
    let paramsObj = {}
    if(pl.params){
        pl.params.forEach(param => {
            paramsObj[param[0]] = param[1]
        })
    }
    console.log({"!sl-trace": pl.text, params: paramsObj, filter: pl.filter})

    // output callStack
    if ( params.isConsoleTraceOn ) {
        console.trace()
    }

}

export const slDateFormat = (TS) => {
    let date = new Date(TS)
    let y = date.getFullYear()
    let m = date.getMonth()+1
    let d = date.getDate()
    if(m < 10) m = "0"+m
    return y + '/' + m + '/' + d
}

export const slTimeFormat = (TS) => {
    let date = new Date(TS)
    let h = date.getHours()
    let m = date.getMinutes()
    if(h < 10) h = "0"+h
    if(m < 10) m = "0"+m
    return  h + ':' + m
}



// export const formulaCalculate2 = (item, list, fieldId) => {
//     // eslint-disable-next-line
//     let formulajsX = formulajs
//     // eslint-disable-next-line
//     let fields = item.data.fields
//     //debugger
//     let formulaStringTemp = "CONCATENATE(" + list.data.fields[fieldId].formula + ", '')"  // this example doesn't work: fields.f1*100
//     let formulaString = 'formulajsX.' + formulaStringTemp
//     let calcVal
//     try {
//         calcVal = eval(formulaString)
//     } catch (e) {
//         console.log(e)
//         calcVal = "formula error"
//     }
//
//     return calcVal
// }