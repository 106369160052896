<template>
  <q-btn class="q-ml-sm" icon="double_arrow" round size="xs" @click="isActive=!isActive">
  <q-menu
    v-model="isActive"
    v-if="isActive"
    transition-show="jump-down"
    transition-hide="jump-up"
  >
    <q-list dense style="min-width: 100px">
      <q-item clickable v-close-popup v-for="drilldown in drilldowns" :key="drilldown.listId"
              @click="drilldownOpen(drilldown.listId, drilldown.viewId, drilldown.drilldownFilterFieldId, drilldown.drilldownFilterValue)"
      >
        <q-item-section side>
          <q-icon name="notes" size="xs"/>
        </q-item-section>
        <q-item-section>
          {{drilldown.listName}} > {{drilldown.viewName}}
        </q-item-section>
      </q-item>
    </q-list>
  </q-menu>
  </q-btn>
</template>

<script>
import { db } from '@/db'
export default {
  name: "sl-drilldown-menu",
  props: ["list5Id", "itemId"],
  data: () => {
    return {
      list5: {},
      drilldowns: [],
      isActive: false
    }
  },
  created() {
    this.list5 = this.$store.state.lists[this.list5Id]
  },
  watch: {
    isActive: async function (newVal){
      if(!newVal) return
      this.drilldowns = []
      let currentListId = this.list5.list.id
      let lists = await db.table("lists").where("spaceId").equals(this.$store.state.lists[this.list5Id].space.id).toArray()
      for(let list of lists){
        for(let fieldId in list.data.fields){
          if(currentListId === list.data.fields[fieldId].listIdForSublist && !list.data.fields[fieldId].sublistBasedOnFieldId){
            for(let viewId in list.data.views){
              if(list.data.views[viewId].data.phoneLayout) continue
              this.drilldowns.push({
                listId: list.id,
                listName: list.data.name,
                viewId: viewId,
                viewName: list.data.views[viewId].data.name,
                drilldownFilterFieldId: fieldId,
                drilldownFilterValue: this.itemId
              })
            }
          }
        }
      }
    }
  },
  methods: {
    async drilldownOpen(listId, viewId, drilldownFilterFieldId, drilldownFilterValue){
      let list = await db.table("lists").get(listId)
      let currentKey = this.spaceId+"_"+listId+"_"+viewId
      this.$store.commit("openedListCurrentKeySet", currentKey)

      let indx = this.$store.state.openedLists.lists.findIndex(el => {
        return el.spaceId === this.list5.space.id &&
          el.listId === listId &&
          el.viewId === viewId &&
          el.upperLisdtId === this.list5.list.id
      })
      if(indx >= 0){
        this.$store.commit("openedListRemove", indx)
        let lists5 = this.$store.state.lists
        for(let list5Id in lists5){
          if(lists5[list5Id].space.id === this.list5.space.id &&
            lists5[list5Id].listId === listId &&
            lists5[list5Id].viewId === viewId &&
            lists5[list5Id].list.data.fields[lists5[list5Id].drilldownFilter.fieldId].listIdForSublist === this.list5.list.id
          ){
            this.$store.commit("listsList5Clear", list5Id)
          }
        }
      }
      let view = list.data.views[viewId]
      this.$store.commit("openedListAdd", {
        key: this.list5.space.id+"_"+listId+"_"+viewId,
        spaceId: this.list5.space.id,
        spaceName: this.list5.space.data.name,
        listId: listId,
        listName: list.data.name,
        viewId: viewId,
        viewName: view.data.name,
        upperListId: this.list5.listId,
        drilldownFilter: {fieldId: drilldownFilterFieldId, value: drilldownFilterValue}
      })
      window.history.pushState(
        {},
        null,
        "/list5/"+this.list5.space.id+"/"+listId+"/"+viewId
      )
    }
  }
}
</script>

<style scoped>

</style>