<template>
  <q-card>
    {{msg}}
    <q-btn @click="conform()">Conform authentication</q-btn>
  </q-card>
</template>

<script>
import { getAuth, isSignInWithEmailLink, EmailAuthProvider, linkWithCredential, signInWithEmailLink } from "firebase/auth";
const auth = getAuth();
export default {
  name: "UserAuthFinish",
  data: () => {
    return {
      msg: ""
    }
  },
  methods: {
    conform() {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt('Please provide your email for confirmation');
        }
        if(this.$store.state.user === null){
          // The client SDK will parse the code from the link for you.
          signInWithEmailLink(auth, email, window.location.href)
              .then((result) => {
                // Clear email from storage.
                window.localStorage.removeItem('emailForSignIn');
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser
                console.log(result)
                this.msg = "You are authorized. You can close this window and continue on previous window."
              })
              .catch((error) => {
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
                this.msg = error
              });
        }
        else{
          // Construct the email link credential from the current URL.
          const credential = EmailAuthProvider.credentialWithLink(
              email, window.location.href);

          linkWithCredential(auth.currentUser, credential)
              .then((usercred) => {
                // The provider is now successfully linked.
                // The phone user can now sign in with their phone number or email.
                this.msg = "You are authorized. You can close this window and continue on previous window."
                console.log(usercred)
              })
              .catch((error) => {
                // Some error occurred.
                this.msg = error
              });
        }

      }
    }
  }

}
</script>

<style scoped>

</style>