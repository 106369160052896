<template>
  <q-card _style="width: 400px; height: 600px">
    <q-card class="q-pa-md justify-center">
      <q-card-section>
        <div class="text-h6" style="text-align: center">Column</div>
      </q-card-section>
      <q-card-section>
        <q-tabs v-model="tab" active-color="green" indicator-color="primary" align="center">
          <q-tab name="general" label="General"/>
          <q-tab name="data" label="Data"/>
          <q-tab name="style" label="Style"/>
        </q-tabs>
      </q-card-section>
      <q-card-section class="flex justify-center">
        <q-scroll-area style="min-width: 300px; height: 400px">
          <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="general">
              <div style="_width: 250px">
                <q-input class="q-ma-sm" v-model="listField.name" label="Field name" dense color="green"/>
                <q-select class="q-ma-sm" v-model="listField.type" :options="typeItems" dense options-dense label="Type"
                          color="green" style="width: 150px"/>

                <q-checkbox class="q-ma-sm" v-model="listField.isFixedSublist" v-if="listField.type === 'sublist'"
                            label="Fixed sublist" color="green" dense/>
                <q-checkbox class="q-ma-sm" v-model="listField.isMultipleInput" :true-value="'X'" :false-value="''"
                            v-if="listField.type === 'sublist' || 'fixedsublist'" label="Multiple input" color="green" dense/>

                <q-select v-model="listField.listIdForSublist"
                          :options="listsForSublistOptions"
                          option-value="id"
                          option-label="name"
                          emit-value
                          map-options
                          v-if="listField.type === 'sublist' && !listField.isFixedSublist"
                          class="q-ma-sm" dense options-dense label="Sublist" color="green" style="width: 150px"
                />
                <q-select v-model="listField.viewIdForSublist"
                          :options="viewsForSublistOptions"
                          option-value="id"
                          option-label="name"
                          emit-value
                          map-options
                          v-if="listField.type === 'sublist' && !listField.isFixedSublist"
                          class="q-ma-sm" dense options-dense label="Sublist view" color="green" style="width: 150px"
                />
                <q-select v-model="listField.fieldIdForSublist"
                          :options="fieldsForSublistOptions"
                          option-value="id"
                          option-label="name"
                          emit-value
                          map-options
                          v-if="listField.type === 'sublist' && !listField.isFixedSublist"
                          class="q-ma-sm" dense options-dense label="Sublist field" color="green" style="width: 150px"/>
                <q-select v-model="listField.sublistBasedOnFieldId"
                          :options="sublistFieldsOptions"
                          option-value="id"
                          option-label="name"
                          emit-value
                          map-options
                          v-if="listField.type === 'sublist' && !listField.isFixedSublist"
                          class="q-ma-sm" dense options-dense label="Sublist based on field" color="green"
                          style="width: 150px"/>

                <sl-list-column-props-fixed-sublist :fixedSublist="listField.fixedSublist"
                                                    v-if="listField.type === 'sublist' && listField.isFixedSublist"/>

                <sl-list-column-props-fixed-sublist2 :fixedSublist="listField.fixedSublist" :fixedSublistOrder="listField.fixedSublistOrder"
                                                    v-if="listField.type === 'fixedsublist'"/>

                <q-select v-model="listField.drilldownListId"
                          :options="drilldownListOptions"
                          option-value="id"
                          option-label="name"
                          emit-value
                          map-options
                          v-if="listField.type === 'drilldown'"
                          class="q-ma-sm" dense options-dense label="Drilldown list id" color="green"
                          style="width: 150px"/>

                <q-checkbox class="q-ma-sm" v-model="listField.isRequired" label="Field is required to fill"
                            color="green" dense/>
                <q-checkbox class="q-ma-sm" v-model="listField.isUnique" label="Field is unique key" color="green"
                            dense/>
                <q-input class="q-ma-sm" v-model="listField.minimumFractionDigits" type="number"
                         v-if="listField.type === 'num'" label="Min fract digits" color="green" dense
                         style="max-width: 70px"/>
                <q-input class="q-ma-sm" v-model="listField.maximumFractionDigits" type="number"
                         v-if="listField.type === 'num'" label="Max fract digits" color="green" dense
                         style="max-width: 70px"/>

                <q-input class="q-ma-sm" v-model="listField.avatarImgFieldId" v-if="listField.type === 'avatar'"
                         label="Field id of avatar image" color="green" dense style="max-width: 70px"/>
                <q-input class="q-ma-sm" v-model="listField.avatarNameFieldId" v-if="listField.type === 'avatar'"
                         label="Field id of avatar name" color="green" dense style="max-width: 70px"/>

                <q-checkbox class="q-ma-sm" v-model="listField.isAuthField" label="is Authorization field?"
                            true-value="X" false-value="" color="green" dense/>
                <q-checkbox class="q-ma-sm" v-model="listField.isToCopyToAuth" label="copy to Authorization"
                            true-value="X" false-value="" color="green" dense/>


                <q-input class="q-ma-sm" v-model="listField.dateLocales" type="text" v-if="listField.type === 'date'"
                         label="Date locales" color="green" dense style="max-width: 70px"/>

                <q-input class="q-ma-sm" v-model="listField.maxLength" type="number"
                         v-if="listField.type === 'text' || listField.type === 'num'" label="Max length" color="green"
                         dense style="max-width: 70px"/>
                <q-checkbox class="q-ma-sm" v-model="listField.exactLength"
                            v-if="listField.type === 'text' || listField.type === 'num'" label="Exact length"
                            color="green" dense/>
                <q-checkbox class="q-ma-sm" v-model="listField.richText" label="Rich text"
                            v-if="listField.type === 'text'" color="green" dense/>
                <q-checkbox class="q-ma-sm" v-model="listField.showInSeparateWindow" label="Show in separate window"
                            v-if="listField.type === 'text'" color="green" dense/>

              </div>
            </q-tab-panel>
            <q-tab-panel name="data">
              <q-list dense>
                <q-item>
                  <q-item-section>Sorting:</q-item-section>
                  <q-item-section side>
                    <q-chip clickable
                            :color="viewField.sort==='none' ? 'grey' : 'green'"
                            text-color="white"
                            @click="sortSet()"
                    >{{ viewField.sort }}
                    </q-chip>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>Grouping:</q-item-section>
                  <q-item-section side>
                    <q-chip clickable
                            :color="viewField.group===false ? 'grey' : 'green'"
                            text-color="white"
                            @click="viewField.group=!viewField.group"
                    >{{ viewField.group ? 'Yes' : 'No' }}
                    </q-chip>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>Filter:</q-item-section>
                  <q-item-section side>
                    <q-chip clickable
                            :color="viewField.filter ? 'green' : 'grey'"
                            text-color="white"
                            @click="dialogListColumnFilterFormShow=true"
                    >{{ viewField.filter ? 'Yes' : 'No' }}
                    </q-chip>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>Calculation:</q-item-section>
                  <q-item-section side>
                    <q-chip clickable
                            :color="viewField.calc==='none' ? 'grey' : 'green'"
                            text-color="white"
                            @click="calcSet()"
                    >{{ viewField.calc }}
                    </q-chip>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-checkbox v-model="viewField.isSticked" label="Stick field" color="green"/>
                  </q-item-section>
                </q-item>
              </q-list>
              <div>
                <q-input class="q-ma-sm" v-model="listField.formula" label="formula" type="textarea"
                         style="background-color: #fafafa" dense color="green"/>
                <q-radio class="q-ma-sm" v-model="listField.formulaType" val="onSave" label="onSave" color="green"
                         dense/>
                <q-checkbox class="q-ma-sm" v-model="listField.onCreateOnly" label="On create only"
                            v-if="listField.formulaType === 'onSave'" color="green" dense/>
                <q-radio class="q-ma-sm" v-model="listField.formulaType" val="beforeDisplay" label="beforeDisplay"
                         color="green" dense/>
                <q-radio class="q-ma-sm" v-model="listField.formulaType" val="beforeDisplay" label="beforeDisplay"
                         color="green" dense/>
<!--                <sl-list-column-props-formula-order :list="list" :formulaOrders="listFormulaOrders"-->
<!--                                                    :fieldId="listField.id"/>-->
              </div>
              Agregator
              <div>
                <sl-list-column-props-aggregator :list="list" :fieldId="listField.id"
                                                 :aggregator="listField.aggregator"/>
              </div>
            </q-tab-panel>
            <q-tab-panel name="style">
              <q-expansion-item
                class="shadow-1 overflow-hidden"
                style="border-radius: 5px"
                label="Head cell style"
              >
                <q-card>
                  <q-card-section>
                    <sl-list-style-form :styleProps="viewField.headCellStyle" v-if="viewField.headCellStyle"/>
                  </q-card-section>
                </q-card>
              </q-expansion-item>
              <q-expansion-item
                class="shadow-1 overflow-hidden"
                style="border-radius: 5px"
                label="Head name style"
              >
                <q-card>
                  <q-card-section>
                    <sl-list-style-form :styleProps="viewField.headNameStyle" v-if="viewField.headNameStyle"/>
                  </q-card-section>
                </q-card>
              </q-expansion-item>
              <q-expansion-item
                class="shadow-1 overflow-hidden"
                style="border-radius: 5px"
                label="Item cell style"
              >
                <q-card>
                  <q-card-section>
                    <sl-list-style-form :styleProps="viewField.cellStyle" v-if="viewField.cellStyle"/>
                  </q-card-section>
                </q-card>
              </q-expansion-item>
              <q-expansion-item
                class="shadow-1 overflow-hidden"
                style="border-radius: 5px"
                label="Item string style"
              >
                <q-card>
                  <q-card-section>
                    <sl-list-style-form :styleProps="viewField.cellStringStyle" v-if="viewField.cellStringStyle"/>
                  </q-card-section>
                </q-card>
              </q-expansion-item>
              <q-expansion-item
                class="shadow-1 overflow-hidden"
                style="border-radius: 5px"
                label="Conditional item cell style"
              >
                <q-card>
                  <q-card-section>
                    <sl-list-style-form :styleProps="viewField.cellConditionalStyle" v-if="false"/>
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </q-tab-panel>
          </q-tab-panels>
        </q-scroll-area>
      </q-card-section>

      <q-card-actions align="center">
        <v-btn flat @click="fieldDelete()">Delete</v-btn>
        <v-btn flat @click="$emit('finish')">Cancel</v-btn>
        <v-btn flat @click="fieldSave()">Save</v-btn>
      </q-card-actions>
    </q-card>

    <q-dialog v-model="dialogColorPickerShow">
      <q-card>
        <q-color no-footer default-view="palette" class="my-picker"/>
        <v-card-actions>
          <q-btn @click="dialogColorPickerShow=false">ok</q-btn>
        </v-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogListColumnFilterFormShow" persistent>
      <sl-list-column-filter-form
        :list="list" :view="view" :viewField="viewField" :fieldId="fieldId"
        @finished="dialogListColumnFilterFormShow=false"
        v-if="dialogListColumnFilterFormShow"
      />
    </q-dialog>
  </q-card>
</template>

<script>
// todo: erase fixedSublist data if user switched on isFixedSublist, introduced data and later he switched off isFixedSublist
// todo: erase column props which value is "", false, [], {}
// todo: add filter for sublist: 1. filter of fixed value (e.g. sublist mast not has inactive values), 2. filter according to previously selected filed (e.g. previously selected field is country, next field must have cities from selected country only)

import {toRaw} from "vue";
import {currentUserId, db} from "@/db";
import {stylePropsListGet} from "@/utils3";
import SlListColumnPropsFixedSublist from "@/components/sl-list-column-props-fixed-sublist"

import SlListColumnFilterForm from "@/components/sl-list-column-filter-form"
import SlListStyleForm from "@/components/sl-list-style-form";
// import SlListColumnPropsFormulaOrder from "@/components/sl-list-column-props-formula-order";
import SlListColumnPropsAggregator from "@/components/sl-list-column-props-aggregator";
import {objStatusFieldsSet} from "@/utils4";
import SlListColumnPropsFixedSublist2 from "@/components/sl-list-column-props-fixed-sublist2";


export default {
  name: "sl-list-column-props-form",
  components: {
    SlListColumnPropsFixedSublist2,
    SlListColumnPropsAggregator,
    SlListStyleForm, SlListColumnFilterForm, SlListColumnPropsFixedSublist
  },
  props: ["list5Id", "fieldId", "list", "view"],
  // inject: ["currentUserPhoneNumber"],
  data: function () {
    return {
      currentUserId: currentUserId,
      tab: "general",
      typeItems: ['text', 'num', 'date', 'time', 'sublist', 'fixedsublist', 'formula', 'thumbnail', 'drilldown', 'checkbox', 'avatar', 'image', 'video', 'document', 'link'],
      dialogFieldDetailsShow: false,
      dialogColorPickerShow: false,
      dialogColorPickerDropdownListShow: false,
      dialogListColumnFilterFormShow: false,
      listField: {},
      listFormulaOrders: [],
      viewField: {},
      styleProps: {},
      // field: {
      //   id: "",
      //   name: "",
      //   fieldForDropdown: false,
      //   keyField: false,
      //   keyFieldName: false,
      //   keyFieldColor: "",
      //   listForDropdown: "",
      //   type: "text",
      //   length: 0,
      //   formula: "",
      //   widthDefault: 0,
      //   multiLineText: false,
      //   boldText: false,
      //   colorText: "rgb(0,0,0)",
      //   orderNum: 999,
      //   style: {
      //     fontWeight: 'normal',
      //     color: ''
      //   }
      // },
      // listFields: [],
      listsForSublist: [],
      listsForSublistOptions: [],
      fieldsForSublistOptions: [],
      viewsForSublistOptions: [],
      sublistFieldsOptions: [],
      drilldownListOptions: [],

    }
  },
  watch: {
    'listField.listIdForSublist': async function (newVal) {
      let i = this.listsForSublist.findIndex(list => list.id === newVal)
      if (i >= 0) {
        let listFields = this.listsForSublist[i].data.fields
        for (let fieldId in listFields) {
          // if(fieldId.charAt(0) === "f"){
          this.fieldsForSublistOptions.push({id: fieldId, name: listFields[fieldId].name})
          // }
        }
        // let listViews = await db.views.where("data.listId").equals(newVal).toArray()
        // debugger
        let listViews = this.listsForSublist[i].data.views
        for (let viewId in listViews) {
          this.viewsForSublistOptions.push({id: listViews[viewId].id, name: listViews[viewId].data.name})
        }
      }
    }
  },
  async created() {

    for (let drilldownListId in this.list.data.drilldownLists) {
      this.drilldownListOptions.push({
        id: drilldownListId,
        name: this.list.data.drilldownLists[drilldownListId].name
      })
    }

    for (let fieldId in this.list.data.fields) {
      if (this.list.data.fields[fieldId].type === "sublist") {
        this.sublistFieldsOptions.push({id: fieldId, name: this.list.data.fields[fieldId].name})
      }
    }

    this.styleProps = stylePropsListGet()
    this.listsForSublist = await db.lists.where("spaceId").equals(this.list.spaceId).toArray()
    this.listsForSublist.forEach(list => {
      this.listsForSublistOptions.push({id: list.id, name: list.data.name})
    })

    if (this.list.data.formulaOrders) this.listFormulaOrders = JSON.parse(JSON.stringify(this.list.data.formulaOrders))
    else this.listFormulaOrders = {}
    if (!this.listFormulaOrders.onCreate) this.listFormulaOrders.onCreate = []
    if (!this.listFormulaOrders.onSave) this.listFormulaOrders.onSave = []
    if (!this.listFormulaOrders.beforeDisplay) this.listFormulaOrders.beforeDisplay = []
    if (!this.listFormulaOrders.beforeDisplay) this.listFormulaOrders.beforeDisplay = []

    this.listField = JSON.parse(JSON.stringify(this.list.data.fields[this.fieldId]))
    if (!this.listField.listIdForSublist) this.listField.listIdForSublist = ""
    if (!this.listField.viewIdForSublist) this.listField.viewIdForSublist = ""
    if (!this.listField.fieldIdForSublist) this.listField.fieldIdForSublist = ""
    if (!this.listField.sublistBasedOnFieldId) this.listField.sublistBasedOnFieldId = ""
    if (!this.listField.isRequired) this.listField.isRequired = false
    if (!this.listField.isUnique) this.listField.isUnique = false
    if (!this.listField.showInSeparateWindow) this.listField.showInSeparateWindow = false
    if (!this.listField.maximumFractionDigits) this.listField.maximumFractionDigits = null
    if (!this.listField.minimumFractionDigits) this.listField.minimumFractionDigits = null
    if (!this.listField.dateLocales) this.listField.dateLocales = ""
    if (!this.listField.maxLength) this.listField.maxLength = ""
    if (!this.listField.exactLength) this.listField.exactLength = false
    if (!this.listField.richText) this.listField.richText = false
    if (!this.listField.isFixedSublist) this.listField.isFixedSublist = false
    if (!this.listField.fixedSublist) this.listField.fixedSublist = {}
    if (!this.listField.fixedSublistOrder) this.listField.fixedSublistOrder = []
    if (!this.listField.isMultipleInput) this.listField.isMultipleInput = ""
    if (!this.listField.isFormula) this.listField.isFormula = ""
    if (!this.listField.formula) this.listField.formula = ""
    if (!this.listField.formulaType) this.listField.formulaType = ""  //onSave; beforeFilter; beforeDisplay
    if (!this.listField.onCreateOnly) this.listField.onCreateOnly = false  //if onSave, there is an option
    if (!this.listField.macrosFormula) this.listField.macrosFormula = ""
    if (!this.listField.aggregator) this.listField.aggregator = {}
    if (!this.listField.avatarImgFieldId) this.listField.avatarImgFieldId = ""
    if (!this.listField.avatarNameFieldId) this.listField.avatarNameFieldId = ""
    //auth
    if (!this.listField.isAuthField) this.listField.isAuthField = "" // applied for List Auth only
    if (!this.listField.isToCopyToAuth) this.listField.isToCopyToAuth = ""
    if (!this.listField.drilldownListId) this.listField.drilldownListId = ""



    this.viewField = JSON.parse(JSON.stringify(this.view.data.fields[this.fieldId]))
    if (!this.viewField.headCellStyle) this.viewField.headCellStyle = {}
    if (!this.viewField.headNameStyle) this.viewField.headNameStyle = {}
    if (!this.viewField.cellStyle) this.viewField.cellStyle = {}
    if (!this.viewField.cellStringStyle) this.viewField.cellStringStyle = {}
    if (!this.viewField.cellConditionalStyle) this.viewField.cellConditionalStyle = []
    for (let styleProp in this.styleProps) {
      if (!this.viewField.headCellStyle[styleProp]) this.viewField.headCellStyle[styleProp] = ""
      if (!this.viewField.headNameStyle[styleProp]) this.viewField.headNameStyle[styleProp] = ""
      if (!this.viewField.cellStyle[styleProp]) this.viewField.cellStyle[styleProp] = ""
      if (!this.viewField.cellStringStyle[styleProp]) this.viewField.cellStringStyle[styleProp] = ""
      if (!this.viewField.cellConditionalStyle[styleProp]) this.viewField.cellConditionalStyle[styleProp] = ""
    }
    if (!this.viewField.sort) this.viewField.sort = "none"
    if (!this.viewField.group) this.viewField.group = false
    if (!this.viewField.filter) this.viewField.filter = null
    if (!this.viewField.calc) this.viewField.calc = "none"
    if (!this.viewField.isSticked) this.viewField.isSticked = false


    // let list = await db.lists.get(this.listId) //.where("id").equals(this.listId) //.toArray()
    // if(list['fields'] !== undefined){
    //   this.listFields = list['fields']
    // }
    // this.fieldSet()
    // let lists = await db.lists.where('spaceId').equals(this.list.spaceId).toArray()
    // for(let i=0; i<lists.length; i++){
    //   this.listsForDropdown.push({value: lists[i].id, label: lists[i].name})
    // }

  },
  methods: {
    sortSet() {
      if (this.viewField.sort === 'none') this.viewField.sort = "asc"
      else if (this.viewField.sort === 'asc') this.viewField.sort = "desc"
      else if (this.viewField.sort === 'desc') this.viewField.sort = "none"
    },
    calcSet() {
      if (this.viewField.calc === 'none') this.viewField.calc = "sum"
      else if (this.viewField.calc === 'sum') this.viewField.calc = "min"
      else if (this.viewField.calc === 'min') this.viewField.calc = "max"
      else if (this.viewField.calc === 'max') this.viewField.calc = "avg"
      else if (this.viewField.calc === 'avg') this.viewField.calc = "none"
    },
    async fieldSave() {
      // let that = this
      let listField = JSON.parse(JSON.stringify(this.listField)) // assign because deletion of prop before saving to db initiates errors
      let viewField = JSON.parse(JSON.stringify(this.viewField))

      for (let prop in listField) {
        if (listField[prop] === "" || listField[prop] === "none" || listField[prop] === false || listField[prop] === null) {
          delete listField[prop]
          continue
        }
        if (typeof listField[prop] === 'object') {
          if (Object.keys(listField[prop]).length === 0) {
            delete listField[prop]
            continue
          }
          if (prop === "aggregator") {
            if (listField.aggregator.listId2 === "") {
              delete listField.aggregator
              continue
            }
          }
        }
      }
      for (let prop in viewField) {
        if (viewField[prop] === "" || viewField[prop] === "none" || viewField[prop] === false || viewField[prop] === null) {
          delete viewField[prop]
        }
        if (typeof viewField[prop] === 'object') {
          if (Object.keys(viewField[prop]).length === 0) delete viewField[prop]
        }
      }

      if (listField.type === "num" && viewField.cellStyle.textAlign === "") viewField.cellStyle.textAlign = "right"
      if (listField.type === "date" && viewField.cellStyle.textAlign === "") viewField.cellStyle.textAlign = "right"
      if (listField.type === "time" && viewField.cellStyle.textAlign === "") viewField.cellStyle.textAlign = "right"
      if (listField.type === "text" && listField.showInSeparateWindow && viewField.cellStyle.textAlign === "") viewField.cellStyle.textAlign = "center"

      for (let prop in viewField.headCellStyle) if (viewField.headCellStyle[prop] === "") delete viewField.headCellStyle[prop]
      for (let prop in viewField.headNameStyle) if (viewField.headNameStyle[prop] === "") delete viewField.headNameStyle[prop]
      for (let prop in viewField.cellStyle) if (viewField.cellStyle[prop] === "") delete viewField.cellStyle[prop]
      for (let prop in viewField.cellStringStyle) if (viewField.cellStringStyle[prop] === "") delete viewField.cellStringStyle[prop]
      // for (let prop in that.viewField.cellStringStyle) if(prop === "") delete prop //

      this.$store.commit('listsList5ListFieldUpdate', {list5Id: this.list5Id, listField: toRaw(listField)})
      this.$store.commit('listsList5ViewFieldUpdate', {list5Id: this.list5Id, viewField: toRaw(viewField)})
      // this.$store.state.lists[this.list5Id].list.data.fields[that.fieldId] = toRaw(listField)
      // this.$store.state.lists[this.list5Id].list.data.views[that.view.id].data.fields[that.fieldId] = toRaw(viewField)


      // await db.lists.where('id').equals(this.list.id).modify(list => {
      //   list.data.fields[that.fieldId] = toRaw(listField)
      //   list.data.views[that.view.id].data.fields[that.fieldId] = toRaw(viewField)
      //   objStatusFieldsSet(list)
      // })
      // await db.views.where('id').equals(this.view.id).modify(view => view.data.fields[that.fieldId] = toRaw(viewField))

      // if (this.listFormulaOrders.onCreate.length === 0) delete this.listFormulaOrders.onCreate
      // if (this.listFormulaOrders.onSave.length === 0) delete this.listFormulaOrders.onSave
      // if (this.listFormulaOrders.beforeFilter.length === 0) delete this.listFormulaOrders.beforeFilter
      // if (this.listFormulaOrders.beforeDisplay.length === 0) delete this.listFormulaOrders.beforeDisplay
      // await db.lists.where('id').equals(this.list.id).modify(list => {
      //   list.data.formulaOrders = toRaw(this.listFormulaOrders)
      //   objStatusFieldsSet(list)
      // })
      this.$emit("finished")
    },
    fieldSet() {
      let that = this
      if (this.fieldId === undefined || this.fieldId === "") {
        this.field.id = "f" + this.listFields.length
        this.field.name = ""
        this.field.fieldForDropdown = false
        this.field.keyField = false
        this.field.keyFieldName = false
        this.field.keyFieldColor = ""
        this.field.type = "text"
        this.field.listForDropdown = ""
        this.field.length = 0
        this.field.formula = ""
        this.field.widthDefault = ""
        this.field.multiLineText = false
        this.field.boldText = false
        this.field.colorText = "rgb(0,0,0)"
        this.field.orderNum = 999
        this.field.style.fontWeight = 'normal'
        this.field.style.color = ''

      }
      else {
        let field = this.listFields.find(field => field.id === that.fieldId)
        this.field.id = field.id
        this.field.name = field.name
        this.field.fieldForDropdown = field.fieldForDropdown
        this.field.keyField = field.keyField
        this.field.keyFieldName = field.keyFieldName
        this.field.keyFieldColor = field.keyFieldColor
        this.field.type = field.type
        this.field.listForDropdown = field.listForDropdown
        this.field.length = field.length
        this.field.formula = field.formula
        this.field.widthDefault = field.widthDefault
        this.field.multiLineText = field.multiLineText
        this.field.boldText = field.boldText
        this.field.colorText = field.colorText
        this.field.orderNum = field.orderNum
        if (field.style) {
          this.field.style.fontWeight = field.style.fontWeight
          this.field.style.color = field.style.color
        }

      }
    },
    async fieldDelete() {
      let result = confirm("Do you really want to delete column?")
      if (result === false) return

      let fieldIndex = this.listFields.findIndex(field => field.id === this.fieldId)
      await db.lists.where('id').equals(this.listId).modify(list => {
        list.fields.splice(fieldIndex, 1)
        objStatusFieldsSet(list, this.currentUserId)
      })
      await db.items.where('listId').equals(this.listId).modify(function (item) {
        delete item.fields[this.fieldId]
        objStatusFieldsSet(item, this.currentUserId)
      })
      this.$emit("finish")

    }
  }
}
</script>

<style scoped>

</style>