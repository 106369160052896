<template>
  <q-card flat v-if="space!==undefined">
    <q-card-section class="row items-center">
      <div class="q-mx-sm">
        <q-avatar size="150px" color="grey-5" text-color="white" v-if="space.data.name">
          <img :src="space.data.avatarImg" v-if="space.data.avatarImg">
          <span v-else>{{space.data.name.charAt(0)}}</span>
        </q-avatar>
      </div>
      <div class="column q-pa-sm">
        <div style="font-size: 30px">{{space.data.name}}</div>
        <div class="bg-grey-2 q-mt-sm" style="min-width: 300px; max-width: 700px; min-height: 70px;">
          <span class="text-grey-7">{{space.data.description}}</span>
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <div class="row">
        <div class="column items-center q-ma-sm" style="width: 80px">
          <q-btn icon="edit" round @click="generalDataFormShow=true"/>
          <div class="text-center q-mt-sm">Edit general data</div>
        </div>
        <div class="column items-center q-ma-sm" style="width: 80px">
          <q-btn icon="cloud_upload" round @click="publishFormShow=true" :disable="space.status!=='unpublished'"/>
          <div class="text-center q-mt-sm">Publish space</div>
        </div>
        <div class="column items-center q-ma-sm" style="width: 80px">
          <q-btn icon="group" round @click="usersListsCreate()"/>
          <div class="text-center q-mt-sm">Create user lists</div>
        </div>
        <div class="column items-center q-ma-sm" style="width: 80px">
          <q-btn icon="contact_phone" @click="usersDataGet()" round/>
          <div class="text-center q-mt-sm">Get users avatar and name</div>
        </div>
        <div class="column items-center q-ma-sm" style="width: 80px">
          <q-btn icon="cloud_upload" round @click="reuploadAllSpaceData()"/>
          <div class="text-center q-mt-sm">Reupload all space data</div>
        </div>
        <div class="column items-center q-ma-sm" style="width: 80px">
          <q-btn icon="delete" round/>
          <div class="text-center q-mt-sm" @click="del()">Delete space</div>
        </div>
      </div>
    </q-card-section>


    <q-card-section>
      <q-checkbox v-model="space.status" label="Published" disable color="green"
                  :true-value="space.status==='unpublished'?'':space.status"
                  :false-value="'unpublished'"
      />
      <q-checkbox v-model="space.$data.isEncrypted" label="Encrypted" disable color="green"
                  true-value="X"
                  false-value=""
      />
      <q-input v-model="space.cryptoKey" label="Crypto key"
               :readonly="cryptoKeyReadonly" color="green"
               v-if="space.$data.isEncrypted"
               style="max-width: 600px"
      >
        <template v-slot:prepend>
          <q-icon name="lock" color="orange" />
        </template>
        <template v-slot:append>
          <q-btn round dense flat icon="edit" @click="cryptoKeyReadonly=false" v-if="cryptoKeyReadonly"/>
          <q-btn round dense flat icon="save" @click="cryptoKeySave()" v-if="!cryptoKeyReadonly"/>
        </template>
      </q-input>
    </q-card-section>
    <q-card-section>
      <q-input v-model="space.data.uploadDataDelay" label="Upload data delay"
               :readonly="uploadDataDelayReadonly" color="green"
               style="max-width: 250px"
               type="number"
      >
        <template v-slot:prepend>
          <q-icon name="lock" color="orange" />
        </template>
        <template v-slot:append>
          <q-btn round dense flat icon="edit" @click="uploadDataDelayReadonly=false" v-if="uploadDataDelayReadonly"/>
          <q-btn round dense flat icon="save" @click="uploadDataDelaySave()" v-else/>
        </template>

      </q-input>
    </q-card-section>



<!--    <q-card-section v-if="space.status==='unpublished'">-->
<!--      <q-btn @click="publishFormShow=true">Publish</q-btn>-->
<!--    </q-card-section>-->
<!--    <q-card-section v-if="space.data.type === 'private'">-->
<!--      Crypto key: {{space.localState.cryptoKey}}-->
<!--      <q-btn @click="inputCryptoKey"-->
<!--             v-if="(space.localState.cryptoKey === '' || !space.localState.cryptoKey) && space.localState.verifyStatus === 'cryptoErr'"-->
<!--      >-->
<!--        Input crypto key-->
<!--      </q-btn>-->
<!--    </q-card-section>-->

    <sl-msgs :msgs="msgs" v-if="msgs.length>0" @finished="msgs=[]"/>
    <q-dialog v-model="publishFormShow">
      <q-card>
        <q-card-section v-if="$store.state.currentUser.userId === ''">
          To publish space you need to set or authorize user
          <q-btn @click="userAuthFormShow=true">Set or Authorize user</q-btn>
        </q-card-section>
        <q-card-actions class="justify-center" v-else>
          <q-btn @click="publish('public')">Publish as public</q-btn>
          <q-btn @click="publish('encrypted')">Publish with encryption</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="nameFormShow">
      <q-card>
        <q-card-section>
          <q-input v-model="spaceName" label="Space name"/>
        </q-card-section>
        <q-card-actions class="justify-center">
          <q-btn flat @click="nameFormShow=false">Cancel</q-btn>
          <q-btn flat @click="nameSave()">Save</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="generalDataFormShow" >
      <sl-space-general-data-form
          :spaceId="spaceId"
          :name="space.data.name"
          :publicName="space.$data.publicName"
          :description="space.data.description"
          :avatarImg="space.data.avatarImg"
          v-if="generalDataFormShow"
          @finished="generalDataFormShow=false"
      />
    </q-dialog>
  </q-card>
</template>

<script>
import {currentUserId, db} from "@/db"
// import EncryptRsa from 'encrypt-rsa'
// const encryptRsa = new EncryptRsa()
import {
  generateKey,
  encrypt,
  decrypt,
  encryptShared,
  decryptShared,
  generateKeyPair,
  sharedKeys,
  generateId
} from "@/utils"
// import SlSpaceUsers from "@/components/sl-space-users";
// import SlSpaceAuthGroups from "@/components/sl-space-auth-groups";
import {useObservable} from "@vueuse/rxjs/index";
import {liveQuery} from "dexie";
// import SlUserAuthForm from "@/components/sl-user-auth-form";
import {listCreate, obj, objStatusFieldsSet} from "@/utils4";
import SlSpaceGeneralDataForm from "@/components/sl-space-general-data-form";
import SlMsgs from "@/components/sl-msgs";
import {uploadAllSpaceData} from "@/db-sync";

export default {
  name: "sl-space",
  components: {SlMsgs, SlSpaceGeneralDataForm},
  // components: {SlUserAuthForm, SlSpaceAuthGroups, SlSpaceUsers},
  props: ["spaceId"],
  // inject: ["currentUserPhoneNumber"],
  data: ()=>{
    return{
      //space: { data: {} },
      msgs: [],
      generalDataFormShow: false,
      publishFormShow: false,
      userAuthFormShow: false,
      nameFormShow: false,
      spaceName: "",
      cryptoKeyReadonly: true,
      uploadDataDelayReadonly: true

    }
  },
  setup(props) {

    //const listId = toRef(props, 'listId')
    //const
    // eslint-disable-next-line no-debugger
    // debugger
    return {
      db,
      space: useObservable(
          // liveQuery(() => db.lists.get(props.listId).fields)
          liveQuery(() => db.spaces.get(props.spaceId))
      )
    }
  },
  async created() {
    //this.space = await db.spaces.get(this.spaceId)
    //debugger
  },
  watch: {
    // spaceId: async function (newVal){
      //this.space = await db.spaces.get(newVal)
    // }
  },
  methods: {
    cryptoKeySave(){
      db.spaces.where("id").equals(this.space.id).modify(space => {
        space.cryptoKey=this.space.cryptoKey
        this.cryptoKeyReadonly = true
      })
    },
    createKeys(){
      // const { privateKey, publicKey } = encryptRsa.createPrivateAndPublicKeys();
      // console.log(privateKey)
      // console.log(publicKey)

      const key = generateKey();
      // const obj = { "hello": "world" };
      const obj = "hello world";
      const encrypted = encrypt(obj, key);
      const decrypted = decrypt(encrypted, key);
      console.log(decrypted, obj); // should be shallow equal
      console.log(encrypted, key);
      console.log(key);

      const pairA = generateKeyPair();
      const pairB = generateKeyPair();
      const sharedA = sharedKeys(pairB.publicKey, pairA.secretKey);
      const sharedB = sharedKeys(pairA.publicKey, pairB.secretKey);
      const encrypted2 = encryptShared(sharedA, obj);
      const decrypted2 = decryptShared(sharedB, encrypted2);
      console.log(obj, encrypted2, decrypted2);

    },
    publish(type){
      let that = this
      db.spaces.where('id').equals(this.spaceId).modify(space => {
        space.$data.userIds.push(currentUserId)
        space.$data.adminIds.push(currentUserId)
        if(type === "public"){
          space.$data.isEncrypted = ""
        }
        if(type === "encrypted"){
          space.cryptoKey = generateKey()
          space.$data.isEncrypted = "X"
        }
        space.status = "changed"
        space.$data.version = 1
        objStatusFieldsSet(space)
      }).then(()=>{
        that.publishFormShow = false
      })
    },
    inputCryptoKey(){

    },
    nameFormOpen(){
      this.spaceName = this.space.data.name
      this.nameFormShow = true
    },
    nameSave(){
      let that = this
      db.spaces.where("id").equals(this.spaceId).modify(space => {
        space.data.name = that.spaceName
        // space.status = "changed"
        // space.$data.lastTS = Date.now()
        // space.$data.lastUserId = that.$store.state.currentUserId
        objStatusFieldsSet(space)
        that.nameFormShow = false
      })
    },
    async del(){
      //checks
      let lists = await db.lists.where("spaceId").equals(this.spaceId).toArray()
      if(lists.length > 0) {
        this.msgs.push("Detele lists first")
        return
      }
      db.spaces.delete(this.spaceId)
      this.$router.push({ name: 'overview'})
    },
    usersListsCreate(){
      debugger
      if(this.space.data.zListIds){
        if(this.space.data.zListIds.usersListId !== "") return
      }
      let usersListId = generateId()
      let usersViewId = generateId()
      let authsListId = generateId()
      let authsViewId = generateId()
      let authUserGroupsListId = generateId()
      let authUserGroupsViewId = generateId()
      let tablesListId = generateId()
      let tablesViewId = generateId()

      listCreate({
        spaceId: this.spaceId,
        listId: usersListId,
        viewId: usersViewId,
        listName: "*Users",
        currentUserId: currentUserId
      }).then(()=>{
        db.lists.where("id").equals(usersListId).modify(list => {
          list.data.fields.z31 = {id: "z31", name: "Phone number", type: "text"}
          list.data.fields.z32 = {id: "z32", name: "User name", type: "text"}
          list.data.fields.z33 = {id: "z33", name: "User type", type: "fixedsublist", fixedSublist: [
              { value: "owner", style: {
                  backgroundColor: "#ffe4b6", color: "#e57c01", padding: "2px 7px", borderRadius: "15px", cursor: "pointer",
                  boxShadow:"0 0 8px lightgrey", display: "inline", whiteSpace: "nowrap"
                }
              },
              { value: "admin", style: {
                backgroundColor: "#ccffcc", color: "#00a300", padding: "2px 7px", borderRadius: "15px", cursor: "pointer",
                  boxShadow:"0 0 8px lightgrey", display: "inline", whiteSpace: "nowrap"
                }
              },
              { value: "user", style: {
                  backgroundColor: "#ccffff", color: "#00a3a3", padding: "2px 7px", borderRadius: "15px", cursor: "pointer",
                  boxShadow:"0 0 8px lightgrey", display: "inline", whiteSpace: "nowrap"
                }
              }
            ]}  // admin  or user
          list.data.fields.z34 = {id: "z34", name: "Avatar image", type: "thumbnail"}
          list.data.fields.z35 = {id: "z35", name: "Display name", type: "text"}
          list.data.fields.z36 = {id: "z36", name: "Update name from user data", type: "checkbox"}
          list.data.fields.z37 = {id: "z37", name: "Update status", type: "text"} // text info: date, time and status
          // list.data.fields.z38 = {id: "z38", name: "Auth user groups", type: "sublist", isMultipleInput: true,
          //   listIdForSublist: authUserGroupsListId,
          //   viewIdForSublist: authUserGroupsViewId,
          //   fieldIdForSublist: "z51"
          // }
          list.data.fields.f1.name = "Details/comments"

          let listDefaultAdminView = list.data.views[list.data.defaultAdminViewId].data
          listDefaultAdminView.fields.z31 = obj({id: "z31"}, "viewDataField")
          listDefaultAdminView.fields.z32 = obj({id: "z32"}, "viewDataField")
          listDefaultAdminView.fields.z33 = obj({id: "z33"}, "viewDataField")
          listDefaultAdminView.fields.z34 = obj({id: "z34"}, "viewDataField")
          listDefaultAdminView.fields.z34.cellStringStyle.borderRadius = "50px"
          listDefaultAdminView.fields.z34.cellStringStyle.width = "50px"
          listDefaultAdminView.fields.z34.cellStringStyle.height = "50px"
          listDefaultAdminView.fields.z35 = obj({id: "z35"}, "viewDataField")
          listDefaultAdminView.fields.z35.cellStringStyle.fontWeight = "bold"
          listDefaultAdminView.fields.z36 = obj({id: "z36"}, "viewDataField")
          listDefaultAdminView.fields.z37 = obj({id: "z37"}, "viewDataField")
          // listDefaultAdminView.fields.z38 = obj({id: "z38"}, "viewDataField")
          listDefaultAdminView.fieldsOrder = ['z1', 'z2', 'z35', 'z36','z33', 'z38', 'z34', 'z31', 'z32', 'z37', 'f1', 'z3']
        })
      })
      listCreate({
        spaceId: this.spaceId,
        listId: authUserGroupsListId,
        viewId: authUserGroupsViewId,
        listName: "*Auth user groups",
        currentUserId: this.$store.state.currentUser.userId
      }).then(()=>{
        db.lists.where("id").equals(authUserGroupsListId).modify(list => {
          list.data.fields.z51 = {id: "z51", name: "Auth group (name)", type: "text"}
          list.data.fields.z52 = {id: "z52", name: "External users", type: "checkbox"}
          list.data.fields.f1.name = "Details/comments"

          list.data.views[authUserGroupsViewId].data.fields.z51 = obj({id: "z51"}, "viewDataField")
          list.data.views[authUserGroupsViewId].data.fields.f1 = obj({id: "f1"}, "viewDataField")
          list.data.views[authUserGroupsViewId].data.fields.z51.cellStringStyle.backgroundColor = "grey"
          list.data.views[authUserGroupsViewId].data.fields.z51.cellStringStyle.color = "white"
          list.data.views[authUserGroupsViewId].data.fields.z51.cellStringStyle.padding = "2px 7px"
          list.data.views[authUserGroupsViewId].data.fields.z51.cellStringStyle.borderRadius = "15px"
          list.data.views[authUserGroupsViewId].data.fields.z51.cellStringStyle.cursor = "pointer"
          list.data.views[authUserGroupsViewId].data.fields.z51.cellStringStyle.boxShadow = "0 0 8px lightgrey"
          list.data.views[authUserGroupsViewId].data.fields.z51.cellStringStyle.display = "inline"
          list.data.views[authUserGroupsViewId].data.fields.z51.cellStringStyle.whiteSpace = "nowrap"
          list.data.views[authUserGroupsViewId].data.fields.z52 = obj({id: "z52"}, "viewDataField")
          list.data.views[authUserGroupsViewId].data.fields.f1.cellStyle.width = "250px"
          list.data.views[authUserGroupsViewId].data.fieldsOrder = ['z1', 'z2', 'z51', 'z52', 'f1', 'z3']
        })
      })
      listCreate({
        spaceId: this.spaceId,
        listId: authsListId,
        viewId: authsViewId,
        listName: "*Auths",
        currentUserId: this.$store.state.currentUser.userId
      }).then(()=>{
        db.lists.where("id").equals(authsListId).modify(list => {
          list.data.fields.z41 = {id: "z41", name: "User", type: "sublist",
            listIdForSublist: usersListId,
            viewIdForSublist: usersViewId,
            fieldIdForSublist: "z35"
          }
          list.data.fields.z42 = {id: "z42", name: "Auth user group", type: "sublist",
            listIdForSublist: authUserGroupsListId,
            viewIdForSublist: authUserGroupsViewId,
            fieldIdForSublist: "z51"
          }
          list.data.fields.z43 = {id: "z43", name: "Lists/Items", type: "sublist", isFixedSublist: "X", fixedSublist: [
              { value: "lists", style: {
                  backgroundColor: "grey", color: "white", padding: "2px 7px", borderRadius: "15px", cursor: "pointer",
                  boxShadow:"0 0 8px lightgrey", display: "inline", whiteSpace: "nowrap"
              }},
              { value: "items", style: {
                  backgroundColor: "grey", color: "white", padding: "2px 7px", borderRadius: "15px", cursor: "pointer",
                  boxShadow:"0 0 8px lightgrey", display: "inline", whiteSpace: "nowrap"
              }},
          ]} // lists or items
          list.data.fields.z44 = {id: "z44", name: "Can create", type: "checkbox"}
          list.data.fields.z45 = {id: "z45", name: "Can change", type: "checkbox"}
          list.data.fields.z46 = {id: "z46", name: "Can delete", type: "checkbox"}
          list.data.fields.f1.name = "Details/comments"

          list.data.views[authsViewId].data.fields.z41 = obj({id: "z41"}, "viewDataField")
          list.data.views[authsViewId].data.fields.z42 = obj({id: "z42"}, "viewDataField")
          list.data.views[authsViewId].data.fields.z43 = obj({id: "z43"}, "viewDataField")
          list.data.views[authsViewId].data.fields.z44 = obj({id: "z44"}, "viewDataField")
          list.data.views[authsViewId].data.fields.z45 = obj({id: "z45"}, "viewDataField")
          list.data.views[authsViewId].data.fields.z46 = obj({id: "z46"}, "viewDataField")
          list.data.views[authsViewId].data.fields.f1 = obj({id: "f1"}, "viewDataField")
          list.data.views[authsViewId].data.fieldsOrder = ['z1', 'z2', 'z41', 'z42', 'z43', 'z44', 'z45', 'z46', 'f1', 'z3']
        })
      })
      listCreate({
        spaceId: this.spaceId,
        listId: tablesListId,
        viewId: tablesViewId,
        listName: "*Tables",
        currentUserId: this.$store.state.currentUser.userId
      }).then(()=>{
        db.lists.where("id").equals(tablesListId).modify(list => {
          list.data.fields.z61 = {id: "z61", name: "Table id", type: "text"}
          list.data.fields.z62 = {id: "z62", name: "Table name", type: "text"}
          list.data.fields.f1.name = "Details/comments"
          list.data.views[tablesViewId].data.fields.z61 = obj({id: "z61"}, "viewDataField")
          list.data.views[tablesViewId].data.fields.z62 = obj({id: "z62"}, "viewDataField")
          list.data.views[tablesViewId].data.fieldsOrder = ['z1', 'z2', 'z61', 'z62', 'f1', 'z3']
        })
        let tables = [
          {tableId: usersListId, name: "*Users"},
          {tableId: authsListId, name: "*Auths"},
          {tableId: authUserGroupsListId, name: "*Auth user groups"},
          {tableId: tablesListId, name: "*Tables"}
        ]
        tables.forEach(table => {
          let item = obj({}, "item")
          item.$data.spaceId = this.space.id
          item.data.listId = tablesListId
          item.data.fields.z61 = table.tableId
          item.data.fields.z62 = table.name
          db.items.put(item)
        })
      })
      debugger
      db.spaces.where("id").equals(this.spaceId).modify(space => {
        debugger
        space.data.zListIds = {
          usersListId: usersListId,
          authsListId: authsListId,
          authUserGroupsListId: authUserGroupsListId,
          tablesListId: tablesListId
        }
        space.data.folders.push({name: "Reference lists", listIds: [usersListId]})
        space.data.folders.push({name: "Technical lists", listIds: [authsListId, authUserGroupsListId, tablesListId]})
        objStatusFieldsSet(space)
      })

    },
    usersDataGet(){
      let that = this
      this.$store.dispatch("userDataGet", this.spaceId).then(()=>{
        that.msgs.push({type: "s", text: "User names and avatars are updated"})
      })
    },
    reuploadAllSpaceData(){
      uploadAllSpaceData(this.spaceId)
    },
    uploadDataDelaySave(){
      db.spaces.where("id").equals(this.spaceId).modify(space => {
        space.data.uploadDataDelay = this.space.data.uploadDataDelay
        objStatusFieldsSet(space, this.$store.state.currentUser.userId)
      }).then(() => this.uploadDataDelayReadonly = true)
    }
  }
}
</script>

<style scoped>


</style>