<template>
  <div class="gantt-chart">
    <svg :width="chartWidth" :height="chartHeight">
      <!-- Рисуем индикаторы недель -->
      <g v-for="week in weeks" :key="week" class="week-indicator-group">
        <line :x1="getXPosition(week.start)" :y1="chartMarginTop" :x2="getXPosition(week.start)" :y2="chartHeight - chartMarginBottom" class="week-indicator" />
        <text :x="getXPosition(week.start) + (getXPosition(week.end) - getXPosition(week.start)) / 2" :y="chartMarginTop - 5" class="week-label">{{ week.label }}</text>

      </g>
      <!-- Рисуем индикатор текущего дня -->
      <line :x1="getXPosition(currentDate)" :y1="chartMarginTop" :x2="getXPosition(currentDate)" :y2="chartHeight - chartMarginBottom" class="current-day-indicator" />

      <!-- Рисуем задачи -->
      <g v-for="task in tasks" :key="task.id">
        <rect :x="getXPosition(task.start)" :y="getYPosition(task.id)" :width="getTaskWidth(task)" :height="taskHeight" class="gantt-task" :fill="getTaskColor(task)" />
        <rect :x="getXPosition(task.start)" :y="getYPosition(task.id)" :width="getTaskWidth(task) / 2" :height="taskHeight" class="gantt-task-done" :fill="getTaskDoneColor(task)" />
        <text :x="getXPosition(task.start) + 5" :y="getYPosition(task.id) - 10" class="task-label" :fill="getTaskLabelColor(task)">{{ task.name }}</text>

        <g v-if="task.id === 2">
          <!-- Рисуем подзадачи для задачи 2 -->
          <rect v-for="subtask in task.subtasks" :key="subtask.id" :x="getXPosition(subtask.start)" :y="getYPosition(task.id) + taskHeight + subtaskOffset" :width="getTaskWidth(subtask)" :height="taskHeight" class="gantt-task" :fill="subtaskColor" />
          <text v-for="subtask in task.subtasks" :key="subtask.id" :x="getXPosition(subtask.start) + 5" :y="getYPosition(task.id) + taskHeight * 1.5 + subtaskOffset" class="task-label" fill="white">{{ subtask.name }}</text>
        </g>
      </g>

    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tasks: [
        {
          id: 1,
          name: 'Задача 1',
          start: new Date('2023/06/26'),
          end: new Date('2023/06/28')
        },
        {
          id: 2,
          name: 'Задача 2',
          start: new Date('2023-06-29'),
          end: new Date('2023-07-05'),
          subtasks: [
            {
              id: 1,
              name: 'Подзадача 1',
              start: new Date('2023-06-29'),
              end: new Date('2023-07-01')
            },
            {
              id: 2,
              name: 'Подзадача 2',
              start: new Date('2023-07-02'),
              end: new Date('2023-07-04')
            }
          ]
        },
        {
          id: 3,
          name: 'Задача 3',
          start: new Date('2023-07-06'),
          end: new Date('2023-07-10')
        }
      ],
      chartWidth: 600,
      chartHeight: 300,
      chartMarginTop: 20,
      chartMarginBottom: 40,
      taskHeight: 10,
      weekIndicatorWidth: 1,
      subtaskOffset: 50,
      taskColor: 'lightgrey',
      subtaskColor: 'orange',
      currentDate: new Date() // Текущая дата
    };
  },
  computed: {
    earliestDate() {
      return Math.min(...this.tasks.map(task => task.start.getTime()));
    },
    latestDate() {
      return Math.max(...this.tasks.map(task => task.end.getTime()));
    },
    weeks() {
      const weeks = [];
      let currentDate = new Date(this.earliestDate);
      while (currentDate <= this.latestDate) {
        const weekStart = new Date(currentDate);
        if (weekStart.getDay() !== 1) {
          weekStart.setDate(weekStart.getDate() - (weekStart.getDay() - 1));
        }
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);
        weeks.push({
          start: weekStart,
          end: weekEnd,
          label: `Week ${this.getWeekNumber(weekStart)}`
        });
        currentDate.setDate(currentDate.getDate() + 7);
      }
      return weeks;
    }
  },
  methods: {
    getXPosition(date) {
      const totalDuration = this.latestDate - this.earliestDate;
      const taskDuration = date - this.earliestDate;
      const chartWidth = this.chartWidth - 10;
      return (taskDuration / totalDuration) * chartWidth + 5;
    },
    getYPosition(taskId) {
      return this.chartMarginTop + taskId * (this.taskHeight + this.subtaskOffset);
    },
    getTaskWidth(task) {
      const taskDuration = task.end - task.start;
      const totalDuration = this.latestDate - this.earliestDate;
      const chartWidth = this.chartWidth - 10;
      return (taskDuration / totalDuration) * chartWidth;
    },
    getWeekNumber(date) {
      const onejan = new Date(date.getFullYear(), 0, 1);
      return Math.ceil(((date - onejan) / 86400000 + onejan.getDay() + 6) / 7);
    },
    getTaskColor(task) {
      return task.id === 2 ? this.taskColor : '#b4e1b4';
    },
    getTaskDoneColor(task) {
      return task.id === 2 ? this.taskColor : '#34a634';
    },
    getTaskLabelColor(task) {
      return task.id === 2 ? 'black' : 'black';
    }
  }
};
</script>

<style>
.gantt-chart {
  border: 1px solid #ccc;
  height: 100%;
}

.gantt-task {
  stroke: none;
  rx: 5px;
  ry: 5px;
  filter: drop-shadow(0 0 5px darkgray)
}
.gantt-task-done {
  stroke: none;
  rx: 5px;
  ry: 5px;
}

.task-label {
  font-size: 12px;
  text-anchor: start;
  _dominant-baseline: middle;
  x: 5px; /* Смещение текста относительно начала задачи */
  y: 5px;
}

.week-indicator-group {
  text-anchor: middle;
}

.week-indicator {
  stroke: #f2f2f2;
  stroke-width: 1px;
}

.week-label {
  font-size: 12px;
}

.current-day-indicator {
  stroke: #fd5050;
  stroke-width: 1px;
}
</style>


<!--<template>-->
<!--  <div class="gantt-chart">-->
<!--    <div class="gantt-header">-->
<!--      <div class="gantt-week" v-for="(week, index) in weeks" :key="index">-->
<!--        {{ week }}-->
<!--      </div>-->
<!--    </div>-->
<!--    <div-->
<!--      class="gantt-row"-->
<!--      v-for="(task, index) in tasks"-->
<!--      :key="index"-->
<!--      draggable="true"-->
<!--      @dragstart="onDragStart(task, index)"-->
<!--      @dragover="onDragOver"-->
<!--      @drop="onDrop"-->
<!--    >-->
<!--      <div class="gantt-bar" :style="getTaskStyle(task)"></div>-->
<!--      <div class="gantt-label">{{ task.name }}</div>-->
<!--    </div>-->
<!--    <div class="current-day-indicator" :style="getCurrentDayIndicatorStyle()"></div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      tasks: [-->
<!--        { name: 'Task 1', start: new Date('2023-06-01'), end: new Date('2023-06-05') },-->
<!--        { name: 'Task 2', start: new Date('2023-06-03'), end: new Date('2023-06-07') },-->
<!--        { name: 'Task 3', start: new Date('2023-06-06'), end: new Date('2023-06-10') },-->
<!--        // Добавьте здесь дополнительные задачи-->
<!--      ],-->
<!--      chartWidth: 600, // Ширина диаграммы-->
<!--      unitWidth: 40, // Ширина единицы времени-->
<!--      startDate: new Date('2023-06-01'), // Дата начала диаграммы-->
<!--      currentDate: new Date(), // Текущая дата-->
<!--      weeks: [], // Массив с неделями-->
<!--      draggingTaskIndex: null,-->
<!--      draggingStartDate: null,-->
<!--      draggingEndDate: null,-->
<!--    };-->
<!--  },-->
<!--  mounted() {-->
<!--    this.generateWeeks();-->
<!--  },-->
<!--  methods: {-->
<!--    generateWeeks() {-->
<!--      const startDate = new Date(this.startDate);-->
<!--      const currentDate = new Date(this.currentDate);-->
<!--      const diffTime = Math.abs(currentDate - startDate);-->
<!--      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));-->
<!--      const numOfWeeks = Math.ceil(diffDays / 7);-->

<!--      for (let i = 0; i < numOfWeeks; i++) {-->
<!--        const weekStartDate = new Date(startDate);-->
<!--        weekStartDate.setDate(weekStartDate.getDate() + i * 7);-->
<!--        const weekEndDate = new Date(weekStartDate);-->
<!--        weekEndDate.setDate(weekEndDate.getDate() + 6);-->
<!--        const weekLabel = `${weekStartDate.toLocaleDateString()} - ${weekEndDate.toLocaleDateString()}`;-->
<!--        this.weeks.push(weekLabel);-->
<!--      }-->
<!--    },-->
<!--    getTaskStyle(task) {-->
<!--      const startDiff = Math.abs(task.start - this.startDate);-->
<!--      const left = (startDiff / (1000 * 60 * 60 * 24)) * this.unitWidth;-->
<!--      const width = ((task.end - task.start) / (1000 * 60 * 60 * 24)) * this.unitWidth;-->
<!--      return {-->
<!--        left: `${left}px`,-->
<!--        width: `${width}px`,-->
<!--      };-->
<!--    },-->
<!--    getCurrentDayIndicatorStyle() {-->
<!--      const startDate = new Date(this.startDate);-->
<!--      const currentDate = new Date(this.currentDate);-->
<!--      const diffTime = Math.abs(currentDate - startDate);-->
<!--      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));-->
<!--      const left = diffDays * this.unitWidth;-->
<!--      return {-->
<!--        left: `${left}px`,-->
<!--      };-->
<!--    },-->
<!--    onDragStart(task, index) {-->
<!--      this.draggingTaskIndex = index;-->
<!--      this.draggingStartDate = task.start;-->
<!--      this.draggingEndDate = task.end;-->
<!--    },-->
<!--    onDragOver(event) {-->
<!--      event.preventDefault();-->
<!--    },-->
<!--    onDrop(event) {-->
<!--      event.preventDefault();-->
<!--      const dropIndex = event.target.getAttribute('key');-->
<!--      const draggedTask = this.tasks[this.draggingTaskIndex];-->
<!--      const dropDateDiff = Math.floor(event.offsetX / this.unitWidth);-->
<!--      const newStartDate = new Date(this.draggingStartDate);-->
<!--      newStartDate.setDate(newStartDate.getDate() + dropDateDiff);-->
<!--      const newEndDate = new Date(newStartDate);-->
<!--      const duration = (this.draggingEndDate - this.draggingStartDate) / (1000 * 60 * 60 * 24);-->
<!--      newEndDate.setDate(newEndDate.getDate() + duration);-->
<!--      draggedTask.start = newStartDate;-->
<!--      draggedTask.end = newEndDate;-->
<!--      this.tasks.splice(this.draggingTaskIndex, 1);-->
<!--      this.tasks.splice(dropIndex, 0, draggedTask);-->
<!--      this.draggingTaskIndex = null;-->
<!--      this.draggingStartDate = null;-->
<!--      this.draggingEndDate = null;-->
<!--    },-->
<!--  },-->
<!--};-->
<!--</script>-->

<!--<style scoped>-->
<!--.gantt-chart {-->
<!--  position: relative;-->
<!--  height: 200px;-->
<!--  border: 1px solid #ccc;-->
<!--}-->

<!--.gantt-header {-->
<!--  display: flex;-->
<!--}-->

<!--.gantt-week {-->
<!--  flex: 1;-->
<!--  text-align: center;-->
<!--  padding: 5px;-->
<!--  border-right: 1px solid #ccc;-->
<!--  font-size: 12px;-->
<!--}-->

<!--.gantt-row {-->
<!--  position: relative;-->
<!--  height: 40px;-->
<!--  margin-bottom: 10px;-->
<!--}-->

<!--.gantt-bar {-->
<!--  position: absolute;-->
<!--  height: 100%;-->
<!--  background-color: #428bca;-->
<!--}-->

<!--.gantt-label {-->
<!--  position: absolute;-->
<!--  top: 10px;-->
<!--  left: 5px;-->
<!--  font-size: 14px;-->
<!--}-->

<!--.current-day-indicator {-->
<!--  position: absolute;-->
<!--  top: 0;-->
<!--  bottom: 0;-->
<!--  width: 1px;-->
<!--  background-color: red;-->
<!--  opacity: 0.5;-->
<!--}-->
<!--</style>-->
