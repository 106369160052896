<template>
  <tr v-if="subtotals?.vals && subtotals?.level > 0">
    <sl-list5-subtotal-cell v-for="rFieldId in view?.data.fieldsOrder" :key="rFieldId"
                            :subtotals="subtotals" :rFieldId="rFieldId" :isOpened="isOpened" :list5Id="list5Id"
                            @openCloseClk="isOpened=!isOpened"
    />
  </tr>
  <template v-if="isOpened">
    <sl-list5-subtotal-row v-for="row in subtotals.subTotals0Arr" :key="'k_'+row.key" :subtotals="row" :list5-id="list5Id"/>
    <sl-list5-rows :list5-id="list5Id"
                   :subtotals-fields="subtotals.fields"
                   :subtotals-fieldsS="subtotals.fieldsS"
                   :subtotals-fieldsFS="subtotals.fieldsFS"
                   v-if="subtotals.subTotals0Arr.length===0"
    />
  </template>
    <tr v-if="subtotals?.vals && subtotals?.level === 0">
    <template v-for="rFieldId in view?.data.fieldsOrder" :key="rFieldId">
      <td v-if="subtotals.vals[rFieldId]" :style="{borderBottom: '1px solid lightgrey'}" class="bg-yellow-lighten-2 font-weight-bold">
        {{subtotals.vals[rFieldId]}}
      </td>
      <td v-else :style="{borderBottom: '1px solid lightgrey'}" class="bg-yellow-lighten-2 font-weight-bold">
      </td>
    </template>
  </tr>
</template>

<script>
import SlList5Rows from "@/components/sl-list5-rows";
import SlList5SubtotalCell from "@/components/sl-list5-subtotal-cell";
export default {
  name: "sl-list5-subtotal-row",
  components: {SlList5SubtotalCell, SlList5Rows},
  props: ["subtotals", "list5Id"],
  data: () => {
    return {
      isOpened: false
    }
  },
  created() {
    if (this.subtotals.level === 0) this.isOpened = true
  },
  computed: {
    list(){
      return this.$store.state.lists[this.list5Id]?.list
    },
    view(){
      return this.$store.state.lists[this.list5Id]?.list.data.views[this.$store.state.lists[this.list5Id]?.viewId]
    }
  },
}
</script>

<style scoped>

</style>