<template>
  <q-card>
    editor
  </q-card>
</template>

<script>
export default {
  name: "sl-list-cell-form-editor"
}
</script>

<style scoped>

</style>